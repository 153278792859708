/* eslint-disable */
import { EllipseShape } from "zrender";
import axios from "../../../api/http";
class ProjectTile {
  //根据earth构造
  constructor(earth, project) {
    //打包之后，这个配置为空就行
    // this._server = "http://localhost:3001";
    // WEBPACK_SERVER_URL配置在vue.config.js中，可以在config中指定特定的字符串。 vtxf 20210520
    this._server = WEBPACK_SERVER_URL;
    if (this._server == "") {
      this._server = window.origin;
    }
    console.log(`ProjectTile server: ${this._server}`);

    //解析当前页面地址
    this.tileset = new XE.Obj.Tileset(earth);
    this.project = project;
    this.tileset.url = this.url();
    this.tileset.colorBlendMode = "Cesium3DTileColorBlendMode.REPLACE";
    this.featureSelected = new Cesium.Event();
    const picking = earth.interaction.picking;

    this._earth = earth;
    //监控
    this.unAutorun = XE.MVVM.watch(
      () => picking.clickedObj,
      () => {
        const pickedObject = picking.clickedObj && picking.clickedAttachedInfo;

        if (pickedObject instanceof Cesium.Cesium3DTileFeature) {

          if (pickedObject.tileset === this.tileset._tileset) {
            //发出对象点击事件
            //console.log(pickedObject);

            console.log(pickedObject.content.tile._contentResource._url);

            var names = pickedObject._content.batchTable.getPropertyNames(
              pickedObject._batchId
            );
            var obj = {};
            names.forEach((n) => {
              obj[n] = pickedObject.getProperty(n);
            });
            //this._onObjectClicked(obj);
            setTimeout(() => {
              this.selectFeature(obj, true);
              this._selected = obj;
            }, 30);
          }
        }
      }
    );
    this.colorStyle = "colorDefault";
    this.refreshLayerConfig();
  }

  _allTilesLoaded() {
    if (!this._selectedTileset || !this._selectedTileset.root) return;

    //并且这个对象显示边界
    if (!this._stage) {
      this._stage = Cesium.PostProcessStageLibrary.createSilhouetteStage();
      this._earth._viewer.postProcessStages.add(this._stage);
      this._stage.uniforms.color.green = 1;
    }
    if (this._selectedTileset.root._content) {
      this._stage.selected = [this._selectedTileset.root._content._model];
      // return;
    } else {
      console.log("this.seleced empty content", this._selected);
    }

    // //"319KX816"
    // if(this._selectedTileset.root._content)
    // {

    // }
    // else{
    //     console.log('this.seleced empty content',this._selected);
    // }

    //并且当前tileset里隐藏这个id（避免重叠）
    this._updateStyle();

    //发送选中事件
    // this.featureSelected.raiseEvent(this._selected);
    //精确定位
    if (!this.nofly)
      this._earth.czm.camera.flyToBoundingSphere(
        this._selectedTileset.boundingSphere
      );
  }
  selectFeature(obj, nofly) {
    this._destroySelected();
    //根据id构造一个3dtiles，等待这个3dtiles的数据加载之后，
    this._selectedTileset = new Cesium.Cesium3DTileset({
      url: this.featureUrl(obj.id),
    });
    //添加到cesium场景
    this._earth.czm.viewer.scene.primitives.add(this._selectedTileset);
    this._selected = obj;
    //监控加载数据
    this._selectedTileset.allTilesLoaded.addEventListener(
      this._allTilesLoaded,
      this
    );
    //
    this.nofly = nofly;
    // 这个飞行不能少，否则不加载
    if (!nofly) this._earth.czm.viewer.flyTo(this._selectedTileset);
    this.featureSelected.raiseEvent(this._selected);
  }

  _destroySelected() {
    if (this._stage) {
      this._earth._viewer.postProcessStages.remove(this._stage);
      this._stage.destroy();
      this._stage = undefined;
    }
    //销毁选中的 tileset
    if (this._selectedTileset) {
      //停止监控事件，避免对象被销毁后，进入到回调中
      this._selectedTileset.allTilesLoaded.removeEventListener(
        this._allTilesLoaded,
        this
      );
      this._earth.czm.viewer.scene.primitives.remove(this._selectedTileset);

      this._selectedTileset.destroy();
      this._selectedTileset = undefined;
    }
    //并且
    this._selected = undefined;
  }

  unSelectFeature(id) {
    if (this._selected && (id === this._selected.id || id == undefined)) {
      this._destroySelected();
      this._updateStyle();
    }
  }
  // _onObjectClicked(obj) {
  //     console.log(obj);
  //     if (!obj)
  //         return;
  //     if (obj.id.indexOf('_') < 0) {
  //         this.pointClicked.raiseEvent(obj);
  //     } else {
  //         this.lineClicked.raiseEvent(obj);
  //     }
  // }

  reload() {
    this.tileset.url = this.url();
    this.unSelectFeature();
    console.log("reload");
  }

  featureUrl(id) {
    return (
      this._server +
      "/featuretiles/" +
      this.project +
      "/" +
      id +
      ".json?t=" +
      new Date().getTime() +
      "&token=" +
      window.localStorage.getItem("token")
    );
  }
  url() {
    //打包后需要换成下面的
    //return window.location.origin + "/3dtiles/" + this.project + "/tileset.json?t=" + new Date();

    return (
      this._server +
      "/3dtiles/" +
      this.project +
      "/tileset.json?t=" +
      new Date().getTime() +
      "&token=" +
      window.localStorage.getItem("token")
    );
  }

  flyToRange(west, south, minz, east, north, maxz) {
    return new Promise((resolve, reject) => {
      var camera = this._earth.czm.camera;
      var points = [
        Cesium.Cartesian3.fromDegrees(west, south, minz),
        Cesium.Cartesian3.fromDegrees(east, north, maxz),
      ];
      camera.flyToBoundingSphere(Cesium.BoundingSphere.fromPoints(points), {
        complete: () => {
          resolve();
        },
      });
    });
  }

  //根据服务器端的配置来刷新
  refreshLayerConfig() {
    //请求图层配置
    if (this.project) {
      axios
        .get("/project/info/layers", { params: { projectid: this.project } })
        .then((res) => {
          //遍历获取所有图层的可见性，以及颜色配置
          this.setLayersConfig(res.data.data.layers);
        })
        .catch((ex) => {
          console.log(ex);
        });
    }
  }

  //实景样式
  colorDefault() {
    this.colorStyle = "colorDefault";
    this._updateStyle();
  }
  //按照图层着色的样式
  colorByLayer() {
    this.colorStyle = "colorByLayer";
    this._updateStyle();
  }
  //按照图层混色的样式
  colorByLayerMixed() {
    this.colorStyle = "colorByLayerMixed";
    this._updateStyle();
  }

  _recursionLayer(configs, parentShow) {
    if (!configs || !configs.length) return [];
    var ret = [];
    configs.forEach((c) => {
      var show = parentShow && c.checked;
      //文件夹
      if (c.children) {
        var sret = this._recursionLayer(c.children, show);
        //合并结果
        ret = ret.concat(sret);
      }
      //图层
      else if (c.config) {
        ret.push({
          id: c.id,
          config: c.config,
          show: show,
        });
      }
    });
    return ret;
  }

  setLayersConfig(configs) {
    //递归获取所有图层配置
    var layers = this._recursionLayer(configs, true);

    //更新可见性配置
    this.showConditions = "";
    layers.forEach((l) => {
      if (this.showConditions != "") this.showConditions += ",";
      this.showConditions += `["\${tuceng} === '${l.id}'", "${l.show}"]`;
    });

    //更新图层颜色配置
    this.colorConditions = "";
    layers.forEach((l) => {
      if(l.config.color != 'rgba(0,0,0,0)'){
        if (this.colorConditions != "") this.colorConditions += ",";
        this.colorConditions += `["\${tuceng} === '${l.id}'", "${l.config.color}"]`;
      }
    });

    //立即更新可见性
    this._updateStyle();
  }

  //更新内部的style
  _updateStyle() {
    //判断颜色模式
    if (this.colorStyle === "colorByLayer")
      this.tileset.colorBlendMode = "Cesium3DTileColorBlendMode.REPLACE";
    else this.tileset.colorBlendMode = "Cesium3DTileColorBlendMode.HIGHLIGHT";

    var selectedHide;
    if (this._selected) {
      selectedHide = `["\${id} === '${this._selected.id}'", "false"]`;
    }

    //对于可见性的配置
    var show;
    if (this.showConditions && selectedHide) {
      show = `show:{conditions:[${selectedHide},${this.showConditions},["true", "true"]]}`;
    } else if (this.showConditions) {
      show = `show:{conditions:[${this.showConditions},["true", "true"]]}`;
    } else if (selectedHide) {
      show = `show:{conditions:[${selectedHide},["true", "true"]]}`;
    }

    //对于颜色的配置
    var color;
    var defaultColor = '["true", "rgb(255, 255, 255)"]';
    //默认颜色配置
    if (this.colorStyle === "colorDefault") {
      //如果有选中的对象
      if (this.elementCondition)
        color = `color:{conditions:[${this.elementCondition},${defaultColor}]}`;
    }
    //按照layer设置的颜色条件
    else if (this.colorConditions) {
      //如果有选中的对象
      if (this.elementCondition)
        color = `color:{conditions:[${this.elementCondition},${this.colorConditions},${defaultColor}]}`;
      else
        color = `color:{conditions:[${this.colorConditions},${defaultColor}]}`;
    }

    //组织style
    var style = `var style = {`;

    if (show && color) {
      style += `${show},${color}`;
    } else if (show) {
      style += `${show}`;
    } else if (color) {
      style += `${color}`;
    }

    style += "}";

    //console.log(style);

    this.tileset.xbsjStyle = style;
  }

  createLabel(content) {
    let p = new XE.Obj.Plots.GeoPin(this._earth);
    p.innerHTML = `<!DOCTYPE HTML>
<html>

<head>
<title></title>
<style>
@keyframes scale {
    0% {
        transform: scale(1)
    }

    50%,
    75% {
        transform: scale(3)
    }

    78%,
    100% {
        opacity: 0
    }
}

@keyframes scales {
    0% {
        transform: scale(1)
    }

    50%,
    75% {
        transform: scale(2)
    }

    78%,
    100% {
        opacity: 0
    }
}
body{
    --BGColor:white;
}
.smallcircle {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color:var(--BGColor);
    opacity: 0.8;
    border-radius: 50%;
    top: -4px;
    left: -6px;
}

.smallcircle:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: .4;
    background-color: var(--BGColor);
    opacity: 0.8;
    animation: scale 1s infinite cubic-bezier(0, 0, .49, 1.02);
}

.bigcircle {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: .4;
    background-color: var(--BGColor);
    opacity: 0.8;
    animation: scales 1s infinite cubic-bezier(0, 0, .49, 1.02);
    top: -4px;
    left: -6px;
}
.contentLabel {
    position: absolute;
    top: -60px;
    left: ${content.length * -5}px;
    width: ${content.length * 10}px;
    height: 20px;
    text-align: center;
    color:rgb(31, 255, 255);
    background-color: rgba(50, 50, 50, 0.5);
    border-radius: 2px;
}

.lineLabel {
    position: absolute;
    top: -40px;
    left: -1px;
    width: 1px;
    height: 36px;
    border-left: 2px solid rgb(31, 255, 255);
}
</style>
</head>

<body> 
<div class="smallcircle"></div>
<div class="bigcircle"></div>
${content && content.length > 0
        ? `<div class="contentLabel">${content}</div>
<div class="lineLabel"></div>`
        : ""
      }
</body>

</html>`;
    return p;
  }
}
export default ProjectTile;

<template>
    <Model :title="'测量'" :width="384" :height="36" @cancel="cancel">
        <div class="measure">
            <span :class="{selected:index==1}" @click="measurementType='POINT',index=1">点位</span>
            <span :class="{selected:index==2}" @click="measurementType='SPACE_DISTANCE',index=2">距离</span>
            <span :class="{selected:index==3}" @click="disGroudMeasure(),index=3">贴地距离</span>
            <span :class="{selected:index==4}" @click="measurementType='TRIANGLE_DISTANCE',index=4">高度</span>
            <span :class="{selected:index==5}" @click="measurementType='SPACE_AREA' ,index=5">面积</span>
            <span @click="clear(),index=6">清除</span>
        </div>
    </Model>

</template>

<script>
    /* eslint-disable */
    import Model from "../../../components/Model"
    import {
        getDisAndLabelPos
    } from "../scripts/utils/measure";
    export default {
        data() {
            return {
                measurementType: "NONE",
                disGroudinterval: 0,
                disGroudMeasureing: true,
                index: 1,
            }
        },
        components: {
            Model
        },
        mounted() {
            this.data = this.$root._earth.analyzation.measurement
            this._disposers = this._disposers || [];
            this._disposers.push(
                XE.MVVM.bind(
                    this,
                    "measurementType",
                    this.$root._earth.analyzation,
                    "measurement.type"
                )
            );
            this.measurementType = 'POINT'
            this._labels = [];
            this._temGeometry = [];
            this._temPrimitive = [];
            this._disGroudLabels = [];
            this._disGroud = []
            this._creating = [];
        },

        methods: {
            ok() {
                this.clear()
                this.$root.destroyTool(this);
            },
            cancel() {
                this.clear()
                this.$bus.emit("cancelMeasure")
                this.$root.destroyTool(this);

            },
            clear() {
                this.clearResults()
                this.disGroudMeasureing = false;
                if (this._disGroud.positions) {
                    this._disGroud.positions = []
                    this.updateMeasure(this._disGroud.positions);
                }
            },

            disGroudMeasure() {
                // this.disGroudinterval = 0;
                if (this.measurementType !== "SPACE_DIS_GROUD") {
                    this._disGroud = new XE.Obj.Plots.GeoPolyline(this.$root._earth);
                    console.dir(this._disGroud)
                    this._disGroud.color = [1, 1, 1, 1]
                    this._disGroud.width = 2.5
                    this._disGroud.creating = true;
                    this.updateCreatingBind();
                    this._creating.push(
                        XE.MVVM.bind(this, "disGroudMeasureing", this._disGroud, "creating")
                    );
                    this._creating.push(
                        XE.MVVM.watch(
                            () => ({
                                positions: [...this._disGroud.positions]
                            }),
                            () => {
                                this.updateMeasure(this._disGroud.positions);
                            }
                        )
                    );
                    this._temGeometry.push(this._disGroud);
                }
            },
            updateCreatingBind() {
                this._creating.forEach(d => d());
                this._creating = [];
                let self = this;
                if (this._labels.length > 0) {
                    this._labels.forEach(l => {
                        self._temGeometry.push(l);
                    });
                    this._labels = [];
                    this.measurementType = "NONE";
                }
            },
            updateMeasure(p) {
                this._disGroudLabels.forEach(l => l.destroy());
                this._disGroudLabels = [];
                if (p.length >= 1) {
                    var it = this.disGroudinterval;
                    var result = getDisAndLabelPos(p, it, this.$root._earth);
                    if (!result) {
                        return;
                    }
                    this._result = result;
                    var labels = result.label;

                    labels.forEach(l => {
                        var lb = this.createLabel(l);
                        this._disGroudLabels.push(lb);
                    });
                }
            },
            createLabel(option) {
                let p = new XE.Obj.Plots.GeoPin(this.$root._earth);
                p.innerHTML =
                    '<div style="cursor:pointer;position: absolute;width:300px;left:6px; line-height:15px;color: white;">' +
                    '<span style="padding:2px;border-radius: 2px;text-stroke:2px #000;font-size: 14px;color:#ffffff;background-color: rgba(50,50,50,0.5)">' +
                    option.dis +
                    "</span></div>";
                p.position = option.pos;
                p._pin.show = false;
                return p;
            },
            clearResults() {
                console.log("清除")
                this.$root._earth.analyzation.measurement.clearResults();
                this.$root._earth.analyzation.cutFillComputing.clearResults();
                this.$root._earth.analyzation.cutFillComputing.positions = [];
                this.cutFillComputingShow = false;
                if (this._temGeometry) {
                    this._temGeometry.forEach(e => {
                        e.destroy();
                    });
                    this._temGeometry = [];
                }
                if (this._temPrimitive) {
                    this._temPrimitive.forEach(e => {
                        this.$root._earth.czm.scene.primitives.remove(e);
                    });
                }
                this.measurementType = "NONE";
                this.$root._earth.analyzation.cutFillComputingOld.clearResults();
            },
        }
    }
</script>

<style scoped>
    .measure {
        display: flex;
        height: 36px;
        align-items: center;
        justify-content: space-around;
        color: #cccccc;
    }

    .measure>span {
        width: 55px;
        height: 20px;
        text-align: center;
        cursor: pointer;
    }

    .selected {
        background: #288DFB;
    }

    .measure>span:hover {
        background: #295f99;
    }

    .measure>span:active {
        background: #288DFB;
    }

    .btn_span:active {
        background: #288DFB;
        box-shadow: -3px -2px 3px 3px #123f70;
        opacity: 1;
    }
</style>>
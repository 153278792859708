<template>
    <Window :footervisible="true" @ok="ok" @cancel="cancel" :height="420" :width="500" :title="'在线模型切片服务'"
        class="xbsj-onlineModel">
        <div class="onlineModelDiv">
            <label>服务地址:</label>
            <input v-model="selectedUrl" />
        </div>
        <div v-if="selected!=null">
            <div v-show="selected.requireField" class="onlineModelDiv">
                <label>{{selected.requireField }}:</label>
                <input v-model="requireValue" />
            </div>
        </div>
        <ul class="flexul" :class="showContent ? 'contentUl' : ''">
            <li v-for="s in services" @click="select(s)" :key="s.url">
                <div :class="[s.cls,{highlight:selected == s} ]" class="backimg">
                    <img style="width:64px;height:64px;" :src="s.thumbnail" alt="">
                </div>
                <div class="onlinModelName">
                    {{s.cnname}}
                    <span class="onlineModelToolTip">{{s.cnname}}</span>
                </div>
            </li>
        </ul>
    </Window>
</template>

<script>
    /* eslint-disable */
    import Window from "../../../../components/Window"
    export default {
        components: {
            Window
        },
        data() {
            return {
                error: "",
                selected: null,
                requireValue: "",
                selectedUrl: "",
                showContent: false,
                lang: {},
                services: [],
            };
        },
        created() {},
        watch: {},
        props: {
            getBind: Boolean,
            default () {
                false
            }
        },
        mounted() {
            this.getonlineinfo();
        },
        methods: {
            getonlineinfo() {
                this.$axios
                    .get("/system/service/3dtiles")
                    .then(res => {
                        this.services = res.data.data.result;
                    })
                    .catch(err => {
                        console.log(err.response || err);
                    });
            },
            select(service) {
                this.selected = service;
                //因为界面可能修改这个值，所以需要单独一个变量
                this.selectedUrl = this.selected.url;
                this.requireValue = "";
                this.error = "";
                this.showContent = true;
            },
            ok() {
                if (this.selectedUrl == "") {
                    //提示需要弹出url
                } else {
                    //构造 tileset
                    const tileset = new XE.Obj.Tileset(this.$root._earth);
                    tileset.url = this.selectedUrl;

                    //添加到场景树中
                    this.$root._earthVue.addSceneObject(
                        tileset,
                        this.selected ? this.selected.cnname : '未命名瓦片'
                    );

                    this.$root.destroyTool(this);
                    this.error = "";
                }
            },
            cancel() {
                this.$root.destroyTool(this);
            }
        },
        computed: {},
        beforeUnmount() {
            if (this.unbind) {
                this.unbind();
                this.unbind = undefined;
            }
        }
    };
</script>

<style scoped>
    /* .xbsj-onlineModel {
        position: absolute;
        top: 20px;
        left: 30px;
        z-index: 9999;
    } */

    /* .xbsj-onlineModel .google_image {
        background: url("../../../images/services/google_image.jpg") !important;
    }

    .xbsj-onlineModel .google_map {
        background: url("../../../images/services/google_map.png") !important;
    } */

    .xbsj-onlineModel .error {
        color: red;
    }

    .xbsj-onlineModel .flexul {
        /* display: flex;
  flex-wrap: wrap; */
        cursor: pointer;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 48px);
    }

    .xbsj-onlineModel .contentUl {
        overflow-x: hidden;
        height: calc(100% - 48px);
    }

    .xbsj-onlineModel .flexul li {
        list-style: none;
        float: left;
        position: relative;
    }

    .xbsj-onlineModel .backimg {
        width: 64px;
        height: 64px;
        border: 2px solid;
        border-radius: 4px;
        background: gray;
        margin: 5px;
    }

    .xbsj-onlineModel .backimg.highlight,
    .xbsj-onlineModel .backimg:hover {
        border: 2px solid rgba(31, 255, 255, 1);
    }

    .xbsj-onlineModel .onlineModelDiv {
        margin: 10px 10px;
    }

    .xbsj-onlineModel .onlineModelDiv>label {
        display: inline-block;
        width: 60px;
        text-align: right;
        margin-right: 15px;
    }

    .xbsj-onlineModel .onlineModelDiv>input {
        width: calc(100% - 100px);
        height: 28px;
        background: #383838;
        border: 1px solid #4D4D4D;
        border-radius: 3px;
        border: none;
        color: #ccc;
        padding: 0 5px;
    }

    .xbsj-onlineModel .flexul li .onlinModelName {
        display: inline-block;
        width: 64px;
        text-align: center;
        margin-left: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .xbsj-onlineModel .flexul li .onlinModelName .onlineModelToolTip {
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.8);
        color: #CCCCCC;
        text-align: center;
        padding: 5px 5px;
        border-radius: 3px;
        /* 定位 */
        position: absolute;
        z-index: 1;
        left: 10px;
        bottom: 30px;
    }

    .xbsj-onlineModel .flexul li .onlinModelName:hover .onlineModelToolTip {
        visibility: visible;
    }
</style>

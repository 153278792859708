<template>
    <div class="addPoint">
        <label v-show="creating">请单击地球，选择管点添加位置</label>
        <div v-show="!creating" class="editor">
            <label>经度:</label>
            <input v-model.number="position[0]" v-number-fixed="6">
            <label>纬度:</label>
            <input v-model.number="position[1]" v-number-fixed="6">
            <label>高度:</label>
            <input v-model.number="position[2]" v-number-fixed="2">
            <button @click="cancel">取消</button>
            <button @click="ok">确定</button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Window from '../../../components/Window'
export default {
	components: {
		Window
	},
	data() {
		return {
			position: [0, 0, 0],
			creating: false
		}
	},
	mounted() {
		this._position = this.createLabel()
		this._position.creating = true
		this._disposers = []
		this._disposers.push(XE.MVVM.bind(this, 'creating', this._position, 'creating'))

		this._disposers.push(XE.MVVM.bindPosition(this, 'position', this._position, 'position'))

		this._disposers.push(
			XE.MVVM.watch(
				() => this._position.creating,
				() => {
					this._position.editing = !this._position.creating
				}
			)
		)
	},
	methods: {
		createLabel(option) {
			let p = new XE.Obj.Plots.GeoPin(this.$root._earth)
			p.innerHTML = `<!DOCTYPE HTML>
<html>

<head>
    <title></title>
    <style>
        @keyframes scale {
            0% {
                transform: scale(1)
            }

            50%,
            75% {
                transform: scale(3)
            }

            78%,
            100% {
                opacity: 0
            }
        }

        @keyframes scales {
            0% {
                transform: scale(1)
            }

            50%,
            75% {
                transform: scale(2)
            }

            78%,
            100% {
                opacity: 0
            }
        }
        body{
            --BGColor:white;
        }
        .smallcircle {
            position: absolute;
            width: 12px;
            height: 12px;
            background-color:var(--BGColor);
            opacity: 0.8;
            border-radius: 50%;
            top: -4px;
            left: -6px;
        }

        .smallcircle:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            opacity: .4;
            background-color: var(--BGColor);
            opacity: 0.8;
            animation: scale 1s infinite cubic-bezier(0, 0, .49, 1.02);
        }

        .bigcircle {
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            opacity: .4;
            background-color: var(--BGColor);
            opacity: 0.8;
            animation: scales 1s infinite cubic-bezier(0, 0, .49, 1.02);
            top: -4px;
            left: -6px;
        }
    </style>
</head>

<body> 
    <div class="smallcircle"></div>
    <div class="bigcircle"></div>
</body>

</html>`
			return p
		},
		cancel() {
			this.creating = true
		},
		ok() {
			let data = {
				projectid: this.$root.projectid,
				lon: this.position[0],
				lat: this.position[1],
				height: this.position[2]
			}
			this.$axios
				.post('/project/point/add', data)
				.then(res => {
					console.log(res)
					//恢复到初始状态
					this.creating = true
					if (res.data.data && res.data.data === true) {
						this.$root._tileset.reload()
					}
				})
				.catch(err => {
					this.$root.$alert(err.data.message)
				})
		}
	},
	beforeUnmount() {
		this._disposers.forEach(d => d())
		this._disposers.length = 0
		this._position.destroy()
	}
}
</script>

<style scoped>
.addPoint {
	flex-grow: 1;
	height: 40px;
	flex-grow: 1;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.editor {
	padding-top: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

input {
	width: 80px;
	background: #383838;
	border: 1px solid #4d4d4d;
	opacity: 1;
	border-radius: 2px;
	color: #ccc;
}

button {
	height: 20px;
	margin-left: 20px;
	margin-right: 20px;
}
</style>
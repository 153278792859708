<template>
  <div class="pipepointquery">
    <div class="chaxunline">
      <div class="condition">

        <div class="row_single">
          <label>ID </label>
          <input type="text"
            v-model="formData.id"
            @keyup.enter="requery" />
        </div>
        <div class="row_single"
          v-for="(item, index) in formData.conditions"
          :key="index">
          <label> {{ customConditions[item.column_name] }}</label>
          <input v-show="item.column_type == 'NUMBER' || item.column_type == 'NUMBER'"
            :class="
              switchType(item.column_type).type == 'checkbox'
                ? 'bty-switch'
                : 'none'
            "
            :type="switchType(item.column_type).type"
            :step="
              switchType(item.column_type).step
                ? switchType(item.column_type).step
                : null
            "
            v-model="item.column_data"
            @keyup.enter="requery" />
          <input v-show="
              !(item.column_type == 'NUMBER' || item.column_type == 'NUMBER')
            "
            :type="switchType(item.column_type).type"
            :step="
              switchType(item.column_type).step
                ? switchType(item.column_type).step
                : null
            "
            v-model="item.min"
            @keyup.enter="requery" />
          <label v-show="
              !(item.column_type == 'NUMBER' || item.column_type == 'NUMBER')
            ">~</label>
          <input v-show="
              !(item.column_type == 'NUMBER' || item.column_type == 'NUMBER')
            "
            :type="switchType(item.column_type).type"
            :step="
              switchType(item.column_type).step
                ? switchType(item.column_type).step
                : null
            "
            v-model="item.max"
            @keyup.enter="requery" />
        </div>

      </div>
      <div class="row_single operation">
        <button class="odpoint"
          @click="odLine"
          v-show="!showdelete">
          绘制查询范围
        </button>
        <button class="xbsj-button-tab"
          @click="clear"
          v-show="showdelete">
          清空
        </button>
        <button @click="requery"
          class="xbsj-button-tab queryclick">
          查询
        </button>
      </div>
    </div>
    <div class="tablePages">
      <div class="taNamepoint">
        <table cellspacing="3">
          <tr>
            <th>
              <label><input type="checkbox"
                  v-model="checked"
                  @change="changeAllChecked" />全选</label><br />
              <label>
                <input type="checkbox"
                  v-model="unchecked"
                  @change="unChangeAllChecked" />反选</label>
            </th>
            <th v-for="(item, index) in thearder"
              :key="index"
              @click="sort(item)"
              title="升序/降序">
              {{ item.name }}{{ item.descasc }}
            </th>
          </tr>
          <tr class="everyone"
            v-for="(item, index) in infos"
            :key="index"
            @dblclick="locate(item)">
            <td>
              <input type="checkbox"
                :value="item.id"
                v-model="checkedNames" />
            </td>
            <td>{{ item.id }}</td>
            <td>{{ item.height }}</td>
            <td>{{ item.dimiangaocheng }}</td>

          </tr>
        </table>
      </div>
      <div class="pagepoint">
        <div>
          <label><input type="checkbox"
              v-model="checkedAll"
              @change="checkedAllP" />全选{{ count }}个建筑
          </label>
          <button @click="updateDingCaiZhiPop">修改顶面材质</button>
          <button @click="updateCeCaiZhiPop">修改侧面材质</button>
          <button @click="updataParameter">修改参数</button>
          <button @click="deleteBuild">删除</button>
          <button @click="refreshData">重构数据</button>
        </div>

        <div>
          <span class="upDown"
            @click="upPage">上一页</span>
          <span class="pageNumber">{{ formData.page }}/{{ pageNumber }}页</span>
          <span class="upDown"
            @click="downPage">下一页</span>
          <span><span class="upDown"
              @click="runPage">跳转</span>
            <input v-model.number="formData.page"
              @keyup.enter="query" />页</span>
        </div>
      </div>
      <Window :title="'修改建筑参数'"
        :footervisible="true"
        v-show="flagcangshu"
        @ok="ok"
        @cancel="cancel"
        :width="300"
        :height="180">

        <div class="row_single">
          <label>高度:</label><input type="number"
            v-model="height" />
        </div>
        <div class="row_single">
          <label>底面高程:</label><input type="number"
            v-model="dimiangaocheng" />
        </div>
      </Window>
    </div>
  </div>
</template>
<script>
//import Buildeditor from "./Buildeditor";
import MaterialSelect from "./MaterialSelect";
import Window from "../../../components/Window"
import utils from "../math/utils"
/* eslint-disable */
export default {
  components: {
    Window,
    MaterialSelect,
    //Buildeditor
  },
  data() {
    return {
      flagcangshu: false,
      creating: false,
      unchecked: false,
      checked: false,
      showdelete: false,
      polylineData: [],
      flag: "",
      count: 0,
      checkedAll: false,
      checked: false,
      checkedNames: [],
      thearder: [{
        zhname: "id",
        name: "id",

      },
      {
        zhname: "height",
        name: "高度",

      },
      {
        zhname: "dimiangaocheng",
        name: "底面高程",

      }
      ],
      infos: "",
      itemsid: [],
      pageNumber: 1,
      formData: {
        id: "",
        pagecap: 20,
        page: 1,
        projectid: this.$root.projectid,
        conditions: [

          {
            column_name: "dimiangaocheng",
            column_type: "INTEGER",
            column_data: undefined,
            min: Number,
            max: Number

          },
          {
            column_name: "height",
            column_type: "INTEGER",
            column_data: undefined,
            min: Number,
            max: Number

          },

        ],
        sort: "", //排序 desc 降序，asc 升序
        sortfield: "", //排序字段
        geojson: ""
      },
      customConditions: {
        dimiangaocheng: "底面高程",
        height: "高度"
      },
      dimiangaocheng: '',
      height: '',
      geojson: "",
      showQuery: true,
      conditionsdefault: [],
      queryConditions: ""

    };
  },

  watch: {
    creating() {
      if (!this.creating) {
        console.log("绘制完成")
        if (this.polylineData.length > 0) {
          let arr = this.ArrayAdd(this.polylineData)
          if ([...arr].length) {
            this.geojson = JSON.stringify({
              "type": "Polygon",
              "coordinates": [
                arr
              ]
            })
          }
        } else {
          this.geojson = ""
        }
      }

    },
    checkedNames: function () {
      if (this.checkedNames.length == this.infos.length && this.checkedNames.length !== 0) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    flag: function () {
      if (this.flag === true) {
        this.formData.sort = "asc"; //降序
      } else if (this.flag === false) {
        this.formData.sort = "desc"; //升序
      } else {
        this.formData.sort = ""; //默认排序
      }
    }
  },
  mounted() {
    this.query()
    // this.getCustom()
    this._polyline = new XE.Obj.Plots.GeoPolygon(this.$root._earth);
    this._disposers = this._disposers || [];
    this._disposers.push(XE.MVVM.bind(this, "creating", this._polyline, "creating"));
  },
  methods: {

    updataParameter() {

      if (this.checkedNames.length > 0) {
        this.flagcangshu = true
      } else {
        this.$root.$alert("未选中数据")
      }
    },
    cancel() {
      this.flagcangshu = false
      this.$root.destroyTool(this);
    },
    ok() {
      // this.updateDiHeight()
      this.updateShape()
      this.checkedNames = []
      this.height = ''
      this.dimiangaocheng = ''
      this.$root._tileset.reload();
    },
    updateDiHeight() {
      let _this = this;
      let data = {
        projectid: _this.$root.projectid,
        ids: this.checkedNames,
        dimiangaocheng: this.dimiangaocheng * 1,
      };
      //console.log("属性面板修改数据", data)
      this.$axios
        .put("/project/build/bottom/update/ids", data)
        .then(res => {
          _this.query();
          console.log(res);
          _this.flagcangshu = false
          _this.$root.$alert("建筑参数修改成功");

        })
        .catch(err => {
          this.$root.$log("error", err);
          console.log(err.response || err);
          this.$root.$alert("建筑参数修改失败");
        });

    },
    updateShape() {
      let _this = this;
      if (!this.checkedAll) {
        let data = {
          projectid: _this.$root.projectid,
          ids: this.checkedNames,
        };
        if (this.height || this.height === 0) {
          data.height = this.height * 1
        }
        if (this.dimiangaocheng || this.dimiangaocheng === 0) {
          data.dimiangaocheng = this.dimiangaocheng * 1
        }
        //console.log("属性面板修改数据", data)
        this.$axios
          .put("/project/build/shape/update/ids", data)
          .then(res => {
            _this.query();
            _this.flagcangshu = false
            console.log(res);
            this.$root._tileset.reload();
            _this.$root.$alert("建筑参数修改成功");

          })
          .catch(err => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("建筑参数修改失败");
          });
      } else {
        /**
         * @api {put} /project/build/shape/update/conditions  修改建筑高度（不用id）
         * @apiName update height by conditions
         * @apiGroup build
         * @apiParam {String} projectid      项目id
         * @apiParam {json} conditions      查询条件
         * @apiParam {json}   geojson      geojson
         * @apiParam {Number} height       建筑高
         * @apiParam {Number} dimiangaocheng  底面高
         * @apiSuccess {json} data           返回状态信息
         */
        let data = {
          projectid: _this.$root.projectid,
          conditions: this.queryConditions.conditions,
          geojson: this.queryConditions.geojson,
        };
        if (this.height || this.height === 0) {
          data.height = this.height * 1
        }
        if (this.dimiangaocheng || this.dimiangaocheng === 0) {
          data.dimiangaocheng = this.dimiangaocheng * 1
        }
        //console.log("属性面板修改数据", this.height, this.dimiangaocheng, data)
        this.$axios
          .put("/project/build/shape/update/conditions", data)
          .then(res => {
            _this.query();
            _this.flagcangshu = false
            console.log(res);
            this.$root._tileset.reload();
            _this.$root.$alert("建筑参数修改成功");

          })
          .catch(err => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("建筑参数修改失败");
          });


      }
      this.checkedAll = false

    },
    requery() {
      this.formData.page = 1;
      this.query()
    },
    refreshData() {
      let _this = this;
      this.$root.$confirm('重构数据比较缓慢是否继续?', () => {

        let data = {
          projectid: this.formData.projectid,
          ids: this.checkedNames,
        };
        //console.log(JSON.stringify(data))

        this.$axios
          .post("/project/build/rebuild", data)
          .then(res => {
            _this.query();
            console.log(res);
            this.$root.$alert("任务已经添加，请在任务查询界面查看进度");
          })
          .catch(err => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert(err.data.message);
          });

      })
      this.unchecked = false,
        this.checked = false
    },
    // 映射input类型
    switchType(type) {
      let step = undefined
      switch (type) {
        case "TEXT":
          type = "text";
          break;
        case "INTEGER":
          type = "number";
          step = 1;
          break;
        case "DOUBLE":
          type = "number";
          step = 0.01;
          break;
        case "BOOLEAN":
          type = "checkbox";
          break;
        case "DATE":
          type = "date";
          break;
        default:
          type = "text";
      }
      return {
        type: type,
        step: step
      };
    },
    // 映射字段类型
    yingshe(type) {
      switch (type) {
        case "text":
          type = "TEXT";
          break;
        case "integer":
          type = "INTEGER";
          break;
        case "double precision":
          type = "DOUBLE";
          break;
        case "boolean":
          type = "BOOLEAN";
          break;
        case "date":
          type = "DATE";
          break;
        default:
          type = "Text"
      }
      return type
    },
    getCustom() {

      this.$axios.get("/project/point/column/query", {
        params: {
          projectid: this.$root.projectid
        }
      }).then(res => {
        console.log("字段信息", res.data.data)
        let arr = res.data.data;
        if (arr instanceof Array && arr.length > 0) {
          arr.forEach(el => {
            if (el.column_editable) {
              if ((el.column_datatype == "text") || (el.column_datatype ==
                "boolean")) {
                this.formData.conditions.push({
                  column_name: el.column_name,
                  column_type: this.yingshe(el
                    .column_datatype),
                  column_data: undefined
                })
              } else {
                this.formData.conditions.push({
                  column_name: el.column_name,
                  column_type: this.yingshe(el
                    .column_datatype),
                  min: undefined,
                  max: undefined
                })
              }
              this.customConditions[el.column_name] = el.column_chinese

            }
          })
        }

      }).catch(err => {
        console.log(err || err.response)
      })
    },
    clear() { //清空几何图形
      if (this._polyline) {
        this._polyline.show = false
        this._polyline.creating = false
        this.polylineData = []
        this.showdelete = false
        this.infos = []
        this.pageNumber = 1
        this.formData.page = 1
        this.count = 0
        this.showQuery = true
      }
      this.geojson = ""
      this.formData.geojson = ""
    },
    odLine() {
      this._polyline.positions = []
      this._polyline._polygon.positions = []
      this._polyline.enabled = true
      //console.log("寂寞")
      this.infos = []
      this.pageNumber = 1
      this.formData.page = 1
      this.count = 0

      this._polyline.creating = true; //可以在地图上画
      XE.MVVM.watch(
        () => ({
          positions: [...this._polyline.positions]
        }), () => {
          if (this._polyline.positions.length > 3) {
            this.polylineData = []
            let lat
            let lng
            for (let index = 0; index < this._polyline.positions.length; index++) {
              lat = this._polyline.positions[index][0] * 180 / Math.PI
              lng = this._polyline.positions[index][1] * 180 / Math.PI
              this.polylineData.push([lat, lng])
            }
            //console.log("多边形经纬度", this.polylineData)
          }
        }
      )
      // }
      this.showQuery = false
      this.showdelete = true
    },
    ArrayAdd(arr) {
      let a = [...new Set(arr)] //去重加首尾相同
      a.push(a[0])
      return a
    },
    sort(item) {
      this.flag = !this.flag;
      if (this.flag === true) {
        item.descasc = "↓"
      } else {
        item.descasc = "↑"
      }
      this.formData.sortfield = item.zhname;
      this.query();
    },
    locate(item) {
      this.$root._tileset.selectFeature({
        id: item.id,
        otype: "build"
      });
    },
    unChangeAllChecked() {
      let new_arrB = [];
      this.itemsid.map(item => {
        if (this.checkedNames.indexOf(item) < 0) {
          // 这行就是最主要的代码
          new_arrB.push(item);
        }
      });
      this.checkedNames = new_arrB;
      //console.log("反选", this.checkedNames);
    },
    changeAllChecked() {
      if (this.checked) {
        this.checkedNames = this.itemsid;

        //console.log("全选", this.checkedNames);
      } else {
        this.checkedNames = [];
        //console.log("全不选", this.checkedNames);
      }
    },
    checkedAllP() {
      if (this.checkedAll) {
        this.checked = true;
        this.checkedNames = this.itemsid;
      } else {
        this.checkedNames = [];
      }
    },
    // 判断左侧查询条件时候为空
    async query() { //建筑查询 /project/build/query get
      let _this = this;
      if (this.geojson != "") {
        this.formData.geojson = this.geojson
      }
      let params = utils.clone(this.formData)
      this.conditionsdefault = params['conditions']
      //console.log(typeof params.conditions[0].max)
      if (params.conditions[0].max < params.conditions[0].min || params.conditions[1].max < params
        .conditions[1].min) {
        this.$root.$alert("最值范围填写有误，请检查最大值和最小值");
        params.conditions[0].max = undefined
        params.conditions[0].min = undefined
        params.conditions[1].max = undefined
        params.conditions[1].min = undefined
        return
      }
      if (params.conditions[0].max && params.conditions[0].min) {
        params.conditions[0].max = params.conditions[0].max * 1
        params.conditions[0].min = params.conditions[0].min * 1
      } else {
        params.conditions[0].max = undefined
        params.conditions[0].min = undefined
      }
      if (params.conditions[1].max && params
        .conditions[1].min) {
        params.conditions[1].max = params.conditions[1].max * 1
        params.conditions[1].min = params.conditions[1].min * 1
      } else {
        params.conditions[1].max = undefined
        params.conditions[1].min = undefined
      }

      //console.log(params)
      params['conditions'] = JSON.stringify(params.conditions)
      console.log("查询条件", params)
      this.queryConditions = params
      //let params = utils.clone(this.formData)
      this.$axios.get("/project/build/query", {
        params: params
      }).then(res => {

        //console.log("建筑查询111111111111111111", res)
        _this.count = res.data.data.count;
        _this.infos = res.data.data.results;
        this.itemsid = [];
        for (let index = 0; index < this.infos.length; index++) {
          this.itemsid.push(this.infos[index].id);
        }
        _this.pageNumber = Math.ceil(
          res.data.data.count / _this.formData.pagecap
        );
      })
        .catch(err => {
          this.$root.$log("error", err);
          console.log(err.response || err);
        });

      this.unchecked = false,
        this.checked = false
    },
    deleteBuild() {
      let _this = this
      //console.log(this.checkedNames)
      if (this.checkedAll) {
        /**
         * @api {delete} /project/build/delete/conditions  批量删除建筑
         * @apiName delete all build by conditions
         * @apiGroup build
         * @apiParam {String} projectid      项目id
         * @apiParam {String} id           id
         * @apiParam {json} conditions    包括：断面,材质,自定义字段等需要模糊查询的字段
         * @apiParam {json}   geojson      geojson
         * @apiSuccess {json} data           返回状态信息
         */
        let data = {
          projectid: this.$root.projectid,
          id: this.formData.id,
          conditions: this.queryConditions.conditions,
          geojson: this.queryConditions.geojson,
        };
        this.$root.$confirm("确定删除全部" + this.count + "座建筑?", () => {
          this.$axios
            .delete("/project/build/delete/conditions", {
              data
            })
            .then(res => {
              console.log(res);
              this.query();
              this.$root.$alert("全部建筑删除成功");
              if (res.data.data && res.data.data === true) {
                this.$root._tileset.reload();
              }
            })
            .catch(err => {
              this.$root.$log("error", err);
              console.log(err.response || err);
              this.$root.$alert("全部建筑删除失败");
            });
        });
      } else {
        if (this.checkedNames.length > 0) {
          let data = {
            projectid: this.$root.projectid,
            ids: this.checkedNames
          };
          this.$root.$confirm("确定删除选中的建筑?", () => {
            _this.$axios
              .delete("/project/build/delete/ids", {
                data
              })
              .then(res => {
                console.log(res);
                this.$root.$alert("建筑删除成功");
                this.query();
                if (res.data.data && res.data.data === true) {
                  this.$root._tileset.reload();
                }
              })
              .catch(err => {
                this.$root.$log("error", err);
                console.log(err.response || err);
                this.$root.$alert("建筑删除失败");
              });
          })
        } else {
          this.$root.$alert("未选中数据")
        }

      }
      this.unchecked = false,
        this.checked = false,
        this.checkedAll = false
    },
    updateCeCaiZhiPop() {

      if (this.checkedNames.length > 0) {
        let _this = this;
        //console.log("选中全部管点？", this.checkedAll);
        this.$root.showPropertyWindow("MaterialSelect", {
          component: MaterialSelect,
          param: {
            ok: option => {
              console.log("( •̀ ω •́ )y拿到了侧面材质", option);
              _this.updateCeCaiZhi(option);
            }
          }
        })
      } else {
        this.$root.$alert("未选中数据")
      };
    },
    updateDingCaiZhiPop() {
      if (this.checkedNames.length > 0) {
        let _this = this;
        this.$root.showPropertyWindow("MaterialSelect", {
          component: MaterialSelect,
          param: {
            ok: option => {
              console.log("( •̀ ω •́ )y拿到了顶面材质", option);
              _this.updateDingCaiZhi(option);
            }
          }
        });
      } else {
        this.$root.$alert("未选中数据")
      }

      // this.updateFushuwu({
      //     name: "",
      //     param: undefined
      // })
    },
    async updateCeCaiZhi(opt) {
      //http://localhost:3001/project/build/material/update/ids put

      let _this = this;
      if (!this.checkedAll) {
        //console.log("传参过程中", opt)
        let data = {
          projectid: this.formData.projectid,
          ids: this.checkedNames,
          cemiancaizhi: JSON.stringify(opt.param),
        };
        //console.log(JSON.stringify(data))
        this.$axios
          .put("/project/build/material/update/ids", data)
          .then(res => {
            _this.query();
            _this.checkedNames = []
            console.log(res);
            this.$root.$alert("选中侧面材质修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch(err => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("选中侧面材质修改失败");
          });
      } else {
        let data = {
          projectid: this.formData.projectid,
          conditions: this.queryConditions.conditions,
          geojson: this.queryConditions.geojson,
          cemiancaizhi: JSON.stringify(opt.param),
        };
        this.$axios
          .put("/project/build/material/update/conditions", data)
          .then(res => {
            _this.query();
            console.log(res);
            this.$root.$alert("全部侧面材质修改成功");
            //if (res.data.data && res.data.data === true) {
            this.$root._tileset.reload();
            // }
            _this.checkedNames = []
            _this.checkedAll = false
          })
          .catch(err => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("全部侧面材质修改失败");
          });
        /**
         * @api {put} /project/build/material/update/conditions  修改建筑材质（不用id）
         * @apiName update material by conditions
         * @apiGroup build
         * @apiParam {String} projectid      项目id
         * @apiParam {json} conditions    包括：断面,材质,自定义字段等需要模糊查询的字段
         * @apiParam {json}   geojson      geojson
         * @apiParam {String} cemiancaizhi      侧面材质
         * @apiParam {String} dingmiancaizhi    顶面材质
         * @apiSuccess {json} data           返回状态信息
         */

      }
      this.unchecked = false,
        this.checked = false
      this.checkedAll = false
    },
    updateDingCaiZhi(opt) {
      //http://localhost:3001/project/build/material/update/ids put
      let _this = this;
      //console.log("传参过程中", opt)
      if (!this.checkedAll) {
        let data = {
          projectid: this.formData.projectid,
          ids: this.checkedNames,
          dingmiancaizhi: JSON.stringify(opt.param),
        };
        //console.log(JSON.stringify(data))
        this.$axios
          .put("/project/build/material/update/ids", data)
          .then(res => {
            _this.query();
            console.log(res);
            this.$root.$alert("选中顶面材质修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch(err => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("选中顶面材质修改失败");
          });
      } else {
        let data = {
          projectid: this.formData.projectid,
          conditions: this.queryConditions.conditions,
          geojson: this.queryConditions.geojson,
          dingmiancaizhi: JSON.stringify(opt.param),
        };
        this.$axios
          .put("/project/build/material/update/conditions", data)
          .then(res => {
            _this.query();
            console.log(res);
            this.$root.$alert("全部侧面材质修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
            _this.checkedNames = []
            _this.checkedAll = false
          })
          .catch(err => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("全部侧面材质修改失败");
          });
      }

      this.unchecked = false,
        this.checked = false,
        this.checkedAll = false
    },
    upPage() {
      this.unchecked = false,
        this.checked = false
      this.checkedAll = false;
      this.checkedNames = [];
      let _this = this;

      if (this.formData.page <= 1) {
        _this.formData.page = 1;
      } else {
        _this.formData.page -= 1;
        this.query();
      }
      console.log(this.formData.page);

    },
    runPage() {
      this.unchecked = false,
        this.checked = false
      this.checkedAll = false;
      this.checkedNames = [];
      let _this = this;

      if (this.formData.page <= this.pageNumber && this.formData.page >= 1) {
        _this.query();
      } else {
        _this.formData.page = 1;
        _this.query();
      }

    },
    downPage() {
      this.unchecked = false,
        this.checked = false
      this.checkedAll = false;
      this.checkedNames = [];
      let _this = this;
      if (this.formData.page >= this.pageNumber) {
        _this.formData.page = this.pageNumber;
      } else {
        _this.formData.page += 1;
        this.query();

      }
      console.log(this.formData.page);

    }
  },
  beforeUnmount() {
    this.clear()
    //this.$root._tileset.unSelectFeature();
  }
};
</script>
<style scoped>
.pipepointquery {
  margin-top: 5px;
  padding: 0px 5px;
  text-align: center;
  line-height: 18px;
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  color: #ccc;
}

.condition {
  max-height: 250px;
  overflow-y: auto;
}

.condition > div {
  margin-top: 5px;
}

.operation {
  margin-top: 5px;
}

.tablePages {
  padding: 5px 5px;
  width: 100%;
  height: 100%;
}

.odline {
  width: 100px;
}

.tablePages input {
  vertical-align: middle;
}

.taNamepoint {
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
}

.taNamepoint > table {
  margin: 0 auto;
  text-align: center;
  cursor: default;
  border: 1px solid #383838;
  width: 100%;
}

.taNamepoint > table > tr > th {
  box-sizing: border-box;
  height: 30px;
  text-align: center;
  cursor: default;
  background-color: #383838;
  color: #ccc;
}

.taNamepoint > table > tr > th:nth-child(1) {
  min-width: 50px;
}

.taNamepoint > table > tr > td {
  box-sizing: border-box;
  height: 30px;
  max-height: 30px;
  line-height: 15px;
  text-align: center;
  cursor: default;
  background: #2e2e2e;
  border: 1px solid #383838;
  border-radius: 2px;
  color: #ccc;
}

.taNamepoint > table > tr:hover td {
  background-color: #288dfb;
  opacity: 0.5;
}

.row_single {
  max-width: 380px;
  display: flex;
  align-items: center;
}

.row_single > label {
  flex-shrink: 0;
  width: 30px;
}

.row_single > label:nth-child(1) {
  flex-shrink: 0;
  min-width: 75px;
}

.row_single > input {
  box-sizing: border-box;
  min-width: 10px;
  flex-grow: 1;
  background: #2e2e2e;
  border: 1px solid #383838;
  border-radius: 2px;
  color: #ccc;
  height: 30px;
  line-height: 30px;
  margin-right: 3px;
}

.row_single > button {
  width: 100%;
}

.row_single > button:last-child {
  margin-left: 3px;
}

.upDown:hover {
  cursor: pointer;
  color: #288dfb;
}

.pagepoint {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-top: 5px;
  padding-left: 25px;
}

.pagepoint > div > span {
  text-align: center;
  margin-right: 5px;
}

.pagepoint > div > button {
  margin-right: 3px;
  width: 100px;
}

.pagepoint > div > span > input {
  text-align: center;
  width: 50px;
  height: 30px;
  background: #2e2e2e;
  border: 1px solid #383838;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  margin: 0 3px;
}

.row_single {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.row_single > label {
  flex-shrink: 0;
  width: 30px;
}

.row_single > label:nth-child(1) {
  flex-shrink: 0;
  width: 75px;
}

.row_single > input {
  min-width: 10px;
  box-sizing: border-box;
  flex-grow: 1;
  background: #2e2e2e;
  border: 1px solid #383838;
  border-radius: 2px;
  color: #ccc;
  height: 30px;
  line-height: 30px;
}
</style>

<template>
    <div class="property">地形属性
        <div>
            <label>url</label> <input class="input" type="text" v-model="url">
        </div>
        <div>
            <button @click="save">确认保存</button>
        </div>


    </div>
</template>
<script>
    export default {
        data() {
            return {
                url: ""
            }
        },
        props: {
            param: Object
        },
        created() {},
        mounted() {
            console.log("地形", this.param)
            this.bind("url")
        },
        methods: {
            bind(prp) {
                this._viewUnbinds = this._viewUnbinds || [];
                this._viewUnbinds.push(
                    XE.MVVM.bind(this, prp, this.param.xbsjTerrainProvider.XbsjCesiumTerrainProvider, prp)
                );
            },
            save() {
                this.$axios.put("/project/info/update", {
                    projectid: this.$root.projectid,
                    basemap: JSON.stringify(this.$root._earth.sceneTree.root.children)
                }).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err.response || err)
                })
            },
        },

        beforeUnmount() {}
    };
</script>

<style scoped>
    .property {
        color: #CCCCCC;
        width: var(--RightWidth);
        height: 100%;
        background: var(--PanelBGColor);
    }

    .property>div {
        display: flex;
        align-items: center;
    }

    .property>div>label {
        width: 100px;
        text-align: end;
        padding: 0 10px;
    }

    .input {
        height: 24px;
        background: #383838;
        border: 1px solid #4D4D4D;
        opacity: 1;
        border-radius: 2px;
        color: #CCC;
    }
</style>
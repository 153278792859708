<template>
    <Window :footervisible="true" @cancel="cancel" @ok="ok" :height="500" :width="500" :minWidth="202" :title="'在线影像服务'"
        class="xbsj-onlineImage">
        <div class="onlineImageBox">
            <div class="onlineImageDiv">
                <label>服务地址:</label>
                <input v-model="selectedUrl" />
            </div>
            <div class="onlineImageDiv onlineImagezb">
                <label class="datazbLabel">数据坐标:</label>

                <div class="onlineimage-selectdiv" @click="selectClick">
                    <span class="onlineimage-selectText">{{srcCoordType}}</span>
                    <button class="onlineimage-selectButton"></button>
                </div>
                <ul class="onlineimage-selectOption" v-show="selectshow">
                    <li v-for="ct in dataCoordTypes" :key="ct" @click="selectName(ct)">
                        <span>{{ ct }}</span>
                    </li>
                </ul>
                <label class="loadzbLabel">加载类型:</label>
                <div class="onlineimage-selectdiv" @click="select2Click">
                    <span class="onlineimage-selectText">{{dstCoordType}}</span>
                    <button class="onlineimage-selectButton"></button>
                </div>
                <ul class="onlineimage-selectOption2" v-show="select2show">
                    <li v-for="ct in dstCoordTypes" :key="ct" @click="select2Name(ct)">
                        <span>{{ ct }}</span>
                    </li>
                </ul>

            </div>
        </div>
        <div v-if="selected!=null" class="onlineImageBox">

            <div v-if="selected.requireField" class="onlineImageDiv">
                <label>{{selected.requireField }}:</label>
                <input v-model="requireValue" />
            </div>
        </div>
        <ul class="flexul" :class="showContent ? 'contentUl' : ''">
            <li v-for="s in services" @click="select(s)" :key="s.name">
                <div :class="[{highlight:selected == s} ]" class="backimg">
                    <img style="width:64px;height:64px;" :src="s.thumbnail" alt />
                </div>
                <div class="onlinImageName">
                    {{getName(s)}}
                    <span class="onlineImageToolTip">{{getName(s)}}</span>
                </div>
            </li>
        </ul>
    </Window>
</template>

<script>
    /* eslint-disable */
    import Window from "../../../../components/Window"
    import languagejs from "./imagery_locale";
    import axios from "axios";
    export default {
        components: {
            Window
        },
        data() {
            return {
                langs: languagejs,
                selectshow: false,
                select2show: false,
                error: "",
                selected: null,
                selectedUrl: "",
                srcCoordType: "",
                dstCoordType: "",
                requireValue: "",
                dataCoordTypes: ["WGS84", "GCJ02", "BD09"],
                dstCoordTypes: ["WGS84", "GCJ02"],
                showContent: false,
                lang: {},
                services: []
            };
        },
        created() {},
        watch: {},
        props: {
            getBind: Boolean,
            default () {
                false
            }
        },
        mounted() {
            this.getonlineinfo()
        },
        methods: {
            getonlineinfo() {
                this.$axios
                    .get("/system/service/image")
                    .then(res => {
                        let arr = res.data.data.result;
                        arr.forEach(s => {
                            if (s.requireField == " ") {
                                s.requireField = undefined;
                            }
                            if (window.location.protocol == "https:") {
                                if (s.url.substr(0, 5) == "https") {
                                    this.services.push(s)
                                }
                            } else {
                                this.services.push(s)
                            }
                        });

                    })
                    .catch(err => {
                        console.log(err.response || err);
                    });
            },
            // 点击select
            selectClick() {
                this.selectshow = !this.selectshow;
            },
            selectName(value) {
                this.srcCoordType = value;
                this.selectshow = false;
            },
            select2Click() {
                this.select2show = !this.select2show;
            },
            select2Name(value) {
                this.dstCoordType = value;
                this.select2show = false;
            },
            select(service) {
                this.selected = service;

                this.srcCoordType = service.coordType;
                this.dstCoordType = "WGS84";
                this.selectedUrl = service.url;

                this.error = "";

                //构造一个imagerylayer 添加到地球上

                if (this._imagelayer) this._imagelayer.destroy();

                this._imagelayer = new XE.Obj.Imagery(this.$root._earth);

                var url = this.selectedUrl;
                if (this.selected.requireField) {
                    url += "&" + this.selected.requireField + "=" + this.requireValue;
                }

                this._imagelayer.xbsjImageryProvider = {
                    type: "XbsjImageryProvider",
                    XbsjImageryProvider: {
                        url: url,
                        srcCoordType: service.coordType,
                        dstCoordType: "WGS84"
                    }
                };
            },
            cancel() {
                this.$root.destroyTool(this);
                if (this._imagelayer) this._imagelayer.destroy();
                this._imagelayer = undefined;
                this.selected = undefined;
            },
            ok() {
                if (this.selectedUrl == "") {
                    //提示需要弹出url
                    // this.error = this.lang.selectinput;
                    // this.$root.$earthUI.promptInfo(this.error, "error");
                } else {
                    //构造imagerylay

                    var url = this.selectedUrl;
                    if (this.selected && this.selected.requireField) {
                        if (!this.requireValue || this.requireValue == "") {
                            // this.$root.$earthUI.promptInfo(
                            //     "please input require value",
                            //     "error"
                            // );
                            return;
                        }
                        url += "&" + this.selected.requireField + "=" + this.requireValue;
                    }

                    var imageLayer = new XE.Obj.Imagery(this.$root._earth);

                    imageLayer.xbsjImageryProvider = {
                        type: "XbsjImageryProvider",
                        XbsjImageryProvider: {
                            url: url,
                            srcCoordType: this.srcCoordType,
                            dstCoordType: this.dstCoordType
                        }
                    };
                    console.log("添加场景")
                    //添加到场景树中
                    this.$root._earthVue.addSceneObject(
                        imageLayer,
                        this.getName(this.selected)
                    );

                    this.$root.destroyTool(this);
                    this.error = "";

                    this.cancel();
                }
            },

            onContexMenu(server, event) {
                //弹出菜单
                this.$root._earth.contextMenu.pop([{
                    text: "保存缩略图",
                    keys: "",
                    func: () => {
                        this.$root.$earthUI.saveScreenToFile(
                            this.$root._earth.czm.viewer.canvas.width,
                            this.$root._earth.czm.viewer.canvas.height,
                            server.name + ".png"
                        );
                    }
                }]);
            },
            getName(s) {
                if (!s) return '未命名影像';
                if (s.cnname in this.langs.zh) return this.langs.zh[s.cnname];
                return s.cnname;
            }
        },
        computed: {},
        filters: {},
        beforeUnmount() {
            this.cancel();
            if (this.unbind) {
                this.unbind();
                this.unbind = undefined;
            }
        }
    };
</script>

<style scoped>
    .xbsj-onlineImage .error {
        color: red;
    }

    .xbsj-onlineImage .flexul {
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 150px);
    }

    .xbsj-onlineImage .contentUl {
        overflow-x: hidden;
        height: calc(100% - 124px);
    }

    .xbsj-onlineImage .flexul li {
        list-style: none;
        /* float: left; */
        position: relative;
        height: 100px;
    }

    .xbsj-onlineImage .flexul li .onlinImageName {
        display: inline-block;
        width: 64px;
        text-align: center;
        margin-left: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .xbsj-onlineImage .flexul li .onlinImageName .onlineImageToolTip {
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        text-align: center;
        padding: 5px 5px;
        border-radius: 3px;
        /* 定位 */
        position: absolute;
        z-index: 1;
        left: 6px;
        bottom: 30px;
    }

    .xbsj-onlineImage .backimg {
        width: 64px;
        height: 64px;
        border: 2px solid;
        border-radius: 4px;
        background: gray;
        margin: 5px;
    }

    .xbsj-onlineImage .backimg.highlight,
    .xbsj-onlineImage .backimg:hover {
        border: 2px solid rgba(31, 255, 255, 1);
    }

    .xbsj-model-content-box {
        overflow-x: hidden;
        /* overflow-y: hidden; */
    }

    .xbsj-onlineImage .onlineImageDiv {
        margin: 10px 10px;
    }

    .xbsj-onlineImage .onlineImageDiv>label {
        display: inline-block;
        width: 60px;
        text-align: right;
        margin-right: 15px;
        /* float: left; */
        margin-top: 6px;
    }

    .xbsj-onlineImage .onlineImageDiv .loadzbLabel {
        margin-left: 25px;
    }

    .xbsj-onlineImage .onlineImageDiv>input {
        width: calc(100% - 100px);
        height: 28px;
        background: #383838;
        border: 1px solid #4D4D4D;
        border-radius: 3px;
        border: none;
        color: #000;
        padding: 0 5px;
        color: #ccc
    }

    .xbsj-onlineImage .onlineImagezb {
        display: flex;
        width: 470px;
        height: 28px;
    }

    .xbsj-onlineImage .onlineimage-selectBox {
        display: inline-block;
        width: 100%;
        margin-top: 10px;
    }

    .xbsj-onlineImage .onlineimage-selectdiv {
        display: flex;
        justify-content: space-between;
        width: 118px;
        background: #383838;
        border: 1px solid #4D4D4D;
        height: 28px;
        position: relative;
        text-align: left;
        line-height: 28px;
        cursor: pointer;
        padding-left: 13px;
        left: -2px;
        top: 2px;
        border-radius: 3px;
        /* float: left; */
    }

    .xbsj-onlineImage .onlineimage-selectText {
        font-size: 12px;
    }

    .xbsj-onlineImage .onlineimage-selectButton {
        width: 12px;
        height: 10px;
        border: none;
        background: url(../../assets/titles-select.png) no-repeat;
        background-size: contain;
        cursor: pointer;
        margin-right: 20px;
        margin-top: 10px;
        outline: none;
    }

    .xbsj-onlineImage .onlineimage-selectOption,
    .xbsj-onlineImage .onlineimage-selectOption2 {
        width: 120px;
        height: 64px;
        background: rgba(138, 138, 138, 1);
        border-radius: 0px 0px 4px 4px;
        list-style: none;
        z-index: 1;
        position: absolute;
        left: 90px;
        top: 90px;
    }

    .xbsj-onlineImage .onlineimage-selectOption2 {
        left: 325px;
    }

    .xbsj-onlineImage .onlineimage-selectOption li,
    .xbsj-onlineImage .onlineimage-selectOption2 li {
        width: 100%;
        height: 20px;
        font-size: 14px;
        color: rgba(221, 221, 221, 1);
        line-height: 20px;
        cursor: pointer;
    }

    .xbsj-onlineImage .onlineimage-selectOption li span,
    .xbsj-onlineImage .onlineimage-selectOption2 li span {
        display: inline-block;
        height: 20px;
        position: relative;
        left: 11px;
    }

    .xbsj-onlineImage .onlineimage-selectOption li:hover,
    .xbsj-onlineImage .onlineimage-selectOption2 li:hover {
        background: rgb(167, 166, 166);
    }

    /* .xbsj-onlineImage .onlineImageBox {
        width: 500px;
    } */

    .xbsj-onlineImage .flexul li .onlinImageName:hover .onlineImageToolTip {
        visibility: visible;
    }
</style>

<template>
    <div>
        <Window :title="title" :footervisible="true" @ok="ok" @cancel="cancel" v-show="show" :left="400" :top="300"
            :width="600" :height="600">
            <input ref="localFile" type="file" title=" " id="files" @change="upLoadFile" multiple="multiple"
                style="display: none;" />
            <input ref="localImg" type="file" title=" " id="img" @change="upLoadImg" style="display: none;" />

            <div class="appendageStatic_top">
                <div @click=" addImg"> <img width="68" height="68"
                        :src="thumbnail?thumbnail:require('../assets/addimg.png')"></div>
                <div>
                    <label>名称</label>
                    <input type="text" v-model="name">
                </div>             
            </div>
            <span style="color: red;">不能缺失低级别lod</span>
            <div class="appendageStatic_content">                
                <div>
                    <div class="single_title">
                        <span>像素</span>
                        <span>顶点</span>
                        <span>三角形</span>
                    </div>
                    <button></button>
                </div>
                <div v-for="(item,index) in imageData.lods" :key="index">

                    <div class="single" draggable="true" @dragstart="dragstart(item)" @dragleave="dragleave(item)"
                        @drop="drop(item,index)" @dragover.prevent="dragover" :class="{draging:item.draging}">
                        <span>{{item.pixel}}x{{item.pixel}}</span>
                        <span>{{vertexNum(item.meshes)}}</span>
                        <span>{{triangleNum(item.meshes)}}</span>
                        <span class="prompt_box">拖拽可复制</span>
                    </div>
                    <button @click="uploadAppendage(index)">添加</button>
                </div>
            </div>
        </Window>
    </div>
</template>
<script>
    import Window from "../../../components/Window"
    import Utils from "../math/utils"
    /* eslint-disable */
    export default {
        components: {
            Window,
        },
        data() {
            return {
                title: "静态附属物编辑器",
                show: true,
                name: "",
                isUploadGlb: false,
                imageData: {
                    type: "static",
                    lods: []
                },
                startItem: {},
                endItem: {},
                imageDataDefault: {
                    type: "static",
                    lods: [{
                            pixel: 8,
                            meshes: [{
                                mat: [],
                                triangle: "",
                                vertex: ""
                            }]
                        },
                        {
                            pixel: 16,
                            meshes: [{
                                mat: [],
                                triangle: "",
                                vertex: ""
                            }]
                        },
                        {
                            pixel: 32,
                            meshes: [{
                                mat: [],
                                triangle: "",
                                vertex: ""
                            }]
                        },
                        {
                            pixel: 64,
                            meshes: [{
                                mat: [],
                                triangle: "",
                                vertex: ""
                            }]
                        },
                        {
                            pixel: 128,
                            meshes: [{
                                mat: [],
                                triangle: "",
                                vertex: ""
                            }]
                        },
                        {
                            pixel: 256,
                            meshes: [{
                                mat: [],
                                triangle: "",
                                vertex: ""
                            }]
                        },
                        {
                            pixel: 512,
                            meshes: [{
                                mat: [],
                                triangle: "",
                                vertex: ""
                            }]
                        },
                        {
                            pixel: 1024,
                            meshes: [{
                                mat: [],
                                triangle: "",
                                vertex: ""
                            }]
                        },
                    ]
                },
                thumbnail: ""
            }
        },
        props: {
            getBind: Object,
            default () {
                {}
            },
            param: {
                type: Object,
                default: undefined
            }
        },
        mounted() {
            let _this = this
            let option = this.getBind
            _this.query();
            if (option) {
                _this.imageData = Utils.clone(option.param, true) //对象拷贝一下
                this.oldname = option.name
                this.name = option.name
                this.thumbnail = option.thumbnail
                this.checkedGlb(this.imageData.lods)
                this.isupdate = true;
                if (this.getBind.isCopy) {
                    this.isupdate = false;
                    this.title = "静态附属物复制器面板"
                }
            } else {
                this.isupdate = false;
                _this.imageData = _this.imageDataDefault
            }



        },
        methods: {
            // 自定义检查
            checkedLocal() {
                if (!this.name) {
                    this.$root.$alert("请输入名称")
                    return false
                } else if (!this.thumbnail) {
                    this.$root.$alert("请添加缩略图片")
                    return false
                } else if (!this.isUploadGlb) {
                    this.$root.$alert("请填入glb参数")
                    return false
                } else {
                    return true
                }
            },
            // 更新障碍物判断是否有glb上传内容
            checkedGlb(data) {
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < data[i].meshes.length; j++) {
                        if (data[i].meshes[j].mat) {
                            this.isUploadGlb = true
                            return this.isUploadGlb
                        }
                    }
                }
            },
            // 自定义判断更改名称是否和其他附属物名称重复
            checkNameBesidesMy_this(oldname, newname) {
                let flag;
                for (let i = 0; i < this.queryData.length; i++) {
                    if (oldname == this.queryData[i].name) {
                        flag = i;
                        break;
                    }
                }
                for (let j = 0; j < this.queryData.length; j++) {
                    if (!(j == flag)) {
                        if (newname == this.queryData[j].name) {
                            return true;
                        }
                    }
                }
                return false
            },
            // 自定义判断新建附属物是否名称重复
            checkName(name) {
                for (let i = 0; i < this.queryData.length; i++) {
                    if (name == this.queryData[i].name) {
                        return true;
                    }
                }
                return false;
            },
            // 计算vertex顶点叠加
            vertexNum(item) {
                let num = 0;
                for (let i = 0; i < item.length; i++) {
                    num = num + item[i].vertex
                }
                return num
            },
            // 计算triangle三角形叠加
            triangleNum(item) {
                let num = 0;
                for (let i = 0; i < item.length; i++) {
                    num = num + item[i].triangle
                }
                return num
            },
            query() {
                // 获取所有的附属物信息
                let _this = this
                let data = {
                    projectid: _this.$root.projectid
                }
                this.$axios.get("/project/accessory/query", {
                    params: data
                }).then(res => {

                    this.queryData = res.data.data
                    console.log("附属物全部信息", this.queryData)
                }).catch(err => {
                    err = err.response || err
                    this.$root.$log("error", err)
                })

            },
            cancel() {
                this.$root.destroyTool(this)
            },
            ok() {
                let _this = this
                if (!this.checkedLocal()) {
                    return
                }
                // 新建附属物
                if (!this.isupdate) {
                    if (this.checkName(this.name)) {
                        this.$root.$alert("名称已经存在")
                        return
                    }
                    let data = {
                        projectid: this.$root.projectid,
                        name: this.name,
                        param: JSON.stringify(this.imageData),
                        thumbnail: this.thumbnail
                    }
                    this.$axios.post("/project/accessory/add", data).then(res => {
                        _this.$root.destroyTool(this)
                        if (_this.param && _this.param.ok) {
                            _this.param.ok();
                        }
                    }).catch(err => {
                        err = err.response || err
                        this.$root.$alert(err.data.message)
                        this.$root.$log("error", err)
                    })

                } else {
                    // 更新附属物
                    if (this.checkNameBesidesMy_this(this.oldname, this.name)) {
                        this.$root.$alert("名称已经存在")
                        return
                    }
                    let data = {
                        projectid: this.$root.projectid,
                        oldname: this.oldname,
                        newname: this.name,
                        param: JSON.stringify(this.imageData), //一个对象
                        thumbnail: this.thumbnail,
                    }
                    this.$axios.put("/project/accessory/update",
                        data
                    ).then(res => {
                        _this.$root.destroyTool(this)
                        if (_this.param && _this.param.ok) {
                            _this.param.ok();
                        }
                    }).catch(err => {
                        err = err.response || err
                        this.$root.$alert(err.data.message)
                        this.$root.$log("error", err)
                    })
                }
            },
            // 添加glb文件
            uploadAppendage(index) {
                // 标记
                this.indexItem = index
                this.$refs.localFile.click();
            },
            // 上传文件
            upLoadFile(event) {
                let file = event.target.files;
                let _this = this
                let formData = new FormData();
                for (let i = 0; i <= file.length - 1; i++) {
                    formData.append('files', file[i]);
                }
                formData.append('projectid', this.$root.projectid, )
                console.log(formData);
                this.$axios.post("/project/accessory/upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                }).then(res => {
                    _this.imageData.lods[_this.indexItem].meshes = JSON.parse(res.data.data)
                    _this.isUploadGlb = true
                }).catch(err => {
                    err = err.response || err
                    this.$root.$log("error", err)
                })
                event.target.value = "";
            },
            // 添加图片
            addImg() {
                this.$refs.localImg.click();
            },
            // 上传图片为base64格式
            upLoadImg(e) {
                let _this = this
                let reader = new FileReader();
                let file = e.target.files[0];
                if (file) {
                    console.log("文件", file)
                    if (file.size > 102400) {
                        this.$root.$alert("请上传100KB以下的照片")
                    } else {
                        reader.readAsDataURL(file);
                        reader.onload = function (e) {
                            _this.thumbnail = reader.result //base64格式

                        }
                    }

                }
            },
            dragstart(item) {
                console.log("源拖拽信息", item)
                this.startItem = item
                item.draging = true
            },
            dragleave(item) {
                item.draging = false
            },
            drop(item, index) {
                console.log("目标拖拽信息", item)
                this.imageData.lods[index].meshes = this.startItem.meshes
                item.draging = false
                console.log("全部信息", this.imageData.lods)
            }
        }
    }
</script>
<style scoped>
    .appendageStatic_top {
        width: 100%;
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid #171717;
        ;

    }

    .appendageStatic_top>div:nth-child(1) {
        width: 120px;
        height: 100px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #171717;
        border-radius: 2px;
        cursor: pointer;
    }

    .appendageStatic_top>:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .appendageStatic_top>:nth-child(2)>label {
        width: 40px;
        height: 19px;
        font-size: 16px;
        color: #ccc;
        margin-bottom: 10px;
    }

    .appendageStatic_top>:nth-child(2)>input {
        box-sizing: border-box;
        width: 470px;
        height: 30px;
        background: #2E2E2E;
        border: 1px solid #383838;
        border-radius: 2px;
        color: #CCC;
    }

    .appendageStatic_content {
        width: 100%;
        height: 380px;
        overflow: auto;
        padding: 20px 0;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .appendageStatic_content>div {
        width: 100%;
        height: 34px;
        display: flex;
        align-items: center;
    }

    .appendageStatic_content>div:hover .single>span {
        background: #288DFB;
        color: #fff;
        opacity: 0.5;
    }

    .appendageStatic_content>div:hover .prompt_box {
        display: inline-block;
        width: 100%;
        font-size: 15px;
        color: #fff !important;
        background: none !important;
        opacity: 0.8 !important;
        background: none;
        cursor: move;

    }

    .appendageStatic_content>div>button {
        width: 52px;
        height: 25px;
        background: #2E2E2E;
        border: 1px solid #383838;
        border-radius: 2px;
        margin-left: 5px;
    }


    .prompt_box {
        display: none;
        position: absolute;
        width: 100%;
        text-align: center;
        left: 0;
        right: 0;
        z-index: 9999;
    }

    .single {
        width: 100%;
        height: 24px;
        position: relative;
        display: flex;
        cursor: move;

    }


    .single>span {
        width: 220px;
        height: 24px;
        line-height: 24px;
        background: #2E2E2E;
        text-align: center;
        border: 1px solid #383838;
        border-radius: 2px;
        margin-left: 5px;
    }

    .single>span:nth-child(1) {
        width: 82px;
        height: 24px;
    }

    .single_title>span {
        width: 220px;
        height: 24px;
        line-height: 24px;
        background: #2E2E2E;
        text-align: center;
        border: 1px solid #383838;
        border-radius: 2px;
        margin-left: 5px;
    }

    .single_title>span:nth-child(1) {
        width: 82px;
        height: 24px;
    }

    .single_title {
        width: 100%;
        height: 24px;
        display: flex;
    }

    .single_title>span {
        background: #383838;
        border: 1px solid #2E2E2E;
    }

    .appendageStatic_content>div:nth-child(1)>button {
        background: #383838;
        border: 1px solid #2E2E2E;
    }
</style>
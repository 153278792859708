<template>
  <div class="editPolygon">
    <div class="editor">
      <label>侧面材质： </label>
      <label>宽度:</label>
      <input v-model.number="cemian.width" v-number-fixed="2" />
      <label>高度:</label>
      <input v-model.number="cemian.height" v-number-fixed="2" />
      <button @click="selectMaterial(cemian)">选择材质</button>
    </div>
    <div class="editor">
      <label>侧面材质： </label>
      <label>宽度:</label>
      <input v-model.number="dingmian.width" v-number-fixed="2" />
      <label>高度:</label>
      <input v-model.number="dingmian.height" v-number-fixed="2" />
      <button @click="selectMaterial(dingmian)">选择材质</button>
    </div>
    <button @click="cancel">取消</button>
    <button @click="ok">确定</button>
  </div>
</template>

<script>
import MaterialSelect from "../components/MaterialSelect"
export default {
  components: {
    Window,
    MaterialSelect
  },
  data() {
    return {
      cemian: { width: 0, height: 0 },
      dingmian: { width: 0, height: 0 }
    };
  },
  props: {
    param: {
      type: Object,
      default: undefined
    }
  },
  mounted() {
  },
  methods: {
    selectMaterial(a) {

      this.$root.showPropertyWindow("MaterialSelect", {
        component: MaterialSelect,
        param: {
          ok: caizhi => {
            console.log("showPropertyWindow材质" + caizhi);
            a = Object.assign(a, caizhi.param);
          }
        }
      });
    },
    ok() {
      let data = {
        projectid: this.$root.projectid,
        ids: [this.param.polygon.id],
        cemiancaizhi: JSON.stringify(this.cemian),
        dingmiancaizhi: JSON.stringify(this.dingmian)
      };
      this.$axios
        .put("/project/build/material/update/ids", data)
        .then(res => {

          //关闭显示 
          this.cancel();
          this.$root._tileset.reload();
          if (res.data.data && res.data.data === true) {
            this.$root._tileset.reload();
          }
        })
        .catch(err => {
          this.$root.$log("error", err);
        });
    },
    cancel() {
      this.$root.$refs.bottom.closeTool();
    }
  },
  beforeUnmount() {
  }
};
</script>

<style scoped>
.editPolygon {
  flex-grow: 1;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.editor {
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
input {
  width: 80px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
}
button {
  height: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>

<template>
    <div class="left">
        <BaseScene></BaseScene>
        <PipeLayer></PipeLayer>
    </div>
</template>

<script>
    import PipeLayer from "./resource/PipeLayer"
    import BaseScene from "./resource/BaseScene"
    export default {
        name: 'Left',
        components: {
            PipeLayer,
            BaseScene
        },
        mounted() {}
    }
</script>

<style>
    .left {
        margin-top: 3px;
        width: 300px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-items: stretch;
        background: var(--PanelBGColor);
        min-width: 300px;
        max-width: 300px;
    }
</style>
<template>
  <div class="inputfile">
    <input
      ref="files"
      type="file"
      title=" "
      @change="upLoadFile"
      multiple="multiple"
      style="display: none"
    />
    <div class="info_top">
      <div>记录数:{{ count }}</div>
      <div>
        {{ fakepath }}
      </div>
      <div>
        <button @click="selectFile">选择文件</button>
      </div>
      <div>
        <button @click="clearFile">清除文件</button>
      </div>
    </div>
    <div class="info_table">
      <table>
        <thead>
          <tr>
            <th v-for="f in fields" :key="f.name">{{ f.name }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(r, index) in records" :key="index">
            <td v-for="(c, index) in r" :key="index">{{ c }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      count: 0,
      fakepath: "",
      records: [],
      fields: []
    };
  },
  methods: {
    getRecords() {
      let self = this;
      return this.records.map(r => {
        var obj = {};
        for (var i = 0; i < self.fields.length; i++) {
          if (self.fields[i].type == "geometry") {
            obj[self.fields[i].name] = JSON.parse(r[i]);
          } else {
            obj[self.fields[i].name] = r[i];
          }
        }
        return obj;
      });
    },
    selectFile() {
      this.$refs.files.click();
    },
    clearFile() {
      this.count = 0;
      this.fakepath = "";
      this.records = [];
      this.fields = [];
      console.log("cli");
    },
    upLoadFile(event) {
      let formData = new FormData();
      let files = event.target.files;
      for (let i = 0; i <= files.length - 1; i++) {
        formData.append("files", files[i]);
      }
      formData.append("projectid", this.$root.projectid);
      console.log(formData);
      this.$axios
        .post("/resource/upload/shp", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          timeout: 0,
        })
        .then(res => {
          if (res.status == 200) {
            let data = res.data.data;

            this.count = data.count;
            this.fakepath = data.fakepath;
            this.records = data.records;
            this.fields = data.fields;

            this.$emit("srsChanged", data.srs);

            var fields = data.fields;
            for (var i = 0; i < fields.length; i++) {
              fields[i].value = data.records[0][i];
            }
            this.$emit("fieldsChanged", fields);
          }
        })
        .catch(err => {
          if (err.data) this.$root.$alert(err.data.msg || err.data.message);
        });
      event.target.value = "";
    }
  }
};
</script>
<style scoped>
.inputfile {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
}

.info_top {
  height: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-items: stretch;
  align-items: center;
}

.info_top > div:nth-child(1) {
  width: 150px;
  flex-grow: 0;
}

.info_top > div:nth-child(2) {
  flex-grow: 1;
}

.info_top > div:nth-child(2) input {
  flex-grow: 1;
  width: 100%;
}

.info_top > div:nth-child(3) {
  width: 100px;
  flex-grow: 0;
  text-align: center;
}

.info_top > div:nth-child(4) {
  width: 100px;
  flex-grow: 0;
  text-align: center;
}

.info_table {
  flex-grow: 1;
  overflow: auto;
  color: #ccc;
  text-align: center;
}

.info_table th {
  background: #4d4d4d;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 24px;
  color: #ccc;
}

.info_table td {
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
  background: #383838;
  line-height: 24px;
}
</style>

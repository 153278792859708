
    /* eslint-disable */
    import {
        Options,
        Vue
    } from "vue-class-component";

    @Options({
        props: {
            value: Number,
            minVal: {
                type: Number,
                default: 0,
            },
            maxVal: {
                type: Number,
                default: 1,
            }
        },
        emits: ['update:value'],
        watch: {
            value(val) {
                this.cval = val;
            },
            cval(val) {
                this.$emit('update:value', val);
            },
        },
    })
    export default class ColorSlider extends Vue {
        cval: number = 0;
        // constructor() {
        //     super();
        //     // @ts-ignore
        //     this.cval = this.value;
        // }

    }

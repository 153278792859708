<template>
  <div class="pipelinequery">
    <div class="chaxunline">
      <div class="condition">
        <div class="row_single">
          <label>起点ID</label>
          <input type="text" v-model="formData.qidian" @keyup.enter="requery" />
        </div>
        <div class="row_single">
          <label>终点ID</label>
          <input type="text" v-model="formData.zhongdian" @keyup.enter="requery" />
        </div>
        <div class="row_single">
          <label>图层ID</label>
          <input type="text" v-model="formData.tuceng" @keyup.enter="requery" />
        </div>
        <div class="row_single" v-for="(item, index) in formData.conditions" :key="index">
          <label>{{ customConditions[item.column_name] }}</label>
          <input v-show="item.column_type == 'BOOLEAN' || item.column_type == 'TEXT'" :class="
            switchType(item.column_type).type == 'checkbox'
              ? 'bty-switch'
              : 'none'
          " :type="switchType(item.column_type).type" :step="
  switchType(item.column_type).step
    ? switchType(item.column_type).step
    : null
" v-model="item.column_data" @keyup.enter="requery" />
          <input v-show="
            !(item.column_type == 'BOOLEAN' || item.column_type == 'TEXT')
          " :type="switchType(item.column_type).type" :step="
  switchType(item.column_type).step
    ? switchType(item.column_type).step
    : null
" v-model="item.min" @keyup.enter="requery" />
          <label v-show="
            !(item.column_type == 'BOOLEAN' || item.column_type == 'TEXT')
          ">~</label>
          <input v-show="
            !(item.column_type == 'BOOLEAN' || item.column_type == 'TEXT')
          " :type="switchType(item.column_type).type" :step="
  switchType(item.column_type).step
    ? switchType(item.column_type).step
    : null
" v-model="item.max" @keyup.enter="requery" />
        </div>
        <div class="row_single">
          <label>管径范围</label>
          <input type="number" v-model.number="formData.minguanjing" @keyup.enter="requery" />
          <label>~</label>
          <input type="number" v-model.number="formData.maxguanjing" @keyup.enter="requery" />
        </div>
        <div class="row_single">
          <label>管壁范围</label>
          <input type="number" v-model.number="formData.minguanbihou" @keyup.enter="requery" />
          <label>~</label>
          <input type="number" v-model.number="formData.maxguanbihou" @keyup.enter="requery" />
        </div>
        <div class="row_single">
          <label>创建时间</label>
          <input type="date" v-model="formData.create_start" @keyup.enter="requery" />
          <label>~</label>
          <input type="date" v-model="formData.create_end" @keyup.enter="requery" />
        </div>
        <div class="row_single">
          <label>更新时间</label>
          <input type="date" v-model="formData.update_start" @keyup.enter="requery" />
          <label>~</label>
          <input type="date" v-model="formData.update_end" @keyup.enter="requery" />
        </div>
        <div class="row_single">
          <label>条件</label>
          <select v-model="condition_select">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
          <select v-model="condition_Symbol">
            <option value="==">==</option>
            <option value="<">《</option>
            <option value=">">></option>
          </select>
          <input type="text" v-model="condition_content" />
        </div>
      </div>
      <div class="row_single operation">
        <button class="odline" @click="odLine" v-show="!showdelete">
          绘制查询范围
        </button>
        <button class="xbsj-button-tab" @click="clear" v-show="showdelete">
          清空
        </button>
        <button @click="requery" class="xbsj-button-tab">查询</button>
      </div>
    </div>
    <div class="tablePages">
      <div class="taNameline">
        <table cellspacing="3">
          <tr>
            <th>
              <label>
                <input type="checkbox" v-model="checked" @change="changeAllChecked" />全选
              </label>
              <br />
              <label>
                <input type="checkbox" v-model="unchecked" @change="unChangeAllChecked" />反选
              </label>
            </th>
            <th v-for="(item, index) in thearder" :key="index" @click="sort(item)" title="升序/降序">
              {{ item.name }}{{ item.descasc }}
            </th>
            <th v-for="(item, index) in otherfields" :key="index + thearder.length" @click="sort(item)" title="升序/降序">
              {{ item.name }}{{ item.descasc }}
            </th>
            <th style="min-width:150px !important">操作</th>
          </tr>
          <tr v-for="(item, index) in infos" :key="index" @dblclick="locate(item)">
            <td>
              <input type="checkbox" :value="item" v-model="checkedNames" />
            </td>
            <td>{{ item.qidian }}</td>
            <td>{{ item.zhongdian }}</td>
            <td>{{ item.tuceng }}</td>
            <td>{{ item.duanmian }}</td>
            <td>{{ Number(item.guanjing * 1000) }}</td>
            <td>{{ Number(item.guanbihou * 1000) }}</td>
            <td>{{ item.caizhi }}</td>
            <td>{{ item.qidianmaishen }}</td>
            <td>{{ item.zhongdianmaishen }}</td>
            <!-- <td>{{ item.create_t }}</td>
            <td>{{ item.update_t }}</td> -->
            <td v-for="(field) in otherfields" :key="field.zhname">
              {{ item[field.zhname] }}
            </td>
            <td><button @click="changeInfo(item)">修改</button><button @click="flip(item)">反向</button></td>
          </tr>
        </table>
      </div>
      <div class="pageline">
        <div>
          <label>
            <input type="checkbox" v-model="checkedAll" @change="checkedAllL" />
            全选{{ count }}条管线
          </label>
          <button @click="updateTucengPop">修改图层</button>
          <button @click="updateCaizhiPop">修改材质</button>
          <button @click="updateCangshuPop">修改参数</button>
          <button @click="deleteLine">删除</button>
          <button @click="refreshData">重构数据</button>
        </div>
        <div>
          <span class="upDown" @click="upPage">上一页</span>
          <span class="pageNumber">{{ formData.page }}/{{ pageNumber }}页</span>
          <span class="upDown" @click="downPage">下一页</span>
          <span>
            <span class="upDown" @click="runPage">跳转</span>
            <input v-model.number="formData.page" @keyup.enter="query" />页
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import parameterTool from "./ParameterTool";
import MaterialSelect from "./MaterialSelect";
import LayerTool from "./LayerTool";
import utils from "../math/utils";
import ModifyFieldInfo from "./ModifyFieldInfo.vue"
/* eslint-disable */
export default {
  components: {
    Window,
  },
  data() {
    return {
      condition_Symbol: "",
      condition_content: "",
      condition_select: "",
      creating: false,
      unchecked: false,
      showdelete: false,
      polylineData: [],
      flag: "",
      count: 0,
      checkedAll: false,
      checked: false,
      checkedNames: [],
      thearder: [
        {
          zhname: "qidian",
          name: "起点ID",
        },
        {
          zhname: "zhongdian",
          name: "终点ID",
        },
        {
          zhname: "tuceng",
          name: "图层",
        },
        {
          zhname: "duanmian",
          name: "断面",
        },
        {
          zhname: "guanjing",
          name: "管径(毫米)",
        },
        {
          zhname: "guanbihou",
          name: "管壁厚(毫米)",
        },
        {
          zhname: "caizhi",
          name: "材质",
        },
        {
          zhname: "qidianmaishen",
          name: "起点埋深",
        },
        {
          zhname: "zhongdianmaishen",
          name: "终点埋深",
        },
        // {
        //   zhname: "create_t",
        //   name: "创建时间",
        // },
        // {
        //   zhname: "update_t",
        //   name: "更新时间",
        // },
      ],
      otherfields: [],
      infos: "",
      itemsid: [],
      pageNumber: 1,
      formData: {
        sort: "", //排序 desc 降序，asc 升序
        sortfield: "", //排序字段
        pagecap: 20,
        page: 1,
        qidian: "",
        zhongdian: "",
        tuceng: "",
        conditions: [
          {
            column_name: "caizhi",
            column_type: "TEXT",
            column_data: undefined,
          },
          {
            column_name: "duanmian",
            column_type: "number",
            column_data: undefined,
          },
        ],
        minguanjing: "",
        maxguanjing: "",
        minguanbihou: "",
        maxguanbihou: "",
        create_start: "",
        create_end: "",
        update_start: "",
        update_end: "",
      },
      geojson: "",
      customConditions: {
        caizhi: "材质",
        duanmian: "断面",
      },
      showQuery: true,
      conditionsdefault: [],
    };
  },
  watch: {
    creating() {
      if (!this.creating) {
        console.log("绘制完成");
        if (this.polylineData.length > 0) {
          let arr = this.ArrayAdd(this.polylineData);
          if ([...arr].length) {
            this.geojson = JSON.stringify({
              type: "Polygon",
              coordinates: [arr],
            });
          }
        } else {
          this.geojson = "";
        }
      }
    },
    checkedNames: function () {
      if (
        this.checkedNames.length == this.infos.length &&
        this.checkedNames.length !== 0
      ) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    flag: function () {
      if (this.flag === true) {
        this.formData.sort = "asc"; //降序
      } else if (this.flag === false) {
        this.formData.sort = "desc"; //升序
      } else {
        this.formData.sort = ""; //默认排序
      }
    },
  },
  mounted() {
    this.getCustom();
    this.query();
    this._polyline = new XE.Obj.Plots.GeoPolygon(this.$root._earth);
    this._disposers = this._disposers || [];
    this._disposers.push(
      XE.MVVM.bind(this, "creating", this._polyline, "creating")
    );
  },
  methods: {
    flip(item) {
      let _this = this;
      let data = {
        projectid: this.$root.projectid,
        qidian: item.qidian,
        zhongdian: item.zhongdian
      };
      this.$axios
        .put("/project/line/flip", data)
        .then((res) => {
          _this.query();
          this.$root.$alert("修改成功");
        })
        .catch((err) => {
          this.$root.$log("error", err);
          console.log(err.response || err);
          this.$root.$alert("修改失败");
        });
    },
    changeInfo(item) {
      this.$root.showPropertyWindow("ModifyFieldInfo", {
        component: ModifyFieldInfo,
        param: {
          item: item,
          ok: (option) => {
            this.updateFieldInfo(item, option);
          },
        },
      }, 'line');
    },
    async updateFieldInfo(item, opt) {
      let _this = this;
      let data = {
        projectid: this.$root.projectid,
        qidian: item.qidian,
        zhongdian: item.zhongdian,
        param: opt,
      };
      this.$axios
        .put("/project/line/update/info", data)
        .then((res) => {
          _this.query();
          console.log(res);
          this.$root.$alert("修改成功");
        })
        .catch((err) => {
          this.$root.$log("error", err);
          console.log(err.response || err);
          this.$root.$alert("修改失败");
        });
    },
    requery() {
      this.formData.page = 1;
      this.query();
    },
    refreshData() {
      let _this = this;
      this.$root.$confirm("重构数据比较缓慢是否继续?", () => {
        if (this.checkedAll) {
          let params = utils.clone(this.formData, true);
          params.minguanjing = this.turnmi(params.minguanjing);
          params.maxguanjing = this.turnmi(params.maxguanjing);
          params.minguanbihou = this.turnmi(params.minguanbihou);
          params.maxguanbihou = this.turnmi(params.maxguanbihou);
          params.conditions = JSON.stringify(params.conditions);

          let data = {
            projectid: this.$root.projectid,
            qidian: params.qidian,
            zhongdian: params.zhongdian,
            tuceng: params.tuceng,
            conditions: this.conditionsdefault,
            minguanjing: params.minguanjing,
            maxguanjing: params.maxguanjing,
            minguanbihou: params.minguanbihou,
            maxguanbihou: params.maxguanbihou,
            create_start: params.create_start,
            create_end: params.create_end,
            update_start: params.update_start,
            update_end: params.update_end,
            geojson: this.geojson,
          };

          this.$axios
            .post("/project/line/rebuild", data)
            .then((res) => {
              _this.query();
              console.log(res);
              this.$root.$alert("任务已经添加，请在任务查询界面查看进度");
            })
            .catch((err) => {
              this.$root.$log("error", err);
              console.log(err.response || err);
              this.$root.$alert(err.data.message);
            });
        } else {
          let data = {
            projectid: this.$root.projectid,
            ids: this.checkedNames,
          };
          //console.log(JSON.stringify(data))

          this.$axios
            .post("/project/line/rebuild", data)
            .then((res) => {
              _this.query();
              console.log(res);
              this.$root.$alert("任务已经添加，请在任务查询界面查看进度");
            })
            .catch((err) => {
              this.$root.$log("error", err);
              console.log(err.response || err);
              this.$root.$alert(err.data.message);
            });
        }
      });
      (this.unchecked = false), (this.checked = false);
    },
    // 映射input类型
    switchType(type) {
      let step = undefined;
      switch (type) {
        case "TEXT":
          type = "text";
          break;
        case "INTEGER":
          type = "number";
          step = 1;
          break;
        case "DOUBLE":
          type = "number";
          step = 0.01;
          break;
        case "BOOLEAN":
          type = "checkbox";
          break;
        case "DATE":
          type = "date";
          break;
        default:
          type = "text";
      }
      return {
        type: type,
        step: step,
      };
    },
    // 映射字段类型
    yingshe(type) {
      switch (type) {
        case "text":
          type = "TEXT";
          break;
        case "integer":
          type = "INTEGER";
          break;
        case "double precision":
          type = "DOUBLE";
          break;
        case "boolean":
          type = "BOOLEAN";
          break;
        case "date":
          type = "DATE";
          break;
        default:
          type = "Text";
      }
      return type;
    },
    getCustom() {
      this.customConditions = {
        caizhi: "材质",
        duanmian: "断面",
      };
      this.formData.conditions = [
        {
          column_name: "caizhi",
          column_type: "TEXT",
          column_data: undefined,
        },
        {
          column_name: "duanmian",
          column_type: "TEXT",
          column_data: undefined,
        },
      ];
      this.$axios
        .get("/project/line/column/query", {
          params: {
            projectid: this.$root.projectid,
          },
        })
        .then((res) => {
          console.log("字段信息", res.data.data);
          let arr = res.data.data;
          if (arr instanceof Array && arr.length > 0) {
            arr.forEach((el) => {
              if (el.column_editable) {
                if (
                  el.column_datatype == "text" ||
                  el.column_datatype == "boolean"
                ) {
                  this.formData.conditions.push({
                    column_name: el.column_name,
                    column_type: this.yingshe(el.column_datatype),
                    column_data: undefined,
                  });
                } else {
                  this.formData.conditions.push({
                    column_name: el.column_name,
                    column_type: this.yingshe(el.column_datatype),
                    min: undefined,
                    max: undefined,
                  });
                }
                if (
                  this.thearder.find((t) => {
                    t.zhname == el.column_name;
                  }) == undefined
                ) {
                  this.otherfields.push({
                    zhname: el.column_name,
                    name: el.column_chinese || el.column_name,
                  });
                }
                this.customConditions[el.column_name] = el.column_chinese || el.column_name;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err || err.response);
        });
    },

    clear() {
      //清空几何图形
      if (this._polyline) {
        this._polyline.show = false;
        this._polyline.creating = false;
        this.polylineData = [];
        this.showdelete = false;
        this.showQuery = true;
        this.infos = [];
        this.pageNumber = 1;
        this.formData.page = 1;
        this.count = 0;
      }
      this.geojson = "";
      this.formData.geojson = "";
    },
    odLine() {
      this._polyline.positions = [];
      this._polyline._polygon.positions = [];
      this._polyline.enabled = true;
      this.infos = [];
      this.pageNumber = 1;
      this.formData.page = 1;
      this.count = 0;
      this._polyline.creating = true;
      XE.MVVM.watch(
        () => ({
          positions: [...this._polyline.positions],
        }),
        () => {
          if (this._polyline.positions.length > 3) {
            this.polylineData = [];
            let lat;
            let lng;
            for (
              let index = 0;
              index < this._polyline.positions.length;
              index++
            ) {
              lat = (this._polyline.positions[index][0] * 180) / Math.PI;
              lng = (this._polyline.positions[index][1] * 180) / Math.PI;
              this.polylineData.push([lat, lng]);
            }
          }
        }
      );

      this.showQuery = false;
      this.showdelete = true;
    },
    sort(item) {
      this.flag = !this.flag;
      if (this.flag === true) {
        item.descasc = "↓";
      } else {
        item.descasc = "↑";
      }
      this.formData.sortfield = item.zhname;
      this.query();
    },
    updateTucengPop() {
      //修改图层弹窗
      if (this.checkedNames.length > 0) {
        //console.log("选中的管线", this.itemsid)
        this.$root.showPropertyWindow("LayerTool", {
          component: LayerTool,
          param: {
            ok: (option) => {
              //console.log("( •̀ ω •́ )y拿到了图层数据", option);
              if (option) {
                this.updateTuceng(option);
              }
            },
          },
        });
        (this.unchecked = false), (this.checked = false);
      } else {
        this.$root.$alert("未选中数据");
      }
    },
    updateTuceng(opt) {
      //修改图层
      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.minguanjing = this.turnmi(params.minguanjing);
        params.maxguanjing = this.turnmi(params.maxguanjing);
        params.minguanbihou = this.turnmi(params.minguanbihou);
        params.maxguanbihou = this.turnmi(params.maxguanbihou);
        params.conditions = JSON.stringify(params.conditions);
        //console.log("内容", params)
        let data = {
          projectid: this.$root.projectid,
          qidian: params.qidian,
          zhongdian: params.zhongdian,
          tuceng: params.tuceng,
          conditions: this.conditionsdefault,
          minguanjing: params.minguanjing,
          maxguanjing: params.maxguanjing,
          minguanbihou: params.minguanbihou,
          maxguanbihou: params.maxguanbihou,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          tuceng_update: opt.id,
          ilayer_update: opt.id,
          geojson: this.geojson,
        };
        this.$axios
          .put("/project/line/layer/update/conditions", data)
          .then((res) => {
            this.query();
            //console.log(res);

            this.$root.$alert("全部图层修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);
            //console.log(err.response || err);
            this.$root.$alert("全部图层修改失败");
          });
      } else {
        this.itemsIdPush();
        let data = {
          projectid: this.$root.projectid,
          tuceng: opt.id,
          ilayer: isNaN(opt.id) ? opt.id : parseFloat(opt.id),
          ids: this.itemsid,
        };
        //console.log("修改图层选中的管线", data)
        this.$axios
          .put("/project/line/layer/update/ids", data)
          .then((res) => {
            this.query();

            console.log(res);
            this.$root.$alert("图层修改成功");
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("图层修改失败");
          });
      }
    },
    locate(item) {
      this.$root._tileset.selectFeature({
        id: item.qidian + "_" + item.zhongdian,
        otype: "pipe",
      });
    },
    checkedAllL() {
      if (this.checkedAll) {
        this.checked = true;
        this.checkedNames = this.infos;
      } else {
        this.checkedNames = [];
      }
    },
    itemsIdPush() {
      this.itemsid = [];
      for (let index = 0; index < this.checkedNames.length; index++) {
        this.itemsid.push([
          this.checkedNames[index].qidian,
          this.checkedNames[index].zhongdian,
        ]);
      }
    },
    unChangeAllChecked() {
      let new_arrB = [];
      this.infos.map((item) => {
        if (this.checkedNames.indexOf(item) < 0) {
          new_arrB.push(item);
        }
      });
      this.checkedNames = new_arrB;
    },
    changeAllChecked() {
      if (this.checked) {
        this.checkedNames = this.infos;
      } else {
        this.checkedNames = [];
      }
    },
    turnmi(a) {
      if (typeof a === "number") {
        return a / 1000;
      }
      return "";
    },
    async query() {
      let _this = this;
      console.log("查询");
      if (this.geojson != "") {
        this.formData.geojson = this.geojson;
      }
      let params = utils.clone(this.formData, true);
      params.minguanjing = this.turnmi(params.minguanjing);
      params.maxguanjing = this.turnmi(params.maxguanjing);
      params.minguanbihou = this.turnmi(params.minguanbihou);
      params.maxguanbihou = this.turnmi(params.maxguanbihou);
      params.conditions = JSON.stringify(params.conditions);
      this.conditionsdefault = params.conditions;

      this.$axios
        .get("/project/line/query", {
          params: {
            projectid: this.$root.projectid,
            param: JSON.stringify(params),
          },
        })
        .then((res) => {
          if (res.data) {
            _this.infos = res.data.data.results;
            this.count = res.data.data.count;
            _this.pageNumber = Math.ceil(
              res.data.data.count / _this.formData.pagecap
            );
          }
        })
        .catch((err) => {
          this.$root.$log("error", err);
          console.log(err.response || err);
        });
      (this.unchecked = false), (this.checked = false);
      this.checkedAll = false;
      this.checkedNames = [];
    },
    ArrayAdd(arr) {
      let a = [...new Set(arr)]; //去重加首尾相同
      a.push(a[0]);
      return a;
    },
    deleteLine() {
      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.minguanjing = this.turnmi(params.minguanjing);
        params.maxguanjing = this.turnmi(params.maxguanjing);
        params.minguanbihou = this.turnmi(params.minguanbihou);
        params.maxguanbihou = this.turnmi(params.maxguanbihou);
        params.conditions = JSON.stringify(params.conditions);
        let data = {
          projectid: this.$root.projectid,
          tuceng: params.tuceng,
          conditions: this.conditionsdefault,
          minguanjing: params.minguanjing,
          maxguanjing: params.maxguanjing,
          minguanbihou: params.minguanbihou,
          maxguanbihou: params.maxguanbihou,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          geojson: this.geojson,
        };
        this.$root.$confirm("确认删除全部" + this.count + "条管线?", () => {
          this.$axios
            .delete("/project/line/delete/conditions", {
              data,
            })
            .then((res) => {
              this.query();
              console.log(res);
              this.$root.$alert("全部管线删除成功");
              if (res.data.data && res.data.data === true) {
                this.$root._tileset.reload();
              }
            })
            .catch((err) => {
              this.$root.$log("error", err);
              console.log(err.response || err);
              this.$root.$alert("全部管线删除失败");
            });
        });
      } else {
        if (this.checkedNames.length > 0) {
          let _this = this;
          this.itemsIdPush();
          let data = {
            projectid: _this.$root.projectid,
            ids: this.itemsid,
          };
          this.$root.$confirm("确认删除管线?", () => {
            this.$axios
              .delete("/project/line/delete/ids", {
                data,
              })
              .then((res) => {
                this.query();
                console.log(res);

                this.$root.$alert("管线删除成功");
                if (res.data.data && res.data.data === true) {
                  this.$root._tileset.reload();
                }
              })
              .catch((err) => {
                this.$root.$log("error", err);
                console.log(err.response || err);
                this.$root.$alert("管线删除失败");
              });
          });
        } else {
          this.$root.$alert("未选中数据");
        }
      }
      (this.unchecked = false), (this.checked = false);
    },
    updateCangshuPop() {
      if (this.checkedNames.length > 0) {
        //更新参数弹窗
        this.$root.showPropertyWindow(
          "parameterTool",
          {
            component: parameterTool,
            param: {
              ok: (option) => {
                //console.log("( •̀ ω •́ )y拿到了参数", option);
                this.updateCangshu(option);
              },
            },
          },
          {
            type: "circle",
            profile: {
              radius: 0.05, //不能为0
              thickness: 0.01,
            },
            flange: {
              length: 0.01,
              thickness: 0.01,
            },
          }
        );
        (this.unchecked = false), (this.checked = false);
      } else {
        this.$root.$alert("未选中数据");
      }
    },
    updateCangshu(opt) {
      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.minguanjing = this.turnmi(params.minguanjing);
        params.maxguanjing = this.turnmi(params.maxguanjing);
        params.minguanbihou = this.turnmi(params.minguanbihou);
        params.maxguanbihou = this.turnmi(params.maxguanbihou);
        params.conditions = JSON.stringify(params.conditions);
        let data = {
          projectid: this.$root.projectid,
          qidian: params.qidian,
          zhongdian: params.zhongdian,
          tuceng: params.tuceng,
          conditions: this.conditionsdefault,
          minguanjing: params.minguanjing,
          maxguanjing: params.maxguanjing,
          minguanbihou: params.minguanbihou,
          maxguanbihou: params.maxguanbihou,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          geojson: this.geojson,
          param_j: JSON.stringify(opt),
        };

        this.$axios
          .put("/project/line/shape/update/conditions", data)
          .then((res) => {
            this.query();
            console.log(res);

            this.$root.$alert("全部参数修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);

            console.log(err.response || err);
            this.$root.$alert("全部参数修改失败");
          });
      } else {
        //修改参数
        this.itemsIdPush();
        let data = {
          ids: this.itemsid,
          projectid: this.$root.projectid,
          param_j: JSON.stringify(opt),
        };
        // console.log(data1)

        this.$axios
          .put("project/line/shape/update/ids", data)
          .then((res) => {
            this.query();
            console.log(res);

            this.$root.$alert("选中参数修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);

            console.log(err.response || err);
            this.$root.$alert("选中参数修改失败");
          });
      }
      (this.unchecked = false), (this.checked = false);
    },
    updateCaizhiPop() {
      //修改材质弹窗
      if (this.checkedNames.length > 0) {
        this.$root.showPropertyWindow("MaterialSelect", {
          component: MaterialSelect,
          param: {
            ok: (caizhi) => {
              console.log("showPropertyWindow材质" + caizhi);
              this.updateCaizhi(caizhi);
            },
          },
        });
        (this.unchecked = false), (this.checked = false);
      } else {
        this.$root.$alert("未选中数据");
      }
    },
    updateCaizhi(opt) {
      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.minguanjing = this.turnmi(params.minguanjing);
        params.maxguanjing = this.turnmi(params.maxguanjing);
        params.minguanbihou = this.turnmi(params.minguanbihou);
        params.maxguanbihou = this.turnmi(params.maxguanbihou);
        params.conditions = JSON.stringify(params.conditions);
        let data = {
          projectid: this.$root.projectid,
          qidian: params.qidian,
          zhongdian: params.zhongdian,
          tuceng: params.tuceng,
          conditions: this.conditionsdefault,
          minguanjing: params.minguanjing,
          maxguanjing: params.maxguanjing,
          minguanbihou: params.minguanbihou,
          maxguanbihou: params.maxguanbihou,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          caizhi_update: opt.name,
          caizhi_j: JSON.stringify(opt.param),
          geojson: this.geojson,
        };
        this.$axios
          .put("/project/line/material/update/conditions", data)
          .then((res) => {
            this.query();
            console.log(res);
            this.$root.$alert("全部材质修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);

            console.log(err.response || err);
            this.$root.$alert("全部材质修改失败");
          });
      } else {
        //修改管线材质请求

        this.itemsIdPush();
        let data = {
          projectid: this.$root.projectid,
          caizhi: opt.name,
          ids: this.itemsid,
          caizhi_j: JSON.stringify(opt.param),
        };
        console.log("选中材质修改", data);

        this.$axios
          .put("/project/line/material/update/ids", data)
          .then((res) => {
            this.query();
            //console.log(res);
            this.$root.$alert("选中材质修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("选中材质修改失败");
          });
      }
      (this.unchecked = false), (this.checked = false);
    },
    upPage() {
      let _this = this;
      this.checkedAll = false;
      this.checkedNames = [];
      (this.unchecked = false), (this.checked = false);
      if (this.formData.page <= 1) {
        _this.formData.page = 1;
      } else {
        _this.formData.page -= 1;
        this.query();
      }

      // console.log(this.formData.page)
    },
    downPage() {
      let _this = this;
      this.checkedAll = false;
      this.checkedNames = [];
      (this.unchecked = false), (this.checked = false);
      if (this.formData.page >= this.pageNumber) {
        _this.formData.page = this.pageNumber;
      } else {
        _this.formData.page += 1;
        this.query();
      }
      //console.log(this.formData.page)
    },
    runPage() {
      let _this = this;
      this.checkedAll = false;
      this.checkedNames = [];
      (this.unchecked = false), (this.checked = false);
      if (this.formData.page <= this.pageNumber && this.formData.page >= 1) {
        _this.query();
      } else {
        _this.formData.page = 1;
        _this.query();
      }
    },
  },
  beforeUnmount() {
    this.clear();
    //this.$root._tileset.unSelectFeature();
  },
};
</script>
<style scoped>
.pipelinequery {
  margin-top: 5px;
  padding: 0px 5px;
  text-align: center;
  line-height: 18px;
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  color: #ccc;
}

.condition {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.condition>div {
  margin-top: 5px;
}

.operation {
  margin-top: 5px;
}

.tablePages {
  padding: 5px 5px;
  width: 100%;
  height: 100%;
}

.odline {
  width: 100px;
}

.tablePages input {
  vertical-align: middle;
}

.taNameline {
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
}

.taNameline>table {
  margin: 0 auto;
  text-align: center;
  cursor: default;
  border: 1px solid #383838;
  width: 100%;
}

.taNameline>table>tr>th {
  box-sizing: border-box;
  height: 30px;
  text-align: center;
  cursor: default;
  background-color: #383838;
  color: #ccc;
}

.taNameline>table>tr>td {
  box-sizing: border-box;
  height: 30px;
  max-height: 30px;
  line-height: 15px;
  text-align: center;
  cursor: default;
  background: #2e2e2e;
  border: 1px solid #383838;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
}

.taNameline>table>tr:hover td {
  background-color: #288dfb;
  opacity: 0.5;
}

.taNameline>table>tr>th:nth-child(1) {
  min-width: 50px;
}

.row_single {
  width: 380px;
  display: flex;
  align-items: center;
}

.row_single>label {
  flex-shrink: 0;
  width: 30px;
}

.row_single>label:nth-child(1) {
  flex-shrink: 0;
  width: 75px;
}

.row_single>input {
  min-width: 10px;
  box-sizing: border-box;
  flex-grow: 1;
  background: #2e2e2e;
  border: 1px solid #383838;
  border-radius: 2px;
  color: #ccc;
  height: 30px;
  line-height: 30px;
}

.row_single>button {
  width: 100%;
}

.row_single>button:last-child {
  margin-left: 3px;
}

.upDown:hover {
  cursor: pointer;
  color: #288dfb;
}

.pageline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-top: 5px;
  padding-left: 25px;
}

.pageline>div>span {
  text-align: center;
  margin-right: 5px;
}

.pageline>div>button {
  margin-right: 3px;
}

.pageline>div>span>input {
  text-align: center;
  width: 50px;
  height: 30px;
  background: #2e2e2e;
  border: 1px solid #383838;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  margin: 0 3px;
}

select {
  text-align: center;
  margin-right: 5px;
  width: 100px;
  height: 25px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  color: #ccc;
}
</style>

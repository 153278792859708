/* eslint-disable */
import {
    createStore
} from 'vuex'

const store = createStore({
    getters: {},
    state: {
        loadingShow: true,
    },
    mutations: {
        showLoading(state) {
            state.loadingShow = true
        },
        hideLoading(state) {
            state.loadingShow = false
        }
    },
    actions: {},
})
export default store
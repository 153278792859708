import * as zrender from "zrender";
// Y轴
/* eslint-disable */
class AxisY {
  intervalCnt = 5; //刻度数量
  constructor() {}

  draw(centery, offsety,offset, height, scale, zr) {
    //间距：像素
    let intervalDis = height / this.intervalCnt;

    // 间距：像素————>毫米（并向下取整）
    let TurnIntervalDis = Math.floor(
      (height / this.intervalCnt / scale) * 1000
    ); //转成毫米并为整数的间隔距离(mm)

    // 间距：(整数)毫米————>像素
    intervalDis = (TurnIntervalDis / 1000) * scale;

    // 转毫米offsety
    let mmoffsety = centery * 1000;
    let centerPointText = Math.ceil(mmoffsety);
    let centerPointX = height - (centerPointText / 1000 + offsety) * scale;

    let downPointX = centerPointX;
    let downPointText = centerPointText;
    while (downPointX < 250) {
      // 刻度线
      let line = new zrender.Line({
        style: {
          stroke: "#fff",
        },
        shape: {
          x1: 3,
          y1: downPointX - offset,
          y2: downPointX - offset,
          x2: 8,
        },
      });
      zr.add(line);
      // 刻度标签
      var text = new zrender.Text({
        style: {
          text: `${downPointText.toFixed(0)}`,
          fontSize: 12,
          textFill: "#000",
          fill: "#fff",
          textBackgroundColor: "#000",
        },
      });
      text.attr("position", [6, downPointX - offset - 8]);
      zr.add(text);
      downPointX += intervalDis;
      downPointText -= TurnIntervalDis;
    }

    let upPointX = centerPointX - intervalDis;
    let upPointText = centerPointText + TurnIntervalDis;
    while (upPointX > 0) {
      // 刻度线
      let line = new zrender.Line({
        style: {
          stroke: "#fff",
        },
        shape: {
          x1: 3,
          y1: upPointX - offset,
          y2: upPointX - offset,
          x2: 8,
        },
      });
      zr.add(line);
      // 刻度标签
      var text = new zrender.Text({
        style: {
          text: `${upPointText.toFixed(0)}`,
          fontSize: 12,
          textFill: "#000",
          fill: "#fff",
          textBackgroundColor: "#000",
        },
      });
      text.attr("position", [10, upPointX - offset - 8]);
      zr.add(text);
      upPointX -= intervalDis;
      upPointText += TurnIntervalDis;
    }

    // 纵向Y轴的直线
    let yaxios = new zrender.Line({
      style: {
        stroke: "#fff",
      },
      shape: {
        x1: 3,
        y1: 0,
        y2: height,
        x2: 3,
      },
    });
    zr.add(yaxios);
    // y轴单位
    var text = new zrender.Text({
      style: {
        text: "地面高程(mm)",
        fontSize: 12,
        textFill: "#000",
        fill: "#fff",
        textBackgroundColor: "#000",
      },
    });
    text.attr("position", [3, 5]);
    zr.add(text);
  }
}
export default AxisY;

<template>
    <div class="property">影像属性
        <div>
            <label>url</label> <input type="text" v-model="url">
        </div>
        <div>
            <label>srcCoordType</label> <input type="text" v-model="srcCoordType">
        </div>
        <div>
            <label>dstCoordType</label> <input type="text" v-model="dstCoordType">
        </div>
        <div>
            <label>maximumLevel</label> <input type="text" v-model.number="maximumLevel">
        </div>
        <div>
            <button @click="save">确认保存</button>
        </div>



    </div>
</template>
<script>
    /* eslint-disable */
    export default {
        data() {
            return {

                url: "",
                srcCoordType: "",
                dstCoordType: "",
                maximumLevel: 0

            }
        },
        props: {
            param: Object
        },
        created() {},
        mounted() {
            console.log("nihao")
            console.log("信息", this.param)
            this.bind("url")
            this.bind("srcCoordType")
            this.bind("dstCoordType")
            this.bind("maximumLevel")

            // this.XbsjImageryProvider = this.param.xbsjImageryProvider.XbsjImageryProvider
        },
        methods: {
            bind(prp) {
                this._viewUnbinds = this._viewUnbinds || [];
                this._viewUnbinds.push(
                    XE.MVVM.bind(this, prp, this.param.xbsjImageryProvider.XbsjImageryProvider, prp)
                );
            },
            save() {
                this.$axios.put("/project/info/update", {
                    projectid: this.$root.projectid,
                    basemap: JSON.stringify(this.$root._earth.sceneTree.root.children)
                }).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err.response || err)
                })
            },
        },
        beforeUnmount() {}
    };
</script>

<style scoped>
    .property {
        width: var(--RightWidth);
        color: #CCCCCC;
        width: 100%;
        height: 100%;
        background: var(--PanelBGColor);
    }

    .property>div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .property>div>label {
        width: 100px;
        text-align: end;
        padding: 0 10px;
    }

    .property>div>input {
        height: 24px;
        background: #383838;
        border: 1px solid #4D4D4D;
        opacity: 1;
        border-radius: 2px;
        color: #CCC;
    }
</style>>
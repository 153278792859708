<template>
    <div class="connectivity">
        <div class="left">
            <div class="start">
                <h4>{{ getInfo() }}</h4>
            </div>
            <div class="start">
                <label>起点:</label>
                <span>{{ qidian }}</span>
                <button @click="clearQidian">清除</button>
            </div>
            <div class="end">
                <label>终点:</label>
                <span>{{ zhongdian }}</span>
                <button @click="clearZhongdian">清除</button>
            </div>
            <div class="submit">
                <button @click="ok">确定</button>
            </div>
        </div>
        <div class="right">
            <table cellspacing="0" cellpadding="0">
                <tr>
                    <th v-for="(item, index) in thearder" :key="index">{{ item }}</th>
                </tr>
                <tr v-for="(item, index) in infos" :key="index">
                    <td>{{ item.qidian }}</td>
                    <td>{{ item.zhongdian }}</td>
                    <td>{{ item.caizhi }}</td>
                    <td>{{ item.tuceng }}</td>
                    <td>{{ item.duanmian }}</td>
                    <td>{{ item.guanjing }}</td>
                    <td>{{ item.guanbihou }}</td>
                    <td>{{ item.length.toFixed(2) }}</td>
                </tr>
            </table>
            <div>{{ total }}</div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
	components: {},
	data() {
		return {
			qidian: undefined,
			zhongdian: undefined,
			thearder: ['起点ID', '终点ID', '材质', '图层', '断面', '管径', '管壁厚', '长度'],
			infos: [],
			total: '',
			qidianProjectId:undefined,
			zhongdianProjectId:undefined
		}
	},
	mounted() {
		//启动鼠标交互
		this.$root.$enablePicking()
		//截获选择管点事件
		this.$bus.on('pickPoint', this.pickPoint)
	},
	methods: {
		pickPoint(obj) {
			this.getPoint(obj)
		},
		clearQidian() {
			this.qidian = undefined
			if (this._qidianLabel) {
				this._qidianLabel.destroy()
				this._qidianLabel = undefined
			}
			if (this._temline) {
				this._temline.destroy()
				this._temline = undefined
			}
		},
		clearZhongdian() {
			this.zhongdian = undefined
			if (this._zhongdianLabel) {
				this._zhongdianLabel.destroy()
				this._zhongdianLabel = undefined
			}
			if (this._temline) {
				this._temline.destroy()
				this._temline = undefined
			}
		},
		getPoint(obj) {
			let id = obj.id
			let point = obj.geom.coordinates
			if (!this.qidian) {
				if (this.zhongdian != id) {
					this.qidianProjectId = obj.projectid
					this.qidian = id
					if (this._qidianLabel) {
						this._qidianLabel.destroy()
						this._qidianLabel = undefined
					}
					this._qidianLabel = this.$root.$createLabel('起点:' + id)
					this._qidianLabel.position = [(point[0] * Math.PI) / 180, (point[1] * Math.PI) / 180, point[2]]
				}
			} else if (!this.zhongdian) {
				if (this.qidian != id) {
					this.zhongdian = id
					this.zhongdianProjectId = obj.projectid
					if (this._zhongdianLabel) {
						this._zhongdianLabel.destroy()
						this._zhongdianLabel = undefined
					}
					this._zhongdianLabel = this.$root.$createLabel('终点:' + id)
					this._zhongdianLabel.position = [(point[0] * Math.PI) / 180, (point[1] * Math.PI) / 180, point[2]]
				}
			}
		},
		getInfo() {
			if (!this.qidian) return '请单击选择管线起点'
			if (!this.zhongdian) return '请单击选择管线终点'
			return ''
		},
		ok() {
			if (!this.qidian) {
				this.$root.$alert('请选择起点')
				return
			}
			if (!this.zhongdian) {
				this.$root.$alert('请选择终点')
				return
			}
			if (this.qidian == this.zhongdian) {
				this.$root.$alert('起点和终点不能相同')
				return
			}
			let projectid = this.$root.projectid;
			if(this.qidianProjectId && this.zhongdianProjectId){
				if(this.qidianProjectId != this.zhongdianProjectId){
					this.total = '共0条管线，总长0米'
					return;
				}else{
					projectid = this.qidianProjectId
				}
			}
			let data = {
				projectid: projectid,
				qidian: this.qidian,
				zhongdian: this.zhongdian
			}
			this.$axios
				.get('/project/line/connectivity', {
					params: data
				})
				.then(res => {
					let result = res.data.data
					this.infos = result.lines
					if (this._temline) {
						this._temline.destroy()
						this._temline = undefined
					}
					if (this.infos) {
						this.total = '共' + this.infos.length + '条管线，总长' + result.length.toFixed(0) + '米'
					} else {
						this.total = '共0条管线，总长0米'
					}
					// this._temline = new XE.Obj.Polyline(this.$root._earth);
					// this._temline.width = 10;
					// this._temline.material.type = 'XbsjODLineMaterial';
					// this._temline.material.XbsjODLineMaterial.image = './image/02.png';
					// result.points.forEach(p => {
					//     this._temline.positions.push([
					//         (p.x * Math.PI) / 180,
					//         (p.y * Math.PI) / 180,
					//         p.z,
					//     ]);
					// });
					// let length = this.$root.$getLengthOfLine(this._temline);
					// this._temline.material.XbsjODLineMaterial.useImageAndRepeat = [1, 1];
					this._temline = new XE.Obj.CustomPrimitiveExt.Tube(this.$root._earth)
					result.points.forEach(p => {
						this._temline.positions.push([(p.x * Math.PI) / 180, (p.y * Math.PI) / 180, p.z])
					})
					this._temline.radius = 2
					this._temline.radialSegments = 4
					this._temline.imageUrl = './image/03.png'
					this._temline.textureSize = [Math.PI, Math.PI]
					this._temline.speed = [-2, 0]
					this._temline.isCurve = false
					//             var condition = [
					//                 `\${id} === '${element.qidian + '_' + element.zhongdian}'`,
					//                 `rgba(255,0, 0, 0.5)`,
					//             ];
					//             conditions.push(condition);
					//             var qidian = [`\${id} === '${element.qidian}'`, `rgba(255,0, 0, 0.5)`];
					//             conditions.push(qidian);
					//         });
					//         var style = `var style = {
					//       color: {
					//         conditions: ${JSON.stringify(conditions)}
					//       }
					//   }`;
					//         console.log(style);
					//         this.$root._tileset.tileset.xbsjStyle = style;
					//关闭显示
					// this.$root.destroyTool(this);
				})
				.catch(err => {
					this.$root.$log('error', err)
				})
		},
		cancel() {
			this.$root.destroyTool(this)
		}
	},
	beforeUnmount() {
		if (this._qidianLabel) {
			this._qidianLabel.destroy()
		}
		if (this._zhongdianLabel) {
			this._zhongdianLabel.destroy()
		}
		if (this._temline) {
			this._temline.destroy()
		}
		//停止监控地球鼠标点击事件
		// var style = `var style = {
		//           color: {
		//             conditions: []
		//           }
		//       }`;
		// this.$root._tileset.tileset.xbsjStyle = style;
		this.$bus.off('pickPoint', this.pickPoint)
		// this.$root._tileset.pointClicked.removeEventListener(this.getPoint, this);
	}
}
</script>

<style scoped>
button {
	width: 30px;
}
.connectivity {
	height: 250px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	overflow: auto;
	line-height: 30px;
	padding-top: 20px;
	box-sizing: border-box;
}

.connectivity > div {
	flex-grow: 1;
}

.left {
	display: flex;
	flex-direction: column;
	width: 26%;
	border-right: 1px solid #4d4d4d;
	margin-right: 20px;
}

.right {
	width: 74%;
}

.start,
.end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 30px;
	line-height: 30px;
	margin-bottom: 10px;
	padding-right: 20px;
	padding-left: 20px;
}

.start > h4 {
	width: 100%;
	text-align: start;
}

.left > div > span {
	display: inline-block;
	width: 150px;
	height: 30px;
	line-height: 30px;
	background: #383838;
	border: 1px solid #4d4d4d;
	opacity: 1;
	border-radius: 2px;
	text-align: start;
	margin-right: 10px;
	margin-left: 10px;
}

.left > div:nth-child(4) {
	display: flex;
	justify-content: flex-end;
	height: 30px;
	line-height: 30px;
	margin-bottom: 10px;
	padding-right: 20px;
}

table {
	width: 100%;
}

table > tr > td,
table > tr > th {
	height: 22px !important;
	line-height: 22px;
	border-left: 1px solid rgb(211, 198, 198);
	border-right: none;
	border-bottom: 1px solid rgb(211, 198, 198);
	text-align: center;
	background: rgb(0, 0, 0, 0);
}

table > tr:first-child > th {
	border-top: 1px solid rgb(211, 198, 198);
}

table > tr > td:last-child,
table > tr > th:last-child {
	border-right: 1px solid rgb(211, 198, 198);
}
</style>

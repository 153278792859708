<template>
  <div>
    <Window :title="'批量修改附属物大小'"
      :footervisible="true"
      @ok="ok"
      @cancel="cancel"
      v-show="show"
      :width="420"
      :height="340">
      <div class="editPoint">
        <div>
          <label>所选管点的附属物缩放值将被设置为以下值（必须大于0），若不勾选则保留原值</label>
        </div>
        <br />
        <div>
          <label><input type="checkbox"
              v-model="computeScalex" />X方向缩放:</label>
          <input v-model.number="scalex"
            v-number-fixed="2" />
        </div>
        <div>
          <label><input type="checkbox"
              v-model="computeScaley" />Y方向缩放:</label>
          <input v-model.number="scaley"
            v-number-fixed="2" />
        </div>
        <div>
          <label><input type="checkbox"
              v-model="computeScalez" />Z方向缩放:</label>
          <input v-model.number="scalez"
            v-number-fixed="2" />
        </div>
      </div>
    </Window>
  </div>
</template>
<script>
/* eslint-disable */
import Window from "../../../components/Window";

export default {
  components: {
    Window,
  },
  data() {
    return {
      show: true,
      computeScalex: true,
      computeScaley: true,
      computeScalez: true,
      scalex: 1,
      scaley: 1,
      scalez: 1
    };
  },
  props: {
    param: {
      type: Object,
      default: undefined,
    },
  },
  mounted() {
    this.scalex = this.param.point.scalex;
    this.scaley = this.param.point.scaley;
    this.scalez = this.param.point.scalez;
  },
  methods: {
    tabSwitch(tab) {
      this.tab = tab;
    },
    ok() {
      this.$root.destroyTool(this);
      let options = {}
      if (this.computeScalex) {
        options.scalex = this.scalex
      }
      if (this.computeScaley) {
        options.scaley = this.scaley
      }
      if (this.computeScalez) {
        options.scalez = this.scalez
      }
      this.param.ok(options);
    },
    cancel() {
      this.$root.destroyTool(this);
    },
  },
};
</script>
<style scoped>
.appendages_top {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
}

.appendages_top > input {
  box-sizing: border-box;
  width: 430px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  margin-right: 4px;
}

.appendages_top > button {
  margin-right: 4px;
}

.appendages_content {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 100px);
  overflow: auto;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.appendages_content > div {
  width: 100px;
  height: 132px;
  margin-left: 12px;
}

.thumbnail {
  position: relative;
  width: 100px;
  height: 100px;
  border: 2px solid #1a1a1a;
  opacity: 1;
  margin-bottom: 8px;
}

.thumbnail > span {
  width: 100%;
  height: 20px;
  line-height: 20px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  background: #000;
  opacity: 0.5;
  text-align: center;
  color: #ccc;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.operation {
  display: flex;
  justify-content: space-around;
}

.operation > img {
  cursor: pointer;
}

.appendages_content > div:last-child:hover {
  cursor: pointer;
}

.param,
.static {
  width: 100%;
  height: 100%;
}

.param {
  display: flex;
}

.param_left {
  width: 60%;
}

.param_right {
  width: 40%;
  overflow: auto;
}

.tab_static,
.tab_param {
  background: #295f99;
}

.cesiumContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #242424;
}

.parameters_top {
  display: flex;
  height: 28px;
  align-items: center;
  justify-content: center;
}

.parameters_top > div:nth-child(2) {
  display: flex;
  border: 1px solid #999595;
  flex-grow: 1;
  height: 24px;
  align-items: center;
}

.parameter_content {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.parameter_content > div:nth-child(1) {
  width: 670px;
  flex-grow: 0;
  border-right: none;
  height: 100%;
}

.parameter_content > div:nth-child(2) {
  width: 300px;
  flex-grow: 1;
  height: 100%;
}

.right {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
  padding-top: 10px;
}

.right > select {
  width: 220px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.right > label {
  width: 110px;
  text-align: center;
}

.right > button,
.right > input {
  width: 220px;
  height: 30px;
  line-height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.preview {
  width: 220px;
  height: 30px;
  background: #383838;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.jsoncontent {
  width: 100%;
  font-size: 16px;
  margin-top: 30px;
  word-wrap: break-word;
  margin-bottom: 40px;
}
</style>

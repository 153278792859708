<template>
    <div class="alert_modal" style="">
        <div class="alert_top" :style="{ width: width/2 + 'px',height: 120 + 'px'}"></div>
        <div class="alert" :style="{ width: width + 'px',height: minHeight + 'px',lineHeight: height + 'px' }">

            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Alert",
        props: {
            width: {
                type: Number,
                default: 200
            },
            height: {
                type: Number,
                default: 100
            },
        },
        data() {
            return {};
        },
        methods: {}
    };
</script>


<style scoped>
    .alert_modal {
        /* 不允许界面其他部分进行操作 */
        position: fixed;
        top: 0px;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20000;

    }

    .alert {
        position: absolute;
        top: 120px;
        background-color: var(--PanelBGColor);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        border: 1px solid #383838;
        color: #ccc;
        vertical-align: middle;
        padding-left: 10px;
        padding-bottom: 10px;
        line-height: 20px;
    }

    .alert_top {
        position: absolute;
        top: 0px;
        border-right: 4px solid var(--PanelBGColor);
        border-left: 4px solid var(--PanelBGColor);
    }
</style>
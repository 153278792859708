<template>
    <div class="component">
        <div class="title">基础场景配置</div>
        <div class="tool">
            <button @click="addImageryOnline()">添加影像</button>
            <button @click="addModelOnline()">添加瓦片</button>
            <button @click="addTerrainOnline()">添加地形</button>
        </div>
        <div class="scenetree">
            <VirtualTree ref="vtree" :tree="tree" @on-context-menu="contextMenu" @contextmenu.prevent="onContexMenu"
                @on-edited="onEdited" @on-item-drop="dropItem" @on-toggle-expand="toggleExpand"
                @on-toggle-checked="toggleChecked" @on-toggle-selected="onToggleSelected" @on-dblclick="itemDoubleClick"
                @on-item-move="moveItem" style="font-size: 14px">
            </VirtualTree>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import VirtualTree from "../../../components/VirtualTree/index";
import TerrainOnline from "./TerrainService/TerrainOnline";
import ModelOnline from "./ModelService/ModelOnline";
import ImageryOnline from "./ImagryService/ImageryOnline";

export default {
    name: "BaseScene",
    components: {
        VirtualTree
    },
    data() {
        return {
            show: true,
            tree: [],
            vueItemTotal: 0,
            index: 0,
            num: 0,
            lang: {
                confirm: "确认删除图层?",
                delete: "删除",
                rename: "重命名",
                property: "属性",
                addFolder: "添加文件夹",
                moving: "拖拽移动",
                newFolder: "新建文件夹",
                title: "图层管理",
                cameraAttached: "相机绑定"
            }
        };
    },
    mounted() {
        this.$bus.on("initearth", () => {
            let _this = this;

            let scenetree = _this.$root._earth.sceneTree;
            console.log("测试", scenetree)
            if (this.$root.projectid) {
                this.$axios
                    .get("/project/info/basemap", {
                        params: {
                            projectid: this.$root.projectid
                        }
                    })
                    .then(res => {
                        try {
                            scenetree.root.children = res.data.data.basemap;
                        } catch (ex) {
                            console.log(ex);
                            scenetree.root.children = null;
                        }

                        _this.setSceneTree(scenetree);
                        //  console.log("sceneTree", scenetree)
                    });
            }
        });
    },
    watch: {
        tree: {
            deep: true,
            handler() {
                if (this.num != 0) {
                    this.save();
                }
                this.num = this.num + 1;
            }
        }
    },
    methods: {
        cancel() {
            this.show = false;
        },
        ok() {
            this.show = false;
        },
        save() {
            this.$axios
                .put("/project/info/update", {
                    projectid: this.$root.projectid,
                    basemap: JSON.stringify(this.$root._earth.sceneTree.root.children)
                })
                .then(res => {
                    console.log(res);
                })
                .catch(err => {
                    console.log(err.response || err);
                });
        },
        addImageryOnline() {
            this.index = 1;
            console.log(this.index);
            this.$root.showPropertyWindow(
                "ImageryOnline", {
                component: ImageryOnline
            },
                true
            );
        },
        addTerrainOnline() {
            this.index = 3;
            this.$root.showPropertyWindow(
                "TerrainOnline", {
                component: TerrainOnline
            },
                true
            );
        },
        addModelOnline() {
            this.index = 2;
            this.$root.showPropertyWindow(
                "ModelOnline", {
                component: ModelOnline
            },
                true
            );
        },
        setSceneTree(sceneTree) {
            if (this._vueTree) {
                this.destroyVueNode(this._vueTree, undefined);
                this._vueTree = undefined;
                this._sceneTree = undefined;
                this.tree = [];
            }
            this._sceneTree = sceneTree;
            this._vueTree = this.createVueNode(this._sceneTree.root);
            this.tree = this._vueTree.children;
        },
        destroyVueNode(ivuNode, parent) {
            if (ivuNode._inner().disposer) {
                ivuNode._inner().disposer.basicPropDisposer &&
                    ivuNode._inner().disposer.basicPropDisposer();
                ivuNode._inner().disposer.childrenInterceptDisposer &&
                    ivuNode._inner().disposer.childrenInterceptDisposer();
            }

            ivuNode.children &&
                ivuNode.children.forEach(child => {
                    this.destroyVueNode(child, ivuNode);
                });

            if (parent) {
                const index = parent.children.indexOf(ivuNode);
                if (~index) {
                    parent.children.splice(index, 1);
                }
            }
        },
        createVueNode(xbsjSceneNode) {
            let _this = this;
            const ivuNode = {};
            ivuNode.id = this.vueItemTotal++;
            ivuNode._inner = () => {
                return {
                    sn: xbsjSceneNode,
                    disposer: {}
                };
            };
            ivuNode.title = xbsjSceneNode.title;
            ivuNode.isSelected = xbsjSceneNode.isSelected;
            ivuNode.checkStatus = xbsjSceneNode.checkStatus;
            ivuNode.checked = xbsjSceneNode.checkStatus == "checked" ? true : false;
            ivuNode.dragType = "string"; //判定是否能拖动
            ivuNode.acceptDrop = this.acceptDrop;
            if (xbsjSceneNode.czmObject) {
                //判断是否有type属性，用于展示树状结构图标
                ivuNode.type = xbsjSceneNode.czmObject.xbsjType;
                //添加attachedPathGuid判断，用于显示pin和model是否绑定了path的guid
                if (xbsjSceneNode.czmObject.cameraAttached !== undefined) {
                    ivuNode.cameraAttached = xbsjSceneNode.czmObject.cameraAttached;
                }
            } else {
                ivuNode.type = "ios-folder";
                ivuNode.expanded = xbsjSceneNode.expand;
            }

            if (xbsjSceneNode instanceof XE.SceneTree.Group) {
                ivuNode.expand = xbsjSceneNode.expand;
                ivuNode.children = [];

                if (xbsjSceneNode.children) {
                    xbsjSceneNode.children.forEach(child => {
                        var obj = _this.createVueNode(child);
                        console.log(obj);
                        ivuNode.children.push(obj);
                    });
                }
            } else if (xbsjSceneNode instanceof XE.SceneTree.Leaf) {
                ivuNode.expand = false;
            }

            const basicPropDisposer = XE.MVVM.watch(() => {
                ivuNode.title = xbsjSceneNode.title;
                ivuNode.isSelected = xbsjSceneNode.isSelected;
                ivuNode.expand = !!xbsjSceneNode.expand;
                ivuNode.checkStatus = xbsjSceneNode.checkStatus;
                if (xbsjSceneNode.czmObject) {
                    if (xbsjSceneNode.czmObject.cameraAttached !== undefined) {
                        ivuNode.cameraAttached = xbsjSceneNode.czmObject.cameraAttached;
                    }
                }
            });
            ivuNode._inner().disposer.basicPropDisposer = basicPropDisposer;
            if (!!xbsjSceneNode.children) {
                const childrenInterceptDisposer = XE.MVVM.reaction.intercept(
                    xbsjSceneNode.children,
                    changed => {
                        if (changed.type === "splice") {
                            const added = changed.added.map(e => _this.createVueNode(e));
                            const toDels = ivuNode.children.slice(
                                changed.index,
                                changed.index + changed.removedCount
                            );
                            toDels.forEach(e => this.destroyVueNode(e, ivuNode));
                            ivuNode.children.splice(changed.index, 0, ...added);
                        } else if (changed.type === "update") {
                            this.destroyVueNode(ivuNode.children[changed.index], ivuNode);
                            const newChild = _this.createVueNode(changed.newValue);
                            _this.tree.push(newChild);
                            ivuNode.children.splice(changed.index, 0, newChild);
                        }
                        // 必须push一下才能监听到？
                        _this.tree.push({});
                        _this.tree.pop();
                        return changed;
                    }
                );
                childrenInterceptDisposer &&
                    (ivuNode._inner().disposer.childrenInterceptDisposer = childrenInterceptDisposer);
            }
            return ivuNode;
        },
        // 空白处右键
        onContexMenu() {
            let self = this;
            const baseItems = [{
                text: this.lang.addFolder,
                keys: "",
                func: () => {
                    const g0 = new XE.SceneTree.Group(this.$root._earth);
                    g0.title = this.lang.newFolder;
                    const xbsjSceneTree = this.$root._earth.sceneTree;
                    xbsjSceneTree.root.children.push(g0);
                }
            }];
            this.$root.$contextMenu.pop(baseItems);
        },
        // 选中某个文件夹或者场景右键
        popContextMenu({
            item,
            vueObject
        }) {
            //右键之后设置当前node
            item._inner().sn.isSelected = true;
            //console.log(this);
            const baseItems = [{
                text: this.lang.rename,
                keys: "",
                border: true,
                func: () => {
                    vueObject.item.editing = true;
                }
            },
            {
                text: this.lang.delete,
                keys: "",
                func: () => {
                    this.$root.$confirm(this.lang.confirm, () => {
                        const sn = item._inner().sn;
                        const index = sn.parent.children.indexOf(sn);
                        sn.parent.children.splice(index, 1);
                    });
                }
            }
            ];

            if (this._currentSceneNode) {
                baseItems.splice(1, 0, {
                    text: this.lang.paste,
                    keys: "",
                    func: () => {
                        const sn = item._inner().sn;
                        if (this._currentSceneNode && sn !== this._currentSceneNode) {
                            if (sn instanceof XE.SceneTree.Group) {
                                this._currentSceneNode.moveTo(sn);
                            } else {
                                sn.parent &&
                                    this._currentSceneNode.moveTo(
                                        sn.parent,
                                        sn.parent.children.indexOf(sn)
                                    );
                            }
                        }
                        this._currentSceneNode = undefined;
                    }
                });
            }

            if (item.children) {
                // 如果存在children，说明是组节点
                item.isSelected = true;
                baseItems.unshift(
                    ...[{
                        text: this.lang.addFolder,
                        keys: "",
                        func: () => {
                            const sn = item._inner().sn;
                            if (sn instanceof XE.SceneTree.Group) {
                                const g = new XE.SceneTree.Group(this.$root._earth);
                                sn.children.push(g);
                            }
                        }
                    }]
                );
            } else {
                baseItems.unshift({
                    type: "divider"
                });
                //如果有cameraAttached属性，就添加一个相机绑定菜单
                if (item._inner().sn.czmObject.cameraAttached !== undefined) {
                    baseItems.unshift({
                        text: this.lang.cameraAttached,
                        func: () => {
                            item._inner().sn.czmObject.cameraAttached = !item._inner().sn
                                .czmObject.cameraAttached;
                        }
                    });
                }
                baseItems.push(
                    ...[{
                        type: "divider"
                    },
                    {
                        text: this.lang.property,
                        func: () => {
                            this.propertyWindow(item._inner().sn.czmObject);
                        }
                    }
                    ]
                );
            }
            this.$root.$contextMenu.pop(baseItems);
        },
        propertyWindow(czmObject) {
            console.log("czmObject", czmObject);
            let t = czmObject.xbsjType;
            console.log("挖坑", t);
            //根据类型去显示界面
            if (t == "Tileset") this.$root.$refs.right.showTiles(czmObject);
            else if (t == "Imagery") this.$root.$refs.right.showImagery(czmObject);
            else if (t == "Terrain") this.$root.$refs.right.showTerrain(czmObject);
            else if (t == "CutSurface")
                this.$root.$refs.right.showCutSurface(czmObject);
        },
        contextMenu({
            item,
            vueObject
        }) {
            this.popContextMenu({
                item,
                vueObject
            });
        },
        onEdited(options) {
            //结束编辑
            options.item.editing = false;
            let sn = options.item._inner().sn;
            sn.title = options.title;
            options.item.title = options.title;
        },

        toggleChecked(treeItem) {
            let checked = !treeItem.item.checked;
            if (treeItem.item._inner().sn instanceof XE.SceneTree.Group) {
                treeItem.item._inner().sn.setAllChildrenEnabled(checked);
            } else if (treeItem.item._inner().sn instanceof XE.SceneTree.Leaf) {
                treeItem.item._inner().sn.enabled = checked;
            } else {
                console.error("toggleChecked got error!");
            }
        },
        itemDoubleClick({
            item,
            vueObject
        }) {
            const czmObject = item._inner().sn.czmObject;
            if (czmObject) {
                console.log(czmObject);
                czmObject.flyTo();
            }
        },
        acceptDrop(types) {
            if (!(types instanceof Array)) return false;
            return true;
        },
        onToggleSelected({
            item,
            vueObject
        }) {
            // 会给双击带来问题， TODO(vtxf): 需要想办法处理！
            // item._inner.sn.isSelected = !item._inner.sn.isSelected;
            item._inner().sn.isSelected = true;
        },
        dropItem(option) {
            //放置源目标到放置目标位置
            let sn1 = option.from._inner().sn;
            let sn = option.to._inner().sn;
            this._currentSceneNode = sn1;

            if (this._currentSceneNode && sn !== this._currentSceneNode) {
                if (sn instanceof XE.SceneTree.Group) {
                    this._currentSceneNode.moveTo(sn);
                } else {
                    sn.parent &&
                        this._currentSceneNode.moveTo(
                            sn.parent,
                            sn.parent.children.indexOf(sn)
                        );
                }
            }
            this._currentSceneNode = undefined;
        },
        toggleExpand(treeItem) {
            treeItem.item.expand = !treeItem.item.expand;
            let sn = treeItem.item._inner().sn;
            sn.expand = treeItem.item.expand;
        }
    }
};
</script>

<style scoped>
.component {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    min-height: 50%;
}

.title {
    width: 100%;
    height: 30px;
    flex-grow: 0;
    background: var(--BackgroundColor);
    text-align: center;
    line-height: 30px;
    color: #cccccc;
}

.tool {
    width: 100%;
    margin-bottom: 10px;
    flex-grow: 0;
    display: flex;
}

.tool>button {
    width: 100%;
    height: 24px;
    flex-flow: 1;
    background: #2e2e2e;
    border: 1px solid #383838;
    border-radius: 2px;
    color: #cccccc;
    font-size: 12px;
    outline: none;
}

.tool>button:hover {
    background: #295f99;
    border: 1px solid #383838;
    color: #ffffff !important;
}

.tool>button:active {
    background: #288dfb;
}

.selected {
    background: #288dfb !important;
    border: 1px solid #383838;
}

.scenetree {
    width: 100%;
    flex-grow: 1;
    overflow: auto;
}

.tree {
    overflow: unset !important;
}
</style>

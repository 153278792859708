<template>
  <div>
    <Window :title="'批量修改附属物角度'"
      :footervisible="true"
      @ok="ok"
      @cancel="cancel"
      v-show="show"
      :width="420"
      :height="340">
      <div>
        <button @click="tabSwitch(1)"
          :class="{ tab_static: tab == 1 }">
          绝对角度
        </button>
        <button @click="tabSwitch(2)"
          :class="{ tab_static: tab == 2}">
          绝对增量
        </button>
        <button @click="tabSwitch(3)"
          :class="{ tab_param: tab == 3 }">
          相对管线
        </button>
      </div>
      <div class="editPoint"
        v-show="tab == 1">
        <div>
          <label>所选管点的附属物角度将被设置为以下值，若不勾选则保留原值</label>
        </div>
        <br />
        <div>
          <label><input type="checkbox"
              v-model="absolute.computeHeading" />偏航角:</label>
          <input v-model.number="absolute.heading"
            v-number-fixed="2" />
        </div>
        <div>
          <label><input type="checkbox"
              v-model="absolute.computePitch" />俯仰角:</label>
          <input v-model.number="absolute.pitch"
            v-number-fixed="2" />
        </div>
        <div>
          <label><input type="checkbox"
              v-model="absolute.computeRoll" />滚转角:</label>
          <input v-model.number="absolute.roll"
            v-number-fixed="2" />
        </div>
      </div>
      <div class="editPoint"
        v-show="tab == 2">
        <div>
          <label>所选管点的附属物角度在原值基础上增加以下值，若不勾选则不做处理</label>
        </div>
        <br />
        <div>
          <label><input type="checkbox"
              v-model="add.computeHeading" />偏航角:</label>
          <input v-model.number="add.heading"
            v-number-fixed="2" />
        </div>
        <div>
          <label><input type="checkbox"
              v-model="add.computePitch" />俯仰角:</label>
          <input v-model.number="add.pitch"
            v-number-fixed="2" />
        </div>
        <div>
          <label><input type="checkbox"
              v-model="add.computeRoll" />滚转角:</label>
          <input v-model.number="add.roll"
            v-number-fixed="2" />
        </div>
      </div>
      <div class="editPoint"
        v-show="tab == 3">
        <div class="editor">
          <label style="width:400px">根据管线的方向计算附属物的角度。以路灯为例，只考虑水平方向的角度，则只需要勾选“是否计算偏航角”，路灯朝向与其所连线垂直，则夹角为90或者-90</label>
        </div>
        <div class="editor">
          <label style="width:400px">注意：当连线大于两条时，取检索到的前两条线进行计算</label>
        </div>
        <br />
        <div class="editor">
          <input type="checkbox"
            v-model="relative.computeHeading" />是否计算偏航角&nbsp;&nbsp;&nbsp;&nbsp;夹角<input v-model.number="relative.offsetheading"
            v-number-fixed="6" />
        </div>
        <div>
          <input type="checkbox"
            v-model="relative.computePitch" />是否计算俯仰角&nbsp;&nbsp;&nbsp;&nbsp;夹角<input v-model.number="relative.offsetpitch"
            v-number-fixed="6" />
        </div>
        <!-- <div>
          <button @click="cancel">取消</button>
          <button @click="ok">确定</button>
        </div> -->
      </div>
    </Window>
  </div>
</template>
<script>
/* eslint-disable */
import Window from "../../../components/Window";

export default {
  components: {
    Window,
  },
  data() {
    return {
      show: true,
      tab: 1,
      absolute: {
        computeHeading: true,
        computePitch: true,
        computeRoll: true,
        heading: 0,
        pitch: 0,
        roll: 0
      },
      add: {
        computeHeading: true,
        computePitch: true,
        computeRoll: true,
        heading: 0,
        pitch: 0,
        roll: 0
      },
      relative: {
        offsetheading: 0,
        offsetpitch: 0,
        computeHeading: true,
        computePitch: true
      },
      x: 0,
      y: 0,
      z: 0,
    };
  },
  props: {
    param: {
      type: Object,
      default: undefined,
    },
  },
  mounted() {
    this.absolute.heading = this.param.point.heading;
    this.absolute.pitch = this.param.point.pitch;
    this.absolute.roll = this.param.point.roll;
  },
  methods: {
    tabSwitch(tab) {
      this.tab = tab;
    },
    ok() {
      this.$root.destroyTool(this);
      let options = { type: this.tab }
      if (this.tab == 1) {
        if (this.absolute.computeHeading) {
          options.heading = this.absolute.heading
        }
        if (this.absolute.computePitch) {
          options.pitch = this.absolute.pitch
        }
        if (this.absolute.computeRoll) {
          options.roll = this.absolute.roll
        }
      } else if (this.tab == 2) {
        if (this.add.computeHeading) {
          options.heading = this.add.heading
        }
        if (this.add.computePitch) {
          options.pitch = this.add.pitch
        }
        if (this.add.computeRoll) {
          options.roll = this.add.roll
        }
      } else {
        options.computeHeading = this.relative.computeHeading
        options.computePitch = this.relative.computePitch
        options.offsetheading = this.relative.offsetheading
        options.offsetpitch = this.relative.offsetpitch
      }
      this.param.ok(options);
    },
    cancel() {
      this.$root.destroyTool(this);
    },
  },
};
</script>
<style scoped>
.appendages_top {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
}

.appendages_top > input {
  box-sizing: border-box;
  width: 430px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  margin-right: 4px;
}

.appendages_top > button {
  margin-right: 4px;
}

.appendages_content {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 100px);
  overflow: auto;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.appendages_content > div {
  width: 100px;
  height: 132px;
  margin-left: 12px;
}

.thumbnail {
  position: relative;
  width: 100px;
  height: 100px;
  border: 2px solid #1a1a1a;
  opacity: 1;
  margin-bottom: 8px;
}

.thumbnail > span {
  width: 100%;
  height: 20px;
  line-height: 20px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  background: #000;
  opacity: 0.5;
  text-align: center;
  color: #ccc;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.operation {
  display: flex;
  justify-content: space-around;
}

.operation > img {
  cursor: pointer;
}

.appendages_content > div:last-child:hover {
  cursor: pointer;
}

.param,
.static {
  width: 100%;
  height: 100%;
}

.param {
  display: flex;
}

.param_left {
  width: 60%;
}

.param_right {
  width: 40%;
  overflow: auto;
}

.tab_static,
.tab_param {
  background: #295f99;
}

.cesiumContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #242424;
}

.parameters_top {
  display: flex;
  height: 28px;
  align-items: center;
  justify-content: center;
}

.parameters_top > div:nth-child(2) {
  display: flex;
  border: 1px solid #999595;
  flex-grow: 1;
  height: 24px;
  align-items: center;
}

.parameter_content {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.parameter_content > div:nth-child(1) {
  width: 670px;
  flex-grow: 0;
  border-right: none;
  height: 100%;
}

.parameter_content > div:nth-child(2) {
  width: 300px;
  flex-grow: 1;
  height: 100%;
}

.right {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
  padding-top: 10px;
}

.right > select {
  width: 220px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.right > label {
  width: 110px;
  text-align: center;
}

.right > button,
.right > input {
  width: 220px;
  height: 30px;
  line-height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.preview {
  width: 220px;
  height: 30px;
  background: #383838;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.jsoncontent {
  width: 100%;
  font-size: 16px;
  margin-top: 30px;
  word-wrap: break-word;
  margin-bottom: 40px;
}
</style>

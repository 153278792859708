<template>
    <div class="treeitem" :class="{ selected: item === selected || draging }"
        :style="{ 'margin-left': 20 * item.level + 10 + 'px', color: itemColor }"
        :draggable="typeof item.dragType === 'string' && !item.editing" @dragstart="dragstart" @dragend="dragend"
        @dragenter="dragenter" @dragover="dragover" @dragleave="dragleave" @drop="drop" @dblclick="dblclick"
        @click="toggleSelected($event)" @contextmenu="onContexMenu">
        <!--这里是展开按钮-->
        <div class="ion-icon" v-if="!(item.children instanceof Array)"></div>
        <IosArrowForwardIcon class="ion-icon" v-show="!item.expanded && item.children instanceof Array"
            @click="toggleExpand($event)"></IosArrowForwardIcon>
        <IosArrowDownIcon class="ion-icon" v-show="item.expanded && item.children instanceof Array"
            @click="toggleExpand($event)"></IosArrowDownIcon>

        <!-- 这里是checkbox   disabled="true"   -->
        <!-- <ion-icon v-show="item.checked===1">
        </ion-icon> -->
        <IosCheckboxOutlineIcon class="ion-icon" v-show="item.checked === true" @click="toggleChecked($event)">
        </IosCheckboxOutlineIcon>
        <IosSquareOutlineIcon class="ion-icon" v-show="item.checked === false" @click="toggleChecked($event)">
        </IosSquareOutlineIcon>
        <!--这里是类型图标-->
        <component class="ion-icon" :is="iconName"></component>
        <!--这里是标题栏-->
        <input v-model="title" class="title inputcolor nomaxwidth" v-if="item.editing" @blur="finishEdit"
            @keyup.enter="finishEdit" ref="editor" />
        <span class="title " :title="item.title" v-if="!item.editing"
            :class="{ nomaxwidth: !(item.sub0 && item.sub1) }">
            {{ item.title }}
        </span>
        <div class="colorshow" v-show="item.config && !(item.children instanceof Array)" :style="{
        background: item.config ? item.config.color : '#fff',
      }"></div>
    </div>
</template>

<script>
    import IosArrowForwardIcon from "vue-ionicons/dist/ios-arrow-forward.vue";
    import IosArrowDownIcon from "vue-ionicons/dist/ios-arrow-down.vue";
    import IosCheckboxOutlineIcon from "vue-ionicons/dist/ios-checkbox-outline.vue";
    import IosSquareOutlineIcon from "vue-ionicons/dist/ios-square-outline.vue";
    import IosFolderOpenIcon from "vue-ionicons/dist/ios-folder-open.vue";
    import IosFolderIcon from "vue-ionicons/dist/ios-folder.vue";
    import IosImageIcon from "vue-ionicons/dist/ios-image.vue";

    export default {
        components: {
            IosArrowDownIcon,
            IosArrowForwardIcon,
            IosCheckboxOutlineIcon,
            IosSquareOutlineIcon,
            IosFolderOpenIcon,
            IosFolderIcon,
            IosImageIcon
        },
        props: {
            item: Object,
            selected: Object
        },
        data() {
            return {
                draging: false,
                title: ""
            };
        },
        mounted() {
            // this.movetreeitem(this.item);
            // console.log(this.item);
            this.title = this.item.title;
        },
        computed: {
            iconName() {
                if (this.item.dragType === "folder") {
                    return "IosFolderIcon";
                } else if (this.item.dragType === "layer") {
                    return "IosImageIcon";
                } else {
                    if (
                        this.item.type === "XbsjTerrain" ||
                        this.item.type === "XbsjImagery" ||
                        this.item.type === "XbsjTileset"
                    ) {
                        this.item.children = !!this.item.children;
                        return this.item.children ? "IosFolderIcon" : this.item.type;
                    } else {
                        return this.item.children ? "IosFolderIcon" : "IosImageIcon";
                    }
                }
            },
            itemColor() {
                if (this.item.color instanceof Function) {
                    return this.item.color();
                } else if (typeof this.item.color === "string") {
                    return this.item.color;
                }
                return "unset";
            }
        },

        methods: {
            finishEdit() {
                this.$emit("on-edited", {
                    item: this.item,
                    vueObject: this,
                    title: this.title
                });
            },
            dblclick($event) {
                this.$emit("on-dblclick", {
                    item: this.item,
                    vueObject: this,
                    $event
                });
            },
            dragstart(event) {
                //设置类型
                event.dataTransfer.setData(this.item.dragType, this.item.title);
                //设置路径
                event.dataTransfer.setData(this.$parent.getItemPath(this.item), "");

                //console.log("dragstart", event);
                //event.dataTransfer.effectAllowed = "all";
            },
            dragend( /*event*/ ) {
                //console.log("dragend", event, this.item);
            },
            dragenter(event) {
                console.log("tuodong", this.item.acceptDrop);
                //event.dataTransfer.dropEffect = "none";
                //拖拽过程中无法获取数据，只有最后的才可以
                //var data = event.dataTransfer.getData("_jingkong_");
                //这里只能判定是否包含对应的数据
                var accept = this.item.acceptDrop;
                if (typeof accept !== "function" || !accept(event.dataTransfer.types)) {
                    return;
                }
                //修改样式
                this.draging = true;
            },
            dragover(event) {
                var accept = this.item.acceptDrop;
                if (typeof accept !== "function" || !accept(event.dataTransfer.types)) {
                    return;
                }
                //这里获取当前item的路径
                var toPath = this.$parent.getItemPath(this.item);
                //如果toPath 是否某两个路径的孩子，那么不拖拽
                var t = event.dataTransfer.types.find(path => {
                    return toPath.indexOf(path) >= 0;
                });
                if (t) return;
                event.dataTransfer.dropEffect = "move";
                //修改样式
                this.draging = true;
                //这句话不能省略，否则拖拽的effect效果就不正常
                event.preventDefault();
            },
            dragleave(event) {
                var accept = this.item.acceptDrop;
                if (typeof accept !== "function" || !accept(event.dataTransfer.types)) {
                    return;
                }
                //恢复样式
                this.draging = false;
            },
            drop(event) {
                this.draging = false;
                //console.log("drop", event, this.item);
                //发送drop事件
                this.$emit("on-drop", {
                    item: this.item,
                    vueObject: this,
                    $event: event
                });
            },
            toggleExpand($event) {
                this.$emit("on-toggle-expand", {
                    item: this.item,
                    vueObject: this,
                    $event
                });
            },
            toggleChecked($event) {
                this.$emit("on-toggle-checked", {
                    item: this.item,
                    vueObject: this,
                    $event
                });
            },
            toggleSelected($event) {
                this.$emit("on-toggle-selected", {
                    item: this.item,
                    vueObject: this,
                    $event
                });
            },
            onContexMenu($event) {
                this.$emit("on-context-menu", {
                    item: this.item,
                    vueObject: this,
                    $event
                });
                return false;
            },
            onClick($event) {
                this.$emit("on-click", {
                    item: this.item,
                    vueObject: this,
                    $event
                });
            }
        },
        watch: {
            "item.editing": function (nv) {
                if (nv) {
                    this.$nextTick(() => {
                        if (this.$refs.editor) this.$refs.editor.focus();
                    });
                }
            },
            "item.title": function (nv) {
                this.title = nv;
            }
        }
    };
</script>

<style scoped>
    .treeitem {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        /* align-items: center; */
        padding: 5px;
        /* min-width: 280px; */
        /* width: 100%; */
        width: 250px;
        color: #cccccc;
    }

    .treeitem:hover {
        background-color: rgba(40, 141, 251, 0.5);
        color: #fff !important;
    }

    .selected {
        background: #288dfb;
        opacity: 0.8;
        color: #cccccc;
    }

    .ion-icon {
        width: 16px;
        height: 16px;
        flex-grow: 0;
        margin-right: 5px;
        cursor: pointer;
        min-width: 16px;
        min-height: 16px;
        font-size: 21px;
    }

    .title {
        /* width: 100%; */
        user-select: none;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80px;
        white-space: nowrap;
        flex-grow: 1;
        min-width: 80px;
        color: #cccccc;
    }

    .inputcolor {
        color: #fff;
        background: #242424;
    }



    .nomaxwidth {
        max-width: unset;
    }

    .colorshow {
        width: 10px;
        height: 10px;
        background: chocolate;
    }
</style>
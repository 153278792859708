<template>
    <div class="addLine">
        <label class="info">{{ getInfo() }}</label>
        <div class="op">
            <div>
                <label>起点:{{ qidian }}</label>
                <button @click="clearQidian" v-show="qidian!==undefined">清除</button>
            </div>
            <div>
                <label>终点:{{ zhongdian }}</label>
                <button @click="clearZhongdian" v-show="zhongdian!==undefined">清除</button>
            </div>

            <button @click="ok" v-show="zhongdian&&qidian">确定</button>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                qidian: undefined,
                zhongdian: undefined
            };
        },
        mounted() {
            //启动鼠标交互
            this.$root._earth.interaction.picking.enabled = true;
            //截获选择管点事件 
            this.$bus.on("pickPoint", this.pickPoint);
        },
        methods: {
            pickPoint(obj) {
                this.getPoint(obj.id, obj.geom.coordinates);
            },
            clearQidian() {
                this.qidian = undefined;
                if (this._qidianLabel) {
                    this._qidianLabel.destroy();
                    this._qidianLabel = undefined;

                    this.$root._tileset.unSelectFeature();
                }
                if (this._temline) {
                    this._temline.destroy();
                    this._temline = undefined;
                }
            },
            clearZhongdian() {
                this.zhongdian = undefined;
                if (this._zhongdianLabel) {
                    this._zhongdianLabel.destroy();
                    this._zhongdianLabel = undefined;
                    this.$root._tileset.unSelectFeature();
                }
                if (this._temline) {
                    this._temline.destroy();
                    this._temline = undefined;
                }
            },
            getPoint(id, position) {
                if (!this.qidian) {
                    if (this.zhongdian != id) {
                        this.qidian = id;
                        if (this._qidianLabel) {
                            this._qidianLabel.destroy();
                            this._qidianLabel = undefined;
                        }
                        this._qidianLabel = this.$root.$createLabel("起点:" + id);
                        this._qidianLabel.position = [
                            (position[0] * Math.PI) / 180,
                            (position[1] * Math.PI) / 180,
                            position[2]
                        ];
                        this.drawLine();
                    }
                } else if (!this.zhongdian) {
                    if (this.qidian != id) {
                        this.zhongdian = id;
                        if (this._zhongdianLabel) {
                            this._zhongdianLabel.destroy();
                            this._zhongdianLabel = undefined;
                        }
                        this._zhongdianLabel = this.$root.$createLabel("终点:" + id);
                        this._zhongdianLabel.position = [
                            (position[0] * Math.PI) / 180,
                            (position[1] * Math.PI) / 180,
                            position[2]
                        ];
                        this.drawLine();
                    }
                }
            },
            drawLine() {
                if (this._temline) {
                    this._temline.destroy();
                    this._temline = undefined;
                }
                if (this._qidianLabel && this._zhongdianLabel) {
                    // this._temline = new XE.Obj.Polyline(this.$root._earth);
                    // this._temline.positions = [
                    //     this._qidianLabel.position,
                    //     this._zhongdianLabel.position,
                    // ];
                    // this._temline.width = 10;
                    // this._temline.material.type = 'XbsjODLineMaterial';
                    // this._temline.material.XbsjODLineMaterial.image = './image/03.png';
                    // let length = this.$root.$getLengthOfLine(this._temline);
                    // this._temline.material.XbsjODLineMaterial.useImageAndRepeat = [1, length];
                    this._temline = new XE.Obj.CustomPrimitiveExt.Tube(this.$root._earth);
                    this._temline.positions = [
                        this._qidianLabel.position,
                        this._zhongdianLabel.position
                    ];
                    this._temline.radius = 2;
                    this._temline.radialSegments = 4;
                    this._temline.imageUrl = "./image/03.png";
                    this._temline.textureSize = [Math.PI, Math.PI];
                    this._temline.speed = [-2, 0];
                    this._temline.isCurve = false
                }
            },
            getInfo() {
                if (!this.qidian) return "请单击选择管线起点";
                if (!this.zhongdian) return "请单击选择管线终点";
                return "点击确定按钮添加管线";
            },
            ok() {
                if (!this.qidian) {
                    this.$root.$alert("请选择起点");
                    return;
                }
                if (!this.zhongdian) {
                    this.$root.$alert("请选择终点");
                    return;
                }
                if (this.qidian == this.zhongdian) {
                    this.$root.$alert("起点和终点不能相同");
                    return;
                }
                let data = {
                    projectid: this.$root.projectid,
                    qidian: this.qidian,
                    zhongdian: this.zhongdian
                };

                this.$axios
                    .post("/project/line/add", data)
                    .then(res => {
                        console.log(res);
                        //关闭显示  

                        this.clearQidian();
                        this.clearZhongdian();
                        //添加明确提示
                        this.$root.$alert("添加管线成功");
                        if (res.data.data && res.data.data === true) {
                            this.$root._tileset.reload();
                        }
                    })
                    .catch(err => {
                        this.$root.$log("error", err);
                        this.$root.$alert("添加管线失败:" + err.data.message);
                    });
            }
        },
        beforeUnmount() {
            //停止监控地球鼠标点击事件
            if (this._qidianLabel) {
                this._qidianLabel.destroy();
            }
            if (this._zhongdianLabel) {
                this._zhongdianLabel.destroy();
            }
            if (this._temline) {
                this._temline.destroy();
            }
            this.$bus.off('pickPoint', this.pickPoint);
        }
    };
</script>

<style scoped>
    .addLine {
        flex-grow: 1;
        height: 60px;

    }

    .info {
        text-align: center;
        padding: 5px;
        display: block;
    }

    .op {
        width: 500px;
        display: grid;
        margin: auto;
        grid-template-columns: 1fr 1fr 40px;
    }

    button {
        height: 20px;
        width: 50px;
        margin-left: 10px;
    }
</style>
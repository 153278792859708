<template>
  <div class="component">
    <div class="title">管线图层配置</div>

    <div class="layertree">
      <!--左右拖动条去掉button -->
      <VirtualTree ref="vtree" :tree="tree" @on-context-menu="onItemContextMenu" @contextmenu="onContextMenu"
        @on-edited="onEdited" @on-item-drop="onItemDrop" @on-toggle-expand="onToggleExpand"
        @on-toggle-checked="onToggleChecked" @on-toggle-selected="onToggleSelected" @on-dblclick="onDblClick">
      </VirtualTree>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import VirtualTree from "../../../components/VirtualTree/index";
import LayerAutoCreateTool from "../components/LayerAutoCreateTool";
export default {
  name: "PipeLayer",
  components: {
    VirtualTree
  },
  props: {
    param: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      tree: [],
      indexdemo: 0,
      num: 0,
      flag: false
    };
  },
  created() { },
  mounted() {
    this.$bus.on("initearth", () => {
      this.loadTree();
    });
    this.$bus.on("reloadlayer", () => {
      this.loadTree();
    });
  },
  watch: {
    tree: {
      deep: true,
      handler() {
        if (this.num != 0) {
          this.save();
        }
        this.num = this.num + 1;
      }
    }
  },
  methods: {
    onDblClick(param) {
      //搜集这个目录下的所有图层id
      function collectLayers(item) {
        if (item.dragType == "layer") return [item.id];
        var ret = [];
        if (item.children) {
          item.children.forEach(it => {
            var r = collectLayers(it);
            ret = ret.concat(r);
          });
        }
        return ret;
      }

      var ids = collectLayers(param.item);

      this.$root.$axios
        .get("/project/line/box", {
          params: {
            tucengids: JSON.stringify(ids),
            projectid: this.$root.projectid
          }
        })
        .then(res => {
          console.log(res.data);
          var box = res.data.data;
          if (
            box.xmin === null ||
            box.ymin === null ||
            box.zmin === null ||
            box.xmax === null ||
            box.ymax === null ||
            box.zmax === null
          ) {
            return;
          }
          this.$root._tileset.flyToRange(
            box.xmin,
            box.ymin,
            box.zmin,
            box.xmax,
            box.ymax,
            box.zmax
          );
        });
    },
    colorDefault() {
      this.indexdemo = 1;
      this.$root._tileset.colorDefault();
    },
    colorByLayer() {
      this.indexdemo = 2;
      this.$root._tileset.colorByLayer();
    },
    colorByLayerMixed() {
      this.indexdemo = 3;
      this.$root._tileset.colorByLayerMixed();
    },
    projectProperty() {
      this.$root.$refs.right.showProject();
    },
    async GenNonDuplicateID() {
      let res = await this.$root.$axios
        .get("/project/layer/newid", {
          params: {
            projectid: this.$root.projectid
          }
        })
      return res.data;
      // //生成用不重复的ID，随机数加时间戳
      // let idStr = Date.now().toString(36);
      // idStr += Math.random()
      //   .toString(36)
      //   .substr(3);
      // return idStr;
    },
    onToggleChecked(option) {
      //console.log("选中的数据", option)
    },
    newFolder(parentItem) {
      //文件夹下新建文件夹,此处还有bug,parent指向有争议
      var parent = parentItem.children;
      var folder = {
        title: "未命名文件夹",
        checked: true,
        children: [],
        dragType: "folder",
        acceptDrop: this.acceptDrop
      };
      parent.push(folder);
      //自动展开父亲
      if (parentItem) {
        parentItem.expanded = true;
      }
      folder.editing = true;
    },
    async newLayer(parentItem) {
      //文件下新建图层,
      var parent = parentItem.children;
      var layer = {
        id: await this.GenNonDuplicateID(),
        title: "未命名图层",
        checked: true,
        dragType: "layer",
        config: {
          color: "rgba(255,255,0,1)"
        },
        acceptDrop: this.acceptDrop
      };
      parent.push(layer);
      //自动展开父亲
      if (parentItem) {
        parentItem.expanded = true;
      }
      layer.editing = true;
    },
    newFolderNaN() {
      //空白文件下 新建文件
      var folder = {
        title: "未命名文件夹",
        checked: true,
        children: [],
        dragType: "folder",
        acceptDrop: this.acceptDrop
      };
      this.tree.push(folder);
      this.save();
    },
    newFolderNull() {
      //layers为空则自动创建一个默认文件夹
      var folder = {
        title: "未命名默认文件夹",
        checked: true,
        children: [],
        dragType: "folder",
        acceptDrop: this.acceptDrop
      };
      this.tree.push(folder);
      this.save();
    },
    async newLayerNaN() {
      //空白文件下 新建图层
      var layer = {
        id: await this.GenNonDuplicateID(),
        title: "未命名图层",
        checked: true,
        dragType: "layer",
        config: {
          color: "rgba(255,255,0,1)"
        },
        acceptDrop: this.acceptDrop
      };
      this.tree.push(layer);
      this.save();
    },
    acceptDrop(types) {
      if (!(types instanceof Array)) return false;
      return true;
    },
    loadTree() {
      if (this.$root.projectid) {
        this.$axios
          .get("/project/info/layers", {
            params: {
              projectid: this.$root.projectid
            }
          })
          .then(res => {
            console.log("管线图层配置文件列表", res.data.data);
            if (!res.data.data || !res.data.data.layers) {
              this.newFolderNull();
            } else {
              this.tree = this.creatTree(res.data.data.layers);
            }
            //this.$root._earth.czm.viewer.entities.removeAll();
          })
          .catch(err => {
            this.$root.$log("error", err);
            console.log(err.response || err);
          });
      }
    }, //创建树
    creatTree(treeData) {
      let self = this;
      let tree = [];

      if (treeData && treeData instanceof Array) {
        treeData.forEach(element => {
          var node = {
            title: element.title
          };
          if (element.dragType === "folder") {
            node.checked = element.checked;
            node.acceptDrop = this.acceptDrop;
            (node.children = []),
              (node.expanded = element.expanded),
              (node.dragType = "folder");
          } else if (element.dragType === "layer") {
            node.checked = element.checked;
            node.acceptDrop = this.acceptDrop;
            (node.id = element.id), (node.dragType = "layer");
            node.config = element.config;
          }
          if (element.children && element.children.length) {
            node.children = self.creatTree(element.children);
          }

          tree.push(node);
        });
      }
      return tree;
    },
    //弹出右键菜单
    onItemContextMenu(options) {
      //分别判定是目录还是图层
      var item = options.item;
      console.log("options右键", options, item);
      if (item.dragType === "folder") {
        this.$root.$contextMenu.pop([
          {
            text: "新建文件夹",
            func: () => {
              this.newFolder(options.item);
            }
          },
          {
            text: "新建图层",
            func: () => {
              this.newLayer(options.item);
            }
          },
          {
            type: "divider"
          },
          {
            text: "重命名",
            keys: "",
            border: true,
            func: () => {
              item.editing = true;
            }
          },
          {
            text: "删除",
            func: () => {
              this.deleteFolder(options.item);
            }
          }
        ]);
      } else if (item.dragType === "layer") {
        //console.log("新建图层", item)
        this.$root.$contextMenu.pop([
          {
            type: "divider"
          },
          {
            text: "重命名",
            keys: "",
            border: true,
            func: () => {
              item.editing = true;
            }
          },
          {
            text: "属性",
            func: () => {
              this.tuCengPop(options.item);
            }
          },
          {
            text: "删除",
            func: () => {
              this.deleteFolder(options.item);
            }
          }
        ]);
      }
      options.$event.preventDefault();
      options.$event.stopPropagation();
    },
    //图层属性弹窗
    tuCengPop(option) {
      //console.log("拿到了图层属性，准备编辑", option)
      this.$root.$refs.right.showLayer(option, this.tree);
    },
    //空白区域右键点击
    onContextMenu($event) {
      this.$root.$contextMenu.pop([
        {
          text: "新建文件夹",
          func: () => {
            this.newFolderNaN();
            //console.log("opt找不到，底层文件夹没有在树上")
          }
        },
        {
          text: "新建图层",
          func: () => {
            //console.log("opt找不到，底层文件夹没有在树上")
            this.newLayerNaN();
          }
        },
        {
          type: "divider"
        },
        {
          text: "图层自动创建",
          func: () => {
            //console.log("opt找不到，底层文件夹没有在树上")
            //this.newLayerNaN();
            this.$root.showPropertyWindow("LayerAutoCreateTool", {
              component: LayerAutoCreateTool
            });
          }
        },
        {
          text: "刷新",
          func: () => {
            this.loadTree();
          }
        }
      ]);
      $event.preventDefault();
    },
    //拖拽
    onItemDrop(options) {
      console.log("开始拖拽", options);
      this.deleteNodeAfterDrop(options);
      //如果 options.to 目标是个文件夹，那么直接把这个
      if (options.to.children instanceof Array) {
        let children = options.to.children;
        children.push(options.from);
        // console.log("目标是个文件夹有儿子", children)
        //保存
      }
      //如果是个叶子节点，那么把from添加到这个节点之前
      else {
        var toPArray = options.to.parent
          ? options.to.parent.children
          : this.tree;
        var idx = toPArray.indexOf(options.to);
        toPArray.splice(idx, 0, options.from);
        // console.log("同级拖拽", toPArray)
        //baocun
      }
    },
    deleteNodeAfterDrop(options) {
      //先从 原始节点的父亲里删除
      // console.log("父亲里删除", options)
      var fromPArray = options.from.parent
        ? options.from.parent.children
        : this.tree;
      var idx = fromPArray.indexOf(options.from);
      if (idx >= 0) {
        fromPArray.splice(idx, 1);
      }
      // console.log("原始节点的父亲里删除", fromPArray)
      //保存
    },
    save() {
      let data = this.changeData(this.tree);
      //console.log("修改后JSON", data.toString())
      this.$axios
        .put("/project/info/update", {
          projectid: this.$root.projectid,
          layers: data
          //layers: JSON.stringify(this.layers)
        })
        .then(res => {
          //this.loadTree()
          console.log("修改保存成功", res.status);

          //更新配置
          this.$root._tileset.setLayersConfig(JSON.parse(data));
        })
        .catch(err => {
          this.$root.$log("error", err);
          console.log(err.response || err);
        });
    },
    onEdited(options) {
      //结束编辑
      options.item.editing = false;
      options.item.title = options.title;
      this.save();
    },
    deleteFolder(item) {
      if (item.dragType === "folder") {
        this.$root.$confirm("是否确认删除该文件夹?", () => {
          var parent = item.parent ? item.parent.children : this.tree;
          const index = parent.indexOf(item);
          parent.splice(index, 1);
          this.save();
        });
      } else {
        this.$root.$confirm("是否确认删除该图层?", () => {
          var parent = item.parent ? item.parent.children : this.tree;
          const index = parent.indexOf(item);
          parent.splice(index, 1);
          this.save();
        });
      }
    },
    changeData(o) {
      var cache = [];
      var str = JSON.stringify(o, function (key, value) {
        if (typeof value === "object" && value !== null) {
          if (cache.indexOf(value) !== -1) {
            // 移除
            return;
          }
          // 收集所有的值
          cache.push(value);
        }
        return value;
      });
      cache = null; // 清空变量，便于垃圾回收机制回收
      return str;
    }
  }
};
</script>

<style scoped>
.component {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  color: #cccccc;
  min-height: 50%;
}

.propertyBtn {
  width: 100%;
  display: flex;
}

.propertyBtn>li {
  width: 100%;
  height: 20px;
  line-height: 20px;
  display: flex;
  border: 1px solid #4d4d4d;
  text-align: center;
  align-items: center;
  justify-content: space-around;
}

.propertyBtn>li {
  cursor: pointer;
}

li>div {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
}

.title {
  width: 100%;
  height: 30px;
  flex-grow: 0;
  text-align: center;
  line-height: 30px;
  background: var(--BackgroundColor);
  color: #cccccc;
}

.tool {
  width: 100%;
  height: 100px;
  flex-grow: 0;
}

.layertree {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
}

.tree {
  overflow: unset !important;
}

.rootFolder {
  font-size: 20px;
  padding: 5px 20px;
  margin: 5px 10px;
}

.root1Folder {
  font-size: 18px;
  padding: 5px 30px;
  margin: 5px 20px;
}

.rootFolder:hover {
  background-color: #808080;
}

.root1Folder:hover {
  background-color: #808080;
}

.project:hover {
  background: #295f99;
}

.project:active {
  background: #288dfb;
}

.selectdemo {
  background: var(--BackgroundColor) !important;
}
</style>

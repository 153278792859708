<template>
    <div ref="loader" class="loaderfather" v-show="isShowLoader">
        <div class="loader">Loading...</div>
    </div>
</template>
<script>
    export default {
        name: "Loader",
        data() {
            return {};
        },
        props: {
            isShowLoader: {
                type: Boolean,
                default () {
                    false;
                },
            },
        },
        methods: {},
    };
</script>

<style>
    .loaderfather {
        pointer-events: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20000;
    }

    .loader {
        margin: auto;
        font-size: 20px;
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        text-indent: 9999em;
        animation: load-effect 1s infinite linear;
    }

    @keyframes load-effect {
        0% {
            box-shadow:
                /* 0:左移3em -3em:下移2em 0:模糊距离0，即纯色 .2em:外扩.2em  #504f4e:白色 */

                0 -3em 0 0.2em #504f4e,
                /*上*/
                2em -2em 0 0 #504f4e,
                /*右上*/
                3em 0 0 -0.5em #504f4e,
                /*右*/
                2em 2em 0 -0.5em #504f4e,
                /*右下*/
                0 3em 0 -0.5em #504f4e,
                /*下*/
                -2em 2em 0 -0.5em #504f4e,
                /*左下*/
                -3em 0 0 -0.5em #504f4e,
                /*左*/
                -2em -2em 0 0 #504f4e;
            /*左上*/
        }

        12.5% {
            box-shadow: 0 -3em 0 0 #504f4e, 2em -2em 0 0.2em #504f4e, 3em 0 0 0 #504f4e,
                2em 2em 0 -0.5em #504f4e, 0 3em 0 -0.5em #504f4e,
                -2em 2em 0 -0.5em #504f4e, -3em 0 0 -0.5em #504f4e,
                -2em -2em 0 -0.5em #504f4e;
        }

        37.5% {
            box-shadow: 0 -3em 0 -0.5em #504f4e, 2em -2em 0 -0.5em #504f4e,
                3em 0 0 0 #504f4e, 2em 2em 0 0.2em #504f4e, 0 3em 0 0 #504f4e,
                -2em 2em 0 -0.5em #504f4e, -3em 0 0 -0.5em #504f4e,
                -2em -2em 0 -0.5em #504f4e;
        }

        50% {
            box-shadow: 0 -3em 0 -0.5em #504f4e, 2em -2em 0 -0.5em #504f4e,
                3em 0 0 -0.5em #504f4e, 2em 2em 0 0 #504f4e, 0 3em 0 0.2em #504f4e,
                -2em 2em 0 0 #504f4e, -3em 0 0 -0.5em #504f4e, -2em -2em 0 -0.5em #504f4e;
        }

        62.5% {
            box-shadow: 0 -3em 0 -0.5em #504f4e, 2em -2em 0 -0.5em #504f4e,
                3em 0 0 -0.5em #504f4e, 2em 2em 0 -0.5em #504f4e, 0 3em 0 0 #504f4e,
                -2em 2em 0 0.2em #504f4e, -3em 0 0 0 #504f4e, -2em -2em 0 -0.5em #504f4e;
        }

        75% {
            box-shadow: 0 -3em 0 -0.5em #504f4e, 2em -2em 0 -0.5em #504f4e,
                3em 0 0 -0.5em #504f4e, 2em 2em 0 -0.5em #504f4e, 0 3em 0 -0.5em #504f4e,
                -2em 2em 0 0 #504f4e, -3em 0 0 0.2em #504f4e, -2em -2em 0 0 #504f4e;
        }

        87.5% {
            box-shadow: 0 -3em 0 0 #504f4e, 2em -2em 0 -0.5em #504f4e,
                3em 0 0 -0.5em #504f4e, 2em 2em 0 -0.5em #504f4e, 0 3em 0 -0.5em #504f4e,
                -2em 2em 0 0 #504f4e, -3em 0 0 0 #504f4e, -2em -2em 0 0.2em #504f4e;
        }

        100% {
            box-shadow: 0 -3em 0 0.2em #504f4e, 2em -2em 0 0 #504f4e,
                3em 0 0 -0.5em #504f4e, 2em 2em 0 -0.5em #504f4e, 0 3em 0 -0.5em #504f4e,
                -2em 2em 0 -0.5em #504f4e, -3em 0 0 -0.5em #504f4e, -2em -2em 0 0 #504f4e;
        }
    }
</style>
<template>
  <div class="section">
    <div class="left">
      <div class="title">
        <label>请绘制两个点</label>
        <label>剖面起点:{{ p1.x.toFixed(6) }}, {{ p1.y.toFixed(6) }}</label>
        <label>剖面终点:{{ p2.x.toFixed(6) }}, {{ p2.y.toFixed(6) }}</label>
      </div>
      <div class="btn">
        <button @click="creating()">重新绘制</button>
      </div>
    </div>
    <div class="right">
      <div class="right-canvas" ref="canvas"></div>
      <table cellspacing="0" cellpadding="0">
        <tr>
          <th v-for="(item, index) in thearder" :key="index">{{ item }}</th>
        </tr>
        <tr v-for="(item, index) in infos" :key="index">
          <td>{{ item.qidian }}</td>
          <td>{{ item.zhongdian }}</td>
          <td>{{ item.caizhi }}</td>
          <td>{{ item.tuceng }}</td>
          <td>{{ item.duanmian }}</td>
          <td>{{ item.guanjing }}</td>
          <td>{{ item.guanbihou }}</td>
          <td>{{ item.length.toFixed(2) }}</td>
          <td>{{ item.slope.toFixed(2) }}</td>
          <td>{{ item.qidianheight.toFixed(2) }}</td>
          <td>{{ item.zhongdianheight.toFixed(2) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Window from "../../../../components/Window";
import DrawAllSection from "./DrawAllSection";
import { init, Group, registerPainter } from "zrender";
import CanvasPainter from "zrender/lib/canvas/Painter";

registerPainter("canvas", CanvasPainter);
export default {
  components: {
    Window,
  },
  data() {
    return {
      p1: {
        x: 0,
        y: 0,
        qidian: "",
      },
      p2: {
        x: 0,
        y: 0,
        zhongdian: "",
      },
      thearder: [
        "起点ID",
        "终点ID",
        "材质",
        "图层",
        "断面",
        "管径",
        "管壁厚",
        "长度",
        "坡度",
        "起点高度",
        "终点高度",
      ],
      infos: [],
      scale: 1,
      scaleMax: 15, // 最大比例
      scaleMin: 0.5, // 最小比例
    };
  },
  mounted() {
    this._zr = init(this.$refs.canvas);
    this._zr.on("click", function(context) {
      if (context.target) {
        alert(context.target.id);
      }
    });
    this._group = new Group({
      slient: true, // 组内子孙元素是否响应鼠标事件
    });
    this._zr.add(this._group);
    this.handleZoom();
    this.handleDrop();
    this.creating();
  },
  methods: {
    // 滚动缩放
    handleZoom() {
      // 在画布子节点上监听鼠标滚轮事件  事件对象
      this._zr.on("mousewheel", (ev) => {
        // wheeldata 返回值说明：正值向上滚动，负值向下滚动 均为120的倍数 缩小20倍 结果可能是 +e.wheelDelta/20 或者是 -e.wheelData/20
        // 缩小20倍数 调整为10
        // 监听到鼠标再
        const e = (ev || event).wheelDelta / 60;
        // scale原始的缩放比例是1 每次滚动在这个基础上加上或者减去滚轮缩放后的数据
        this.scale += e;
        // 调用缩放的函数 将缩放的比例传入
        this.setScale(this.scale);
      });
    },

    /** * 缩放 */
    setScale(scale, item) {
      if (scale > this.scaleMax) {
        scale = this.scaleMax;
      } else if (scale < this.scaleMin) {
        scale = this.scaleMin;
      }
      this.scale = scale;
      this._group.attr({
        scale: [this.scale, this.scale],
        origin: [this._zr.getWidth() / 5, this._zr.getHeight() / 5],
      });
    },
    // 拖拽
    handleDrop() {
      this._zr.dragData = {
        drag: false,
        pos: [0, 0],
        group: null,
        target: null,
      };
      this._zr.on("mousedown", (e) => {
        // 画布拖拽的起始位置是 事件对象中的画布的坐标位置
        this._zr.dragData.pos = [e.event.zrX, e.event.zrY];
        this._zr.dragData.drag = true;
      });
      // 鼠标抬起事件 关闭拖拽  将拖拽的目标元素设置为空
      this._zr.on("mouseup", (e) => {
        this._zr.dragData.drag = false;
        this._zr.dragData.group = null;
      });
      // 鼠标移出事件 关闭拖拽
      this._zr.on("mouseout", (e) => {
        this._zr.dragData.drag = false;
        this._zr.dragData.group = null;
      });
      // 鼠标移动事件
      this._zr.on("mousemove", (e) => {
        if (this._zr.dragData.drag !== true) return;
        const new_pos = [e.event.zrX, e.event.zrY];
        var pos = [
          new_pos[0] - this._zr.dragData.pos[0],
          new_pos[1] - this._zr.dragData.pos[1],
        ];
        this._group.children().forEach((x) => {
          x.position = [0, 0];
        });
        this._group.position[0] += pos[0];
        this._group.position[1] += pos[1];
        this._group.dirty();
        this._zr.dragData.pos = [e.event.zrX, e.event.zrY];
      });
    },
    drawSection(min, max) {
      let p1 = Cesium.Cartesian3.fromDegrees(this.p1.x, this.p1.y);
      let p2 = Cesium.Cartesian3.fromDegrees(this.p2.x, this.p2.y);
      let p3 = Cesium.Cartesian3.fromDegrees(this.p1.x + 0.1, this.p1.y);
      let c1 = Cesium.Cartesian3.subtract(p1, p3, new Cesium.Cartesian3());
      let c2 = Cesium.Cartesian3.subtract(p1, p2, new Cesium.Cartesian3());
      let scale = Cesium.Cartesian3.distance(p1, p2) / 2;
      let angle = Cesium.Cartesian3.angleBetween(c1, c2);
      if (this.p2.y > this.p1.y) angle *= -1;
      let ellipsoid = this.$root._earth.czm.viewer.scene.globe.ellipsoid;
      var cartesian3 = new Cesium.Cartesian3(
        (p1.x + p2.x) / 2,
        (p1.y + p2.y) / 2,
        (p1.z + p2.z) / 2
      );
      var cartographic = ellipsoid.cartesianToCartographic(cartesian3);

      var renderState = {
        cull: {
          enabled: true,
        },
        polygonOffset: {},
        scissorTest: {
          rectangle: {},
        },
        depthRange: {},
        depthTest: {
          enabled: true,
        },
        colorMask: {},
        depthMask: true,
        blending: {
          enabled: true,
          color: {},
          equationRgb: 32774,
          equationAlpha: 32774,
          functionSourceRgb: 770,
          functionDestinationRgb: 771,
          functionDestinationAlpha: 771,
        },
        stencilTest: {
          frontOperation: {},
          backOperation: {},
        },
        sampleCoverage: {},
      };
      if (this._section) {
        this._section.destroy();
        this._section = undefined;
      }
      this._section = new XE.Obj.CustomPrimitive(this.$root._earth);
      this._section.renderState = renderState;
      this._section.imageUrl = "./image/01.png";
      this._section.position = [
        cartographic.longitude,
        cartographic.latitude,
        (max + min) / 2,
      ];
      this._section.rotation = [angle - Math.PI / 2, 0, 0];
      this._section.scale = [scale / 33, scale / 33, scale / 33];
      this._section.positions = [
        0,
        -32,
        -1,
        0,
        32,
        -1,
        0,
        32,
        1,
        0,
        -32,
        1,
        0,
        32,
        -1,
        0,
        -32,
        -1,
        0,
        -32,
        1,
        0,
        32,
        1,
      ];
      this._section.sts = [0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1];
      this._section.indices = [0, 1, 2, 0, 2, 3, 4, 5, 6, 4, 6, 7];
      this._section.preUpdateEvalString = `p.position[2] -= ${scale / 100};
                if (p.position[2] < ${(min + max) / 2 - (scale * 31) / 32}) {
                    p.position[2] = ${(min + max) / 2 + (scale * 31) / 32};
                }`;
      if (this._section2) {
        this._section2.destroy();
        this._section2 = undefined;
      }
      this._section2 = new XE.Obj.CustomPrimitive(this.$root._earth);
      this._section2.renderState = renderState;
      this._section2.imageUrl = "./image/02.png";
      this._section2.position = [
        cartographic.longitude,
        cartographic.latitude,
        (max + min) / 2,
      ];
      this._section2.rotation = [angle - Math.PI / 2, 0, 0];
      this._section2.scale = [scale, scale, scale];
      this._section2.positions = [
        0,
        -1,
        -1,
        0,
        1,
        -1,
        0,
        1,
        1,
        0,
        -1,
        1,
        0,
        1,
        -1,
        0,
        -1,
        -1,
        0,
        -1,
        1,
        0,
        1,
        1,
      ];
      this._section2.sts = [0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1];
      this._section2.indices = [0, 1, 2, 0, 2, 3, 4, 5, 6, 4, 6, 7];
      this._zhongdianLabel.position[2] = this._qidianLabel.position[2] =
        (max + min) / 2;
    },
    creating() {
      this.clearAll();
      this._qidianLabel = this.$root.$createLabel(" 起点 ");
      this._zhongdianLabel = this.$root.$createLabel(" 终点 ");
      this._qidianLabel.creating = true;

      XE.MVVM.watch(
        () => this._qidianLabel.creating,
        () => {
          if (!this._qidianLabel.creating) {
            this.p1.x = (this._qidianLabel.position[0] * 180) / Math.PI;
            this.p1.y = (this._qidianLabel.position[1] * 180) / Math.PI;
            this._zhongdianLabel.creating = true;
            this.queryClosestQiDian();
          }
        }
      );
      XE.MVVM.watch(
        () => this._zhongdianLabel.creating,
        async () => {
          if (!this._zhongdianLabel.creating && !this._qidianLabel.creating) {
            this.p2.x = (this._zhongdianLabel.position[0] * 180) / Math.PI;
            this.p2.y = (this._zhongdianLabel.position[1] * 180) / Math.PI;
            await this.queryClosestZhongDian();
            this.ok();
          }
        }
      );
    },
    draw(data) {
      console.log(data);
      this._group.removeAll();
      var draw = new DrawAllSection(data);
      const clientWidth = this.$refs.canvas.clientWidth;
      const clientHeight = this.$refs.canvas.clientHeight;
      draw.draw(clientWidth - 10, clientHeight - 10, 5, this._group);
    },
    queryClosestQiDian() {
      let data = {
        projectid: this.$root.projectid,
        lon: this.p1.x,
        lat: this.p1.y,
      };

      this.$axios
        .get("/project/point/query/closest", {
          params: data,
        })
        .then((res) => {
          //  res.data.data = [{distance:10,height:10,qidian:'ss',zhongdian:'ff',duanmian:"100"}]
          this.qidian = res.data.data.id;
        })
        .catch((err) => {
          this.$root.$log("error", err);
        });
    },
    async queryClosestZhongDian() {
      let data = {
        projectid: this.$root.projectid,
        lon: this.p2.x,
        lat: this.p2.y,
      };

      var res = await this.$axios.get("/project/point/query/closest", {
        params: data,
      });
      this.zhongdian = res.data.data.id;
    },
    ok() {
      let data = {
        projectid: this.$root.projectid,
        qidian: this.qidian,
        zhongdian: this.zhongdian,
      };

      this.$axios
        .get("/project/line/connectivity", {
          params: data,
        })
        .then((res) => {
          //  res.data.data = [{distance:10,height:10,qidian:'ss',zhongdian:'ff',duanmian:"100"}]
          let result = res.data.data;
          this.infos = result.lines;
          if (this._temline) {
            this._temline.destroy();
            this._temline = undefined;
          }
          if (this.infos) {
            this.total =
              "共" +
              this.infos.length +
              "条管线，总长" +
              result.length.toFixed(0) +
              "米";
          } else {
            this.total = "共0条管线，总长0米";
          }
          this._temline = new XE.Obj.CustomPrimitiveExt.Tube(this.$root._earth);
          result.points.forEach((p) => {
            this._temline.positions.push([
              (p.x * Math.PI) / 180,
              (p.y * Math.PI) / 180,
              p.z,
            ]);
          });
          this._temline.radius = 2;
          this._temline.radialSegments = 4;
          this._temline.imageUrl = "./image/03.png";
          this._temline.textureSize = [Math.PI, Math.PI];
          this._temline.speed = [-2, 0];
          this._temline.isCurve = false;
          this.draw(res.data.data);
        })
        .catch((err) => {
          this.$root.$log("error", err);
        });
    },
    cancel() {
      this.$root.destroyTool(this);
    },
    clearAll() {
      if (this._qidianLabel) {
        this._qidianLabel.destroy();
        this._qidianLabel = undefined;
      }
      if (this._zhongdianLabel) {
        this._zhongdianLabel.destroy();
        this._zhongdianLabel = undefined;
      }
      if (this._section) {
        this._section.destroy();
        this._section = undefined;
      }
      if (this._section2) {
        this._section2.destroy();
        this._section2 = undefined;
      }
    },
  },
  beforeUnmount() {
    this.clearAll();
  },
};
</script>

<style scoped>
.section {
  height: 300px;
  width: 100%;
  display: flex;
  overflow: auto;
  line-height: 30px;
  padding-top: 10px;
}

.left {
  width: 30%;
}

.right {
  width: 70%;
  height: 300px;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
}
.right-canvas {
  height: 250px !important;
}
.right table tr:nth-child(1) th {
  border-top: 1px solid rgb(148, 147, 147);
}
.right table tr th:last-child {
  border-right: 1px solid rgb(148, 147, 147);
}
.right table th {
  border-bottom: 1px solid rgb(148, 147, 147);
  border-left: 1px solid rgb(148, 147, 147);
  text-align: center;
}
.right table tr td:last-child {
  border-right: 1px solid rgb(148, 147, 147);
}
.right table td {
  border-bottom: 1px solid rgb(148, 147, 147);
  border-left: 1px solid rgb(148, 147, 147);
  text-align: center;
}

.title {
  display: flex;
  flex-direction: column;
  line-height: 30px;
}

.title > label:nth-child(3) {
  display: inline-block;
  width: 450px;
}

.title > label:nth-child(2) {
  display: inline-block;
  width: 450px;
}

.submit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.section > table {
  width: 96%;
}

.canvas {
  border: 1px solid #fff;
}

.btn > button:nth-child(3) {
  margin-left: 40px;
}

.section > table > tr > td,
.section > table > tr > th {
  border-left: 1px solid rgb(211, 198, 198);
  border-right: none;
  border-bottom: 1px solid rgb(211, 198, 198);
  text-align: center;
  background: rgb(0, 0, 0, 0);
}

.section > table > tr:first-child > th {
  border-top: 1px solid rgb(211, 198, 198);
}

.section > table > tr > td:last-child,
.section > table > tr > th:last-child {
  border-right: 1px solid rgb(211, 198, 198);
}
</style>

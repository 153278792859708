<template>
    <div class="c">
        <div v-show="showQuery" id="chaxun">
            <input v-model="submit.key" @keyup.enter="query" /><button @click="query">
                查询
            </button>
        </div>
        <table :class="{ all: flag }">
            <tbody id="form">
                <tr>
                    <th style="width: 40px">序号</th>
                    <th v-for="(item, index) in columns" :style="{ width: item.width + 'px' }" :key="index">
                        <span v-html="item.displayName"></span>
                        <span v-show="item.sortable" class="ivu-table-sort"><i class="ivu-icon ivu-icon-md-arrow-dropup"
                                :class="{
                  on: item.sort == 'asc' && submit.sortfield == item.fieldName,
                }" @click="sort(item, 'asc')"></i>
                            <i class="ivu-icon ivu-icon-md-arrow-dropdown" :class="{
                  on: item.sort == 'desc' && submit.sortfield == item.fieldName,
                }" @click="sort(item, 'desc')"></i></span>
                    </th>
                </tr>
                <tr class="jianxi" v-for="(item, index) in datas" :key="item.id">
                    <td style="width: 40px">
                        {{ index + 1 }}
                    </td>
                    <td v-for="(column, cindex) in columns" :key="cindex" :style="{ width: column.width + 'px' }">
                        <span v-if="column.template === undefined" v-html="
                typeof column.format === 'function'
                  ? column.format(item[column.fieldName])
                  : item[column.fieldName]
              "></span>
                        <span v-else>
                            <component :is="getComponentName(column)" :row="item"></component>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagebottom" v-show="showPageControl">
            <label>每页</label>
            <input class="page" v-model.number="submit.pagecap" />
            <label>条</label>
            <button class="button1" @click="changeLose" :disabled="submit.page <= 1">
                上一页</button><label>{{ submit.page }}</label><button class="button2" @click="changeAdd"
                :disabled="submit.page >= pagecount">
                下一页</button><label>共{{ pagecount }}页</label>
        </div>
    </div>
</template>

<script>
    // import { createComponent } from 'vue'
    export default {
        name: "Table",
        data() {
            return {
                datas: [],
                options: {},
                submit: {
                    key: "",
                    page: 1,
                    pagecap: 10,
                    sort: "desc",
                    sortfield: "created_at",
                },
                pagecount: 0,
            };
        },
        props: {
            flag: {
                type: Boolean,
                default: false,
            },
            showQuery: {
                type: Boolean,
                default: true,
            },
            showPageControl: {
                type: Boolean,
                default: true,
            },
            columns: {
                type: Array,
                default: function () {
                    return [{
                        displayName: "名称",
                        fieldName: "name",
                        width: 30,
                        sortable: true,
                    }, ];
                },
            },
            url: {
                type: String,
                default: "",
            },
        },
        created() {
            for (let row of this.columns) {
                if (row.template) {
                    this.$app.component(`cmp-${row.uniqueName}`, {
                        props: ["row"],
                        template: row.template,
                        methods: row.methods,
                    });
                }
            }
        },
        methods: {
            getComponentName(row) {
                return `cmp-${row.uniqueName}`;
            },
            sort(item, desc) {
                this.submit.sortfield = item.fieldName;
                item.sort = this.submit.sort = desc;
                this.query(this.options);
            },
            changeAdd() {
                this.submit.page++;
                this.query(this.options);
            },
            changeLose() {
                this.submit.page--;
                this.query(this.options);
            },
            query(options, filter, handleResult) {
                //console.log("发送的请求为", this.url);
                this.options = options || {};
                var params = Object.assign(this.options, this.submit);
                let self = this;
                this.$axios
                    .get(this.url, {
                        params: params,
                    })
                    .then((res) => {
                        if (res.data) {
                            if (typeof handleResult === "function") {
                                //console.log("返回内容", handleResult(res));
                                self.datas = handleResult(res);
                            } else {
                                self.datas = res.data.data.result.results;
                                //console.log(self.datas);
                                self.pagecount = res.data.data.result.pagecount;
                            }

                            if (filter) {
                                self.datas.forEach((element) => {
                                    filter(element);
                                });
                            }
                        }

                    });
            },
        },
    };
</script>
<style scoped>
    table {
        width: 100%;
    }

    #form>tr {
        padding: 0;
        margin: 0;
        list-style: none;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.16078431372549018);
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.16);
        opacity: 1;
        text-align: center;
    }

    #from>tr {
        box-sizing: content-box;
        width: 100%;
    }

    #chaxun>input {
        text-indent: 5px;
        width: 430px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 4px;
    }

    #chaxun>button {
        width: 56px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #707070;
        margin-left: 6px;
        opacity: 1;
        border-radius: 4px;
    }

    #chaxun {
        text-align: left;
        margin-top: -15px;
        margin-bottom: 15px;
        /* position: absolute;
  top: 0px; */
    }

    #form>tr:first-child {
        line-height: 30px;
        width: 100%;
        text-align: center;
        list-style: none;
        height: 30px;
        background: #f5f5f5;
        border: 1px solid rgba(0, 0, 0, 0.16078431372549018);
        opacity: 0.96;
    }

    .ivu-icon {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-family: Ionicons;
        font-style: normal;
        font-variant: normal;
        font-weight: 400;
        line-height: 1;
        speak: none;
        text-rendering: auto;
        text-transform: none;
        vertical-align: middle;
    }

    .ivu-table-sort {
        cursor: pointer;
        display: inline-block;
        height: 12px;
        margin-top: -1px;
        overflow: hidden;
        position: relative;
        vertical-align: middle;
        width: 14px;
    }

    .ivu-table-sort i {
        -webkit-transition: color 0.2s ease-in-out;
        color: #c5c8ce;
        display: block;
        font-size: 16px;
        height: 6px;
        line-height: 6px;
        overflow: hidden;
        position: absolute;
        transition: color 0.2s ease-in-out;
    }

    .ivu-table-sort i:first-child {
        top: 0;
    }

    .ivu-table-sort i:last-child {
        bottom: 0;
    }

    .ivu-table-sort i.on {
        color: #2d8cf0;
    }

    .ivu-icon-md-arrow-dropup:before {
        content: "\F343";
    }

    .ivu-icon-md-arrow-dropdown:before {
        content: "\F33D";
    }

    #spanbutton {
        display: flex;
        height: 30px;
    }

    .pagebottom {
        width: 500px;
        margin: auto;
        margin-top: 5px;
        text-align: center;
    }

    .pagebottom>input {
        border-radius: 4px;
    }

    .pagebottom>button {
        min-width: 50px;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
        padding: 2px 5px;
        border-radius: 3px;
        text-decoration: none;
        border-radius: 3px;
        cursor: pointer;
        border: 1px solid #c2c2c2;
        background-image: linear-gradient(0deg, #ebe6e6, white);
        color: #000000;
        outline: none;
    }

    .button1 {
        margin-left: 20px;
        margin-right: 5px;
    }

    .button2 {
        margin-right: 20px;
        margin-left: 5px;
    }

    .page {
        width: 60px;

        text-align: center;
        box-sizing: border-box;
        height: 25px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.16);
    }

    .all {
        border-collapse: separate;
        border-spacing: 0px 5px;
        background: #f5f5f5;
    }
</style>

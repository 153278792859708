<template>
  <Window :title="'参数设置工具面板'"
    :footervisible="true"
    @ok="ok"
    @cancel="cancel"
    :width="1050"
    :height="719">

    <div class="parameter_content">
      <div>
        <div ref="cesiumContainer"
          class="cesiumContainer"></div>
      </div>
      <div>
        <div v-if="modeler == 'wantou'">
          <div class="right">
            <label>造型器:</label>
            <select v-model="modeler">
              <option value="wantou">弯头</option>
              <!-- <option value="weld">焊口</option> -->
            </select>
          </div>
          <div class="right">
            <label>曲率半径(m)</label><input type="number"
              step="0.01"
              v-model.number="wantou.wantou.qulvbanjing" />
          </div>
          <div class="right">
            <label>直线段长(m)</label><input type="number"
              step="0.01"
              v-model.number="wantou.wantou.zhixianchang" />
          </div>
          <div class="right">
            <label>法兰宽度(m)</label><input type="number"
              step="0.01"
              v-model.number="wantou.flange.length" />
          </div>
          <div class="right">
            <label>法兰厚度(m)</label><input type="number"
              step="0.01"
              v-model.number="wantou.flange.thickness" />
          </div>
          <div class="right">
            <label>
            </label>
            <button @click="preview"
              class="preview">预览模型</button>
          </div>
          <div class="jsoncontent">
            {{JSON.stringify(wantou)}}
          </div>
        </div>
        <div v-if="modeler == 'weld'">
          <div class="right">
            <label>造型器:</label>
            <select v-model="modeler">
              <option value="wantou">弯头</option>
              <!-- <option value="weld">焊口</option> -->
            </select>
          </div>
          <div class="right">
            <label>焊口宽度(m)</label><input type="number"
              step="0.01"
              v-model.number="weld.weld.length" />
          </div>
          <div class="right">
            <label>焊口厚度(m)</label><input type="number"
              step="0.01"
              v-model.number="weld.weld.thickness" />
          </div>
          <div class="right">
            <label>
            </label>
            <button @click="preview"
              class="preview">预览模型</button>
          </div>
          <div class="jsoncontent">
            {{JSON.stringify(weld)}}
          </div>
        </div>

      </div>
    </div>
  </Window>
</template>

<script>
/* eslint-disable */
import Window from "../../../components/Window";

import {
  createViewer
} from "../scripts/misc/createViewer";

export default {
  components: {
    Window
  },
  data() {
    return {
      modeler: "wantou",
      wantou: {
        type:"wantou",
        wantou: {
          qulvbanjing: 0.1,
          zhixianchang: 0.1
        },
        flange: {
          length: 0,
          thickness: 0
        }
      },
      weld: {
        type:"weld",
        weld: {
          length: 0.01,
          thickness: 0.01
        }
      },
    };
  },
  props: {
    getBind: Object,
    default() {
      { }
    },
    param: {
      type: Object,
      default: undefined
    }
  },
  mounted() {
    // 现在默认传过来的参数是一个对象(圆形)(后续看传入什么形式，如果是Json在parse一下)
    debugger
    if (this.getBind) {
      if (this.getBind.type == "wantou") {
        this.modeler = "wantou";
        this.wantou = this.getBind;
      } else if (this.getBind.type == "weld") {
        this.weld = this.getBind;
        this.modeler = "weld";
      }
    }

    // //初始化cesium
    // this._viewer = createViewer(this.$refs.cesiumContainer);

    // this.preview();
  },
  methods: {
    jingparamcontent() {
      this.jingparam.param.param[1].bottom = this.jingparam.param.param[0].bottom + this.jingparam.param
        .param[0].height
      this.jingparam.param.param[2].bottom = this.jingparam.param.param[1].bottom + this.jingparam.param
        .param[1].height
      this.jingparam.param.param[3].bottom = this.jingparam.param.param[2].bottom + this.jingparam.param
        .param[2].height
      this.jingparam.param.param[1].bottom_radius = this.jingparam.param.param[0].radius
      this.jingparam.param.param[1].top_radius = this.jingparam.param.param[2].radius
    },
    preview() {
      var param;
      //深拷贝
      if (this.modeler == "wantou") {
        param = JSON.parse(JSON.stringify(this.wantou));
      } else if (this.modeler == "weld") {
        param = JSON.parse(JSON.stringify(this.weld));
      }

      this.loadGlb("./glb/preview?previewjson=" + JSON.stringify(param) + "&projectid=" + this.$root.projectid);
    },
    resetGlb() {
      if (this._model && this._viewer) {
        this._viewer.scene.primitives.remove(this._model);
        this._model = undefined;
      }
    },
    loadGlb(url) {
      this.resetGlb();
      fetch(url).then(response => {
        response.arrayBuffer().then(arrayBuffer => {
          // @ts-ignore
          var parsedGltf = Cesium.parseGlb(new Uint8Array(arrayBuffer));
          if (this._viewer) {
            const hpr = new Cesium.HeadingPitchRoll(Math.PI * -0.5, 0, 0);
            const origin = Cesium.Cartesian3.fromDegrees(116.39, 39.9, 100);
            const modelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(
              origin,
              hpr
            );
            this._model = this._viewer.scene.primitives.add(
              new Cesium.Model({
                gltf: parsedGltf,
                modelMatrix
              })
            );
            if (!this._model) {
              throw new Error(`!this._model`);
            }
            const viewer = this._viewer;
            const camera = this._viewer.camera;
            this._model.readyPromise.then(() => {
              if (!this._model) {
                throw new Error(`!this._model`);
              }

              this._model.activeAnimations.addAll({
                multiplier: 1,
                loop: Cesium.ModelAnimationLoop.REPEAT
              });

              let radius = this._model.boundingSphere.radius;
              if (radius < 10) {
                const scale = 10 / radius;
                this._model.scale = scale;
                radius = 10;
              }

              const controller = viewer.scene.screenSpaceCameraController;
              controller.minimumZoomDistance = radius * 0.5;

              const {
                heading,
                pitch
              } = camera;
              camera.frustum.near = radius * 0.01;
              camera.lookAt(
                origin,
                new Cesium.HeadingPitchRange(heading, pitch, radius *
                  2.0)
              );
            });
          }
        });
      });
    },
    checkLocal() {
      if (this.modelling == "圆形造型器") {
        if (this.circle.profile.radius == 0) {
          this.$root.$alert("横截面半径不能为0");
        }
      } else if (this.modelling == "方形造型器") {
        if (this.circle.profile.width == 0) {
          this.$root.$alert("横截面宽度不能为0");
        } else if (this.circle.profile.height == 0) {
          this.$root.$alert("横截面高度不能为0");
        }
      }
    },
    ok() {
      if (this.modeler == "wantou") {
        if (this.param && this.param.ok) {
          this.param.ok(this.wantou);
        }
      } else if (this.modeler == "weld") {
        if (this.param && this.param.ok) {
          this.param.ok(this.weld);
        }
      }

      this.$root.destroyTool(this);
    },
    cancel() {
      this.$root.destroyTool(this);
    }
  },
  watch: {
    modeler: function () {
      this.preview();
    }
  }
};
</script>

<style scoped>
.cesiumContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #242424;
}

.parameters_top {
  display: flex;
  height: 28px;
  align-items: center;
  justify-content: center;
}

.parameters_top > div:nth-child(2) {
  display: flex;
  border: 1px solid #999595;
  flex-grow: 1;
  height: 24px;
  align-items: center;
}

.parameter_content {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.parameter_content > div:nth-child(1) {
  width: 670px;
  flex-grow: 0;
  border-right: none;
  height: 100%;
}

.parameter_content > div:nth-child(2) {
  width: 300px;
  flex-grow: 1;
  height: 100%;
}

.right {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
  padding-top: 10px;
}

.right > select {
  width: 220px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.right > label {
  width: 110px;
  text-align: center;
}

.right > input {
  width: 220px;
  height: 30px;
  line-height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.preview {
  width: 220px;
  height: 30px;
  background: #383838;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.jsoncontent {
  width: 100%;
  font-size: 16px;
  margin-top: 30px;
  word-wrap: break-word;
}
</style>


  /* eslint-disable */

  import {
    Options,
    Vue
  } from "vue-class-component";
  import * as Cesium from "cesium";
  import {
    readFileFromBrowser
  } from "../scripts/utils/readFileFromBrowser";
  import {
    createViewer
  } from "../scripts/misc/createViewer";
  import TextureSelect from "./TextureSelect.vue";
  import ColorSlider from "./ColorSlider.vue";
  import ColorButton from "../../../components/ColorButton/Index.vue";

  function propValid(
    modelMaterial: Cesium.ModelMaterial | undefined,
    prop: string
  ) {
    return !!modelMaterial && typeof modelMaterial.getValue(prop) !== "undefined";
  }

  @Options({
    components: {
      ColorSlider,
      TextureSelect,
      ColorButton,
    },
    watch: {
      stylecolor: {
        deep: true,
        handler() {
          this.baseColorFactor[0] = Number(this.stylecolor.r) / 255;
          this.baseColorFactor[1] = Number(this.stylecolor.g) / 255;
          this.baseColorFactor[2] = Number(this.stylecolor.b) / 255;
          this.baseColorFactor[3] = Number(this.stylecolor.a) / 255;
        },
      },
      lightcolor: {
        deep: true,
        handler() {
          this.emissiveFactor[0] = Number(this.lightcolor.r) / 255;
          this.emissiveFactor[1] = Number(this.lightcolor.g) / 255;
          this.emissiveFactor[2] = Number(this.lightcolor.b) / 255;
        },
      },
      id: {
        deep: true,
        handler() {
          if (this.id) {
            this.baseColorTextureUrl =
              "/project/texture/" + this.$root.projectid + "/" + this.id + ".jpg";
          } else {
            this.resetBaseColorTexture();
          }
        },
      },
      id2: {
        deep: true,
        handler() {
          if (this.id2) {
            this.normalTextureUrl =
              "/project/texture/" +
              this.$root.projectid +
              "/" +
              this.id2 +
              ".jpg";
          }
        },
      },
      baseColorFactor: {
        deep: true,
        handler(val) {
          if (this._currentModelMaterial) {
            this.stylecolor.r = Number(val[0]) * 255;
            this.stylecolor.g = Number(val[1]) * 255;
            this.stylecolor.b = Number(val[2]) * 255;
            this.stylecolor.a = Number(val[3]) * 255;
            console.log("12", val);
            const [x, y, z, w] = val;
            this._currentModelMaterial.setValue(
              "baseColorFactor",
              new Cesium.Cartesian4(x, y, z, w)
            );
          }
        },
      },
      emissiveFactor: {
        deep: true,
        handler(val) {
          if (this._currentModelMaterial) {
            this.lightcolor.r = Number(val[0]) * 255;
            this.lightcolor.g = Number(val[1]) * 255;
            this.lightcolor.b = Number(val[2]) * 255;
            this.lightcolor.a = 1;
            const [x, y, z] = val;
            this._currentModelMaterial.setValue(
              "emissiveFactor",
              new Cesium.Cartesian4(x, y, z)
            );
          }
        },
      },
      metallicFactor(val) {
        if (this._currentModelMaterial) {
          this._currentModelMaterial.setValue("metallicFactor", val);
        }
      },
      roughnessFactor(val) {
        if (this._currentModelMaterial) {
          this._currentModelMaterial.setValue("roughnessFactor", val);
        }
      },
      matSelected(val) {
        if (this._model && val) {
          // @ts-ignore
          this._setCurrentModelMaterial(this._model._runtime.materialsById[val]);
          this._originBaseColorTexture = this._originBaseColorTextures[val];
          this._originNormalTexture = this._originNormalTextures[val];
        } else {
          this._currentModelMaterial = undefined;
        }
      },
      glbUrl(val: string) {
        if (val.endsWith(".glb")) {
          this.readGlb(val);
        } else {
          this.resetGlb();
        }
      },
      baseColorTextureUrl(val: string) {
        if (val.endsWith(".jpg") || val.endsWith(".png")) {
          this.setBaseColorTexture(val);
        } else {}
      },
      baseColorTextureOffset: {
        deep: true,
        handler(val) {
          if (propValid(this._currentModelMaterial, "baseColorTextureOffset")) {
            const [x, y] = val;
            this._currentModelMaterial.setValue(
              "baseColorTextureOffset",
              new Cesium.Cartesian2(x, y)
            );
          }
        },
      },
      baseColorTextureRotation(val) {
        if (propValid(this._currentModelMaterial, "baseColorTextureRotation")) {
          this._currentModelMaterial.setValue("baseColorTextureRotation", val);
        }
      },
      baseColorTextureScale: {
        deep: true,
        handler(val) {
          if (propValid(this._currentModelMaterial, "baseColorTextureScale")) {
            const [x, y] = val;
            this._currentModelMaterial.setValue(
              "baseColorTextureScale",
              new Cesium.Cartesian2(x, y)
            );
          }
        },
      },
      normalTextureUrl(val: string) {
        if (val.endsWith(".jpg") || val.endsWith(".png")) {
          let currentTexture =
            this._currentModelMaterial.getValue("normalTexture");
          if (currentTexture) {
            this.setNormalTexture(val);
          } else {
            this.normalTextureUrl = "";
          }
        }
      },
      normalTextureOffset: {
        deep: true,
        handler(val) {
          if (propValid(this._currentModelMaterial, "normalTextureOffset")) {
            const [x, y] = val;
            this._currentModelMaterial.setValue(
              "normalTextureOffset",
              new Cesium.Cartesian2(x, y)
            );
          }
        },
      },
      normalTextureRotation(val) {
        if (propValid(this._currentModelMaterial, "normalTextureRotation")) {
          this._currentModelMaterial.setValue("normalTextureRotation", val);
        }
      },
      normalTextureScale: {
        deep: true,
        handler(val) {
          if (propValid(this._currentModelMaterial, "normalTextureScale")) {
            const [x, y] = val;
            this._currentModelMaterial.setValue(
              "normalTextureScale",
              new Cesium.Cartesian2(x, y)
            );
          }
        },
      },
    },
  })
  export default class CesiumContainerComp extends Vue {
    id: string = "";
    id2: string = "";
    _viewer ? : Cesium.Viewer = undefined; // 注意：Earth和Cesium的相关变量放在vue中，必须使用下划线作为前缀！
    _model ? : Cesium.Model = undefined;
    _defaultTexture ? : any;
    matSelected: string = "";
    modelMaterialIds: string[] = [];
    _currentModelMaterial ? : Cesium.ModelMaterial = undefined;
    flag: boolean = false;
    width: number = 0;
    height: number = 0;
    glbUrl: string = ""; // models/Cesium_Air.glb
    name: string = "";
    selectedgeometry: string = "pipe";
    // m模型自身涉及到的属性数据 vtxf
    // baseColorFactor: {value: Cartesian4, clone: ƒ, func: ƒ}
    // baseColorTexture: DelayLoadedTextureUniform {_value: Proxy, _textureId: 0, _textures: {…}, _defaultTexture: Texture, func: ƒ}
    // baseColorTextureOffset: {value: Cartesian2, clone: ƒ, func: ƒ}
    // baseColorTextureRotation: {value: 0.5, clone: ƒ, func: ƒ}
    // baseColorTextureScale: {value: Cartesian2, clone: ƒ, func: ƒ}
    // emissiveFactor: {value: Cartesian3, clone: ƒ, func: ƒ}
    // metallicFactor: {value: 0, clone: ƒ, func: ƒ}
    // normalTexture: DelayLoadedTextureUniform {_value: Proxy, _textureId: 1, _textures: {…}, _defaultTexture: Texture, func: ƒ}
    // normalTextureOffset: {value: Cartesian2, clone: ƒ, func: ƒ}
    // normalTextureRotation: {value: 0.7, clone: ƒ, func: ƒ}
    // normalTextureScale: {value: Cartesian2, clone: ƒ, func: ƒ}
    // roughnessFactor: {value: 0.449999988079071, clone: ƒ, func: ƒ}

    baseColorFactor: [number, number, number, number] = [0, 0, 0, 0];
    stylecolor: Object = {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
    };
    lightcolor: Object = {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
    };

    emissiveFactor: [number, number, number] = [0, 0, 0];
    metallicFactor: number = 0;
    roughnessFactor: number = 0;
    baseColorTextureUrl: string = "";
    baseColorTextureOffset: [number, number] = [0, 0];
    baseColorTextureRotation: number = 0; // 弧度到度的转换
    baseColorTextureScale: [number, number] = [1, 1];

    normalTextureUrl: string = "";
    normalTextureOffset: [number, number] = [0, 0];
    normalTextureRotation: number = 0; // 弧度到度的转换
    normalTextureScale: [number, number] = [1, 1];

    _originBaseColorTextures: {
      [k: string]: any;
    } = {};
    _originBaseColorTexture: any;

    _originNormalTextures: {
      [k: string]: any;
    } = {};
    _originNormalTexture: any;

    // 1.1 资源创建
    mounted() {
      console.log("CesiumContainer");
      let _this = this;
      const container = this.$refs.cesiumContainer as HTMLElement;
      this._viewer = createViewer(container);
      let p1 = document.getElementsByClassName(
        "cesium-viewer-bottom"
      )[1] as HTMLElement;
      p1.style.display = "none";
      let canvas = document.getElementsByTagName("canvas")[0] as HTMLElement;
      console.log(this._viewer);
      // earth.czm.scene.backgroundColor = new Cesium.Color(0, 0, 0, 1)

      // @ts-ignore
      this._defaultTexture = this._viewer.scene.context.defaultTexture;
    }

    // 1.2 资源销毁
    beforeUnmount() {
      if (this._viewer) {
        this._viewer.destroy();
        this._viewer = undefined;
      }
    }

    setBaseColorTexture(baseColorTextureUrl: string) {
      const viewer = this._viewer as Cesium.Viewer;
      const mat = this._currentModelMaterial;
      // readFileFromBrowser().then((file) => {
      //   const reader = new FileReader();
      //   reader.readAsDataURL(file);
      //   reader.onloadend = function () {
      //     const image = new Image();
      //     // @ts-ignore
      //     image.src = this.result;
      //     image.onload = function () {
      //       // @ts-ignore
      //       const t = new Cesium.Texture({
      //         // @ts-ignore
      //         context: viewer.scene.context,
      //         source: image,
      //       });
      //       if (mat) {
      //         mat.setValue("baseColorTexture", t);
      //       }
      //     };
      //   };
      // });

      if (baseColorTextureUrl) {
        const image = new Image();
        // @ts-ignore
        image.src = baseColorTextureUrl;
        // image.onload = function () {
        //     // @ts-ignore
        //     const t = new Cesium.Texture({
        //         // @ts-ignore
        //         context: viewer.scene.context,
        //         source: image,
        //     });
        //     if (mat) {
        //         mat.setValue("baseColorTexture", t);
        //     }
        // };
        image.onload = function () {
          if (!mat) {
            return;
          }

          const oldT = mat.getValue("baseColorTexture");
          // @ts-ignore
          const t = new Cesium.Texture({
            // @ts-ignore
            context: viewer.scene.context,
            source: image,
            // @ts-ignore
            // sampler: oldT.sampler, // sampler中含有repeat等参数，需要设置上，否则会导致纹理映射不对
            sampler: new Cesium.Sampler({
              wrapS: 10497,
              wrapT: 10497,
            }),
          });
          // t.generateMipmap();
          mat.setValue("baseColorTexture", t);
        };
      } else {
        this.resetBaseColorTexture();
      }
    }
    clearBaseColorTexture() {
      if (this._currentModelMaterial) {
        const currentTexture =
          this._currentModelMaterial.getValue("baseColorTexture");
        if (currentTexture) {
          // 这个属性存在，才可以赋值
          this._currentModelMaterial.setValue(
            "baseColorTexture",
            this._defaultTexture
          );
          this.baseColorTextureUrl = "";
          this.id = "";
        } else {
          // @ts-ignore
          this.$root.$alert(`当前模型的该属性不存在，无法赋值！`);
        }
        if (this._defaultTexture !== currentTexture) {
          currentTexture.destroy();
        }
      }
    }
    resetBaseColorTexture() {
      if (this._currentModelMaterial) {
        const currentTexture =
          this._currentModelMaterial.getValue("baseColorTexture");
        if (currentTexture) {
          // 这个属性存在，才可以赋值
          this._currentModelMaterial.setValue(
            "baseColorTexture",
            this._originBaseColorTexture
          );
          this.baseColorTextureUrl = "";
          this.id = "";
        } else {
          // @ts-ignore
          this.$root.$alert(`当前模型的该属性不存在，无法赋值！`);
        }
        if (this._defaultTexture !== currentTexture) {
          currentTexture.destroy();
        }
      }
    }
    selectTexture(baseColorTextureUrl: string) {
      if (this._currentModelMaterial) {
        if (this._currentModelMaterial.getValue("baseColorTexture")) {
          let id = "";
          if (!(baseColorTextureUrl == "")) {
            let last = baseColorTextureUrl.lastIndexOf(".");
            let begain = baseColorTextureUrl.lastIndexOf("/") + 1;
            id = baseColorTextureUrl.substring(begain, last);
          }
          // @ts-ignore
          this.$root.showPropertyWindow(
            "TextureSelect", {
              component: TextureSelect,
            }, {
              type: 1,
              id: id,
              self: this,
            }
          );
        } else {
          // @ts-ignore
          this.$root.$alert(`当前模型的该属性不存在，无法赋值！`);
        }
      }
    }
    selectPipe() {
      this.flag = false
      this.glbUrl = "./models/pipe_texturetransform.glb";
      this.selectedgeometry = "pipe";
    }
    selectCircle() {
      this.flag = false
      var param = {
        param: {
          param: [{
            bottom: 0,
            height: 0.01,
            radius: 0.4,
            material: {
              height: 0,
              width: 0,
              emissiveFactor: [0, 0, 0],
              matSelected: "",
              normalTexture: {
                extensions: {
                  KHR_texture_transform: {
                    offset: [0, 0],
                    rotation: 90,
                    scale: [1, 1],
                  },
                },
                id: "1d2c9694c527dc63e21a2fd38d4d94c8",
              },
              pbrMetallicRoughness: {
                baseColorFactor: [1, 1, 1, 1],
                baseColorTexture: {
                  extensions: {
                    KHR_texture_transform: {
                      offset: [0, 0],
                      rotation: 90,
                      scale: [1, 1],
                    },
                  },
                  id: "f3505fdd5ff732a301420905cb42e43a",
                },
                metallicFactor: 0,
                roughnessFactor: 1,
              },
            },
            type: "jinggai",
          }, ],
          type: "well",
        },
        type: "param",
        direction: [1, 0, 0],
        distanceRatio: 10,
      };
      // @ts-ignore
      var url = "./glb/preview?previewjson=" + JSON.stringify(param) + "&projectid=" + this.$root.projectid;
      this.readGlb(url, Math.PI * 0.5);
      this.selectedgeometry = "circle";
    }
    selectCylinder() {
      this.flag = false
      var param = {
        param: {
          param: [{
            bottom: -1,
            height: 2,
            radius: 1,
            material: {
              height: 0,
              width: 0,
              emissiveFactor: [0, 0, 0],
              matSelected: "",
              normalTexture: {
                extensions: {
                  KHR_texture_transform: {
                    offset: [0, 0],
                    rotation: 90,
                    scale: [1, 1],
                  },
                },
                id: "1d2c9694c527dc63e21a2fd38d4d94c8",
              },
              pbrMetallicRoughness: {
                baseColorFactor: [1, 1, 1, 1],
                baseColorTexture: {
                  extensions: {
                    KHR_texture_transform: {
                      offset: [0, 0],
                      rotation: 90,
                      scale: [1, 1],
                    },
                  },
                  id: "f3505fdd5ff732a301420905cb42e43a",
                },
                metallicFactor: 0,
                roughnessFactor: 1,
              },
            },
            type: "jingshi",
          }, ],
          type: "well",
        },
        type: "param",
        direction: [1, 0, 0],
        distanceRatio: 10,
      };
      // @ts-ignore
      var url = "/glb/preview?previewjson=" + JSON.stringify(param) + "&projectid=" + this.$root.projectid;
      this.readGlb(url);
      this.selectedgeometry = "cylinder";
    }
    selectBuild() {
      this.flag = true
      var param = {
        param: {
          param: [{
            bottom: 0,
            height: 0.01,
            radius: 0.4,
            material: {
              height: 0,
              width: 0,
              emissiveFactor: [0, 0, 0],
              matSelected: "",
              normalTexture: {
                extensions: {
                  KHR_texture_transform: {
                    offset: [0, 0],
                    rotation: 90,
                    scale: [1, 1],
                  },
                },
                id: "1d2c9694c527dc63e21a2fd38d4d94c8",
              },
              pbrMetallicRoughness: {
                baseColorFactor: [1, 1, 1, 1],
                baseColorTexture: {
                  extensions: {
                    KHR_texture_transform: {
                      offset: [0, 0],
                      rotation: 90,
                      scale: [1, 1],
                    },
                  },
                  id: "f3505fdd5ff732a301420905cb42e43a",
                },
                metallicFactor: 0,
                roughnessFactor: 1,
              },
            },
            type: "jinggai",
          }, ],
          type: "well",
        },
        type: "param",
        direction: [1, 0, 0],
        distanceRatio: 10,
      };
      // @ts-ignore
      var url = "/glb/preview?previewjson=" + JSON.stringify(param) + "&projectid=" + this.$root.projectid;
      this.readGlb(url, Math.PI * 0.5);
      this.selectedgeometry = "polygon";
    }
    selectNormalTexture(normalTextureUrl: string) {
      if (this._currentModelMaterial) {
        if (this._currentModelMaterial.getValue("normalTexture")) {
          let id2 = "";
          if (!(normalTextureUrl == "")) {
            let last = normalTextureUrl.lastIndexOf(".");
            let begain = normalTextureUrl.lastIndexOf("/") + 1;
            id2 = normalTextureUrl.substring(begain, last);
          }
          // @ts-ignore
          this.$root.showPropertyWindow(
            "TextureSelect", {
              component: TextureSelect,
            }, {
              type: 2,
              id2: id2,
              self: this,
            }
          );
        } else {
          // @ts-ignore
          this.$root.$alert(`当前模型的该属性不存在，无法赋值！`);
        }
      }
    }

    setNormalTexture(normalTextureUrl: string) {
      // const viewer = this._viewer as Cesium.Viewer;
      // const mat = this._currentModelMaterial;
      // readFileFromBrowser().then((file) => {
      //   const reader = new FileReader();
      //   reader.readAsDataURL(file);
      //   reader.onloadend = function () {
      //     const image = new Image();
      //     // @ts-ignore
      //     image.src = this.result;
      //     image.onload = function () {
      //       // @ts-ignore
      //       const t = new Cesium.Texture({
      //         // @ts-ignore
      //         context: viewer.scene.context,
      //         source: image,
      //       });
      //       if (mat) {
      //         if (mat.getValue("normalTexture")) {
      //           mat.setValue("normalTexture", t);
      //         } else {
      //           alert(`当前模型的该属性不存在，无法赋值！`);
      //         }
      //       }
      //     };
      //   };
      // });

      const viewer = this._viewer as Cesium.Viewer;
      const mat = this._currentModelMaterial;
      if (normalTextureUrl) {
        const image = new Image();
        // @ts-ignore
        image.src = normalTextureUrl;
        // image.onload = function () {
        //     // @ts-ignore
        //     const t = new Cesium.Texture({
        //         // @ts-ignore
        //         context: viewer.scene.context,
        //         source: image,
        //     });
        //     if (mat) {
        //         mat.setValue("normalTexture", t);
        //     }
        // };

        image.onload = function () {
          if (!mat) {
            return;
          }

          // const oldT = mat.getValue("normalTexture");
          // @ts-ignore
          const t = new Cesium.Texture({
            // @ts-ignore
            context: viewer.scene.context,
            source: image,
            // @ts-ignore
            // sampler: oldT.sampler, // sampler中含有repeat等参数，需要设置上，否则会导致纹理映射不对
            sampler: new Cesium.Sampler({
              wrapS: 10497,
              wrapT: 10497,
            }),
          });
          // t.generateMipmap();

          mat.setValue("normalTexture", t);
        };
      } else {
        this.resetNormalTexture();
      }
    }
    clearNormalTexture() {
      if (this._currentModelMaterial) {
        const currentTexture =
          this._currentModelMaterial.getValue("normalTexture");
        if (currentTexture) {
          // 这个属性存在，才可以赋值
          this._currentModelMaterial.setValue(
            "normalTexture",
            this._defaultTexture
          );
          this.normalTextureUrl = "";
          this.id2 = "";
        } else {
          // @ts-ignore
          this.$root.$alert(`当前模型的该属性不存在，无法赋值！`);
        }
        if (this._defaultTexture !== currentTexture) {
          currentTexture.destroy();
        }
      }
    }
    resetNormalTexture() {
      if (this._currentModelMaterial) {
        const currentTexture =
          this._currentModelMaterial.getValue("normalTexture");
        if (currentTexture) {
          // 这个属性存在，才可以赋值
          this._currentModelMaterial.setValue(
            "normalTexture",
            this._originNormalTexture
          );
          this.normalTextureUrl = "";
          this.id2 = "";
        } else {
          // @ts-ignore
          this.$root.$alert(`当前模型的该属性不存在，无法赋值！`);
        }
        if (this._defaultTexture !== currentTexture) {
          currentTexture.destroy();
        }
      }
    }

    _setCurrentModelMaterial(currentModelMaterial: Cesium.ModelMaterial) {
      // @ts-ignore
      // const currentModelMaterial = this._model._runtime.materialsById[
      //   id
      // ] as Cesium.ModelMaterial;
      if (!currentModelMaterial) {
        throw new Error(`!currentModelMaterial`);
      }

      {
        const baseColorFactor = currentModelMaterial.getValue("baseColorFactor");
        const {
          x,
          y,
          z,
          w
        } = baseColorFactor;
        this.baseColorFactor = [x, y, z, w];
      } {
        const emissiveFactor = currentModelMaterial.getValue("emissiveFactor");
        const {
          x,
          y,
          z
        } = emissiveFactor;
        this.emissiveFactor = [x, y, z];
      }
      this.metallicFactor = currentModelMaterial.getValue("metallicFactor");
      this.roughnessFactor = currentModelMaterial.getValue("roughnessFactor");

      this._currentModelMaterial = currentModelMaterial;
    }

    resetGlb() {
      if (this._model && this._viewer) {
        this._viewer.scene.primitives.remove(this._model);
        this._model = undefined;

        this.modelMaterialIds = [];
        this.matSelected = "";
        this._currentModelMaterial = undefined;
        this.stylecolor = {
          r: 0,
          g: 0,
          b: 0,
          a: 0,
        };
        this.lightcolor = {
          r: 0,
          g: 0,
          b: 0,
          a: 0,
        };
        this.baseColorFactor = [0, 0, 0, 0];
        this.emissiveFactor = [0, 0, 0];
        this.metallicFactor = 0;
        this.roughnessFactor = 0;
        this.glbUrl = ""; // models/Cesium_Air.glb
        this.name = "";
        this.baseColorTextureUrl = "";
        this.baseColorTextureOffset = [0, 0];
        this.baseColorTextureRotation = 0;
        this.baseColorTextureScale = [1, 1];
        this.normalTextureUrl = "";
        this.normalTextureOffset = [0, 0];
        this.normalTextureRotation = 0;
        this.normalTextureScale = [1, 1];
        this.width = 0;
        this.height = 0;
      }
    }

    readGlb(url: string, pitch: number = 0) {
      // readFileFromBrowser().then((file) => {
      //   if (!file.name.endsWith(".glb")) {
      //     alert("目前只能拾取后缀为glb的文件！");
      //     return;
      //   }
      // file.arrayBuffer().then((arrayBuffer) => {

      // fetch('models/Cesium_Air.glb').then(response => {

      this.resetGlb();
      // var url = "/glb/preview?previewjson=" + JSON.stringify(param) + "&projectid=" + this.$root.projectid;
      this.glbUrl = url;
      fetch(url).then((response) => {
        response.arrayBuffer().then((arrayBuffer) => {
          // @ts-ignore
          var parsedGltf = Cesium.parseGlb(new Uint8Array(arrayBuffer));
          if (this._viewer) {
            const hpr = new Cesium.HeadingPitchRoll(Math.PI * -0.5, pitch, 0);
            const origin = Cesium.Cartesian3.fromDegrees(116.39, 39.9, 100);
            const modelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(
              origin,
              hpr
            );
            this._model = this._viewer.scene.primitives.add(
              new Cesium.Model({
                gltf: parsedGltf,
                modelMatrix,
              })
            );
            if (!this._model) {
              throw new Error(`!this._model`);
            }
            const viewer = this._viewer;
            const camera = this._viewer.camera;
            this._model.readyPromise.then(() => {
              if (!this._model) {
                throw new Error(`!this._model`);
              }

              this._model.activeAnimations.addAll({
                multiplier: 1,
                loop: Cesium.ModelAnimationLoop.REPEAT,
              });

              let radius = this._model.boundingSphere.radius;
              if (radius < 10) {
                const scale = 10 / radius;
                this._model.scale = scale;
                radius = 10;
              }

              const controller = viewer.scene.screenSpaceCameraController;
              controller.minimumZoomDistance = radius * 0.5;

              const {
                heading,
                pitch
              } = camera;
              camera.frustum.near = radius * 0.01;
              camera.lookAt(
                origin,
                new Cesium.HeadingPitchRange(heading, pitch, radius * 2.0)
              );

              // @ts-ignore
              window.model = this._model;
              // @ts-ignore
              this.modelMaterialIds = Object.keys(
                // @ts-ignore
                this._model._runtime.materialsById
              );

              this._originBaseColorTextures = {};
              for (let id of this.modelMaterialIds) {
                // @ts-ignore
                const mat = this._model._runtime.materialsById[id];
                this._originBaseColorTextures[id] =
                  mat.getValue("baseColorTexture");
              }

              this._originNormalTextures = {};
              for (let id of this.modelMaterialIds) {
                // @ts-ignore
                const mat = this._model._runtime.materialsById[id];
                this._originNormalTextures[id] = mat.getValue("normalTexture");
              }

              const id = this.modelMaterialIds[0];
              //this.matSelected = id;

              if (this._model && id) {
                this._setCurrentModelMaterial(
                  // @ts-ignore
                  this._model._runtime.materialsById[id]
                );
                this._originBaseColorTexture = this._originBaseColorTextures[id];
                this._originNormalTexture = this._originNormalTextures[id];
              } else {
                this._currentModelMaterial = undefined;
              }

              this.$emit("glbLoaded");
            });
          }
        });
      });
    }

    toJson() {
      const {
        baseColorFactor,
        metallicFactor,
        roughnessFactor,
        baseColorTextureUrl,
        normalTextureUrl,
        emissiveFactor,
        matSelected,
        baseColorTextureOffset,
        baseColorTextureRotation,
        baseColorTextureScale,
        normalTextureOffset,
        normalTextureRotation,
        normalTextureScale,
      } = this;

      const json = {
        width: this.width,
        height: this.height,
        pbrMetallicRoughness: {
          baseColorFactor,
          baseColorTexture: {
            id: this.id,
            extensions: {
              KHR_texture_transform: {
                offset: this.baseColorTextureOffset,
                rotation: this.baseColorTextureRotation,
                scale: this.baseColorTextureScale,
              },
            },
          },

          metallicFactor,
          roughnessFactor,
        },
        normalTexture: {
          id: this.id2,
          extensions: {
            KHR_texture_transform: {
              offset: this.normalTextureOffset,
              rotation: this.normalTextureRotation,
              scale: this.normalTextureScale,
            },
          },
        },
        emissiveFactor,
        matSelected,
      };

      return json;
    }
  }

import * as Cesium from "cesium";

export function createViewer(container: HTMLElement) {
    var viewer = new Cesium.Viewer(container, {
        timeline: false,
        animation: false,
        infoBox: false,
        homeButton: false,
        vrButton: false,
        fullscreenButton: false,
        baseLayerPicker: false,
        geocoder: false,
        sceneModePicker: false,
        selectionIndicator: false,
        navigationHelpButton: false,
    });

    viewer.scene.globe.show = false;
    viewer.scene.skyAtmosphere.show = false;
    viewer.scene.skyBox.show = false;
    // viewer.scene.backgroundColor = new Cesium.Color(0.6, 0.6, 0.6, 1.0);
    viewer.scene.backgroundColor = Cesium.Color.fromCssColorString('rgb(56,56,56)');
    viewer.scene.sun.show = false;
    viewer.scene.moon.show = false;

    viewer.camera.lookAt(
        Cesium.Cartesian3.fromDegrees(116.39, 39.9, 100),
        new Cesium.HeadingPitchRange(0, 0, 1.0 * 2.0)
    );

    viewer.camera.frustum.near = 0.1;
    viewer.camera.frustum.far = 1000;

    // @ts-ignore
    window.viewer = viewer;

    return viewer;
}
<template>
  <div class="editPoint">
    <label>请拖拽移动管点</label>
    <div class="editor">
      <label>经度:</label>
      <input v-model.number="position[0]" v-number-fixed="6" />
      <label>纬度:</label>
      <input v-model.number="position[1]" v-number-fixed="6" />
      <label>高度:</label>
      <input v-model.number="position[2]" v-number-fixed="2" />
      <button @click="cancel">取消</button>
      <button @click="ok">确定</button>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Window
  },
  data() {
    return {
      position: [0, 0, 0]
    };
  },
  props: {
    param: {
      type: Object,
      default: undefined
    }
  },
  mounted() {
    this.createTempModel();
    this.createTempLine();
  },
  methods: {
    createTempModel() {
      this._temmodel = this.$root.$createLabel('管点:' + this.param.point.id);
      var coordinate = this.param.point.geom.coordinates;
      this._temmodel.position = [
        (coordinate[0] * Math.PI) / 180,
        (coordinate[1] * Math.PI) / 180,
        coordinate[2]
      ];
      this._temmodel.editing = true;
      this._positionBind = XE.MVVM.bindPosition(
        this,
        "position",
        this._temmodel,
        "position"
      );
    },
    createTempLine() {
      let self = this;
      this._temLines = [];
      let params = {
        projectid: this.$root.projectid,
        id: this.param.point.id
      };
      this.$axios
        .get("/project/line/connected/point", {
          params
        })
        .then(res => {
          var lines = res.data.data;
          // var coordinate = this.dataPoint.geom.coordinates;
          lines.forEach(element => {
            var line = new XE.Obj.Polyline(this.$root._earth);
            line.positions.push(self._temmodel.position);
            line.positions.push([
              (element.x * Math.PI) / 180,
              (element.y * Math.PI) / 180,
              element.z
            ]);
            self._temLines.push(line);
          });
        })
        .catch(err => {
          console.log(err.response || err);
          this.$root.$log("error", err);
        });
    },

    ok() {
      let data = {
        projectid: this.$root.projectid,
        id: this.param.point.id,
        lon: this.position[0],
        lat: this.position[1],
        height: this.position[2],
        dimiangaocheng: this.position[2]
      };
      this.$axios
        .put("/project/point/position/update", data)
        .then(res => {

          //关闭显示 
          this.cancel();
          this.$root._tileset.reload();
          if (res.data.data && res.data.data === true) {
            this.$root._tileset.reload();
          }
        })
        .catch(err => {
          this.$root.$log("error", err);
        });
    },
    cancel() {
      this.$root.$refs.bottom.closeTool();
    }
  },
  beforeUnmount() {
    if (this._positionBind) {
      this._positionBind();
    }
    if (this._temmodel) {
      this._temmodel.destroy();
    }
    if (this._temLines) {
      this._temLines.forEach(e => {
        e.destroy();
      });
    }
  }
};
</script>

<style scoped>
.editPoint {
  flex-grow: 1;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.editor {
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
input {
  width: 80px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
}
button {
  height: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>

import DrawOneSection from "./DrawOneSection";
import AxisY from "./AxisY";
import AxisX from './AxisX'
class DrawAllSection {
  constructor(data) {
    this._lineList = [];
    this._Axisy = new AxisY();
    this._Axisx = new AxisX()
    let lines = data.lines;
    let x = 0;
    for (var i = 0; i < lines.length; i++) {
      var element = lines[i];
      this._lineList.push(new DrawOneSection(element, x));
      x += element.length;
    }
  }

  box() {
    var box = this._lineList[0].box();
    if (this._lineList.length > 1) {
      for (var i = 1; i < this._lineList.length; i++) {
        var cbox = this._lineList[i].box();
        box.minx = Math.min(box.minx, cbox.minx);
        box.miny = Math.min(box.miny, cbox.miny);
        box.maxx = Math.max(box.maxx, cbox.maxx);
        box.maxy = Math.max(box.maxy, cbox.maxy);
      }
    }
    return box;
  }

  draw(width, height, offset, zr) {
    if (this._lineList.length == 0) {
      return "没有相交的管线";
    }
    var box = this.box();
    var scalex = (width - offset) / (box.maxx - box.minx);
    var scaley = (height - offset) / (box.maxy - box.miny);
    scaley = scaley < scalex * 10 ? scaley : scalex * 10;
    var offsetx = 0 - box.minx; // x偏移多少米
    var offsety =
      (height - (box.maxy - box.miny) * scaley) / 2 / scaley - box.miny;

    for (var i = 0; i < this._lineList.length; i++) {
      this._lineList[i].draw(offsetx, offsety, height, scalex, scaley, zr);
    }
    this._Axisy.draw(
      (box.maxy + box.miny) / 2,
      offsety,
      offset,
      height,
      scaley,
      zr
    );
    this._Axisx.draw(
      (box.maxx + box.minx) / 2,
      offsetx,
      offset,
      width,
      height,
      scalex,
      zr,
    )
  }
}

export default DrawAllSection;

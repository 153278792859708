import DrawOneSection from './DrawOneSection'
import AxisY from './AxisY'
import AxisX from './AxisX'
class DrawAllSection {
  constructor(lines) {
    this._lineList = []
    this._Axisy = new AxisY()
    this._Axisx = new AxisX()
    for (var i = 0; i < lines.length; i++) {
      var element = lines[i]
      if (element.duanmian) {
        this._lineList.push(new DrawOneSection(element))
      }
    }
  }

  box() {
    var box = this._lineList[0].box()
    if (this._lineList.length > 1) {
      for (var i = 1; i < this._lineList.length; i++) {
        var cbox = this._lineList[i].box()
        box.minx = Math.min(box.minx, cbox.minx)
        box.miny = Math.min(box.miny, cbox.miny)
        box.maxx = Math.max(box.maxx, cbox.maxx)
        box.maxy = Math.max(box.maxy, cbox.maxy)
      }
    }
    return box
  }

  draw(width, height, offset, zr) {
    if (this._lineList.length == 0) {
      return '没有相交的管线'
    }
    var box = this.box()
    var offsetx, offsety, scale
    if ((box.maxx - box.minx) / width > (box.maxy - box.miny) / height) {
      console.log('scale', box.maxx - box.minx)
      scale = (width - offset) / (box.maxx - box.minx)
      offsetx = 0 - box.minx // x偏移多少米
      offsety = (height - (box.maxy - box.miny) * scale) / 2 / scale - box.miny
    } else {
      scale = (height - offset) / (box.maxy - box.miny)
      offsety = 0 - box.miny
      offsetx = (width - (box.maxx - box.minx) * scale) / 2 / scale - box.minx
    }
    for (var i = 0; i < this._lineList.length; i++) {
      this._lineList[i].draw(offsetx, offsety, offset, height, scale, zr)
    }

    this._Axisy.draw(
      (box.maxy + box.miny) / 2,
      offsety,
      offset,
      height,
      scale,
      zr,
    )
    this._Axisx.draw(
      (box.maxx + box.minx) / 2,
      offsetx,
      offset,
      width,
      height,
      scale,
      zr,
    )
  }
}

export default DrawAllSection

<template>
    <div class="property">
        <div class="row">
            <label class="label">是否阴影:</label>
            <input class="bty-switch" type="checkbox" v-model="enabled">
        </div>
        <div class="row">
            <label class="label" @click="darkness=0.3">暗度:</label>
            <input type="range" min="0" max="1" step="0.01" v-model.number="darkness">
        </div>
        <div class="row">
            <label class="label" @click="maximumDistance=5000.0">最大距离:</label>
            <input type="range" min="1" max="100000" step="1" v-model.number="maximumDistance">
        </div>

        <div class="row">
            <label class="label">地形阴影:</label>
            <input class="bty-switch" type="checkbox" v-model="terrainShadow">
        </div>

        <div class="row">
            <label class="label">软阴影:</label>
            <input class="bty-switch" type="checkbox" v-model="softShadows">
        </div>

        <div class="row">
            <label class="label" @click="normalOffset=true">法向量偏移:</label>
            <input class="bty-switch" type="checkbox" v-model="normalOffset">
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import Window from "../../../../components/Window"
    export default {
        data() {
            return {
                enabled: true,
                terrainShadow: true,
                softShadows: false,
                darkness: 0.3,
                maximumDistance: 5000.0,
                normalOffset: true
            };
        },
        components: {
            Window
        },
        mounted() {
            this.bind("enabled")
            this.bind("terrainShadow");
            this.bind("softShadows");
            this.bind("darkness");
            this.bind("maximumDistance");
            this.bind("normalOffset");
        },
        methods: {
            bind(prp) {
                this._viewUnbinds = this._viewUnbinds || [];
                this._viewUnbinds.push(
                    XE.MVVM.bind(this, prp, this.$root._earth.effect.shadow, prp)
                );
            },
            cancel() {
                this.$root.destroyTool(this)
            },
            ok() {
                this.$root.destroyTool(this)
            },
        },

    };
</script>

<style scoped>
    .property {
        width: 300px;
        height: 100%;
        background: var(--PanelBGColor);
    }

    .label {
        display: inline-block;
        width: 74px;
        text-align: right;
        margin-right: 5px;
        vertical-align: middle;
        line-height: 29px;
    }

    .row {
        display: flex;
        margin-top: 10px;
    }
</style>
<template>
    <div class="xbsj-flatten" @click.stop="showSelect=false">
        <div class="flatten" style="position: relative;">
            <label>挖坑瓦片</label>
            <input type="text" v-model="connectedTileset" readonly style="cursor: pointer;"
                @click.stop="showSelect=!showSelect" />
            <button class="selectButton" @click.stop="showSelect=!showSelect"></button>
            <div class="cutselectbox" v-show="showSelect">
                <div v-show="showSelect" v-for="(c,index) in connections" :key="index" @click="toggleConnection(c)">
                    <span> {{c.name}}</span>
                    <span v-show="c.guid == guid">✔</span>
                    <span v-show="c.guid != '' && c.guid!=guid">✘</span>
                </div>
            </div>
        </div>
        <!-- 名称 -->
        <div class="flatten">
            <label>名称</label>
            <input style="float:left;" type="text" v-model="name" />
        </div>
        <div class="flatten-flex">
            <!-- 鼠标点选 -->
            <div class="flatten">
                <label>位置</label>
                <div class="buttonGroup">
                    <button class="attitudeEditCameraButton" @click="creating =!creating"
                        :class="creating?'btncoloron':''">创建</button>
                </div>
            </div>
        </div>

        <div class="flatten">
            <!-- 采样间距 -->
            <label>采样间距</label>
            <div class="flatten-box">
                <input type="number" v-model.number="sampledDistance">
            </div>
        </div>

        <div class="flatten">
            <!-- 侧面纹理图像 -->
            <label style="width:20px">侧面纹理图像</label>
            <input type="text" style="float:left;" v-model="wallImageUrl" />
        </div>

        <div class="flatten">
            <!-- 侧面纹理高度 -->
            <label>侧面纹理高度</label>
            <div class="flatten-box">
                <input type="number" v-model.number="wallImageHeight">
            </div>
            <!-- 宽度缩放比例 -->

        </div>
        <div class="flatten">
            <label>宽度缩放比例</label>
            <div class="flatten-box">
                <input type="number" v-model.number="wallImageWidthScale">
            </div>
        </div>


        <div class="flatten">
            <!-- 底面纹理图像 -->
            <label>底面纹理图像</label>
            <input style="float:left;" type="text" v-model="bottomImageUrl" />
        </div>

        <div class="flatten">
            <!-- 底面纹理高度 -->
            <label>底面纹理高度</label>
            <div class="flatten-box">
                <input type="number" v-model.number="bottomImageHeight">
            </div>

        </div>
        <div class="flatten">
            <!-- 宽度缩放比例 -->
            <label>宽度缩放比例</label>
            <div class="flatten-box">
                <input type="number" v-model.number="bottomImageWidthScale">
            </div>
        </div>
        <div class="flatten">
            <button @click="ok" class="submit">确定</button>
        </div>

    </div>
</template>

<script>
    /* eslint-disable */
    export default {
        components: {

        },
        props: {
            param: Object
        },
        data() {
            return {
                lang: {},
                showPinSelect: false,
                drag_over: false,
                name: "",
                show: true,
                creating: false,
                editing: false,
                sampledDistance: 10,
                textureRotationEditing: false,
                imageWidthScale: 1.0,
                wallImageUrl: "",
                wallImageHeight: 3,
                wallImageWidthScale: 1,
                bottomImageUrl: "",
                bottomImageHeight: 3,
                bottomImageWidthScale: 1,
                bottomTextureRotationEditing: false,

                connections: [],
                connectedTileset: "",
                showSelect: false,
            };
        },
        mounted() {
            // 数据关联
            this._disposers = this._disposers || [];
            var czmObj = this.param;
            this._disposers.push(XE.MVVM.bind(this, "creating", czmObj, "creating"));

            this.updateConnections();


            console.log("挖坑")

            if (czmObj) {
                this._czmObj = czmObj;
                const bindData = {
                    name: "name",
                    creating: "creating",
                    show: "show",
                    editing: "editing",
                    sampledDistance: "sampledDistance",
                    wallImageUrl: "wallImageUrl",
                    wallImageHeight: "wallImageHeight",
                    wallImageWidthScale: "wallImageWidthScale",
                    bottomImageUrl: "bottomImageUrl",
                    bottomImageHeight: "bottomImageHeight",
                    bottomImageWidthScale: "bottomImageWidthScale",
                    bottomTextureRotationEditing: "bottomTextureRotationEditing"
                };

                Object.entries(bindData).forEach(([sm, vm]) => {
                    if (typeof vm === "string") {
                        this._disposers.push(XE.MVVM.bind(this, vm, this._czmObj, sm));
                    } else {
                        this._disposers.push(vm.handler(this, vm.prop, this._czmObj, sm));
                    }
                });
                this.$root._earth.terrainEffect.depthTest = true;
                this._czmObj.applyTerrain();
            }
            this.ok()
        },
        computed: {
            name() {
                return this.name;
            },
            guid() {
                return this.param.getDiscardGuid();
            }
        },
        methods: {
            toggleConnection(con) {
                var tileset = con.tileset();

                if (con.guid == this.guid) {
                    tileset.xbsjFlattenGuid = "";
                } else {
                    if (con.guid != "" && con.guid != this.guid) {
                        //弹出提示
                        this.$root.$confirm("该数据已经绑定其他组，确认替换到当前组上?", () => {
                            tileset.xbsjFlattenGuid = this.guid;
                            this.updateConnections();
                        });
                    } else {
                        tileset.xbsjFlattenGuid = this.guid;
                    }
                }

                this.updateConnections();
                this.showSelect = false;
            },

            updateConnections() {
                //获取当前所有tileset  只需要名称，对象，是否关联三个属性
                var cc = this.$root._earth.tilesetCollection;
                var connections = [];
                var guid = this.param.getDiscardGuid();
                var connectedTileset = undefined;
                var count = 0;
                cc.forEach(t => {



                    var con = {
                        name: t.name,
                        tileset: () => {
                            return t;
                        },
                        guid: t.xbsjFlattenGuid
                    };

                    if (con.guid == this.guid) {
                        if (!connectedTileset) {
                            connectedTileset = con.name;
                        }
                        count++;
                    }
                    connections.push(con);
                });

                if (count >= 2) {
                    connectedTileset += "等"
                    connectedTileset += count;
                }
                this.connectedTileset = connectedTileset;
                this.connections = connections;
                console.log("connections", this.connections, this.guid)

            },
            terrainDiscardClick() {
                console.log("作用到地形")
                this.$root._earth.terrainEffect.depthTest = true;
                this._czmObj.applyTerrain();
            },
            ok() {
                console.log("挖坑对象保存")
                const modelToolObj = this._czmObj;
                modelToolObj.editing = false;
                if (!modelToolObj) {
                    return;
                }
                modelToolObj.positionEditing = false;
                if (modelToolObj.isCreating) {
                    modelToolObj.isCreating = false;
                    const sceneObject = new XE.SceneTree.Leaf(modelToolObj);
                    this.$root._earthVue.addSceneObject(sceneObject.czmObject, this.name);
                }
            },
        },
        beforeUnmount() {
            this._czmObj.creating = false;
            this._czmObj.editing = false;
            this._czmObj.isCreating = false
            console.log("czmObj", this._czmObj)
            // 解绑数据关联
            this._polygonDisposers = this._polygonDisposers && this._polygonDisposers();
            this._disposers.forEach(e => e());
            this._disposers.length = 0;

        }
    };
</script>

<style scoped>
    .flatten-flex {
        display: flex;
    }


    .field {
        padding-left: 4px;
        display: inline-block;
        width: 220px;
    }

    .header-add .add {
        background: url(../../assets/flatten/add.png) no-repeat;
        background-position: center;
        border-left: none !important;
    }

    .header-add .add:focus {
        outline: none;
    }

    .item-btn-box input {
        float: left;
    }

    .item-btn-box .del {
        background: url(../../assets/flatten/del.png) no-repeat !important;
        background-position: center !important;
    }

    .item-btn-box .edit {
        background: url(../../assets/flatten/edit.png) no-repeat !important;
        background-position: center !important;
    }

    .item-btn-box .edit-on {
        background: url(../../assets/flatten/edit_on.png) no-repeat !important;
        background-position: center !important;
    }

    .item-btn-box .position {
        background: url(../../assets/flatten/position.png) no-repeat !important;
        background-position: center !important;
    }

    .item-btn-box .autoheight {
        background: url(../../assets/flatten/autoheight.png) no-repeat !important;
        background-position: center !important;
    }

    .flatten-btn {
        background: none;
        border: none;
        width: 100%;
        height: 34px;
        /* border-left: 1px solid black; */
        margin-top: -1px;
        cursor: pointer;
    }

    .flatten-btn:focus {
        outline: none;
    }

    .item-btn-box {
        width: 100%;
        padding-top: 1px;
        border: 1px solid #000;
        border-collapse: collapse;
    }

    .item-btn-box thead tr {
        border-bottom: 1px solid #000;
    }

    .item-btn-box thead tr th {
        width: 16.66%;
        text-align: center;
        border-right: 1px solid #000;
    }

    .item-btn-box tbody tr td {
        border-right: 1px solid #000;
        text-align: center;
    }

    .item-btn-box tbody tr {
        border-top: 1px solid #000;
    }

    .iteminput {
        width: 72px;
        background: #383838;
        border-radius: 3px;
        border: none;
        color: #CCC;
        padding-left: 10px;
        margin: 2px;
    }

    .item-order {
        width: 51px;
        height: 34px;
        text-align: center;
        line-height: 30px;
        border-right: none !important;
        border-top: none !important;
    }

    button {
        background: none;
        border: none;
        margin: 0 auto;
        display: block;
        line-height: 25px;
    }

    .header-add {
        width: 61px;
        height: 30px;
        /* border-bottom: none !important; */
    }

    .falatten-body-item div {
        float: left;
    }

    .header-content {
        width: 264px;
        height: 30px;
        border-left: none !important;
        border-right: none !important;
    }

    .flatten-table-header {
        width: 100%;
        height: 30px;
    }

    .flatten-table-header .border {
        float: left;
    }

    .flatten-table-order {
        width: 51px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        /* border-bottom: none !important; */
    }

    .xbsj-flatten {
        min-width: 462px;
        font-size: 12px;
        height: 100%;
        background: var(--PanelBGColor);
        padding-top: 20px;
    }

    .xbsj-flatten>div {
        width: 100%;
        height: 28px;
        margin-top: 10px;
    }

    .xbsj-flatten label {
        float: left;
        min-width: 72px;
        height: 28px;
        line-height: 28px;
        text-align: right;
        margin-left: 8px;
        margin-right: 15px;
    }

    .xbsj-flatten .flatten input,
    .xbsj-flatten .attributePath input {
        width: 150px;
        height: 28px;
        background: #383838;
        border-radius: 3px;
        border: none;
        color: #ccc;
        padding-left: 10px;
    }

    .xbsj-flatten .attributePlay .playspan {
        display: inline-block;
        width: 17px;
        height: 20px;
        background: url(../../assets/play.png) no-repeat;
        background-size: contain;
        margin-top: 4px;
    }

    .xbsj-flatten .attributePlay .suspendspan {
        display: inline-block;
        width: 15px;
        height: 20px;
        background: url(../../assets/suspend.png) no-repeat;
        background-size: contain;
        margin-top: 4px;
    }

    .xbsj-flatten .attributeLocation input,
    .xbsj-flatten .attributeAttitude input {
        width: 66px;
        height: 28px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        border: none;
        color: #ccc;
        padding-left: 10px;
        margin-right: 6px;
    }

    input[type="text"]:focus,
    button:focus {
        outline: 1px solid rgba(31, 255, 255, 1);
    }

    .flatten-table {
        display: inline-block;
        width: 80%;
    }

    .border {
        border: 1px solid black;
    }

    .select-ul {
        width: 80%;
        padding-left: 8px;
        margin-left: calc(10% + 23px);
        background: #383838;
        z-index: 9999;
        position: relative;
    }

    .cutselectbox {
        position: absolute;
        top: 30px;
        right: 215px;
        width: 150px;
        background: #383838;
        border-radius: 0px 0px 4px 4px;
        overflow: auto;
        z-index: 999;
    }

    .cutselectbox div {
        width: 100%;
        height: 28px;
        font-size: 14px;
        color: rgba(221, 221, 221, 1);
        cursor: pointer;
        line-height: 28px;
    }

    .cutselectbox div:hover {
        background: rgba(107, 107, 107, 1);
    }

    .cutselectbox span {
        display: inline-block;
        /* width: 72px; */
        text-align: left;
        margin-left: 10px;
    }

    .selectButton {
        width: 12px;
        height: 10px;
        border: none;
        background: url(../../assets/titles-select.png) no-repeat;
        background-size: contain;
        cursor: pointer;
        position: relative;
        top: -20px;
        right: -10px;
        /* margin-right: 15px; */
        /* margin-top: 10px; */
        outline: none;
    }

    .buttonGroup {
        display: inline-block;
    }

    .buttonGroup div {
        display: inline-block;
        height: 25px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        color: #ccc;
    }

    .attitudeEditCameraButton {
        background: #383838;
        color: #ccc;
    }

    .btncoloron {
        color: #1fffff !important;
    }

    button {
        background: none;
        border: none;
        margin: 0 auto;
        display: block;
        line-height: 25px;
        outline: none;
    }

    button:focus {
        outline: none !important;
    }

    .attitudeEditCameraButton {
        display: block;
        float: left;
        height: 25px;
        margin-left: 0;
        border-radius: 3px;
        color: #CCC;
    }

    .xbsj-videoAttribute .attributePlay button {
        width: 99px;
        height: 28px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        border: none;
        cursor: pointer;
    }

    .buttonGroup div .playButton {
        background: url(../../assets/play.png) no-repeat !important;
        background-size: contain;
        height: 30px;
        width: 100px;
    }

    .buttonGroup div .suspendButton {
        background: url(../../assets/suspend.png) no-repeat !important;
        background-size: contain;
        height: 30px;
        width: 100px;
    }

    .dragButton {
        float: left;
        width: 120px;
        height: 25px;
        margin-left: 18px;
        /* background: url(../assets/drag.png) no-repeat; */
        background: #383838;
        background-size: contain;
        text-align: center;
        line-height: 25px;

    }

    .dragButton.highlight {
        background: url(../../assets/drag_on.png) no-repeat;
        background-size: contain;
        color: #1fffff;
    }

    .submit {
        width: 64px;
        height: 30px;
        background: #383838;
        border: 1px solid #4D4D4D;
        opacity: 1;
        border-radius: 2px;
    }
</style>
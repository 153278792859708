<template>
  <div class="property">
    <input ref="files" type="file" title="选择图片" accept="image/*" @change="upLoadFile" multiple="multiple"
      style="display: none" />
    <div class="propertyHeard">
      <h4>管点属性</h4>
      <ul>
        <li>管点ID：{{ dataPoint.id }}</li>
        <li>附属物：{{ dataPoint.fushuwu }}</li>
        <li>埋深： {{ dataPoint.maishen }}米</li>
        <li>特征： {{ dataPoint.tezheng }}</li>
        <li>经度： {{ Number(lat).toFixed(6) }} °</li>
        <li>纬度： {{ Number(lng).toFixed(6) }} °</li>
        <li>高度： {{ Number(height).toFixed(2) }}米</li>
        <li>创建时间： {{ dataPoint.create_t }}</li>
        <li>修改时间： {{ dataPoint.update_t }}</li>
        <li v-for="(item, index) in custom" :key="index">{{ item[0] }}:{{ item[1] }}</li>
        <li>资源：
          <div v-for="img in dataPoint.resource">
            <img :src="'resource/' + projectid + '/' + img" />
            <button @click="deleteResource(img)">删除</button>
          </div>
          <button @click="selectFile">添加</button>
        </li>
      </ul>
    </div>
    <div class="propertyfoo">
      <h4>管点操作</h4>
      <div class="propertybtn">
        <button @click="updateCoordinate">修改位置</button>
        <button @click="updateCaizhiPop">修改材质</button>
        <button @click="updateCanshuPop">修改参数</button>
        <button @click="deletePoint">删除</button>
        <button @click="locate">定位</button>
      </div>
    </div>
    <div class="propertyfoo">
      <h4>附属物操作</h4>
      <div class="propertybtn">
        <button @click="updateFushuwuPop">选择附属物</button>
        <button @click="editeFSW" :disabled="
          dataPoint.fushuwu_j === null ||
          dataPoint.fushuwu_j.define === undefined
        ">编辑附属物</button>
        <button @click="clearFushuwuPop" :disabled="
          dataPoint.fushuwu_j === null ||
          dataPoint.fushuwu_j.define === undefined
        ">清除附属物</button>
      </div>
    </div>
  </div>
</template>

<script>
//import Window from "../../../components/Window"
import Appendages from '../../page-editor/components/Appendages'
import parameterTool from '../components/PointParameterTool'
import MaterialSelect from '../components/MaterialSelect'
export default {
  components: {
    // Window,
    //Appendages
  },
  data() {
    return {
      dataPoint: { fushuwu_j: null },
      showQuery: true,
      show: true,
      lat: '',
      lng: '',
      height: '',
      custom: [],
      maptable: [],
      projectid: ""
    }
  },
  name: 'PointProperty',
  props: {
    param: Object
  },
  created() {
    this.query()
  },
  beforeUnmount() {
    this.$root._tileset.unSelectFeature(this.param.id)
  },
  mounted() {
    this.projectid = this.$root.projectid
  },
  methods: {
    deleteResource(id) {
      this.$root.$confirm("确定删除？", () => {
        var deleteIndex = this.dataPoint.resource.findIndex(item => {
          return item === id;
        })
        if (deleteIndex < 0) {
          return;
        }
        var resource = this.dataPoint.resource;
        resource.splice(deleteIndex, 1)
        let data = {
          projectid: this.$root.projectid,
          id: id,
          param: {
            resource: JSON.stringify(resource)
          },
        };
        let _this = this;
        this.$axios
          .put("/project/point/update/info", data)
          .then((res) => {
            this.$root.$alert("删除成功");
            _this.dataPoint.resource = resource;
            _this.$axios
              .delete(`/resource/${_this.projectid}/${id}`)
              .then(res => {
              })
              .catch((err) => {
              });
          })
      })
    },
    upLoadFile(event) {
      let _this = this;
      let formData = new FormData();
      let files = event.target.files;
      for (let i = 0; i <= files.length - 1; i++) {
        formData.append("files", files[i]);
      }
      formData.append("projectid", this.$root.projectid);
      this.$axios
        .post("/resource/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          timeout: 0,
        })
        .then(res => {
          if (res.status == 200) {
            var resource = _this.dataPoint.resource || [];
            for (var i = 0; i < res.data.data.length; i++) {
              resource.push(res.data.data[i].id);
            }
            let data = {
              projectid: _this.$root.projectid,
              id: _this.dataPoint.id,
              param: {
                resource: JSON.stringify(resource)
              },
            };
            _this.$axios
              .put("/project/point/update/info", data)
              .then((res) => {
                _this.query();
                this.$root.$alert("添加成功");
              })
              .catch((err) => {
                this.$root.$log("error", err);
                console.log(err.response || err);
                this.$root.$alert("添加失败");
              });
          }
        })
        .catch(err => {
          if (err.data) this.$root.$alert(err.data.msg || err.data.message);
        });
      event.target.value = "";
    },
    selectFile() {
      this.$refs.files.click();
    },
    locate() {
      this.$root._tileset.selectFeature({ id: this.dataPoint.id, otype: 'pipe' })
    },
    mapquery() {
      this.custom = []
      let self = this
      let data = {
        projectid: this.$root.projectid
      }
      this.$axios
        .get('/project/point/column/query', {
          params: data
        })
        .then(res => {
          let arr = Object.keys(self.dataPoint)
          self.maptable = res.data.data
          for (let j = 0; j < arr.length; j++) {
            for (let i = 0; i < self.maptable.length; i++) {
              if (arr[j] === self.maptable[i]['column_name'] && self.maptable[i]['column_editable']) {
                let tempObject = [self.maptable[i].column_chinese || self.maptable[i].column_name, self.dataPoint[arr[j]]]
                var found = self.custom.find(c => {
                  return c[0] == tempObject[0]
                })
                if (!found)
                  self.custom.push(tempObject)
              }
            }
          }
        })
        .catch(err => {
          console.log(err.response || err)
        })
    },
    async query() {
      //let _this = this
      let params = {
        projectid: this.$root.projectid,
        id: this.param.id
      }
      this.$axios
        .get('/project/point/query', {
          params
        })
        .then(res => {
          if (res.data) {
            if (res.data.data.results.length == 1) {
              this.dataPoint = res.data.data.results[0]
              this.mapquery()
              this.lat = this.dataPoint.geom.coordinates[0]
              this.lng = this.dataPoint.geom.coordinates[1]
              this.height = this.dataPoint.geom.coordinates[2]
              this.$bus.emit('pickPoint', this.dataPoint)
            }
          }
        })
        .catch(err => {
          console.log(err.response || err)
          this.$root.$log('error', err)
        })
    },
    updateCoordinate() {
      this.$root.$refs.bottom.switchTool({
        name: '修改管点位置:' + this.dataPoint.id,
        component: 'ModifyPointTool',
        param: {
          point: this.dataPoint
        }
      })
    },
    editeFSW() {
      this.$root.$refs.bottom.switchTool({
        name: '修改附属物位置:' + this.dataPoint.id,
        component: 'ModifyFSWTool',
        param: {
          point: this.dataPoint
        }
      })
      this.$bus.emit('updateAppend', '修改附属物')

      //取消选中
      this.$root._tileset.unSelectFeature(this.param.id)
    },
    updateFushuwuPop() {
      this.$root.showPropertyWindow('Appendages', {
        component: Appendages,
        param: {
          ok: option => {
            //console.log('( •̀ ω •́ )y拿到了附属物' + option);
            this.updateFushuwu(option)
          }
        }
      })
    },
    updateCaizhiPop() {
      this.$root.showPropertyWindow('MaterialSelect', {
        component: MaterialSelect,
        param: {
          ok: caizhi => {
            console.log('showPropertyWindow材质' + caizhi)
            this.updateCaizhi(caizhi)
          }
        }
      })
    },
    updateCaizhi(opt) {
      //修改管线材质请求
      let data = {
        projectid: this.$root.projectid,
        ids: [this.dataPoint.id],
        caizhi_j: JSON.stringify(opt.param)
      }

      this.$axios
        .put('/project/point/material/update/ids', data)
        .then(res => {
          this.query()
          //console.log(res);
          this.$root.$alert('材质修改成功')
          if (res.data.data && res.data.data === true) {
            this.$root._tileset.reload()
          }
        })
        .catch(err => {
          this.$root.$log('error', err)
          console.log(err.response || err)
          this.$root.$alert('材质修改失败')
        })
    },
    updateCanshuPop() {
      //更新参数弹窗
      var tezheng_j = this.dataPoint.tezheng_j
      this.$root.showPropertyWindow(
        'parameterTool',
        {
          component: parameterTool,
          param: {
            ok: option => {
              //console.log("( •̀ ω •́ )y拿到了参数", option);
              this.updateCanshu(option)
            }
          }
        },
        tezheng_j
      )
    },
    updateCanshu(opt) {
      //修改参数
      let data = {
        projectid: this.$root.projectid,
        ids: [this.dataPoint.id],
        tezheng_j: JSON.stringify(opt)
      }
      // console.log(data1)

      this.$axios
        .put('/project/point/shape/update/ids', data)
        .then(res => {
          this.query()
          console.log(res)

          this.$root.$alert('参数修改成功')
          if (res.data.data && res.data.data === true) {
            this.$root._tileset.reload()
          }
        })
        .catch(err => {
          this.$root.$log('error', err)

          console.log(err.response || err)
          this.$root.$alert('参数修改失败')
        })
    },
    clearFushuwuPop() {
      this.updateFushuwu({ name: '', param: undefined })
    },
    async updateFushuwu(opt) {
      //let _this = this
      //console.log("数据", projectid, pointid, opt.param)
      let data = {
        fushuwu: opt.name,
        fushuwu_j: {
          define: opt.param
        }
      }
      let data1 = {
        ids: [this.dataPoint.id],
        projectid: this.$root.projectid,
        param: JSON.stringify(data)
      }
      //console.log(JSON.stringify(data))

      this.$axios
        .put('/project/point/accessory/update/ids', data1)
        .then(res => {
          this.query()
          console.log(res)

          this.$root.$alert('附属物修改成功')
          if (res.data.data && res.data.data === true) {
            this.$root._tileset.reload()
          }
        })
        .catch(err => {
          this.$root.$log('error', err)

          console.log(err.response || err)
          this.$root.$alert('附属物修改失败')
        })
    },
    async deletePoint() {
      let _this = this
      // console.log(_this.items.id);
      let data = {
        projectid: _this.$root.projectid,
        ids: JSON.stringify([this.dataPoint.id])
      }
      let params = {
        projectid: this.$root.projectid,
        ids: JSON.stringify([this.dataPoint.id])
      }
      //包含要删除管点的所有管线查询
      this.$axios
        .get('/project/point/connected/line', {
          params: params
        })
        .then(res => {
          //console.log("包含要删除管点的所有管线查询", res.data.data.length)
          let dependLine = res.data.data.length
          if (dependLine === 0) {
            _this.$root.$confirm('确定删除该管点?', () => {
              _this.$axios
                .delete('/project/point/delete/ids', {
                  data
                })
                .then(res => {
                  console.log(res)

                  this.dataPoint = ''
                  this.$root.$alert('管点删除成功')
                  if (res.data.data && res.data.data === true) {
                    this.$root._tileset.reload()
                  }
                })
                .catch(err => {
                  this.$root.$log('error', err)
                  console.log(err.response || err)
                  this.$root.$alert('管点删除失败')
                })
            })
          } else {
            _this.$root.$confirm('该管点共被' + dependLine + '根管线所依赖，删除后所有依赖管线都将被删除，确定删除?', () => {
              _this.$axios
                .delete('/project/point/delete/ids', {
                  data
                })
                .then(res => {
                  console.log(res)

                  this.dataPoint = ''
                  this.$root.$alert('管点删除成功')
                  if (res.data.data && res.data.data === true) {
                    this.$root._tileset.reload()
                  }
                })
                .catch(err => {
                  this.$root.$log('error', err)
                  console.log(err.response || err)
                  this.$root.$alert('管点删除失败')
                })
            })
          }
        })
        .catch(err => {
          console.log(err.response || err)
          this.$root.$log('error', err)
          this.$root.$alert('管点删除失败,该管点查询失败导致')
        })
    }
  }
}
</script>

<style scoped>
.property {
  color: #cccccc;
  width: var(--RightWidth);
  height: 100%;
  background: var(--PanelBGColor);
}

.propertyHeard {
  border: 1px solid #383838;
  margin-bottom: 10px;
}

.propertyHeard>ul {
  height: 300px;
  margin-left: 30px;
  text-align: left;
  overflow: auto;
}

.propertyHeard li {
  height: 20px;
}

.propertyfoo {
  border: 1px solid #383838;
  margin-top: 10px;
}

.propertybtn {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.propertybtn button {
  height: 30px;
  width: 120px;
  margin: 10px 0px;
}
</style>

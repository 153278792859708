<template>
  <div class="editPoint">
    <div class="editor">
      <div class="angle">
        <div>
          <label>偏航角：</label>
          <input
            type="range"
            min="0"
            max="360"
            step="1"
            v-model.number="heading"
          /><label>{{heading}}</label>
        </div>
        <div>
          <label>俯仰角：</label>
          <input
            type="range"
            min="0"
            max="360"
            step="1"
            v-model.number="pitch"
          /><label>{{pitch}}</label>
        </div>
        <label>翻滚角：</label>
        <input type="range" min="0" max="360" step="1" v-model.number="roll" /><label>{{roll}}</label>
      </div>
      <div style="margin-left: 20px">
        <div>
          <label>X缩放：</label>
          <input type="number" v-model.number="scale[0]" />
        </div>
        <div>
          <label>Y缩放：</label>
          <input type="number" v-model.number="scale[1]" />
        </div>
        <div>
          <label>Z缩放：</label>
          <input type="number" v-model.number="scale[2]" />
        </div>
      </div>
      <button @click="cancel">取消</button>
      <button @click="ok">确定</button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {
    Window,
  },
  data() {
    return {
      position: [0, 0, 0],
      location: [0, 0, 0],
      heading: 0,
      pitch: 0,
      roll: 0,
      scale: [1, 1, 1],
      mat: [],
      pointenu: undefined,
    };
  },
  props: {
    param: {
      type: Object,
      default: undefined,
    },
  },
  mounted() {
    let point = this.param.point;
    if (point.fushuwu_j === null || point.fushuwu_j.define === undefined) {
      this.cancel();
      return;
    }
    var coordinate = point.geom.coordinates;
    var pointenu = XE.Tool.Math.getModelMatrix(
      [
        (coordinate[0] * Math.PI) / 180,
        (coordinate[1] * Math.PI) / 180,
        coordinate[2],
      ],
      [0, 0, 0],
      [1, 1, 1]
    );
    var inverseenu = new Cesium.Matrix4();
    this.inverseenu = Cesium.Matrix4.inverse(pointenu, inverseenu);

    var matrix = point.fushuwu_j.matrix;
    console.log("保存后的：", matrix);
    if (matrix !== undefined) {
      this.decodeMatrix();
    } else {
      this.location = [point.offsetx, point.offsety, point.offsetz];
      this.heading = point.heading;
      this.pitch = point.pitch;
      this.roll = point.roll;

      this.scale = [point.scalex, point.scaley, point.scalez];
      //获得旋转矩阵
      var hpr = Cesium.HeadingPitchRoll.fromDegrees(
        point.heading,
        point.pitch,
        point.roll
      );

      var rotationMatrix = new Cesium.Matrix3();
      Cesium.Matrix3.fromHeadingPitchRoll(hpr, rotationMatrix);

      var quat = new Cesium.Quaternion();
      Cesium.Quaternion.fromRotationMatrix(rotationMatrix, quat);
      this.relativeMatrix = Cesium.Matrix4.fromTranslationQuaternionRotationScale(
        new Cesium.Cartesian3(point.offsetx, point.offsety, point.offsetz),
        quat,
        new Cesium.Cartesian3(point.scalex, point.scaley, point.scalez),
        new Cesium.Matrix4()
      );
    }
    var mat4 = new Cesium.Matrix4();
    mat4 = Cesium.Matrix4.multiply(pointenu, this.relativeMatrix, mat4);
    let cart3 = Cesium.Matrix4.getTranslation(mat4, new Cesium.Cartesian3());
    var p2 = Cesium.Cartographic.fromCartesian(cart3);
    this.position = [
      (p2.longitude * 180) / Math.PI,
      (p2.latitude * 180) / Math.PI,
      p2.height,
    ];

    this.createTempModel();
  },
  watch: {
    heading() {
      this._temmodel.xbsjRotation = [
        (this.heading * Math.PI) / 180,
        (this.pitch * Math.PI) / 180,
        (this.roll * Math.PI) / 180,
      ];
    },
    pitch() {
      this._temmodel.xbsjRotation = [
        (this.heading * Math.PI) / 180,
        (this.pitch * Math.PI) / 180,
        (this.roll * Math.PI) / 180,
      ];
    },
    roll() {
      this._temmodel.xbsjRotation = [
        (this.heading * Math.PI) / 180,
        (this.pitch * Math.PI) / 180,
        (this.roll * Math.PI) / 180,
      ];
    },
    scale: {
      deep: true,
      handler(c) {
        if(c[1] > 0 && c[0] > 0 && c[2] > 0){
          this._temmodel.xbsjScale = [c[1],c[0],c[2]];
        }
      },
    },
  },
  methods: {
    decodeMatrix(matrix) {
      this.relativeMatrix = Cesium.Matrix4.fromArray(matrix);

      var cart3 = new Cesium.Cartesian3();
      cart3 = Cesium.Matrix4.getTranslation(this.relativeMatrix, cart3);
      this.location = [cart3.x, cart3.y, cart3.z];

      var mt3 = new Cesium.Matrix3();
      //需要抵消scale矩阵
      Cesium.Matrix3.getRotation(
        Cesium.Matrix4.getMatrix3(this.relativeMatrix, mt3),
        mt3
      );
      let magicmat = Cesium.Matrix3.fromArray([0, -1, 0, 1, 0, 0, 0, 0, 1]);
      //旋转矩阵乘以此矩阵
      Cesium.Matrix3.multiply(mt3, magicmat, mt3);
      let quaternion = Cesium.Quaternion.fromRotationMatrix(
        mt3,
        new Cesium.Quaternion()
      );
      let relativeHpr = Cesium.HeadingPitchRoll.fromQuaternion(
        quaternion,
        new Cesium.HeadingPitchRoll()
      );
      this.heading = ((relativeHpr.heading * 180) / Math.PI + 360) % 360;
      this.pitch = ((relativeHpr.pitch * 180) / Math.PI + 360) % 360;
      this.roll = ((relativeHpr.roll * 180) / Math.PI + 360) % 360;

      var scale = Cesium.Matrix4.getScale(
        this.relativeMatrix,
        new Cesium.Cartesian3()
      );
      this.scale = [scale.x, scale.y, scale.z];
    },
    modifyRotation() {
      this._temmodel.rotationEditing = true;
    },
    createTempModel() {
      this._temmodel = new XE.Obj.Model(this.$root._earth);
      this._temmodel.positionEditing = true;
      this._positionBind = XE.MVVM.watch(this._temmodel, "xbsjPosition", () => {
        this.setLocation();
      });
      this._temmodel.xbsjPosition = [
        (this.position[0] * Math.PI) / 180,
        (this.position[1] * Math.PI) / 180,
        this.position[2],
      ];
      this._temmodel.xbsjRotation = [
        (this.heading * Math.PI) / 180,
        (this.pitch * Math.PI) / 180,
        (this.roll * Math.PI) / 180,
      ];
      this._temmodel.xbsjScale = this.scale;

      var define = JSON.stringify(this.param.point.fushuwu_j.define);
      define = JSON.parse(define);
      define.lods = [define.lods[0], define.lods[define.lods.length - 1]]; //解决url太长的问题

      this._temmodel.url =
        "./glb/preview?projectid=" +
        this.$root.projectid +
        "&previewjson=" +
        JSON.stringify(define);
    },
    getFSWMatrix() {
      var rotaion = [this.heading, this.pitch, this.roll];

      var fswenu = XE.Tool.Math.getModelMatrix(
        this._temmodel.xbsjPosition,
        rotaion,
        this._temmodel.xbsjScale
      );
      fswenu = Cesium.Matrix4.multiply(this.inverseenu, fswenu, fswenu);
      return fswenu;
    },
    setLocation() {
      var fswmatrix = this.getFSWMatrix();
      var cart3 = new Cesium.Cartesian3();
      cart3 = Cesium.Matrix4.getTranslation(fswmatrix, cart3);
      this.location = [cart3.x, cart3.y, cart3.z];
    },
    ok() {
      let data = {
        projectid: this.$root.projectid,
        ids: [this.param.point.id],
        param: JSON.stringify({
          fushuwu: this.param.point.fushuwu,
          fushuwu_j: this.param.point.fushuwu_j,
          offsetx: this.location[0],
          offsety: this.location[1],
          offsetz: this.location[2],
          heading: this.heading,
          pitch: this.pitch,
          roll: this.roll,
          scalex: this.scale[0],
          scaley: this.scale[1],
          scalez: this.scale[2],
        }),
      };
      this.$axios
        .put("/project/point/accessory/update/ids", data)
        .then((res) => {
          if (res.data.data) {
            this.$root.$alert("附属物修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
            this.cancel();
          }
          //关闭显示
          // this.cancel();
        })
        .catch((err) => {
          this.$root.$log("error", err);
          this.$root.$alert("附属物修改失败");
        });
    },
    cancel() {
      this.$root.$refs.bottom.closeTool();
    },
  },
  beforeUnmount() {
    if (this._positionBind) {
      this._positionBind();
    }
    if (this._temmodel) {
      this._temmodel.destroy();
    }
  },
};
</script>

<style scoped>
.editPoint {
  flex-grow: 1;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.editor {
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.editor label {
  width: 58px;
  text-align: right;
}
input {
  width: 220px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
}
button {
  height: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>

<template>
    <div>
        <Window :title="'纹理选择器面板'" :footervisible="true" @ok="ok" @cancel="cancel" v-show="show" :width="600"
            :height="600">
            <input ref="localImg" type="file" title=" " id="files" @change="upLoadImg" accept=".jpg"
                style="display: none;" />
            <div class="texture_content">
                <div v-for="(item,index) in imageData" :key="index">
                    <div class="thumbnail">
                        <img :src="item.thumbnail" style="width:100px;height:100px">
                    </div>
                    <div class="operation">
                        <img v-show="getBind && getBind.type==1 && !(id===item.id)" src="../assets/selectup.png"
                            @click="selectTexture(item.id)" title="选中">
                        <img v-show="getBind && getBind.type==1 && (id===item.id)" src="../assets/selectdown.png"
                            @click="selectTexture(item.id)" title="选中">
                        <img v-show="getBind && getBind.type==2 && !(id2===item.id)" src="../assets/selectup.png"
                            @click="selectnormalTexture(item.id)" title="选中">
                        <img v-show="getBind && getBind.type==2 && (id2===item.id)" src="../assets/selectdown.png"
                            @click="selectnormalTexture(item.id)" title="选中">
                        <img @click="deleteTexture(item.id)" src="../assets/delete.png" title="删除">
                    </div>

                </div>
                <div @click="textureSelect">
                    <img src="../assets/add.png" alt="" title="添加">
                </div>
            </div>
        </Window>
    </div>
</template>
<script>
    import Window from "../../../components/Window"
    /* eslint-disable */
    export default {
        components: {
            Window,
        },
        data() {
            return {
                show: true,
                id: "",
                id2: "",
                imageData: [],
            }
        },
        props: {
            getBind: Object,
            default () {
                return {}
            }
        },
        mounted() {
            let _this = this;
            console.log("纹理")
            if (this.getBind) {
                this.id2 = this.getBind.id2;
                this.id = this.getBind.id;
                console.log(this.id, this.id2)
            }
            this.query()
        },
        methods: {
            //获取当前项目下的所有纹理图片信息
            query() {
                let _this = this
                this.$axios.get("/project/texture/query/usage", {
                    params: {
                        projectid: _this.$root.projectid,
                        usage: 'line'
                    }
                }).then(res => {
                    console.log("res", res)
                    this.imageData = res.data.data
                    console.log("图片", this.imageData)
                }).catch(err => {
                    err = err.response || err
                    this.$root.$log("error", err)
                })
            },

            textureSelect() {
                this.$refs.localImg.click();
            },
            upLoadImg(event) {
                console.log(event);
                let formData = new FormData();
                formData.append('image', event.target.files[0]);
                formData.append('projectid', this.$root.projectid);
                formData.append('usage', "line");
                console.log(formData)
                let _this = this;
                let _file = event.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(_file);
                reader.onload = function (e) {
                    let image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        if (!_this.checkNumber(this.width)) {
                            //提示宽度不符合2的幂次方
                            _this.$root.$alert("宽度不符合纹理图片规定——宽度必须为2的幂次方")
                            return false;
                        } else if (!_this.checkNumber(this.height)) {
                            _this.$root.$alert("高度不符合纹理图片规定——高度必须为2的幂次方")
                            return false;
                        } else {
                            formData.append('width', this.width)
                            formData.append('height', this.height)
                            _this.$axios.post("/project/texture/add", formData, {
                                    headers: {
                                        "Content-Type": "multipart/form-data"
                                    },
                                })
                                .then((res) => {
                                    if (res.data.data == false) {
                                        _this.$root.$alert("纹理图片上传重复")
                                        _this.$root.$log("warning", "纹理上传重复")
                                    }

                                    _this.query()
                                }).catch(err => {
                                    err = err.response || err
                                    _this.$root.$log("error", err)

                                })
                        }
                    }
                }
                event.target.value = "";
            },
            // 检查高度和宽度是否为2的幂次方
            checkNumber(num) {
                return (num > 0) && ((num & (num - 1)) == 0);
            },
            deleteTexture(id) {
                let _this = this;
                let data = {
                    projectid: _this.$root.projectid,
                    id: id
                }
                this.$root.$confirm("是否确认删除这个纹理?", () => {

                    this.$axios.delete("/project/texture/delete", {
                        data
                    }).then(res => {

                        _this.query()
                    }).catch(err => {

                        err = err.response || err
                        this.$root.$log("error", err)
                    })
                })
            },
            selectTexture(id) {
                console.log("打印id", id)
                this.id = id
            },
            // 选中/取消法向纹理
            selectnormalTexture(id) {

                this.id2 = id

            },
            cancel() {
                this.$root.destroyTool(this)
            },
            ok() {
                if (this.getBind) {
                    if (this.getBind.type == 1) {
                        this.getBind.self.id = this.id
                        console.log("纹理", this.getBind.self.id)
                        this.$root.destroyTool(this)
                    } else {
                        this.getBind.self.id2 = this.id2
                        this.$root.destroyTool(this)

                    }
                } else {

                }
            },
        }
    }
</script>
<style scoped>
    .texture_content {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        overflow: auto;
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start
    }

    .texture_content>div {
        width: 100px;
        height: 132px;
        margin-left: 12px;

    }

    .thumbnail {
        width: 100px;
        height: 100px;
        border: 2px solid #1A1A1A;
        opacity: 1;
        margin-bottom: 8px;
    }

    .operation {
        display: flex;
        justify-content: space-around;
    }

    .operation>img {
        cursor: pointer;
    }

    .texture_content>div:last-child:hover {
        cursor: pointer;
    }
</style>
<template>
  <div class="editPolygon">
    <div class="editor">
      <!-- <label>经度:</label>
      <input v-model.number="positions[0]" v-number-fixed="6" />
      <label>纬度:</label>
      <input v-model.number="positions[1]" v-number-fixed="6" />
      <label>高度:</label>
      <input v-model.number="positions[2]" v-number-fixed="2" /> -->
      <button @click="cancel">取消</button>
      <button @click="ok">确定</button>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Window
  },
  data() {
    return {
      positions: []
    };
  },
  props: {
    param: {
      type: Object,
      default: undefined
    }
  },
  mounted() {
    this.createTempModel();
  },
  methods: {
    createTempModel() {
      this._temmodel = new XE.Obj.Polygon(this.$root._earth);
      var coordinates = this.param.polygon.geom.coordinates[0];
      coordinates.forEach(p => {
        this._temmodel.positions.push(p[0] * Math.PI / 180)
        this._temmodel.positions.push(p[1] * Math.PI / 180)
      })
      this._temmodel.height = this.param.polygon.dimiangaocheng;
      this._temmodel.editing = true;
      // this._positionBind = XE.MVVM.bindPosition(
      //   this,
      //   "positions",
      //   this._temmodel,
      //   "positions"
      // );
    },
    ok() {
      var coords = [];
      for (var i = 0; i < this._temmodel.positions.length - 1; i += 2) {
        coords.push([this._temmodel.positions[i] * 180 / Math.PI, this._temmodel.positions[i + 1] * 180 / Math.PI, this._temmodel.height]);
      }
      let data = {
        projectid: this.$root.projectid,
        id: this.param.polygon.id,
        geojson: {
          type: this.param.polygon.geom.type,
          coordinates: [coords]
        },
      };
      this.$axios
        .put("/project/build/positions/update", data)
        .then(res => {

          //关闭显示 
          this.cancel();
          this.$root._tileset.reload();
          if (res.data.data && res.data.data === true) {
            this.$root._tileset.reload();
          }
        })
        .catch(err => {
          this.$root.$log("error", err);
        });
    },
    cancel() {
      this.$root.$refs.bottom.closeTool();
    }
  },
  beforeUnmount() {
    if (this._positionBind) {
      this._positionBind();
    }
    if (this._temmodel) {
      this._temmodel.destroy();
    }
    if (this._temLines) {
      this._temLines.forEach(e => {
        e.destroy();
      });
    }
  }
};
</script>

<style scoped>
.editPolygon {
  flex-grow: 1;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.editor {
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
input {
  width: 80px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
}
button {
  height: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>

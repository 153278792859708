<template>
    <div class="property">场景属性
        <br>
        这里包含 earthui效果栏的所有东西

    </div>
</template>
<script>
    export default {
        props: {
            param: Object
        },
        created() {},
        beforeUnmount() {}
    };
</script>

<style scoped>
    .property {
        color: #CCCCCC;
        width: var(--RightWidth);
        height: 100%;
        background: var(--PanelBGColor);
    }
</style>>
<template>
  <div class="pipepointquery">
    <div class="chaxunline">
      <div class="condition">
        <div class="row_single">
          <label>孤立点</label>
          <input type="checkbox" class="bty-switch" v-model="formData.isolatedpoint" />
        </div>
        <div class="row_single">
          <label>ID</label>
          <input type="text" v-model="formData.id" @keyup.enter="requery" />
        </div>
        <div class="row_single" v-for="(item, index) in formData.conditions" :key="index">
          <label>{{ customConditions[item.column_name] }}</label>
          <input v-show="item.column_type == 'BOOLEAN' || item.column_type == 'TEXT'" :class="
            switchType(item.column_type).type == 'checkbox'
              ? 'bty-switch'
              : 'none'
          " :type="switchType(item.column_type).type" :step="
  switchType(item.column_type).step
    ? switchType(item.column_type).step
    : null
" v-model="item.column_data" @keyup.enter="requery" />
          <input v-show="
            !(item.column_type == 'BOOLEAN' || item.column_type == 'TEXT')
          " :type="switchType(item.column_type).type" :step="
  switchType(item.column_type).step
    ? switchType(item.column_type).step
    : null
" v-model.number="item.min" @keyup.enter="requery" />
          <label v-show="
            !(item.column_type == 'BOOLEAN' || item.column_type == 'TEXT')
          ">~</label>
          <input v-show="
            !(item.column_type == 'BOOLEAN' || item.column_type == 'TEXT')
          " :type="switchType(item.column_type).type" :step="
  switchType(item.column_type).step
    ? switchType(item.column_type).step
    : null
" v-model.number="item.max" @keyup.enter="requery" />
        </div>
        <div class="row_single">
          <label>创建时间</label>
          <input type="date" v-model="formData.create_start" @keyup.enter="requery" />
          <label>~</label>
          <input type="date" v-model="formData.create_end" @keyup.enter="requery" />
        </div>
        <div class="row_single">
          <label>更新时间</label>
          <input type="date" v-model="formData.update_start" @keyup.enter="requery" />
          <label>~</label>
          <input type="date" v-model="formData.update_end" @keyup.enter="requery" />
        </div>
        <!-- <div class="row_single">
          <label>条件</label>
          <select v-model="condition_select">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
          <select v-model="condition_Symbol">
            <option value="==">==</option>
            <option value="<">《</option>
            <option value=">">></option>
          </select>
          <input type="text"
            v-model="condition_content">
        </div> -->
      </div>
      <div class="row_single operation">
        <button class="odpoint" @click="odLine" v-show="!showdelete">
          绘制查询范围
        </button>
        <button class="xbsj-button-tab" @click="clear" v-show="showdelete">
          清空
        </button>
        <button @click="requery" class="xbsj-button-tab queryclick">
          查询
        </button>
      </div>
    </div>
    <div class="tablePages">
      <div class="taNamepoint">
        <table cellspacing="3">
          <tr>
            <th>
              <label>
                <input type="checkbox" v-model="checked" @change="changeAllChecked" />全选
              </label>
              <br />
              <label>
                <input type="checkbox" v-model="unchecked" @change="unChangeAllChecked" />反选
              </label>
            </th>
            <th v-for="(item, index) in thearder" :key="index" @click="sort(item)" title="升序/降序">
              {{ item.name }}{{ item.descasc }}
            </th>
            <th v-for="(item, index) in otherfields" :key="index + thearder.length" @click="sort(item)" title="升序/降序">
              {{ item.name }}{{ item.descasc }}
            </th>
            <th :min-width="50">操作</th>
          </tr>
          <tr class="everyone" v-for="(item, index) in infos" :key="index" @dblclick="locate(item)">
            <td>
              <input type="checkbox" :value="item.id" v-model="checkedNames" />
            </td>
            <td>{{ item.id }}</td>
            <td>{{ item.geom.coordinates[0] }}</td>
            <td>{{ item.geom.coordinates[1] }}</td>
            <td>{{ item.geom.coordinates[2] }}</td>
            <td>{{ item.dimiangaocheng }}</td>
            <td>{{ item.maishen }}</td>
            <td>{{ item.tezheng }}</td>
            <td>{{ item.fushuwu }}</td>
            <!-- <td>{{ item.create_t }}</td>
            <td>{{ item.update_t }}</td> -->
            <td v-for="field in otherfields" :key="field.zhname">
              {{ item[field.zhname] }}
            </td>
            <td><button @click="changeInfo(item)">修改</button></td>
          </tr>
        </table>
      </div>
      <div class="pagepoint">
        <div>
          <label>
            <input type="checkbox" v-model="checkedAll" @change="checkedAllP" />
            全选{{ count }}个管点
          </label>
          <button @click="updateTucengPop">修改图层</button>
          <button @click="updateFushuwuPop">修改附属物</button>
          <button @click="clearFushuwuPop">清空附属物</button>
          <button @click="updateFSWPositionPop">附属物偏移</button>
          <button @click="updateFSWRotationPop">附属物角度</button>
          <button @click="updateFSWScalePop">附属物大小</button>
          <button @click="updatePositionsPop">修改高度</button>
          <button @click="updateCaizhiPop">修改材质</button>
          <button @click="updateCanshuPop">修改参数</button>
          <button @click="deletePoint">删除</button>
          <button @click="refreshData">重构数据</button>
        </div>
        <div>
          <span class="upDown" @click="upPage">上一页</span>
          <span class="pageNumber">{{ formData.page }}/{{ pageNumber }}页</span>
          <span class="upDown" @click="downPage">下一页</span>
          <span>
            <span class="upDown" @click="runPage">跳转</span>
            <input v-model.number="formData.page" @keyup.enter="query" />页
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Appendages from "./Appendages";
import PointsPosition from "./PointsPosition";
import ModifyFSWRotation from "./ModifyFSWRotation.vue";
import ModifyFSWPosition from "./ModifyFSWPosition.vue";
import ModifyFSWScale from "./ModifyFSWScale.vue";
import parameterTool from "./PointParameterTool";
import MaterialSelect from "./MaterialSelect";
import utils from "../math/utils";
import LayerTool from "./LayerTool";
import ModifyFieldInfo from "./ModifyFieldInfo.vue"
/* eslint-disable */
export default {
  components: {
    Appendages,
    PointsPosition,
    ModifyFSWRotation,
    ModifyFSWPosition,
    LayerTool,
    ModifyFSWScale
  },
  data() {
    return {
      condition_Symbol: "",
      condition_content: "",
      condition_select: "",
      creating: false,
      unchecked: false,
      checked: false,
      showdelete: false,
      polylineData: [],
      flag: "",
      count: 0,
      checkedAll: false,
      checked: false,
      checkedNames: [],
      thearder: [
        {
          zhname: "id",
          name: "id",
        },
        {
          zhname: "st_x(geom)",
          name: "经度",
        },
        {
          zhname: "st_y(geom)",
          name: "纬度",
        },

        {
          zhname: "st_z(geom)",
          name: "高度",
        },
        {
          zhname: "dimiangaocheng",
          name: "地面高程",
        },
        {
          zhname: "maishen",
          name: "埋深",
        },
        {
          zhname: "tezheng",
          name: "特征",
        },
        {
          zhname: "fushuwu",
          name: "附属物",
        },
        // {
        //   zhname: "create_t",
        //   name: "创建时间",
        // },
        // {
        //   zhname: "update_t",
        //   name: "更新时间",
        // },
      ],
      otherfields: [],
      infos: "",
      itemsid: [],
      pageNumber: 1,
      formData: {
        id: "",
        isolatedpoint: false,
        pagecap: 20,
        page: 1,
        projectid: this.$root.projectid,
        conditions: [
          {
            column_name: "tezheng",
            column_type: "TEXT",
            column_data: undefined,
          },
          {
            column_name: "fushuwu",
            column_type: "TEXT",
            column_data: undefined,
          },
        ],
        create_start: "",
        create_end: "",
        update_start: "",
        update_end: "",
        sort: "", //排序 desc 降序，asc 升序
        sortfield: "", //排序字段
      },
      customConditions: {
        tezheng: "特征",
        fushuwu: "附属物",
      },
      geojson: "",
      showQuery: true,
      conditionsdefault: [],
    };
  },

  watch: {
    creating() {
      if (!this.creating) {
        console.log("绘制完成");
        if (this.polylineData.length > 0) {
          let arr = this.ArrayAdd(this.polylineData);
          if ([...arr].length) {
            this.geojson = JSON.stringify({
              type: "Polygon",
              coordinates: [arr],
            });
          }
        } else {
          this.geojson = "";
        }
      }
    },
    checkedNames: function () {
      if (
        this.checkedNames.length == this.infos.length &&
        this.checkedNames.length !== 0
      ) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    flag: function () {
      if (this.flag === true) {
        this.formData.sort = "asc"; //降序
      } else if (this.flag === false) {
        this.formData.sort = "desc"; //升序
      } else {
        this.formData.sort = ""; //默认排序
      }
    },
  },
  mounted() {
    this.getCustom();
    this.query();
    this._polyline = new XE.Obj.Plots.GeoPolygon(this.$root._earth);
    this._disposers = this._disposers || [];
    this._disposers.push(
      XE.MVVM.bind(this, "creating", this._polyline, "creating")
    );
  },
  methods: {
    updateTucengPop() {
      //修改图层弹窗
      if (this.checkedNames.length > 0) {
        //console.log("选中的管线", this.itemsid)
        this.$root.showPropertyWindow("LayerTool", {
          component: LayerTool,
          param: {
            ok: (option) => {
              //console.log("( •̀ ω •́ )y拿到了图层数据", option);
              if (option) {
                this.updateTuceng(option);
              }
            },
          },
        });
        (this.unchecked = false), (this.checked = false);
      } else {
        this.$root.$alert("未选中数据");
      }
    },
    updateTuceng(opt) {
      //修改图层
      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.conditions = JSON.stringify(params.conditions);
        let data = {
          projectid: params.projectid,
          isolatedpoint: params.isolatedpoint,
          id: params.id,
          conditions: this.conditionsdefault,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          geojson: this.geojson,
          tuceng: opt.id,
          ilayer: isNaN(opt.id) ? opt.id : parseFloat(opt.id),
        };

        this.$axios
          .post("/project/point/layer/update/conditions", data)
          .then((res) => {
            this.query();
            //console.log(res);

            this.$root.$alert("全部图层修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);
            //console.log(err.response || err);
            this.$root.$alert("全部图层修改失败");
          });
      } else {
        let data = {
          projectid: this.formData.projectid,
          ids: this.checkedNames,
          tuceng: opt.id,
          ilayer: isNaN(opt.id) ? opt.id : parseFloat(opt.id),
        };
        //console.log("修改图层选中的管线", data)
        this.$axios
          .put("/project/point/layer/update/ids", data)
          .then((res) => {
            this.query();
            console.log(res);
            this.$root.$alert("图层修改成功");
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("图层修改失败");
          });
      }
    },
    changeInfo(item) {
      this.$root.showPropertyWindow("ModifyFieldInfo", {
        component: ModifyFieldInfo,
        param: {
          item: item,
          ok: (option) => {
            this.updateFieldInfo(item, option);
          },
        },
      }, 'point');
    },
    async updateFieldInfo(item, opt) {
      let _this = this;
      let data = {
        projectid: this.$root.projectid,
        id: item.id,
        param: opt,
      };
      this.$axios
        .put("/project/point/update/info", data)
        .then((res) => {
          _this.query();
          console.log(res);
          this.$root.$alert("修改成功");
        })
        .catch((err) => {
          this.$root.$log("error", err);
          console.log(err.response || err);
          this.$root.$alert("修改失败");
        });
    },
    requery() {
      this.formData.page = 1;
      this.query();
    },
    refreshData() {
      let _this = this;
      this.$root.$confirm("重构数据比较缓慢是否继续?", () => {
        if (this.checkedAll) {
          let params = utils.clone(this.formData, true);
          params.conditions = JSON.stringify(params.conditions);

          let data = {
            projectid: params.projectid,
            id: params.id,
            conditions: this.conditionsdefault,
            isolatedpoint: params.isolatedpoint,
            create_start: params.create_start,
            create_end: params.create_end,
            update_start: params.update_start,
            update_end: params.update_end,
            geojson: this.geojson,
          };

          this.$axios
            .post("/project/point/rebuild", data)
            .then((res) => {
              _this.query();
              console.log(res);
              this.$root.$alert("任务已经添加，请在任务查询界面查看进度");
            })
            .catch((err) => {
              this.$root.$log("error", err);
              console.log(err.response || err);
              this.$root.$alert(err.data.message);
            });
        } else {
          let data = {
            projectid: this.formData.projectid,
            ids: this.checkedNames,
          };
          //console.log(JSON.stringify(data))

          this.$axios
            .post("/project/point/rebuild", data)
            .then((res) => {
              _this.query();
              console.log(res);
              this.$root.$alert("任务已经添加，请在任务查询界面查看进度");
            })
            .catch((err) => {
              this.$root.$log("error", err);
              console.log(err.response || err);
              this.$root.$alert(err.data.message);
            });
        }
      });
      (this.unchecked = false), (this.checked = false);
    },
    // 映射input类型
    switchType(type) {
      let step = undefined;
      switch (type) {
        case "TEXT":
          type = "text";
          break;
        case "INTEGER":
          type = "number";
          step = 1;
          break;
        case "DOUBLE":
          type = "number";
          step = 0.01;
          break;
        case "BOOLEAN":
          type = "checkbox";
          break;
        case "DATE":
          type = "date";
          break;
        default:
          type = "text";
      }
      return {
        type: type,
        step: step,
      };
    },
    // 映射字段类型
    yingshe(type) {
      switch (type) {
        case "text":
          type = "TEXT";
          break;
        case "integer":
          type = "INTEGER";
          break;
        case "double precision":
          type = "DOUBLE";
          break;
        case "float8":
          type = "DOUBLE";
          break;
        case "boolean":
          type = "BOOLEAN";
          break;
        case "date":
          type = "DATE";
          break;
        default:
          type = "Text";
      }
      return type;
    },
    getCustom() {
      this.$axios
        .get("/project/point/column/query", {
          params: {
            projectid: this.$root.projectid,
          },
        })
        .then((res) => {
          let arr = res.data.data;
          if (arr instanceof Array && arr.length > 0) {
            arr.forEach((el) => {
              if (el.column_editable) {
                if (
                  el.column_datatype == "text" ||
                  el.column_datatype == "boolean"
                ) {
                  this.formData.conditions.push({
                    column_name: el.column_name,
                    column_type: this.yingshe(el.column_datatype),
                    column_data: undefined,
                  });
                } else {
                  this.formData.conditions.push({
                    column_name: el.column_name,
                    column_type: this.yingshe(el.column_datatype),
                    min: undefined,
                    max: undefined,
                  });
                }
                if (
                  this.thearder.find((t) => {
                    t.zhname == el.column_name;
                  }) == undefined && el.column_name !== 'resource'
                ) {
                  this.otherfields.push({
                    zhname: el.column_name,
                    name: el.column_chinese || el.column_name
                  });
                }
                this.customConditions[el.column_name] =
                  el.column_chinese || el.column_name;
              }
            });
          }
          console.log(this.formData, this.customConditions);
        })
        .catch((err) => {
          console.log(err || err.response);
        });
    },
    clear() {
      //清空几何图形
      if (this._polyline) {
        this._polyline.show = false;
        this._polyline.creating = false;
        this.polylineData = [];
        this.showdelete = false;
        this.infos = [];
        this.pageNumber = 1;
        this.formData.page = 1;
        this.count = 0;
        this.showQuery = true;
      }
      this.geojson = "";
      this.formData.geojson = "";
    },
    odLine() {
      this._polyline.positions = [];
      this._polyline._polygon.positions = [];
      this._polyline.enabled = true;
      //console.log("寂寞")
      this.infos = [];
      this.pageNumber = 1;
      this.formData.page = 1;
      this.count = 0;

      this._polyline.creating = true; //可以在地图上画
      XE.MVVM.watch(
        () => ({
          positions: [...this._polyline.positions],
        }),
        () => {
          if (this._polyline.positions.length > 3) {
            this.polylineData = [];
            let lat;
            let lng;
            for (
              let index = 0;
              index < this._polyline.positions.length;
              index++
            ) {
              lat = (this._polyline.positions[index][0] * 180) / Math.PI;
              lng = (this._polyline.positions[index][1] * 180) / Math.PI;
              this.polylineData.push([lat, lng]);
            }
            //console.log("多边形经纬度", this.polylineData)
          }
        }
      );
      // }
      this.showQuery = false;
      this.showdelete = true;
    },
    ArrayAdd(arr) {
      let a = [...new Set(arr)]; //去重加首尾相同
      a.push(a[0]);
      return a;
    },
    sort(item) {
      this.flag = !this.flag;
      if (this.flag === true) {
        item.descasc = "↓";
      } else {
        item.descasc = "↑";
      }
      this.formData.sortfield = item.zhname;
      this.query();
    },
    locate(item) {
      this.$root._tileset.selectFeature({
        id: item.id,
        otype: "pipe",
      });
    },
    unChangeAllChecked() {
      let new_arrB = [];
      this.itemsid.map((item) => {
        if (this.checkedNames.indexOf(item) < 0) {
          // 这行就是最主要的代码
          new_arrB.push(item);
        }
      });
      this.checkedNames = new_arrB;
      //console.log("反选", this.checkedNames);
    },
    changeAllChecked() {
      if (this.checked) {
        this.checkedNames = this.itemsid;

        //console.log("全选", this.checkedNames);
      } else {
        this.checkedNames = [];
        //console.log("全不选", this.checkedNames);
      }
    },
    checkedAllP() {
      if (this.checkedAll) {
        this.checked = true;
        this.checkedNames = this.itemsid;
      } else {
        this.checkedNames = [];
      }
    },
    // 判断左侧查询条件时候为空

    async query() {
      let _this = this;
      if (this.geojson != "") {
        this.formData.geojson = this.geojson;
      }
      let params = utils.clone(this.formData);
      params["conditions"] = JSON.stringify(params.conditions);
      this.conditionsdefault = params["conditions"];

      this.$axios
        .get("/project/point/query", {
          params: params,
        })
        .then((res) => {
          if (res.data.data) {
            this.count = res.data.data.count;
            var arrData = res.data.data.results;
            for (var i = 0; i < arrData.length; i++) {
              arrData[i].geom.coordinates[0] = arrData[
                i
              ].geom.coordinates[0].toFixed(6);
              arrData[i].geom.coordinates[1] = arrData[
                i
              ].geom.coordinates[1].toFixed(6);
              arrData[i].geom.coordinates[2] = arrData[
                i
              ].geom.coordinates[2].toFixed(2);
              arrData[i].dimiangaocheng = Number(
                arrData[i].dimiangaocheng
              ).toFixed(2);
              arrData[i].maishen = Number(arrData[i].maishen).toFixed(2);
            }
            _this.infos = arrData;
            this.itemsid = [];
            for (let index = 0; index < this.infos.length; index++) {
              this.itemsid.push(this.infos[index].id);
            }
            //_this.count = res.data.data.count
            _this.pageNumber = Math.ceil(
              res.data.data.count / _this.formData.pagecap
            );
          }
        })
        .catch((err) => {
          this.$root.$log("error", err);

          console.log(err.response || err);
        });
      (this.unchecked = false), (this.checked = false);
    },
    deletePoint() {
      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.conditions = JSON.stringify(params.conditions);
        let data = {
          isolatedpoint: params.isolatedpoint,
          projectid: this.$root.projectid,
          conditions: this.conditionsdefault,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          geojson: this.geojson,
        };
        this.$root.$confirm("确定删除全部" + this.count + "个管点?", () => {
          this.$axios
            .delete("/project/point/delete/conditions", {
              data,
            })
            .then((res) => {
              console.log(res);
              this.query();
              this.$root.$alert("全部管点删除成功");
              if (res.data.data && res.data.data === true) {
                this.$root._tileset.reload();
              }
            })
            .catch((err) => {
              this.$root.$log("error", err);
              console.log(err.response || err);
              this.$root.$alert("全部管点删除失败");
            });
        });
      } else {
        if (this.checkedNames.length > 0) {
          let _this = this;
          let params = {
            projectid: this.$root.projectid,
            ids: JSON.stringify(this.checkedNames),
          };
          let data = {
            projectid: this.$root.projectid,
            ids: this.checkedNames,
          };

          this.$axios
            .get("/project/point/connected/line", {
              params: params,
            })
            .then((res) => {
              console.log("包含要删除管点的所有管线查询", res);
              let dependLine = res.data.data.length;
              if (dependLine === 0) {
                _this.$root.$confirm("确定删除选中的管点?", () => {
                  _this.$axios
                    .delete("/project/point/delete/ids", {
                      data,
                    })
                    .then((res) => {
                      console.log(res);
                      this.query();
                      this.$root.$alert("管点删除成功");
                      if (res.data.data && res.data.data === true) {
                        this.$root._tileset.reload();
                      }
                    })
                    .catch((err) => {
                      this.$root.$log("error", err);

                      console.log(err.response || err);
                      this.$root.$alert("管点删除失败");
                    });
                });
              } else {
                _this.$root.$confirm(
                  "选中的管点共被" +
                  dependLine +
                  "根管线所依赖，删除后所有依赖管线都将被删除，确定删除?",
                  () => {
                    _this.$axios
                      .delete("/project/point/delete/ids", {
                        data,
                      })
                      .then((res) => {
                        console.log(res);
                        this.query();
                        this.$root.$alert("管点删除成功");
                        if (res.data.data && res.data.data === true) {
                          this.$root._tileset.reload();
                        }
                      })
                      .catch((err) => {
                        this.$root.$log("error", err);

                        console.log(err.response || err);
                        this.$root.$alert("管点删除失败");
                      });
                  }
                );
              }
            })
            .catch((err) => {
              this.$root.$log("error", err);
              console.log(err.response || err);
              this.$root.$alert("管点删除失败,该管点查询失败导致");
            });
        } else {
          this.$root.$alert("未选中数据");
        }
      }
      (this.unchecked = false), (this.checked = false);
    },
    updateFushuwuPop() {
      //console.log("选中全部管点？", this.checkedAll);
      if (this.checkedNames.length > 0) {
        this.$root.showPropertyWindow("Appendages", {
          component: Appendages,
          param: {
            ok: (option) => {
              console.log("( •̀ ω •́ )y拿到了附属物", option);
              this.updateFushuwu(option);
            },
          },
        });
      } else {
        this.$root.$alert("未选中数据");
      }
    },
    updateFSWRotationPop() {
      if (this.checkedNames.length > 0) {
        this.$root.showPropertyWindow("ModifyFSWRotation", {
          component: ModifyFSWRotation,
          param: {
            point: this.infos.find((e) => e.id == this.checkedNames[0]),
            ok: (option) => {
              this.updateFSWRotation(option);
            },
          },
        });
      } else {
        this.$root.$alert("未选中数据");
      }
    },
    updateFSWPositionPop() {
      if (this.checkedNames.length > 0) {
        this.$root.showPropertyWindow("ModifyFSWPosition", {
          component: ModifyFSWPosition,
          param: {
            point: this.infos.find((e) => e.id == this.checkedNames[0]),
            ok: (option) => {
              this.updateFSWPosition(option);
            },
          },
        });
      } else {
        this.$root.$alert("未选中数据");
      }
    },
    updateFSWScalePop() {
      if (this.checkedNames.length > 0) {
        this.$root.showPropertyWindow("ModifyFSWScale", {
          component: ModifyFSWScale,
          param: {
            point: this.infos.find((e) => e.id == this.checkedNames[0]),
            ok: (option) => {
              this.updateFSWScale(option);
            },
          },
        });
      } else {
        this.$root.$alert("未选中数据");
      }
    },
    updatePositionsPop() {
      if (this.checkedNames.length > 0) {
        this.$root.showPropertyWindow("PointsPosition", {
          component: PointsPosition,
          param: {
            point: this.infos.find((e) => e.id == this.checkedNames[0]),
            ok: (option) => {
              this.updatePosition(option);
            },
          },
        });
      } else {
        this.$root.$alert("未选中数据");
      }
    },
    clearFushuwuPop() {
      if (this.checkedNames.length > 0) {
        this.updateFushuwu({
          name: "",
          param: undefined,
        });
      } else {
        this.$root.$alert("未选中数据");
      }
    },
    async updateFushuwu(opt) {
      let _this = this;
      console.log("传参过程中");
      let data1 = {
        fushuwu: opt.name,
        fushuwu_j: {
          define: opt.param,
        },
      };

      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.conditions = JSON.stringify(params.conditions);

        let data = {
          projectid: params.projectid,
          isolatedpoint: params.isolatedpoint,
          id: params.id,
          conditions: this.conditionsdefault,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          geojson: this.geojson,
          param: JSON.stringify(data1),
        };
        this.$axios
          .post("/project/point/accessory/update/conditions", data)
          .then((res) => {
            _this.query();
            console.log(res);
            this.$root.$alert("全部附属物修改成功");
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("全部附属物修改失败");
          });
      } else {
        let data = {
          projectid: this.formData.projectid,
          ids: this.checkedNames,
          param: JSON.stringify(data1),
        };
        //console.log(JSON.stringify(data))

        this.$axios
          .put("/project/point/accessory/update/ids", data)
          .then((res) => {
            _this.query();
            console.log(res);
            this.$root.$alert("选中附属物修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("选中附属物修改失败");
          });
      }
      (this.unchecked = false), (this.checked = false);
    },
    updateFSWRotation(opt) {
      let _this = this;

      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.conditions = JSON.stringify(params.conditions);

        let data = {
          projectid: params.projectid,
          isolatedpoint: params.isolatedpoint,
          id: params.id,
          conditions: this.conditionsdefault,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          geojson: this.geojson,
          param_j: JSON.stringify(opt),
        };
        this.$axios
          .put("/project/point/accessory/rotation/update/conditions", data)
          .then((res) => {
            _this.query();
            console.log(res);
            this.$root.$alert("全部附属物修改成功");
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("全部附属物修改失败");
          });
      } else {
        let data = {
          projectid: this.formData.projectid,
          ids: this.checkedNames,
          param_j: JSON.stringify(opt),
        };

        this.$axios
          .put("/project/point/accessory/rotation/update/ids", data)
          .then((res) => {
            _this.query();
            console.log(res);
            this.$root.$alert("选中附属物修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("选中附属物修改失败");
          });
      }
      (this.unchecked = false), (this.checked = false);
    },
    updateFSWPosition(opt) {
      let _this = this;

      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.conditions = JSON.stringify(params.conditions);

        let data = {
          projectid: params.projectid,
          isolatedpoint: params.isolatedpoint,
          id: params.id,
          conditions: this.conditionsdefault,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          geojson: this.geojson,
          param_j: JSON.stringify(opt),
        };
        this.$axios
          .put("/project/point/accessory/position/update/conditions", data)
          .then((res) => {
            _this.query();
            console.log(res);
            this.$root.$alert("全部附属物修改成功");
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("全部附属物修改失败");
          });
      } else {
        let data = {
          projectid: this.formData.projectid,
          ids: this.checkedNames,
          param_j: JSON.stringify(opt),
        };

        this.$axios
          .put("/project/point/accessory/position/update/ids", data)
          .then((res) => {
            _this.query();
            console.log(res);
            this.$root.$alert("选中附属物修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("选中附属物修改失败");
          });
      }
      (this.unchecked = false), (this.checked = false);
    },
    updateFSWScale(opt) {
      let _this = this;

      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.conditions = JSON.stringify(params.conditions);

        let data = {
          projectid: params.projectid,
          isolatedpoint: params.isolatedpoint,
          id: params.id,
          conditions: this.conditionsdefault,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          geojson: this.geojson,
          param_j: JSON.stringify(opt),
        };
        this.$axios
          .put("/project/point/accessory/scale/update/conditions", data)
          .then((res) => {
            _this.query();
            console.log(res);
            this.$root.$alert("全部附属物修改成功");
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("全部附属物修改失败");
          });
      } else {
        let data = {
          projectid: this.formData.projectid,
          ids: this.checkedNames,
          param_j: JSON.stringify(opt),
        };

        this.$axios
          .put("/project/point/accessory/scale/update/ids", data)
          .then((res) => {
            _this.query();
            console.log(res);
            this.$root.$alert("选中附属物修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("选中附属物修改失败");
          });
      }
      (this.unchecked = false), (this.checked = false);
    },
    async updatePosition(opt) {
      let _this = this;
      console.log("传参过程中");
      let data1 = {
        x: 0,
        y: 0,
        z: opt.z,
        updatebyline: opt.updatebyline
      };

      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.conditions = JSON.stringify(params.conditions);

        let data = {
          projectid: params.projectid,
          isolatedpoint: params.isolatedpoint,
          id: params.id,
          conditions: this.conditionsdefault,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          geojson: this.geojson,
          param: JSON.stringify(data1),
        };
        this.$axios
          .post("/project/point/position/update/conditions", data)
          .then((res) => {
            _this.query();
            console.log(res);
            this.$root.$alert("修改成功");
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("修改失败");
          });
      } else {
        let data = {
          projectid: this.formData.projectid,
          ids: this.checkedNames,
          param: JSON.stringify(data1),
        };
        //console.log(JSON.stringify(data))

        this.$axios
          .put("/project/point/position/update/ids", data)
          .then((res) => {
            _this.query();
            console.log(res);
            this.$root.$alert("修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("修改失败");
          });
      }
      (this.unchecked = false), (this.checked = false);
    },
    updateCaizhiPop() {
      //修改材质弹窗
      if (this.checkedNames.length > 0) {
        this.$root.showPropertyWindow("MaterialSelect", {
          component: MaterialSelect,
          param: {
            ok: (caizhi) => {
              console.log("showPropertyWindow材质" + caizhi);
              this.updateCaizhi(caizhi);
            },
          },
        });
        (this.unchecked = false), (this.checked = false);
      } else {
        this.$root.$alert("未选中数据");
      }
    },
    updateCaizhi(opt) {
      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.conditions = JSON.stringify(params.conditions);

        let data = {
          projectid: params.projectid,
          isolatedpoint: params.isolatedpoint,
          id: params.id,
          conditions: this.conditionsdefault,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          caizhi_j: JSON.stringify(opt.param),
          geojson: this.geojson,
        };
        this.$axios
          .put("/project/point/material/update/conditions", data)
          .then((res) => {
            this.query();
            console.log(res);
            this.$root.$alert("全部材质修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);

            console.log(err.response || err);
            this.$root.$alert("全部材质修改失败");
          });
      } else {
        //修改管线材质请求
        let data = {
          projectid: this.formData.projectid,
          ids: this.checkedNames,
          caizhi_j: JSON.stringify(opt.param),
        };
        console.log("选中材质修改", data);

        this.$axios
          .put("/project/point/material/update/ids", data)
          .then((res) => {
            this.query();
            //console.log(res);
            this.$root.$alert("选中材质修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);
            console.log(err.response || err);
            this.$root.$alert("选中材质修改失败");
          });
      }
      (this.unchecked = false), (this.checked = false);
    },
    updateCanshuPop() {
      if (this.checkedNames.length > 0) {
        //更新参数弹窗
        this.$root.showPropertyWindow(
          "parameterTool",
          {
            component: parameterTool,
            param: {
              ok: (option) => {
                //console.log("( •̀ ω •́ )y拿到了参数", option);
                this.updateCanshu(option);
              },
            },
          },
          {
            type: "circle",
            profile: {
              radius: 0.05, //不能为0
              thickness: 0.01,
            },
            flange: {
              length: 0.01,
              thickness: 0.01,
            },
          }
        );
        (this.unchecked = false), (this.checked = false);
      } else {
        this.$root.$alert("未选中数据");
      }
    },
    updateCanshu(opt) {
      if (this.checkedAll) {
        let params = utils.clone(this.formData, true);
        params.conditions = JSON.stringify(params.conditions);

        let data = {
          projectid: params.projectid,
          isolatedpoint: params.isolatedpoint,
          id: params.id,
          conditions: this.conditionsdefault,
          create_start: params.create_start,
          create_end: params.create_end,
          update_start: params.update_start,
          update_end: params.update_end,
          tezheng_j: JSON.stringify(opt),
          geojson: this.geojson,
        };
        this.$axios
          .put("/project/point/shape/update/conditions", data)
          .then((res) => {
            this.query();
            console.log(res);

            this.$root.$alert("全部参数修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);

            console.log(err.response || err);
            this.$root.$alert("全部参数修改失败");
          });
      } else {
        //修改参数
        let data = {
          projectid: this.formData.projectid,
          ids: this.checkedNames,
          tezheng_j: JSON.stringify(opt),
        };
        // console.log(data1)

        this.$axios
          .put("/project/point/shape/update/ids", data)
          .then((res) => {
            this.query();
            console.log(res);

            this.$root.$alert("选中参数修改成功");
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload();
            }
          })
          .catch((err) => {
            this.$root.$log("error", err);

            console.log(err.response || err);
            this.$root.$alert("选中参数修改失败");
          });
      }
      (this.unchecked = false), (this.checked = false);
    },
    upPage() {
      (this.unchecked = false), (this.checked = false);
      this.checkedAll = false;
      this.checkedNames = [];
      let _this = this;

      if (this.formData.page <= 1) {
        _this.formData.page = 1;
      } else {
        _this.formData.page -= 1;
        this.query();
      }
      console.log(this.formData.page);
    },
    runPage() {
      (this.unchecked = false), (this.checked = false);
      this.checkedAll = false;
      this.checkedNames = [];
      let _this = this;

      if (this.formData.page <= this.pageNumber && this.formData.page >= 1) {
        _this.query();
      } else {
        _this.formData.page = 1;
        _this.query();
      }
    },
    downPage() {
      (this.unchecked = false), (this.checked = false);
      this.checkedAll = false;
      this.checkedNames = [];
      let _this = this;
      if (this.formData.page >= this.pageNumber) {
        _this.formData.page = this.pageNumber;
      } else {
        _this.formData.page += 1;
        this.query();
      }
      console.log(this.formData.page);
    },
  },
  beforeUnmount() {
    this.clear();
    //this.$root._tileset.unSelectFeature();
  },
};
</script>
<style scoped>
.pipepointquery {
  margin-top: 5px;
  padding: 0px 5px;
  text-align: center;
  line-height: 18px;
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  color: #ccc;
}

.condition {
  max-height: 250px;
  overflow-y: auto;
}

.condition>div {
  margin-top: 5px;
}

.operation {
  margin-top: 5px;
}

.tablePages {
  padding: 5px 5px;
  width: 100%;
  height: 100%;
}

.odline {
  width: 100px;
}

.tablePages input {
  vertical-align: middle;
}

.taNamepoint {
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
}

.taNamepoint>table {
  margin: 0 auto;
  text-align: center;
  cursor: default;
  border: 1px solid #383838;
  width: 100%;
}

.taNamepoint>table>tr>th {
  box-sizing: border-box;
  height: 30px;
  text-align: center;
  cursor: default;
  background-color: #383838;
  color: #ccc;
}

.taNamepoint>table>tr>th:nth-child(1) {
  min-width: 50px;
}

.taNamepoint>table>tr>td {
  box-sizing: border-box;
  height: 30px;
  max-height: 30px;
  line-height: 15px;
  text-align: center;
  cursor: default;
  background: #2e2e2e;
  border: 1px solid #383838;
  border-radius: 2px;
  color: #ccc;
}

.taNamepoint>table>tr:hover td {
  background-color: #288dfb;
  opacity: 0.5;
}

.row_single {
  max-width: 380px;
  display: flex;
  align-items: center;
}

.row_single>label {
  flex-shrink: 0;
  width: 30px;
}

.row_single>label:nth-child(1) {
  flex-shrink: 0;
  min-width: 75px;
}

.row_single>input {
  box-sizing: border-box;
  min-width: 10px;
  flex-grow: 1;
  background: #2e2e2e;
  border: 1px solid #383838;
  border-radius: 2px;
  color: #ccc;
  height: 30px;
  line-height: 30px;
  margin-right: 3px;
  outline: none;
}

.row_single>button {
  width: 100%;
}

.row_single>button:last-child {
  margin-left: 3px;
}

.upDown:hover {
  cursor: pointer;
  color: #288dfb;
}

.pagepoint {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-top: 5px;
  padding-left: 25px;
}

.pagepoint>div>span {
  text-align: center;
  margin-right: 5px;
}

.pagepoint>div>button {
  margin-right: 3px;
}

.pagepoint>div>span>input {
  text-align: center;
  width: 50px;
  height: 30px;
  background: #2e2e2e;
  border: 1px solid #383838;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  margin: 0 3px;
}

select {
  text-align: center;
  margin-right: 5px;
  width: 100px;
  height: 25px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  color: #ccc;
}
</style>

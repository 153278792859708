import * as zrender from 'zrender'
/* eslint-disable */
class DrawOneSection {
  constructor(line, x) {
    this._line = line
    this._line.distance = x
  }
  draw(offsetx, offsety, height, scalex, scaley, zr) {
    var x1 = (this._line.distance + offsetx) * scalex
    var x2 = (this._line.distance + this._line.length + offsetx) * scalex
    var y1 = height - (this._line.qidianheight + offsety) * scaley
    var y2 = height - (this._line.zhongdianheight + offsety) * scaley

    let line = new zrender.Line({
      id: this._line.qidian + "_" + this._line.zhongdian,
      style: {
        stroke: "#fff",
      },
      shape: {
        x1: x1,
        y1: y1,
        y2: y2,
        x2: x2,
      },
    });

    zr.add(line)
  }
  box() {
    return {
      minx: this._line.distance,
      miny: Math.min(this._line.qidianheight, this._line.zhongdianheight),
      maxx: this._line.distance + this._line.length,
      maxy: Math.max(this._line.qidianheight, this._line.zhongdianheight)
    }
  }
}

export default DrawOneSection

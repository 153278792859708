<template>
    <div class="modal-backdrop">
        <div class="modal" :style="{ width: width + 'px', height: height + 'px' }">
            <div class="delete-header" v-show="title !== ''">
                {{ title }}
            </div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Modal",
        props: {
            width: {
                type: Number,
                default: 200
            },
            height: {
                type: Number,
                default: 100
            },
            title: {
                type: String,
                default: ""
            }
        },
        data() {
            return {};
        },
        methods: {}
    };
</script>


<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20000;
    }

    .modal {
        background-color: var(--PanelBGColor);
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.4);
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        border-radius: 2px;
        color: #ccc;
    }

    .delete-header {
        height: 30px;
        line-height: 30px;
        background: var(--BackgroundColor);

    }
</style>
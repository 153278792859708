<template>
    <Window :title="'参数设置面板'" :footervisible="true" @ok="ok" @cancel="cancel" :width="1050" :height="719">

        <div class="parameter_content">
            <div>
                <div ref="cesiumContainer" class="cesiumContainer"></div>
            </div>
            <div>
                <div v-if="modeler == 'circle'">
                    <div class="right">
                        <label>造型器:</label>
                        <select v-model="modeler">
                            <option value="circle">圆管造型器</option>
                            <option value="rectangle">方管造型器</option>
                            <option value="ditch">管沟造型器</option>
                        </select>
                    </div>
                    <div class="right">
                        <label>横截面半径(m)</label><input type="number" step="0.01"
                            v-model.number="circle.profile.radius" />
                    </div>
                    <div class="right">
                        <label>横截面管壁厚(m)</label><input type="number" step="0.01"
                            v-model.number="circle.profile.thickness" />
                    </div>
                    <div class="right">
                        <label>法兰宽度(m)</label><input type="number" step="0.01" v-model.number="circle.flange.length" />
                    </div>
                    <div class="right">
                        <label>法兰厚度(m)</label><input type="number" step="0.01"
                            v-model.number="circle.flange.thickness" />
                    </div>
                    <div class="right">
                        <label>
                        </label>
                        <button @click="preview" class="preview">预览模型</button>
                    </div>
                    <div class="jsoncontent">
                        {{JSON.stringify(circle)}}
                    </div>
                </div>
                <div v-if="modeler == 'rectangle'">
                    <div class="right">
                        <label>造型器:</label>
                        <select v-model="modeler">
                            <option value="circle">圆管造型器</option>
                            <option value="rectangle">方管造型器</option>
                            <option value="ditch">管沟造型器</option>
                        </select>
                    </div>
                    <div class="right">
                        <label>横截面宽度(m)</label><input type="number" step="0.01"
                            v-model.number="rectangle.profile.width" />
                    </div>
                    <div class="right">
                        <label>横截面高度(m)</label><input type="number" step="0.01"
                            v-model.number="rectangle.profile.height" />
                    </div>
                    <div class="right">
                        <label>横截面管壁厚(m)</label><input type="number" step="0.01"
                            v-model.number="rectangle.profile.thickness" />
                    </div>
                    <div class="right">
                        <label>法兰宽度(m)</label><input type="number" step="0.01"
                            v-model.number="rectangle.flange.length" />
                    </div>
                    <div class="right">
                        <label>法兰厚度(m)</label><input type="number" step="0.01"
                            v-model.number="rectangle.flange.thickness" />
                    </div>
                    <div class="right">
                        <label>
                        </label>
                        <button @click="preview" class="preview">预览模型</button>
                    </div>
                    <div class="jsoncontent">
                        {{JSON.stringify(rectangle)}}
                    </div>
                </div>

                <div v-if="modeler == 'ditch'">
                    <div class="right">
                        <label>造型器:</label>
                        <select v-model="modeler">
                            <option value="circle">圆管造型器</option>
                            <option value="rectangle">方管造型器</option>
                            <option value="ditch">管沟造型器</option>
                        </select>
                    </div>
                    <div class="right">
                        <label>横截面宽度(m)</label><input type="number" step="0.01"
                            v-model.number="ditch.profile.width" />
                    </div>
                    <div class="right">
                        <label>横截面高度(m)</label><input type="number" step="0.01"
                            v-model.number="ditch.profile.height" />
                    </div>
                    <!-- <div class="right">
                        <label>横截面管壁厚(m)</label><input type="number" step="0.01"
                            v-model.number="rectangle.profile.thickness" />
                    </div> -->
                
                    <div class="right">
                        <label>
                        </label>
                        <button @click="preview" class="preview">预览模型</button>
                    </div>
                    <div class="jsoncontent">
                        {{JSON.stringify(rectangle)}}
                    </div>
                </div>

                <div v-if="modeler == 'jingparam'">
                    <div class="right">
                        <label>造型器:</label>
                        <select v-model="modeler">
                            <option value="circle">圆管造型器</option>
                            <option value="rectangle">方管造型器</option>
                            <option value="jingparam">井造型器</option>
                        </select>
                    </div>
                    <div class="right">
                        <label>井室底部高度</label><input type="number" step="0.01"
                            v-model.number="jingparam.param.param[0].bottom" />
                    </div>
                    <div class="right">
                        <label>井室底高度</label><input type="number" step="0.01"
                            v-model.number="jingparam.param.param[0].height" />
                    </div>
                    <div class="right">
                        <label>井室半径</label><input type="number" step="0.01"
                            v-model.number="jingparam.param.param[0].radius" />
                    </div>
                    <!-- <div class="right">
                        <label>收口底部高度</label><input type="number" disabled="true" step="0.01"
                            v-model.number="jingparam.param.param[1].bottom" />
                    </div> -->
                    <div class="right">
                        <label>收口高度</label><input type="number" step="0.01"
                            v-model.number="jingparam.param.param[1].height" />
                    </div>
                    <!-- <div class="right">
                        <label>收口底部半径</label><input type="number" step="0.01"
                            v-model.number="jingparam.param.param[1].bottom_radius" />
                    </div>
                    <div class="right">
                        <label>收口顶部半径</label><input type="number" step="0.01"
                            v-model.number="jingparam.param.param[1].top_radius" />
                    </div> -->
                    <!-- <div class="right">
                        <label>井筒底部高</label><input type="number" disabled="true" step="0.01"
                            v-model.number="jingparam.param.param[2].bottom" />
                    </div> -->
                    <div class="right">
                        <label>井筒高度</label><input type="number" step="0.01"
                            v-model.number="jingparam.param.param[2].height" />
                    </div>
                    <div class="right">
                        <label>井筒半径</label><input type="number" step="0.01"
                            v-model.number="jingparam.param.param[2].radius" />
                    </div>
                    <!-- <div class="right">
                        <label>井盖底部高</label><input type="number" disabled="true" step="0.01"
                            v-model.number="jingparam.param.param[3].bottom" />
                    </div> -->
                    <div class="right">
                        <label>井盖高度</label><input type="number" step="0.01"
                            v-model.number="jingparam.param.param[3].height" />
                    </div>
                    <div class="right">
                        <label>井盖半径</label><input type="number" step="0.01"
                            v-model.number="jingparam.param.param[3].radius" />
                    </div>
                    <div class="right">
                        <label>
                        </label>
                        <button @click="preview" class="preview">预览模型</button>
                    </div>
                    <div class="jsoncontent">
                        {{JSON.stringify(jingparam)}}
                    </div>
                </div>


            </div>


        </div>
    </Window>
</template>

<script>
    /* eslint-disable */
    import Window from "../../../components/Window";

    import {
        createViewer
    } from "../scripts/misc/createViewer";

    export default {
        components: {
            Window
        },
        data() {
            return {
                modeler: "circle",
                circle: {
                    type: "circle",
                    profile: {
                        radius: 0.5, //不能为0
                        thickness: 0.05
                    },
                    flange: {
                        length: 0.02,
                        thickness: 0.02
                    }
                },
                rectangle: {
                    type: "rectangle",
                    profile: {
                        width: 1, //不能为0
                        height: 0.5, //不能为0
                        thickness: 0.1
                    },
                    flange: {
                        length: 0.1,
                        thickness: 0.1
                    }
                },
                ditch: {
                    type: "ditch",
                    profile: {
                        width: 1, //不能为0
                        height: 0.5, //不能为0
                        thickness: 0.1
                    }
                },

            };
        },
        props: {
            getBind: Object,
            default () {
                {}
            },
            param: {
                type: Object,
                default: undefined
            }
        },
        mounted() {
            // 现在默认传过来的参数是一个对象(圆形)(后续看传入什么形式，如果是Json在parse一下)
            if (this.getBind) {
                if (this.getBind.type == "circle") {
                    this.modeler = "circle";
                    this.circle = this.getBind;
                } else if (this.getBind.type == "rectangle") {
                    this.rectangle = this.getBind;
                    this.modeler = "rectangle";
                } else if (this.getBind.type == "ditch") {
                    this.ditch = this.getBind;
                    this.modeler = "ditch";
                } else if (this.getBind.type == "jingparam") {
                    this.modeler = "jingparam"
                }
            }

            //初始化cesium
            this._viewer = createViewer(this.$refs.cesiumContainer);

            this.preview();
        },
        methods: {
            jingparamcontent() {
                this.jingparam.param.param[1].bottom = this.jingparam.param.param[0].bottom + this.jingparam.param
                    .param[0].height
                this.jingparam.param.param[2].bottom = this.jingparam.param.param[1].bottom + this.jingparam.param
                    .param[1].height
                this.jingparam.param.param[3].bottom = this.jingparam.param.param[2].bottom + this.jingparam.param
                    .param[2].height
                this.jingparam.param.param[1].bottom_radius = this.jingparam.param.param[0].radius
                this.jingparam.param.param[1].top_radius = this.jingparam.param.param[2].radius
            },
            preview() {
                var param;
                //深拷贝
                if (this.modeler == "circle") {
                    param = JSON.parse(JSON.stringify(this.circle));
                } else if (this.modeler == "rectangle") {
                    param = JSON.parse(JSON.stringify(this.rectangle));
                } else if (this.modeler == "ditch") {
                    param = JSON.parse(JSON.stringify(this.ditch));
                } else if (this.modeler == "jingparam") {
                    this.jingparamcontent()
                    param = JSON.parse(JSON.stringify(this.jingparam));

                }
                param.direction = [1, 0, 0];
                param.length = 1;
                param.distanceRatio = 10;

                this.loadGlb("./glb/preview?previewjson=" + JSON.stringify(param) + "&projectid=" + this.$root.projectid);
            },
            resetGlb() {
                if (this._model && this._viewer) {
                    this._viewer.scene.primitives.remove(this._model);
                    this._model = undefined;
                }
            },
            loadGlb(url) {
                this.resetGlb();
                fetch(url).then(response => {
                    response.arrayBuffer().then(arrayBuffer => {
                        // @ts-ignore
                        var parsedGltf = Cesium.parseGlb(new Uint8Array(arrayBuffer));
                        if (this._viewer) {
                            const hpr = new Cesium.HeadingPitchRoll(Math.PI * -0.5, 0, 0);
                            const origin = Cesium.Cartesian3.fromDegrees(116.39, 39.9, 100);
                            const modelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(
                                origin,
                                hpr
                            );
                            this._model = this._viewer.scene.primitives.add(
                                new Cesium.Model({
                                    gltf: parsedGltf,
                                    modelMatrix
                                })
                            );
                            if (!this._model) {
                                throw new Error(`!this._model`);
                            }
                            const viewer = this._viewer;
                            const camera = this._viewer.camera;
                            this._model.readyPromise.then(() => {
                                if (!this._model) {
                                    throw new Error(`!this._model`);
                                }

                                this._model.activeAnimations.addAll({
                                    multiplier: 1,
                                    loop: Cesium.ModelAnimationLoop.REPEAT
                                });

                                let radius = this._model.boundingSphere.radius;
                                if (radius < 10) {
                                    const scale = 10 / radius;
                                    this._model.scale = scale;
                                    radius = 10;
                                }

                                const controller = viewer.scene.screenSpaceCameraController;
                                controller.minimumZoomDistance = radius * 0.5;

                                const {
                                    heading,
                                    pitch
                                } = camera;
                                camera.frustum.near = radius * 0.01;
                                camera.lookAt(
                                    origin,
                                    new Cesium.HeadingPitchRange(heading, pitch, radius *
                                        2.0)
                                );
                            });
                        }
                    });
                });
            },
            checkLocal() {
                if (this.modelling == "圆形造型器") {
                    if (this.circle.profile.radius == 0) {
                        this.$root.$alert("横截面半径不能为0");
                    }
                } else if (this.modelling == "方形造型器") {
                    if (this.circle.profile.width == 0) {
                        this.$root.$alert("横截面宽度不能为0");
                    } else if (this.circle.profile.height == 0) {
                        this.$root.$alert("横截面高度不能为0");
                    }
                }
            },
            ok() {
                if (this.modeler == "circle") {
                    if (this.param && this.param.ok) {
                        this.param.ok(this.circle);
                        console.log("圆形参数", this.circle)
                    }
                } else if (this.modeler == "rectangle") {
                    if (this.param && this.param.ok) {
                        this.param.ok(this.rectangle);
                        console.log("方形参数", this.rectangle)
                    }
                } else if (this.modeler == "ditch") {
                    if (this.param && this.param.ok) {
                        this.param.ok(this.ditch);
                        console.log("管沟参数", this.ditch)
                    }
                } else if (this.modeler == "jingparam") {
                    if (this.param && this.param.ok) {
                        this.jingparamcontent()
                        this.param.ok(this.jingparam);
                        console.log("井参数", this.jingparam)
                    }
                }

                this.$root.destroyTool(this);
            },
            cancel() {
                this.$root.destroyTool(this);
            }
        },
        watch: {
            modeler: function () {
                this.preview();
            }
        }
    };
</script>

<style scoped>
    .cesiumContainer {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: #242424;
    }

    .parameters_top {
        display: flex;
        height: 28px;
        align-items: center;
        justify-content: center;
    }

    .parameters_top>div:nth-child(2) {
        display: flex;
        border: 1px solid #999595;
        flex-grow: 1;
        height: 24px;
        align-items: center;
    }

    .parameter_content {
        display: flex;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .parameter_content>div:nth-child(1) {
        width: 670px;
        flex-grow: 0;
        border-right: none;
        height: 100%;
    }

    .parameter_content>div:nth-child(2) {
        width: 300px;
        flex-grow: 1;
        height: 100%;
    }

    .right {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 0 auto;
        align-items: center;
        padding-top: 10px;
    }

    .right>select {
        width: 220px;
        height: 30px;
        background: #383838;
        border: 1px solid #4d4d4d;
        opacity: 1;
        border-radius: 2px;
        color: #ccc;
        text-align: center;

    }

    .right>label {
        width: 110px;
        text-align: center;
    }

    .right>input {
        width: 220px;
        height: 30px;
        line-height: 30px;
        background: #383838;
        border: 1px solid #4d4d4d;
        opacity: 1;
        border-radius: 2px;
        color: #ccc;
        text-align: center;
    }

    .preview {
        width: 220px;
        height: 30px;
        background: #383838;
        opacity: 1;
        border-radius: 2px;
        color: #ccc;
        text-align: center;
    }

    .jsoncontent {
        width: 100%;
        font-size: 16px;
        margin-top: 30px;
        word-wrap: break-word;

    }
</style>
<template>
    <div class="tree">
        <VirtualTreeItem v-for="(item, index) of items" :index="index" :item="item" :selected="selected"
            :key="(typeof (item.id) !== 'undefined') ? item.id : index" @on-toggle-expand="toggleExpand"
            @on-toggle-checked="toggleChecked" @on-toggle-selected="toggleSelected" @on-drop="onDrop"
            @on-context-menu="onContextMenu" @on-dblclick="onDblClick" @on-edited="onEdited"></VirtualTreeItem>
    </div>
</template>

<script>
    import VirtualTreeItem from "./VirtualTreeItem.vue";

    export default {
        components: {
            VirtualTreeItem
        },
        mounted() {
            this.updateData();
        },
        props: {
            tree: Array,
            name: {
                type: String,
                default: "newtree",
            },
        },
        data() {
            return {
                items: [],
                selected: {},
            };
        },
        methods: {
            //滚动定位到某个item
            scrollToItem(item) {
                //从items寻找item
                var idx = this.items.indexOf(item);
                if (idx >= 0) {
                    //有了序号，我们计算 滚动的高度
                    console.log("scrollToItem", idx);

                    this.$el.scrollTop = (idx - 1) * 27;
                }
            },
            //选择某个对象
            selectItem(item) {
                console.log("selectItem选择", item);
                this.selected = item;

                if (!item) return;

                //它的parent 会全部展开
                function expandParent(item) {
                    var p = item.parent;
                    if (p) {
                        p.expanded = true;
                        expandParent(p);
                    }
                }
                expandParent(item);

                //并且确保障碍物可见
                this.$nextTick(() => {
                    //控制滚动条
                    this.scrollToItem(item);
                });

                //发送对象选中事件
                this.$emit("on-toggle-selected", {
                    item: item,
                });
            },
            //根据路径从一个数组寻找对象
            getItemFromArray(array, path) {
                if (!array || !path || path === "") return;
                var sidx = path.indexOf("_");

                var ss = path;
                if (sidx > 0) ss = path.substr(0, sidx);

                var idx = parseInt(ss);
                if (idx < 0 || isNaN(idx) || idx >= array.length) return;

                if (sidx >= 0) path = path.substr(sidx + 1);
                else path = "";

                if (path === "") return array[idx];

                return this.getItemFromArray(array[idx].children, path);
            },
            //根据路径从tree中寻找对象
            getItemFromPath(path) {
                if (!path) return;
                if (!path.startsWith(this.name)) return;

                return this.getItemFromArray(
                    this.tree,
                    path.substr(this.name.length + 1)
                );
            },
            //获取item的树路径
            getItemPath(item) {
                var pArray = item.parent ? item.parent.children : this.tree;
                var idx = pArray.indexOf(item);
                if (!item.parent) return this.name + "_" + idx;
                return this.getItemPath(item.parent) + "_" + idx;
            },
            //拍平tree结构
            flattenJsonObject(jsonObjectArray, parent, level, items, parentExp) {
                jsonObjectArray.forEach((jsonObject) => {
                    jsonObject.level = level;
                    jsonObject.parent = parent;

                    if (parentExp) items.push(jsonObject);
                    //if (jsonObject.expanded && jsonObject.children)
                    if (jsonObject.children) {
                        this.flattenJsonObject(
                            jsonObject.children,
                            jsonObject,
                            level + 1,
                            items,
                            jsonObject.expanded && parentExp
                        );
                    }
                });
            },
            toggleItemChecked(item, c, options) {
                if (item.checked !== c) {
                    item.checked = c;

                    //options.item = item;
                    this.$emit("on-toggle-item-checked", {
                        $event: options.$event,
                        item: item,
                    });
                }

                if (item.children instanceof Array) {
                    item.children.forEach((it) => {
                        this.toggleItemChecked(it, c, options);
                    });
                }
            },
            toggleExpand(options) {
                options.item.expanded = !options.item.expanded;
                this.$emit("on-toggle-expand", options);
            },
            toggleChecked(options) {
                //发送整体切换的事件
                this.$emit("on-toggle-checked", options);
                this.toggleItemChecked(options.item, !options.item.checked, options);
            },
            toggleSelected(options) {
                this.selected = options.item;
                this.$emit("on-toggle-selected", options);
            },
            onContextMenu(options) {
                this.selected = options.item;
                console.log("on-context-menu右键", options);
                this.$emit("on-context-menu", options);
            },
            onDrop(options) {
                //console.log("on-drop", options);
                this.$emit("on-drop", options);
                var event = options.$event;
                if (event.dataTransfer.types.length < 2) return;

                var srcItem;
                event.dataTransfer.types.forEach((path) => {
                    if (!srcItem) {
                        srcItem = this.getItemFromPath(path);
                    }
                });

                //把itemPath 反解成item
                console.log(srcItem);
                this.$emit("on-item-drop", {
                    from: srcItem,
                    to: options.item,
                });
            },
            onDblClick(options) {
                //console.log("on-dblclick", options);
                this.$emit("on-dblclick", options);
            },
            onEdited(options) {
                this.$emit("on-edited", options);
            },
            updateData() {
                // console.log("是否更新了", this.tree)
                this.items.splice(0);
                this.flattenJsonObject(this.tree, undefined, 0, this.items, true);
            },
        },
        watch: {
            tree: {
                handler: function () {
                    this.updateData();
                },
                deep: true,
                immediate: true

            },
        },
    };
</script>

<style>
    .tree {
        overflow: auto;
        height: 100%;
        flex-grow: 1;
        max-width: 300px;
        color: #CCCCCC;
    }
</style>
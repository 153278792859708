<template>
  <div class="task">
    <div v-for="(item, index) in taskData" :key="index">
      <div class="day_time">
        {{ item.time }}
      </div>
      <div v-for="(el, i) in item.content" :key="i">
        <div class="task_title">
          <div class="start"
            :class="{ status_running: el.status == '运行中', status_finished: el.status == '已完成', status_waiting: el.status == '排队中', status_error: el.status == '错误' }">
            {{ el.create_date }}
          </div>
          <div>{{ el.name }}</div>
        </div>
        <div class="task_content">
          <div>结果:{{ el.status }}</div>
          <div v-show="el.start_date">开始时间:{{ el.start_date }}</div>
          <div v-show="el.percent">进度:{{ el.percent }}</div>
          <div v-show="el.end_date">结束时间:{{ el.end_date }}</div>
          <div v-show="el.end_date">持续时间:{{ el.keep_date }}</div>
          <button v-show="el.status == '运行中' || el.status == '排队中'" style="height:24px" @click="deleteTask(el)">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      taskData: [],
    }
  },
  created() {
    this.query()
  },
  mounted() {
    this.$bus.on('taskupdated', this.query);
  },
  beforeUnmount() {
    this.$bus.off('taskupdated', this.query);
  },
  methods: {
    deleteTask(item) {
      if(item.status == '运行中'){
        this.$root.$alert("运行中的任务无法直接中断运行，请取消之后重启服务！");
      }
      var data = {
        projectid: this.$root.projectid,
        id: item.id
      }
      this.$axios
        .delete("/task/cancel", {data})
        .then(res => {
          this.query();
        })
        .catch(err => {
          console.log(err)
        })
    },
    getTasksNumber(taskid) {
      let data = {
        user_phone: this.$root.phone,
        projectid: this.$root.projectid,
        taskid: taskid,
        showlog: false
      };
      this.$axios
        .get("/project/taskrunning", data)
        .then(res => {
          if (this.taskData[0].content[0].status == "已完成") {
            this.taskData[0].content[0].percent = undefined
            clearInterval(this.interval)
          }
          else if (res.data.data.length > 0) {
            this.taskData[0].content[0].percent = (res.data.data[0].percent * 100).toFixed(2)
            this.taskData[0].content[0].keep_date = this.computeTime(this.taskData[0].time + " " + this.taskData[0].content[0].start_date,
              Date.now())
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 计算持续时间
    computeTime(startTime, endTime) {
      if (startTime && endTime) {
        startTime = new Date(startTime).getTime()
        endTime = new Date(endTime).getTime()
        let days = Math.floor((endTime - startTime) / (1000 * 3600 * 24))
        let leave1 = (endTime - startTime) % (1000 * 3600 * 24)
        let hours = Math.floor(leave1 / (3600 * 1000))
        let leave2 = leave1 % (3600 * 1000)
        let minutes = Math.floor(leave2 / (60 * 1000))
        let leave3 = leave2 % (60 * 1000)
        let seconds = Math.floor(leave3 / 1000)
        if (seconds || minutes) {
          if (minutes) {
            if (days) {
              return days + "天" + hours + "时" + minutes + "分" + seconds + "秒"
            } else {
              return minutes + "分" + seconds + "秒"
            }
          } else {
            return seconds + "秒"
          }
        } else {
          return seconds + "秒"
        }

      }
      return ""

    },
    cancel() {
      this.$root.destroyTool(this);
    },
    ok() {
      this.$root.destroyTool(this);
    },
    query() {
      let data = {
        user_phone: this.$root.phone,
        projectid: this.$root.projectid,
      };
      this.$axios
        .post("/task/query", data)
        .then((res) => {
          if (res.data) {
            this.taskData = res.data.data.results
            // 分组
            let j = 0;
            let arr = []
            for (let i = 0; i < this.taskData.length; i++) {
              if (i == 0) {
                arr.push({
                  time: moment(this.taskData[i].create_date).format("YYYY-MM-DD"),
                  content: []
                })
                if (this.taskData[i].type == 'saveClt' && this.taskData[i].status == "running") {
                  this.getTasksNumber(this.taskData[i].id)
                  this.interval = setInterval(() => {
                    this.getTasksNumber()
                  }, 5000)
                }
              } else {
                if (moment(this.taskData[i].create_date).format("YYYY-MM-DD") != arr[j].time) {
                  j = j + 1;
                  arr[j] = {
                    time: moment(this.taskData[i].create_date).format("YYYY-MM-DD"),
                    content: []
                  }
                }
              }
              arr[j].content.push({
                id: this.taskData[i].id,
                name: this.taskData[i].name,
                result: this.taskData[i].result,
                status: this.taskData[i].status == 'waiting' ? '排队中' : this.taskData[i]
                  .status == 'running' ? '运行中' : this.taskData[i].status ==
                    'finished' ? '已完成' : "错误",
                create_date: moment(this.taskData[i].create_date).format(
                  "HH:mm"),
                start_date: this.taskData[i].start_date ? moment(this.taskData[i]
                  .start_date).format(
                    "HH:mm:ss") : "",
                end_date: this.taskData[i].end_date ? (moment(this.taskData[i]
                  .end_date).format(
                    "YYYY-MM-DD") == arr[j].time ? moment(this.taskData[i]
                      .end_date).format(
                        "HH:mm:ss") : this.taskData[i]
                  .end_date) : "",
                keep_date: (this.taskData[i].start_date && this.taskData[i]
                  .end_date) ? this.computeTime(this.taskData[i].start_date,
                    this.taskData[i].end_date) : ""

              })
            }
            this.taskData = arr
          }
        })
        .catch((err) => {
          this.$root.$log("error", err)
          console.log(err.response || err);
        });
    }
  }
}
</script>

<style scoped>
.task {
  width: 300px;
  height: 100%;
  overflow: auto;
  background: var(--PanelBGColor);
}

.start {
  width: 32px;
  height: 32px;
  line-height: 32px;
  color: #ccc;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.16);
  font-weight: 400;
  border-radius: 50%;
  opacity: 1;
  font-size: 12px;
  margin-right: 10px;
  text-align: center;
}

.task>div {
  opacity: 1;
  margin-left: 7px;
}

.task>div:last-child {
  margin-bottom: 20px;
}

.task_title {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.task_content {
  height: 90px;
  margin-left: 16px;
  border-left: 1px solid #cccccc;
  padding-left: 15px;
  font-size: 12px;
}

.day_time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #d7d7d7;
  opacity: 1;
}

.status_running {
  background: #88d601;
}

.status_finished {
  background: #288dfb;
}

.status_waiting {
  background: #c17e31;
}

.status_error {
  background: #c13147;
}
</style>

<template>
  <Window
    :title="'导入管线'"
    :footervisible="false"
    :width="1260"
    :height="600"
    @cancel="cancel"
  >
    <div class="maincontent">
      <div class="line_data">
        <InputFile
          @srsChanged="srsChanged"
          @fieldsChanged="lineFieldsChanged"
          ref="line_file"
        >
        </InputFile>
      </div>
      <div class="srs_data">
        空间参考
        <textarea class="srs" v-model="srs"></textarea>
      </div>
      <div class="page_config">
        <div class="config_left">
          <div class="operation">
            <label>生成管点</label>
            <input class="bty-switch" type="checkbox" v-model="generateid" />

            <label class="tishi">注:若不存在对应的管点，则开启此选项</label>
            <label></label>
            <label></label>

            <label>打断折线</label>
            <input
              class="bty-switch"
              type="checkbox"
              v-model="split"
              :disabled="generateid == false"
            />
            <label class="tishi2">注:当为折线时，打断为多段，否则不处理</label>

            <label>使用Z值</label>
            <input
              class="bty-switch"
              type="checkbox"
              v-model="withZ"
              :disabled="generateid == false"
            />

            <label>相邻阈值</label>
            <div>
              <input
                type="text"
                v-model.number="threshold"
                :disabled="generateid == false"
              />
            </div>
          </div>
        </div>

        <div class="config_center">
          <div class="config_field">
            <div v-show="config == 'line'">
              <FieldCode
                ref="line_config"
                @changeField="changeField"
                @codeChanged="pointFieldChanged"
                @fieldFocused="pointFieldFocused"
              ></FieldCode>
            </div>
          </div>
        </div>

        <div class="config_right">
          <div class="code" ref="code"></div>
        </div>
      </div>
      <div class="options">
        <button @click="Selectfile">导入代码</button>
        <button @click="Exportcode">导出代码</button>

        <button @click="startImport">开始导入</button>
      </div>
      <input
        type="file"
        ref="refFile"
        v-on:change="Importcode"
        style="display: none"
        accept=".js"
      />
    </div>
  </Window>
</template>

<script>
import Window from "../../../components/Window";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api.js";
import "monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution";
//import * as monaco from 'monaco-editor'
import InputFile from "./import/InputFile";
import FieldCode from "./import/FieldCode";

// function importPoint(input, context) {
//   //根据输入数据的空间参考，把坐标转成wgs84经纬度坐标
//   var point = context.xbsj_projectToWGS84(
//     input["geometry"].coordinates[0],
//     input["geometry"].coordinates[1],
//     input["高度"]
//   );

//   return {
//     id: input["id"],
//     //存入数据的经纬度
//     geom: [point.lon, point.lat, point.height],
//     dimiangaocheng: input["地面高程"],
//     fushuwu: input["附属物"],
//     fushuwu_j: {
//       //附属的偏移矩阵,默认是单位矩阵
//       matrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
//       //根据附属物名称，查询附属物配置
//       define: context.xbsj_getFushuwuByName(input["附属物"]),
//       //附属物的构造参数
//       param: {}
//     }
//   };
// }
function importLine(input, context) {
  //根据输入输入数据 构造管线造型参数
  var param = {
    type: "circle",
    profile: {
      radius: input["管径"] * 0.5,
      thickness: input["管壁厚"],
    },
    flange: {
      length: 0.01,
      thickness: 0.01,
    },
  };
  return {
    qidian: input["起点"],
    zhongdian: input["终点"],
    param_j: param,
    guanjing: param.profile.radius * 2,
    guanbihou: param.profile.thickness,
    duanmian: (param.profile.radius * 2).toFixed(2),
    caizhi: input["材质"],
    tuceng: input["图层"],
    //根据材质名称，从数据库里寻找材质配置
    caizhi_j: context.xbsj_getCaizhiByName(input["材质"]),
  };
}

export default {
  components: {
    Window,
    InputFile,
    FieldCode,
  },
  data() {
    return {
      page: "data",
      config: "line",
      srs: "",
      generateid: false,
      withZ: false,
      split: true,
      threshold: 0.3,
    };
  },
  mounted() {
    this.initEditor();
    let self = this;
    this.$axios
      .get("/project/line/importcode", {
        params: {
          projectid: this.$root.projectid,
        },
      })
      .then((res) => {
        var code = "";
        var line_code = JSON.parse(res.data.data.line_code);
        if (line_code.code) {
          code = line_code.code;
        } else {
          code += importLine.toString();
        }
        self.generateid = line_code.generateid;
        self.withZ = line_code.withZ;
        self.split = line_code.split;
        self.threshold = line_code.threshold;
        this._editor.setValue(code);
        this.setPointFields();
        this.setLineFields();
      })
      .catch((err) => {
        console.log(err.response || err);
      });
  },
  watch: {
    generateid() {
      if (!this.generateid) {
        this.withZ = false;
        this.split = true;
      }
    },
  },
  methods: {
    Selectfile() {
      this.$refs.refFile.click();
    },
    Importcode() {
      let selectedFile = null;
      selectedFile = this.$refs.refFile.files[0];
      if (selectedFile === undefined) {
        return;
      }
      var reader = new FileReader();
      reader.readAsText(selectedFile);
      reader.onload = () => {
        this._editor.setValue(reader.result);
      };
    },
    Exportcode() {
      var bin = this._editor.getValue();
      var mimeType = "text/javascript,charset=UTF-8";
      var name = "js导出代码";
      bin = "\ufeff" + bin; //防止乱码,不能删除
      const blob = new Blob([bin], {
        type: mimeType,
      }); //bin是数据，type是MIME类型
      let objectUrl = URL.createObjectURL(blob); //用来将blob或file读取成一个url

      var a = document.createElement("a"); //动态创建一个a标签
      var event = new MouseEvent("click");
      a.download = name; // 把a链接的download属性设置下，该属性值就是我们的下载文件的文件名
      a.href = objectUrl; //设置下载URL
      a.dispatchEvent(event); //点击触发下载
      URL.revokeObjectURL(objectUrl); //下载完成后释放
    },
    // pointFieldsChanged(fields) {
    //     this.$refs.point_config.setInputFields(fields);
    // },
    lineFieldsChanged(fields) {
      this.$refs.line_config.setInputFields(fields);
    },
    srsChanged(srs) {
      console.log(srs);
      this.srs = srs;
    },
    initEditor() {
      this._editor = monaco.editor.create(this.$refs.code, {
        // value: importPoint.toString(),
        value: "",
        language: "javascript",
        theme: "vs-dark",
        // 自适应layout
        automaticLayout: true,
        // 右侧预览
        minimap: {
          enabled: false,
        },
        wordWrap: "on",
      });
      window.editor = this._editor;
    },
    cancel() {
      this.$root.destroyTool(this);
    },
    switchPage(page) {
      this.page = page;
    },
    setPointFields() {
      var fields = [
        {
          name: "ID",
          inner: "id",
          type: "string",
          code: "input['id']",
        },
        {
          name: "X",
          type: "number",
          code: "input['geometry'].coordinates[0]",
        },
        {
          name: "Y",
          type: "number",
          code: "input['geometry'].coordinates[1]",
        },
        {
          name: "Z",
          type: "number",
          code: "0",
        },
        {
          name: "地面高程",
          inner: "dimiangaocheng",
          type: "number",
          unit: "米",
          code: "0",
        },
        {
          name: "附属物",
          inner: "fushuwu",
          type: "string",
          code: "undefined",
        },
      ];
      var data = {
        projectid: this.$root.projectid,
      };
      this.$axios
        .get("/project/point/column/query", {
          params: data,
        })
        .then((res) => {
          console.log("全部数据", res);

          if (res.data.data.length > 10) {
            for (var a = 10; a < res.data.data.length; a++) {
              var one = {};
              one.name = res.data.data[a].column_name;
              one.inner = res.data.data[a].column_name;
              one.type = res.data.data[a].column_datatype;
              one.code = "undefined";
              one.default = "undefined";
              fields.push(one);
            }
          }
          // this.$refs.point_config.setFields(fields);
          // fields.forEach(f => {
          //   this.pointFieldChanged(f);
          // });
        })
        .catch((err) => {
          console.log(err.response || err);
        });
    },
    setLineFields() {
      var fields = [
        {
          name: "起点",
          inner: "qidian",
          type: "string",
          code: "",
        },
        {
          name: "终点",
          inner: "zhongdian",
          type: "string",
          code: "",
        },
        // {
        //     name: "管径",
        //     type: "number",
        //     code: "1",
        //     unit: "米"
        // },
        // {
        //     name: "管壁厚",
        //     type: "number",
        //     code: "0.01",
        //     unit: "米"
        // },
        {
          name: "材质",
          inner: "caizhi",
          type: "string",
          code: "",
        },
        {
          name: "图层",
          inner: "tuceng",
          type: "string",
          code: "",
        },
      ];

      //其他字段需要请求数据库获得
      var data = {
        projectid: this.$root.projectid,
      };
      this.$axios
        .get("/project/line/column/query", {
          params: data,
        })
        .then((res) => {
          if (res.data.data.length > 12) {
            for (var a = 12; a < res.data.data.length; a++) {
              var one = {};
              one.name = res.data.data[a].column_name;
              one.inner = res.data.data[a].column_name;
              one.type = res.data.data[a].column_datatype;
              one.code = "input['" + one.name + "']";
              one.default = "undefined";
              // fields.push(one);
            }
          }
          this.$refs.line_config.setFields(fields);
          // fields.forEach(f => {
          //     this.lineFieldChanged(f);
          // });
        })
        .catch((err) => {
          console.log(err.response || err);
        });
    },

    //处理固定值输入框
    pointFieldChanged(field) {
      if (!field) return;
      //console.log(field);
      //选中代码里对应的
      // 判断importPoint(**)开始的地方
      var code = this._editor.getValue();
      var func = code.indexOf("importLine");
      if (func < 0) return;

      function replace(str, sidx, eidx, newstr) {
        return str.substring(0, sidx + 1) + newstr + str.substring(eidx);
      }

      //特殊处理的几个
      if (field.name === "起点") {
        let pfunc = code.indexOf("return", func);
        let sidx = code.indexOf(field.inner, pfunc);
        sidx = code.indexOf(":", sidx);
        let eidx = code.indexOf(",", sidx);
        code = replace(code, sidx, eidx, '"' + String(field.code) + '"');
        this._editor.setValue(code);
      } else if (field.name === "终点") {
        let pfunc = code.indexOf("return", func);
        let sidx = code.indexOf(field.inner, pfunc);
        sidx = code.indexOf(":", sidx);
        let eidx = code.indexOf(",", sidx);
        code = replace(code, sidx, eidx, '"' + String(field.code) + '"');
        this._editor.setValue(code);
      } else if (field.name === "材质") {
        let pfunc1 = code.indexOf("return", func);
        let sidx1 = code.indexOf(field.inner, pfunc1);
        sidx1 = code.indexOf(":", sidx1);
        let eidx1 = code.indexOf(",", sidx1);
        code = replace(code, sidx1, eidx1, '"' + String(field.code) + '"');
        this._editor.setValue(code);

        //第二个材质
        let pfunc = code.indexOf("xbsj_getCaizhiByName", func);
        let sidx = code.indexOf("(", pfunc);
        let eidx = code.indexOf(")", sidx);
        code = replace(code, sidx, eidx, '"' + String(field.code) + '"');
        this._editor.setValue(code);
      } else if (field.name === "图层") {
        let pfunc = code.indexOf("return", func);
        let sidx = code.indexOf(field.inner, pfunc);
        sidx = code.indexOf(":", sidx);
        let eidx = code.indexOf(",", sidx);
        code = replace(code, sidx, eidx, '"' + String(field.code) + '"');
        this._editor.setValue(code);
      }
    },

    //处理字段下拉框选择
    changeField(val) {
      if (!val) return;
      var code = this._editor.getValue();
      var func = code.indexOf("importLine");
      if (func < 0) return;
      function replace(str, sidx, eidx, newstr) {
        return str.substring(0, sidx + 1) + newstr + str.substring(eidx);
      }
      if (val.name === "起点") {
        let pfunc = code.indexOf("return", func);
        let sidx = code.indexOf(val.inner, pfunc);
        sidx = code.indexOf(":", sidx);
        let eidx = code.indexOf(",", sidx);
        code = replace(code, sidx, eidx, val.code + "+\"\"");
        this._editor.setValue(code);
      } else if (val.name === "终点") {
        let pfunc = code.indexOf("return", func);
        let sidx = code.indexOf(val.inner, pfunc);
        sidx = code.indexOf(":", sidx);
        let eidx = code.indexOf(",", sidx);
        code = replace(code, sidx, eidx, val.code + "+\"\"");
        this._editor.setValue(code);
      } else if (val.name === "材质") {
        let pfunc1 = code.indexOf("return", func);
        let sidx1 = code.indexOf(val.inner, pfunc1);
        sidx1 = code.indexOf(":", sidx1);
        let eidx1 = code.indexOf(",", sidx1);
        code = replace(code, sidx1, eidx1, val.code + "+\"\"");
        this._editor.setValue(code);

        //第二个材质
        let pfunc = code.indexOf("xbsj_getCaizhiByName", func);
        let sidx = code.indexOf("(", pfunc);
        let eidx = code.indexOf(")", sidx);
        code = replace(code, sidx, eidx, val.code + "+\"\"");
        this._editor.setValue(code);
      } else if (val.name === "图层") {
        let pfunc = code.indexOf("return", func);
        let sidx = code.indexOf(val.inner, pfunc);
        sidx = code.indexOf(":", sidx);
        let eidx = code.indexOf(",", sidx);
        code = replace(code, sidx, eidx, val.code + "+\"\"");
        this._editor.setValue(code);
      }
    },

    lineFieldChanged(field) {
      if (!field) return;
      //console.log(field);
      //选中代码里对应的
      // 判断importPoint(**)开始的地方
      var code = this._editor.getValue();
      var func = code.indexOf("importLine");
      if (func < 0) return;

      function replace(str, sidx, eidx, newstr) {
        return str.substring(0, sidx + 1) + newstr + str.substring(eidx);
      }

      //特殊处理的几个
      if (field.name == "管径") {
        let pfunc = code.indexOf("radius", func);
        let sidx = code.indexOf(":", pfunc);
        let eidx = code.indexOf(",", sidx);
        code = replace(code, sidx, eidx, field.code + "*0.5");
        this._editor.setValue(code);
      } else if (field.name == "管壁厚") {
        let pfunc = code.indexOf("thickness", func);

        let sidx = code.indexOf(":", pfunc);
        let eidx = code.indexOf("\n", sidx);
        code = replace(code, sidx, eidx, field.code);
        this._editor.setValue(code);
      } else if (field.name == "材质") {
        let pfunc = code.indexOf("xbsj_getCaizhiByName", func);
        let sidx = code.indexOf("(", pfunc);
        let eidx = code.indexOf(")", sidx);
        code = replace(code, sidx, eidx, field.code);
        this._editor.setValue(code);
      }

      //其他字段
      // if (field.inner) {
      //     console.log("线")
      //     let pfunc = code.indexOf("return", func);
      //     let sidx = code.indexOf(field.inner, pfunc);
      //     sidx = code.indexOf(":", sidx);
      //     let eidx = code.indexOf(",", sidx);
      //     code = replace(code, sidx, eidx, field.code);
      //     this._editor.setValue(code);
      // }
      if (field.inner) {
        console.log("线");
        let pfunc = code.indexOf("return", func);
        let sidx = code.indexOf(field.inner, pfunc);
        if (sidx < 0) {
          let eidx = code.indexOf(
            "    //根据材质名称，从数据库里寻找材质配置",
            pfunc
          );
          code = replace(
            code,
            eidx - 1,
            eidx,
            "    " + field.name + ":" + field.code + ",\n"
          );
          this._editor.setValue(code);
        } else {
          sidx = code.indexOf(":", sidx);
          let eidx = code.indexOf(",", sidx);
          code = replace(code, sidx, eidx, field.code);
          this._editor.setValue(code);
        }
      }
    },
    pointFieldFocused() {},
    lineFieldFocused() {},
    transCode(code) {
      //1,寻找
      let line = code.match(/function\s*?importLine/);
      if (line == null) throw "importLine函数未定义";
      //       let point = code.match(/function\s*?importPoint/);
      // if (point == null) throw "importPoint函数未定义";
      // code = code.replace(/function\s*?importPoint/, ",importPoint");
      code = code.replace(/function\s*?importLine/, ",importLine");
      code = "({a:0" + code + "})";
      return code;
    },
    startImport() {
      console.log(this.$root.projectid);
      //1, 获取线路径
      var data = {
        linepath: this.$refs.line_file.fakepath,
        // pointpath: this.$refs.point_file.fakepath,
        generateid: true,
        projectid: this.$root.projectid,
        code: this._editor.getValue(),
        srs: this.srs,
      };
      console.log(data);
      //检测参数完整性
      if (data.linepath == "") {
        this.$root.$alert("请上传线数据");
        return;
      }

      if (data.srs == "") {
        this.$root.$alert("请设置空间参考");
        return;
      }
      if (data.code == "") {
        this.$root.$alert("请设置代码");
        return;
      }
      //运行代码定义函数
      var mappingObject;
      try {
        var code = this.transCode(data.code);
        mappingObject = eval(code);
      } catch (ex) {
        this.$root.$alert("代码有误", ex);
        return;
      }
      if (!mappingObject) return;
      console.log(mappingObject);

      var context = {
        xbsj_projectToWGS84(x, y, z) {
          //检测x,y,z 都是数字
          if (
            typeof x != "number" ||
            typeof y != "number" ||
            typeof z != "number"
          ) {
            throw "x,y,z字段需要是数字";
          }
          return {
            lon: 0,
            lat: 0,
            height: 0,
          };
        },
        xbsj_getFushuwuByName(fushuwu) {
          console.log(fushuwu);
        },
        xbsj_getCaizhiByName(caizhi) {
          console.log(caizhi);
        },
      };

      // //如果点数据有效，才检测点数据
      // if (data.pointpath != "") {
      //     //从点里获取三组数据
      //     var points = this.$refs.point_file.getRecords();
      //     //检测所有点
      //     try {
      //         points.forEach((point) => {
      //             var p = mappingObject.importPoint(point, context);

      //             //确保关键
      //             //1,id 必须是字符串而且不为空
      //             if (typeof p.id != "string") {
      //                 throw "点id字段必须是字符串";
      //             }
      //             if (p.id.length == 0) {
      //                 throw "点id字段不能为空字符";
      //             }
      //             //2,geom 必须是数字数组
      //             if (typeof p.geom != "object" || p.geom.length != 3) {
      //                 throw "点geom字段必须为数字数组";
      //             }
      //             for (var i = 0; i < p.geom.length; i++) {
      //                 if (typeof p.geom[i] != "number")
      //                     throw "点geom字段必须为数字数组";
      //             }
      //             //3,dimiangaocheng 必须是数字
      //             if (typeof p.dimiangaocheng != "number")
      //                 throw "点dimiangaocheng字段必须为数字";
      //             //4,fushuwu_j 必须是对象
      //             if (typeof p.fushuwu_j != "object") {
      //                 throw "点fushuwu_j字段必须为对象";
      //             }
      //         });
      //     } catch (ex) {
      //         this.$root.$alert("代码配置代码有误:" + ex);
      //         return;
      //     }
      // }

      //检测所有线
      var lines = this.$refs.line_file.getRecords();
      try {
        lines.forEach((l) => {
          var p = mappingObject.importLine(l, context);

          //确保关键
          //起点和终点可能不存在，暂不用该部分
          // //1,qidian 必须是字符串而且不为空
          // if (typeof p.qidian != "string") {
          //     throw "线qidian字段必须是字符串";
          // }
          // if (p.qidian.length == 0) {
          //     throw "线qidian字段不能为空字符";
          // }
          // //2,zhongdian 必须是字符串而且不为空
          // if (typeof p.zhongdian != "string") {
          //     throw "线zhongdian字段必须是字符串";
          // }
          // if (p.zhongdian.length == 0) {
          //     throw "线zhongdian字段不能为空字符";
          // }

          //3,param_j 必须是对象
          if (typeof p.param_j != "object") {
            throw "线param_j字段必须为对象";
          }
          //4, guanjing 必须是数字
          if (typeof p.guanjing != "number") {
            throw "线guanjing字段必须为数字";
          }
          //5, guanbihou 必须是数字
          if (typeof p.guanbihou != "number") {
            throw "线guanbihou字段必须为数字";
          }
        });
      } catch (ex) {
        this.$root.$alert("代码配置代码有误:" + ex);
        return;
      }
      data.generateid = this.generateid;
      if (this.generateid) {
        data.withZ = this.withZ;
        data.split = this.split;
        data.threshold = this.threshold;
      }
      this.$axios
        .post("/project/line/import", data)
        .then((res) => {
          if (res.status == 202) {
            this.close();
            this.$root.$alert(
              `总计${res.data.data.total}条记录，共生成${res.data.data.success}条管线。稍后后台会开始三维数据创建过程，请在任务查询里查看数据处理进度`
            );
          }
        })
        .catch((err) => {
          if (err === "timeout") {
            this.$root.$alert("数据导入耗时较长，请稍候刷新页面");
          } else {
            this.$root.$alert(
              "数据导入失败:" + err.data.message || err.response
            );
          }
        });
    },
    close() {
      this.$root.destroyTool(this);
    },
  },
  beforeUnmount() {
    //销毁
  },
};
</script>

<style scoped>
.page_config {
  display: flex;
  flex-direction: row;
  justify-items: stretch;
  height: 100%;
  justify-content: stretch;
}

.config_left {
  flex-grow: 0;
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-items: stretch;
  justify-content: stretch;
  border: 1px solid #d3d3d3;
  border-right: none;
}

.config_tab {
  flex-grow: 0;
  width: 50px;
  display: flex;
  flex-direction: column;
}

.config_field {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  /* border-left: 1px solid #d3d3d3; */
  /* margin-top: 40px;
  padding-right: 30px; */
  /* overflow-y: auto; */
  display: grid;
  align-content: center;
}

/* .config_field > div:nth-child(1) {
  height: 70%;
  overflow: auto;
} */

.config_center {
  flex-grow: 0;
  width: 360px;
  /* height: 100%; */
  border: solid 1px #ccc;
}
.config_right {
  flex-grow: 2;
  border: 1px solid #d3d3d3;
  width: calc(50% - 300px);
}

textarea {
  background: #242424;
  color: #cccccc;
}

.maincontent {
  background: #242424;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
}

.tab {
  height: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-items: stretch;
  margin-bottom: 2px;
  align-items: stretch;
}

/* .page {
  flex-grow: 1;
  padding: 0px;
  height: calc(100% - 70px);
}

.page_data {
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  height: 100%;
}

.point_data {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  margin-bottom: 2px;
  color: #ccc;
} 
.line_data {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  color: #ccc;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  margin-bottom: 2px;
}
*/
.line_data {
  border: 1px solid #d3d3d3;
  min-height: 150px;
}

.srs_data {
  flex-grow: 0;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  background: #242424;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  margin-bottom: 2px;
  color: #ccc;
  font-size: 14px;
}

.srs {
  width: 100%;
  flex-grow: 1;
  border: none;
}

.tabbutton {
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
}

.tabbutton.checked {
  background: #288dfb;
}

.tabbutton::before {
  display: inline-block;
  content: "";
  height: 100%;
  vertical-align: middle;
}

.options {
  display: flex;
  width: 100%;
  text-align: right;
  height: 40px;
  flex-grow: 0;
}

.options > button {
  height: 33px;
  width: 100%;
  margin-top: 10px;
}

.code {
  width: 100%;
  height: 100%;
}

.operation {
  /* box-sizing: border-box;
  padding-left: 90px; */
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-content: center;
  width: 100%;
  justify-items: center;
  align-items: center;
  grid-row-gap: 6.5px;
}

.operation > div > label {
  display: inline-block;
  width: 120px;
}

.operation > div:last-child > input {
  width: 65px;
  box-sizing: border-box;
  text-align: center;
  height: 30px;
  line-height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
}

.tishi {
  grid-column: 1 / span 2;
  grid-row: 2;
  font-size: 12px;
  color: rgb(143, 140, 140);
  padding-left: 22px;
}

.tishi2 {
  grid-column: 1 / span 2;
  grid-row: 5;
  font-size: 12px;
  color: rgb(143, 140, 140);
  padding-left: 22px;
}
</style>

<template>
    <div id="app">
        <div class="header">
            <Header></Header>
        </div>
        <div class="content">
            <Left></Left>
            <div class="middle">
                <div class="middle_earth">
                    <Earth></Earth>
                </div>
                <div class="middle_bottom">
                    <Bottom ref="bottom"></Bottom>
                </div>
            </div>
            <Right ref="right"></Right>
        </div>

        <component v-for="(tool, index) in tools" :is="tool.component" :ref="tool.ref" :_toolIndex="index"
            :key="_getToolID(tool)" :getBind="tool.item" :param="tool.param"></component>
        <DeleteWindow ref="confirm" :title="'确认'" :width="300" :height="140" v-show="confirmContent != ''">
            <div class="confirm">{{ confirmContent }}</div>
            <div class="confirm_footer">
                <button @click="confirm_cancel">取消</button>
                <button @click="confirm_ok">确定</button>
            </div>
        </DeleteWindow>
        <Alert ref="alert" :width="350" :height="100" v-show="alertContent != ''">
            <div class="alert_content" v-html="alertContent"></div>
            <div class="alert_footer">
                <button @click="alert_ok">确定</button>
            </div>
        </Alert>
        <Loader :isShowLoader="this.$store.state.loadingShow"></Loader>
        <ContextMenu></ContextMenu>
    </div>
</template>

<script>
    /* eslint-disable */
    import Header from "./EditorHeader";
    import Right from "./EditorRight";
    import Left from "./EditorLeft";
    import DeleteWindow from "../../components/DeleteWindow";
    import Utils from "./math/utils";
    import ContextMenu from "../../components/ContextMenu/Index";
    import Alert from "../../components/Alert";
    import Loader from "../../../src/components/Loader";
    import Earth from "./components/Earth";
    import Bottom from "./EditorBottom";
    export default {
        name: "App",
        components: {
            Header,
            Right,
            Left,
            DeleteWindow,
            ContextMenu,
            Alert,
            Loader,
            Earth,
            Bottom
        },
        data() {
            return {
                confirmContent: "",
                alertContent: "",
                logContent: [],
                tools: [],
                isShowLoader: false,
                t: Date.now()
            };
        },
        created() {
            var params = Utils.queryToObject(window.location.search.substring(1));
            if (!params.projectid) {
                window.location.href = "/user.html";
            } else {
                this.projectid = params.projectid;
            }
        },
        mounted() {
            console.log("地球数据", this.$root._earth);
            this.queryInformation();
            this.getLastTask();
        },
        methods: {
            getLastTask() {
                setTimeout(() => {
                    this.$axios
                        .get("/project/taskreport", {
                            params: {
                                projectid: this.$root.projectid,
                                t: this.t
                            },
                            timeout: 0,
                            showlog: false
                        })
                        .then(res => {
                            this.$root._tileset.reload();
                            this.t = Date.now();
                            this.getLastTask();
                            //发送任务更新事件
                            this.$bus.emit('taskupdated');
                        })
                        .catch((err) => {
                            if (err.data == '项目不存在') {
                                this.$root.$alert(err.data);
                            } else {
                                this.getLastTask();
                            }
                        });
                }, 100);
            },
            //显示对象的属性窗口
            showPropertyWindow(czmObject, options, elementItem) {
                var component;
                if (
                    options &&
                    (typeof options.component == "string" ||
                        typeof options.component == "object")
                ) {
                    component = options.component;
                }
                var guid = czmObject;
                //新建窗口
                this.tools.push({
                    component: component,
                    ref: guid,
                    guid: guid,
                    item: elementItem,
                    param: options.param
                });
            },
            destroyTool(tool) {
                const index = tool.$attrs._toolIndex;
                if (index !== -1) {
                    this.tools.splice(index, 1);
                }
            },

            _getToolID(tool) {
                if (!tool.guid) {
                    tool.guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                        /[xy]/g,
                        function (c) {
                            var r = (Math.random() * 16) | 0;
                            var v = c === "x" ? r : (r & 0x3) | 0x8;
                            return v.toString(16);
                        }
                    );
                }
                return tool.guid;
            },
            $confirm(info, ok, cancel) {
                this.confirmContent = info;
                this._confirm_ok = ok;
                this._confirm_cancel = cancel;
            },
            confirm_ok() {
                this.confirmContent = "";
                if (this._confirm_ok) {
                    this._confirm_ok();
                }
            },
            confirm_cancel() {
                this.confirmContent = "";
                if (this._confirm_cancel) {
                    this._confirm_cancel();
                }
            },
            $alert(info) {
                this.alertContent = info;
            },
            alert_ok() {
                this.alertContent = "";
            },
            $log(type, content) {
                if (type && content) {
                    this.logContent.push({
                        type: type,
                        time: new Date().toLocaleString(),
                        content: content
                    });
                    this.$refs.bottom.logContent = this.logContent;
                }
            },
            $createLabel(content) {
                return this._tileset.createLabel(content);
            },
            $getLengthOfLine(line) {
                if (line.positions.length < 2) {
                    return 0;
                }
                var sumlen = 0;
                var p1 = line.positions[0];
                for (var i = 1; i < line.positions.length; i++) {
                    var p2 = line.positions[i];
                    let c1 = Cesium.Cartesian3.fromRadians(p1[0], p1[1], p1[2]);
                    let c2 = Cesium.Cartesian3.fromRadians(p2[0], p2[1], p2[2]);
                    sumlen += Cesium.Cartesian3.distance(c1, c2);
                    p1 = p2;
                }
                return sumlen;
            },
            $enablePicking() {
                this.$root._earth.interaction.picking.enabled = true;
                this.$root._earth.interaction._cameraTrackingBinding.target = undefined;
                this.$root._earth.interaction._creatingPolygonBinding.target = undefined;
                this.$root._earth.interaction._creatingPolylineBinding.target = undefined;
                this.$root._earth.interaction._directionCameraBinding.target = undefined;
                this.$root._earth.interaction._editingPolygonBinding.target = undefined;
                this.$root._earth.interaction._editingPolylineBinding.target = undefined;
                this.$root._earth.interaction._flattenedPolygonCreatingBinding.target = undefined;
            },
            $disablePicking() {
                this.$root._earth.interaction.picking.enabled = false;
            },
            //王 改于5.10  后期删除注释
            queryInformation() {
                //解决：退出后，点击浏览器后退，跳转至登录页面
                // if (localStorage.getItem("token")) {
                //     debugger
                    this.$axios
                        .get("/user/info?t="+new Date())
                        .then(res => {
                            if (res.data.data.auth_level < 8) {
                                window.location.href = "/login.html";
                            }
                            this.$root.phone = res.data.data.phone;
                            this.$root.auth_level = res.data.data.scope || res.data.data.auth_level;
                        })
                        .catch(err => {
                            console.log(err);
                        });
                // } else {
                //     window.location.href = "/login.html";
                // }
            }
        }
    };
</script>

<style>
    #app {
        position: absolute;
        width: 100%;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
    }

    .header {
        line-height: var(--HeaderHeight);
        text-align: center;
    }

    .content {
        position: absolute;
        width: 100%;
        top: var(--HeaderHeight);
        bottom: 0px;
        display: flex;
        flex-direction: row;
        justify-items: stretch;

    }

    .iframe {
        position: absolute;
        top: 10px;
    }

    .alert_content {
        display: flex;
        align-content: center;
        align-items: center;
        min-height: 80px;
        line-height: 25px;
    }

    .alert_footer {
        flex-grow: 0;
        display: flex;
        padding-right: 5px;
        justify-content: flex-end;
    }

    .confirm_footer {
        flex-grow: 0;
        height: 100px;
        display: flex;
        padding: 10px;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        background-color: var(--FormBGColor);
    }

    .middle {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .middle_earth {
        height: 200px;
        width: 100%;
        display: flex;
        position: relative;
        flex-grow: 1;
        display: flex;
    }
</style>

<template>
    <Window :title="'管线图层管理面板'" :footervisible="true" @ok="ok" @cancel="cancel" :width="300" :height="430">
        <div class="Layer">
            <button @click="loadTree">刷新图层列表</button>
            <VirtualTree ref="vtree" :tree="tree" @on-toggle-checked="onToggleChecked">
            </VirtualTree>

        </div>

    </Window>
</template>

<script>
    import Window from "../../../components/Window"
    import VirtualTree from "../../../components/VirtualTree/index"

    export default {
        components: {
            Window,
            VirtualTree,
        },
        data() {
            return {
                tree: [],
                sendData: ""
            }
        },
        props: {
            param: {
                type: Object,
                default: undefined
            }
        },
        mounted() {
            this.loadTree()
        },
        methods: {
            cancel() {
                this.$root.destroyTool(this);
            },
            ok() {

                //console.log(this.sendData)
                if (this.sendData) {
                    if (this.param && this.param.ok) {
                        this.param.ok(this.sendData);
                        this.$root.destroyTool(this);
                    }
                } else {
                    this.$root.$alert("未选择图层")
                }

            },
            onToggleChecked(option) {
                console.log("选中的数据", option.item)
                if (!option.item.checked) {
                    //console.log("选中的数据", option.item.checked)
                    this.sendData = option.item
                    this.tree = this.cancelSelectOther(this.tree, option.item)
                } else {
                    return
                    //console.log("取消选择", option.item.checked)
                }


            },
            cancelSelectOther(treeData, item) {
                let tree = [];
                if (treeData && treeData instanceof Array) {
                    treeData.forEach((element) => {
                        if (element.dragType === "folder") {
                            if (element.children && element.children.length) {
                                this.cancelSelectOther(element.children, item);
                            }
                        } else if (element.dragType === "layer") {
                            if (element.id != item.id) {
                                element.checked = false
                            }
                        }
                    });
                }
                return treeData
            },

            loadTree() {
                if(this.$root.projectid){
                    this.$axios
                    .get("/project/info/layers", {
                        params: {
                            projectid: this.$root.projectid
                        }
                    })
                    .then((res) => {
                        console.log("树状图层", res.data)
                        this.tree = this.creatTree(res.data.data.layers);
                    })
                    .catch((err) => {
                        this.$root.$log("error", err)
                        console.log(err.response || err);
                    });
                }
            },
            creatTree(treeData) {

                let self = this;
                let tree = [];

                if (treeData && treeData instanceof Array) {

                    treeData.forEach((element) => {
                        var node = {
                            title: element.title,
                        };

                        if (element.dragType === "folder") {
                            node.checked = 1
                            node.children = [],
                                node.expanded = element.expanded,
                                node.dragType = "folder";
                        } else if (element.dragType === "layer") {
                            node.checked = false
                            node.id = element.id
                            node.dragType = "layer";
                            node.config = element.config
                        }
                        if (element.children && element.children.length) {
                            node.children = self.creatTree(element.children);
                        }

                        tree.push(node);
                    });
                }
                return tree;
            },
            save() {
                let data = this.changeData(this.tree)
                //console.log("修改后JSON", data.toString())
                this.$axios.put("/project/info/update", {
                    projectid: this.$root.projectid,
                    layers: data
                }).then(res => {
                    this.loadTree()
                    console.log("修改保存成功", res.status)
                }).catch(err => {
                    this.$root.$log("error", err)
                    console.log(err.response || err)
                })
            },
            changeData(o) { //解决对象涉及内部循环引用导致无法被后面使用
                var cache = [];
                var str = JSON.stringify(o, function (key, value) {
                    if (typeof value === 'object' && value !== null) {
                        if (cache.indexOf(value) !== -1) {
                            // 移除
                            return;
                        }
                        // 收集所有的值
                        cache.push(value);
                    }
                    return value;
                });
                cache = null; // 清空变量，便于垃圾回收机制回收
                return str
            }

        }
    }
</script>

<style>
    .Layer {
        width: 100%;
        height: 80%;
        margin: 30px 0 0 5px;
    }

    .Layer button {
        width: 100px;
        left: 10px;
    }
</style>

<template>
  <Window
    :title="getBind == 'line' ? '管线属性' : '管点属性'"
    :footervisible="false"
    @cancel="cancel"
    :width="650"
    :height="500"
  >
    <div class="pipepointquery">
      <div class="tablePages">
        <div class="taNamepoint">
          <table cellspacing="3">
            <tr>
              <th v-for="(item, index) in thearder" :key="index">
                {{ item.name }}
              </th>
              <th @click="openfieldeditoradd">添加字段+</th>
            </tr>
            <tr v-for="(item, index) in infos" :key="index">
              <!-- <input type="checkbox" @change="singleChecked(item)"> -->
              <td>{{ item.column_name }}</td>
              <td>{{ item.column_datatype }}</td>
              <td>{{ item.column_default }}</td>
              <td>{{ item.column_description }}</td>
              <td>{{ item.column_chinese }}</td>
              <td>{{ item.add3dtiles }}</td>
              <td>
                <div v-show="item.column_editable">
                  <button @click="openfieldeditorupdate(item)">修改</button>
                  <button @click="deletefield(item)">删除</button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <FieldEditor ref="addfield" :title="title"></FieldEditor>
    </div>
  </Window>
</template>
<script>
import FieldEditor from "./FieldEditor";
import Window from "../../../components/Window";
/* eslint-disable */
export default {
  components: {
    FieldEditor,
    Window,
  },
  props: {
    getBind: {
      type: String,
      default: "point",
    },
  },
  data() {
    return {
      addfield: false,
      thearder: [
        {
          zhname: "column_name",
          name: "属性名",
        },
        {
          zhname: "column_datatype",
          name: "类型",
        },
        {
          zhname: "column_default",
          name: "默认值",
        },
        {
          zhname: "column_description",
          name: "描述",
        },
        {
          zhname: "column_chinese",
          name: "中文名",
        },
        {
          zhname: "add3dtiles",
          name: "保存到3dtiles(注意，修改之后重构数据才能生效)",
        },
      ],
      infos: [],
      selectitem: undefined,
      title: "",
    };
  },
  mounted() {
    this.query();
    console.log("传入参数", this.getBind);
  },
  methods: {
    openfieldeditoradd(item) {
      this.title = "添加属性字段";
      this.$refs.addfield.open(this.getBind, item);
    },
    openfieldeditorupdate(item) {
      this.title = "编辑属性字段";
      this.$refs.addfield.open(this.getBind, item);
    },
    deletefield(item) {
      var columns = [item.column_name];
      let data = {
        projectid: this.$root.projectid,
        columns: columns,
      };
      this.$root.$confirm("确定删除该字段?", () => {
        this.$axios
          .delete("/project/" + this.getBind + "/column/delete", {
            data,
          })
          .then((res) => {
            if (res.data.data == true) {
              this.$root.$alert("删除成功");
              this.query();
            }
          })
          .catch((err) => {
            console.log(err.response || err);
          });
      });
    },
    cancel() {
      this.$root.destroyTool(this);
    },
    ok() {
      this.$root.destroyTool(this);
    },
    async query() {
      var data = {
        projectid: this.$root.projectid,
      };
      this.$axios
        .get("/project/" + this.getBind + "/column/query", {
          params: data,
        })
        .then((res) => {
          console.log("全部数据", res);
          if (res.data.data) {
            this.infos = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err.response || err);
        });
    },
  },
  beforeUnmount() {},
};
</script>
<style scoped>
.pipepointquery {
  text-align: center;
  line-height: 18px;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  color: #ccc;
}

.chaxunline > div {
  margin-top: 10px;
}

.tablePages {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.odline {
  width: 100px;
}

.tablePages input {
  vertical-align: middle;
}

.taNamepoint {
  width: 100%;
  overflow: auto;
}

.taNamepoint > table {
  margin: 0 auto;
  text-align: center;
  cursor: default;
  border: 1px solid #383838;
  width: 100%;
}

.taNamepoint > table > tr > th {
  box-sizing: border-box;
  height: 30px;
  text-align: center;
  cursor: default;
  background-color: #383838;
  color: #ccc;
}

.taNamepoint > table > tr > th:last-child {
  min-width: 150px;
  cursor: pointer;
}

.taNamepoint > table > tr > td {
  box-sizing: border-box;
  height: 30px;
  text-align: center;
  cursor: default;
  background: #2e2e2e;
  border: 1px solid #383838;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
}

.taNamepoint > table > tr:hover {
  cursor: default;
  background-color: #288dfb;
}

.row_single {
  width: 100%;
  display: flex;
  align-items: center;
}

.row_single > label {
  flex-shrink: 0;
  width: 60px;
}

.row_single > input {
  box-sizing: border-box;
  flex-grow: 1;
  background: #2e2e2e;
  border: 1px solid #383838;
  border-radius: 2px;
  color: #ccc;
  height: 30px;
  line-height: 30px;
  margin-right: 3px;
}

.row_single > button {
  width: 100%;
}

.row_single > button:last-child {
  margin-left: 3px;
}

.upDown:hover {
  cursor: pointer;
  color: #288dfb;
}

.pagepoint {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-top: 5px;
  padding-left: 25px;
}

.pagepoint > div > span {
  text-align: center;
  margin-right: 5px;
}

.pagepoint > div > button {
  margin-right: 3px;
}

.pagepoint > div > span > input {
  text-align: center;
  width: 50px;
  height: 30px;
  background: #2e2e2e;
  border: 1px solid #383838;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  margin: 0 3px;
}
</style>

<template>
  <Window
    :title="title"
    :footervisible="true"
    @ok="ok"
    @cancel="cancel"
    v-show="show"
    :width="400"
    :height="350"
  >
    <div class="parameter_content">
      <div>
        <div class="right">
          <label>字段名</label>
          <input type="text" v-model="column_name" />
        </div>
        <div class="right">
          <span>注意：字段名只支持小写字符和下划线</span>
        </div>
        <div class="right">
          <label>字段类型:</label>
          <select v-model="column_datatype">
            <option value="text">文本</option>
            <option value="integer">整型</option>
            <option value="double">浮点型</option>
            <option value="boolean">布尔型</option>
            <option value="date">日期</option>
            <option value="bytea">二进制</option>
          </select>
        </div>
        <div class="right">
          <label>默认值</label>
          <input type="text" v-model="column_default" />
        </div>
        <div class="right">
          <label>描述</label>
          <input type="text" v-model="column_description" />
        </div>
        <div class="right">
          <label>中文名</label>
          <input type="text" v-model="column_chinese" />
        </div>
        <div class="right" v-show="authType == 'auth'">
          <label>添加到3dtiles</label>
          <input type="checkbox" class="bty-switch" v-model="add3dtiles" /><span
            class="width:180px"
            >修改此选项之后必须重构数据</span
          >
        </div>
      </div>
    </div>
  </Window>
</template>

<script>
/* eslint-disable */
import Window from "../../../components/Window";

export default {
  components: {
    Window,
  },
  data() {
    return {
      show: false,
      old_column_name: "",
      column_name: "",
      column_datatype: "",
      column_default: "",
      column_description: "",
      column_chinese: "",
      param: "",
      item: undefined,
      add3dtiles: false,
      authType: "test"
    };
  },
  props: {
    title: String,
    default() {
      return "添加/编辑属性字段";
    },
  },
  mounted() {
    this.$axios
			.get('/project/info', {
				params: {
					projectid: this.$root.projectid
				}
			})
			.then(res => {
				this.authType = res.data.data.type
			})
			.catch(err => {
				console.log(err.response || err)
			})
  },
  methods: {
    open(param, item) {
      this.param = param;
      this.item = item;
      if (item) {
        this.old_column_name = item.column_name;
        this.column_name = item.column_name;
        this.column_default = item.column_default;
        this.add3dtiles = item.add3dtiles;
        this.column_description = item.column_description;
        this.column_chinese = item.column_chinese;
        this.column_datatype = item.column_datatype;
        // switch (item.column_datatype) {
        // 	case 'text':
        // 		this.column_datatype = 'TEXT'
        // 		break
        // 	case 'integer':
        // 		this.column_datatype = 'INTEGER'
        // 		break
        // 	case 'double precision':
        // 		this.column_datatype = 'DOUBLE'
        // 		break
        // 	case 'boolean':
        // 		this.column_datatype = 'BOOLEAN'
        // 		break
        // 	case 'date':
        // 		this.column_datatype = 'DATE'
        // 		break
        // 	default:
        // 		this.column_datatype = item.column_datatype
        // }
        // if (item.column_datatype == "double precision") {
        //   this.column_datatype = "double";
        // }
      }
      // console.log(item.column_datatype);
      this.show = true;
    },
    ok() {
      let self = this;
      if (
        this.column_name == "" ||
        this.column_name == undefined ||
        this.column_datatype == "" ||
        this.column_datatype == undefined
      ) {
        this.$root.$alert("未填写字段名或数据类型");
        return;
      } else {
        if (this.title == "添加属性字段") {
          var data = {
            projectid: this.$root.projectid,
            columns: [
              {
                name: this.column_name,
                type: this.column_datatype,
                default: this.column_default,
                zhushi: this.column_description,
                chinese_name: this.column_chinese,
                add3dtiles: this.add3dtiles,
              },
            ],
          };
          this.$axios
            .put("/project/" + this.param + "/column/add", data)
            .then(() => {
              this.cancel();
              self.$parent.$parent.query();
              this.$root.$alert("添加成功");
            })
            .catch((err) => {
              this.$root.$alert(err.data.message);
            });
        }
        if (this.title == "编辑属性字段") {
          var data = {
            projectid: this.$root.projectid,
            oldcolumn: this.old_column_name,
            newcolumn: this.column_name,
            type: this.column_datatype,
            default: this.column_default,
            zhushi: this.column_description,
            chinese_name: this.column_chinese,
            add3dtiles: this.add3dtiles,
          };
          console.log(data);
          this.$axios
            .put("/project/" + this.param + "/column/update", data)
            .then(() => {
              this.cancel();
              self.$parent.$parent.query();
              this.$root.$alert("编辑成功");
            })
            .catch((err) => {
              this.$root.$alert(err.data.message);
            });
        }
      }
    },
    cancel() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.cesiumContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #242424;
}

.parameters_top {
  display: flex;
  height: 28px;
  align-items: center;
  justify-content: center;
}

.parameters_top > div:nth-child(2) {
  display: flex;
  border: 1px solid #999595;
  flex-grow: 1;
  height: 24px;
  align-items: center;
}

.parameter_content {
  display: flex;
  width: 100%;
  height: 100%;
}

.parameter_content > div:nth-child(1) {
  width: 670px;
  flex-grow: 0;
  border-right: none;
  height: 100%;
}

.parameter_content > div:nth-child(2) {
  width: 300px;
  flex-grow: 1;
  height: 100%;
}

.right {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
  padding-top: 10px;
}

.right > select {
  width: 220px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.right > label {
  width: 110px;
  text-align: center;
}

.right > input {
  width: 220px;
  height: 30px;
  line-height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.preview {
  width: 220px;
  height: 30px;
  background: #383838;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}
</style>

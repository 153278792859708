function defaultValue(a, b) {
    if (a !== undefined && a !== null) {
        return a;
    }
    return b;
}

var Utils = {

    toDms(value) {
        //度
        var d = Math.floor(value);
        //小数部分
        var dec = value - Math.floor(value);
        //分
        var m = Math.floor(dec * 60);
        //秒
        var s = ((dec * 3600) % 60);

        return {
            d,
            m,
            s
        };
    },
    formatJingduDMS(jingdu) {
        var dms = this.toDms(jingdu);

        var res = dms.d < 0 ? "W" : "E";

        res += Math.abs(dms.d).toFixed(0) + "°\t";

        res += dms.m.toFixed(0) + "′\t";

        res += dms.s.toFixed(2) + "″";

        return res;
    },
    formatWeiduDMS(weidu) {
        var dms = this.toDms(weidu);

        var res = dms.d < 0 ? "S" : "N";

        res += Math.abs(dms.d).toFixed(0) + "°\t";

        res += dms.m.toFixed(0) + "′\t";

        res += dms.s.toFixed(2) + "″";

        return res;
    },

    queryToObject( /*String*/ str) {
        var dec = decodeURIComponent,
            qp = str.split("&"),
            ret = {},
            name,
            val;
        for (var i = 0, l = qp.length, item; i < l; ++i) {
            item = qp[i];
            if (item.length) {
                var s = item.indexOf("=");
                if (s < 0) {
                    name = dec(item);
                    val = "";
                } else {
                    name = dec(item.slice(0, s));
                    val = dec(item.slice(s + 1));
                }
                if (typeof ret[name] == "string") {
                    // inline'd type check
                    ret[name] = [ret[name]];
                }

                if (ret[name] instanceof Array) {
                    ret[name].push(val);
                } else {
                    ret[name] = val;
                }
            }
        }
        return ret; // Object
    },
    clone(object, deep) {
        if (object === null || typeof object !== "object") {
            return object;
        }

        deep = defaultValue(deep, false);

        var result = new object.constructor();
        for (var propertyName in object) {
            if (Object.prototype.hasOwnProperty.call(object, propertyName)) {
                var value = object[propertyName];
                if (deep) {
                    value = Utils.clone(value, deep);
                }
                result[propertyName] = value;
            }
        }

        return result;
    },

    //arp绘制
    drawArp(jichang, arp, canvas) {
        if (!canvas) return;
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.save();
        //定位到中心点
        ctx.translate(canvas.width * 0.5, canvas.height * 0.5);
        //设置y朝上
        ctx.scale(1, -1);
        ctx.strokeStyle = "#41509C";
        const scale = 120000;

        function toCanvas(m) {
            return (m * canvas.width) / scale;
        }

        //绘制55公里外园
        ctx.beginPath();
        ctx.arc(0, 0, toCanvas(55000), 0, 2 * Math.PI);
        ctx.stroke();

        jichang.paodao.forEach(paodao => {
            //绘制跑道
            ctx.beginPath();
            ctx.moveTo(toCanvas(paodao.p1.x), toCanvas(paodao.p1.y));
            ctx.lineTo(toCanvas(paodao.p2.x), toCanvas(paodao.p2.y));
            ctx.stroke();
        });

        //绘制机场区域
        ctx.beginPath();
        var points = jichang.rectangle;
        ctx.moveTo(
            toCanvas(points[points.length - 1].x),
            toCanvas(points[points.length - 1].y)
        );
        points.forEach(p => {
            ctx.lineTo(toCanvas(p.x), toCanvas(p.y));
        });

        ctx.stroke();

        //绘制障碍物点
        if (arp) {
            ctx.strokeStyle = "red";
            ctx.beginPath();
            ctx.arc(
                toCanvas(arp.pos.x),
                toCanvas(arp.pos.y),
                toCanvas(1000),
                0,
                2 * Math.PI
            );
            ctx.stroke();
        }

        ctx.restore();
    }
}



export default Utils;
const zh = {
  title: "在线影像",
  address: "服务地址",
  datazb: "数据坐标",
  loadzb: "加载坐标",
  google_image: "谷歌影像",
  google_image_label: "谷歌影像标注",
  google_imagewithlabel: "谷歌影像&标注",
  google_map: "谷歌地图",
  tianditu_image: "天地图影像",
  tianditu_image_label: "天地图影像上标注",
  tianditu_map: "天地图地图",
  tianditu_map_label: "天地图地图标注",
  gaode_map: "高德地图",
  gaode_image: "高德影像",
  gaode_image_label: "高德影像标注",
  baidu_map: "百度地图",
  baidu_image: "百度影像",
  baidu_image_label: "百度影像标注",
  baidu_map_midnight: "百度暗色风格",
  baidu_map_dark: "百度黑色风格",
  openstreetmap: "OpenStreetMap",
  mapbox_satellite:"mapbox影像",
  mapbox_streets:"mapbox地图",
  mapbox_light:"mapbox浅色",
  mapbox_dark:"mapbox暗色",

  selectinput: "请选择或者输入服务地址",
  localtitle: "CesiumLab本机服务",
  cloudtitle: "云服务",
  search: "查询",
  maxzoom: "最大级别",
  dilifanwei: "地理范围",
  updatethumb: "更新缩略图",
  selectservice: "请选择服务",
  unName:'未命名影像' 
};

const en = {
  title: "Online Image",
  address: "Addr",
  datazb: "SrcCoord",
  loadzb: "DstCoord",
  gooleimage: "gooleimage",
  goolemarkup: "goolemarkup",
  gooleimageannotation: "googleimageannotation",
  goolemap: "goolemap",
  skyimage: "skyimage",
  skymarkup: "skymarkup",
  skymap: "skymap",
  skymapmarkup: "skymapmarkup",
  gaodemap: "gaodemap",
  gaodeimage: "gaodeimage",
  selectinput: "Please select or enter the service address",
  localtitle: "CesiumLab Local Service",
  cloudtitle: "Cloud Service",
  search: "Search",
  maxzoom: "MaxZoom",
  dilifanwei: "Rectangle",
  updatethumb: "updatethumb",
  selectservice: "Please choose the service",
  unName:'no name imagery' 
};

export default { zh, en };

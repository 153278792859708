<template>
  <div>
    <Window :title="'附属物库'" :footervisible="true" @ok="ok" @cancel="cancel" v-show="show" :width="820" :height="640">
      <div>
        <button @click="tabSwitch" :class="{ tab_static: tab }">
          静态附属物
        </button>
        <button @click="tabSwitch" :class="{ tab_param: !tab }">
          参数附属物
        </button>
      </div>
      <div class="static" v-show="tab">
        <div class="appendages_top">
          <input v-model="searchContent" type="text" @keyup.enter="searchQuery" />
          <button @click="searchQuery">搜索</button>
          <button @click="eidtorAppendage(null)">添加</button>
        </div>
        <div class="appendages_content">
          <div v-for="(item, index) in imageData" :key="index">
            <div class="thumbnail">
              <img :src="item.thumbnail" style="width: 100px; height: 100px" />
              <span>{{ item.name }}</span>
            </div>
            <div class="operation">
              <img v-show="param && !(indexshow === index)" src="../assets/selectup.png" @click="selectAppendage(index)"
                title="选中" />
              <img v-show="param && indexshow === index" src="../assets/selectdown.png" title="选中" />
              <img src="../assets/editor.png" @click="eidtorAppendage(item)" title="编辑" />
              <img src="../assets/delete.png" @click="deleteAppendage(item.name)" title="删除" />
              <img src="../assets/copy.png" @click="copyAppendage(item)" title="复制" />
            </div>
          </div>
        </div>
      </div>
      <div class="param" v-show="!tab">
        
        <div class="param_right" v-show="modeler == 'jingparam'">
          <div class="right">
            <label>造型器:</label>
            <select v-model="modeler">
              <option value="jingparam">井造型器</option>
              <option value="cube">立方体造型器</option>
            </select>
          </div>
          <div class="right">
            <label>名称</label><input v-model="jingparam.name" />
          </div>
          <div class="right">
            <label>使用字段</label><input type="checkbox" v-model="usefield"/>
          </div>
          <div class="right">
            <label>井室高度</label><input step="0.01" v-model.number="jingparam.param.param[0].height" />
          </div>
          <div class="right">
            <label>井室尺寸</label><input type="text" v-model="jingparam.param.param[0].size" />
          </div>

          <div class="right">
            <label>收口高度</label><input step="0.01" v-model.number="jingparam.param.param[1].height" />
          </div>

          <div class="right">
            <label>井筒高度</label><input step="0.01" v-model.number="jingparam.param.param[2].height" />
          </div>
          <div class="right">
            <label>井筒尺寸</label><input type="text" v-model="jingparam.param.param[2].size" />
          </div>

          <div class="right">
            <label>井盖高度</label><input step="0.01" v-model.number="jingparam.param.param[3].height" />
          </div>
          <div class="right">
            <label>井盖尺寸</label><input type="text" v-model="jingparam.param.param[3].size" />
          </div>
          <div class="right">
            <label>井身材质</label><button @click="selectMaterial('', setJingMaterial)">
              选择材质
            </button>
          </div>
          <div class="right">
            <label>井盖材质</label><button @click="selectMaterial('jinggai', setJingMaterial)">
              选择材质
            </button>
          </div>
          <div class="right">
            <label> </label>
            <button @click="preview" class="preview">预览模型</button>
          </div>
          <div class="right">
            {{ fushuwuparam }}
          </div>
        </div>
        <div class="param_right" v-show="modeler == 'cube'">
          <div class="right">
            <label>造型器:</label>
            <select v-model="modeler">
              <option value="jingparam">井造型器</option>
              <option value="cube">立方体造型器</option>
            </select>
          </div>
          <div class="right">
            <label>名称</label><input v-model="cube.name" />
          </div>

          <div class="right">
            <label>长</label><input type="number" step="0.01" v-model.number="cube.param.length" />
          </div>
          <div class="right">
            <label>宽</label><input type="number" step="0.01" v-model.number="cube.param.width" />
          </div>

          <div class="right">
            <label>高</label><input type="number" step="0.01" v-model.number="cube.param.height" />
          </div>
          <div class="right">
            <label>侧面材质1</label><button @click="
              selectMaterial(
                { name: 'cemiancaizhi', index: 0 },
                setCubeMaterial
              )
            ">
              选择材质
            </button>
          </div>
          <div class="right">
            <label>侧面材质2</label><button @click="
              selectMaterial(
                { name: 'cemiancaizhi', index: 1 },
                setCubeMaterial
              )
            ">
              选择材质
            </button>
          </div>
          <div class="right">
            <label>侧面材质3</label><button @click="
              selectMaterial(
                { name: 'cemiancaizhi', index: 2 },
                setCubeMaterial
              )
            ">
              选择材质
            </button>
          </div>
          <div class="right">
            <label>侧面材质4</label><button @click="
              selectMaterial(
                { name: 'cemiancaizhi', index: 3 },
                setCubeMaterial
              )
            ">
              选择材质
            </button>
          </div>
          <div class="right">
            <label>顶面材质</label><button @click="
              selectMaterial({ name: 'dingmiancaizhi' }, setCubeMaterial)
            ">
              选择材质
            </button>
          </div>
          <div class="right">
            <label> </label>
            <button @click="preview" class="preview">预览模型</button>
          </div>
          <div class="right">
            {{ fushuwuparam }}
          </div>
        </div>
        <div class="param_left" v-show="!usefield">
          <div ref="cesiumContainer" class="cesiumContainer"></div>
        </div>
      </div>
    </Window>
  </div>
</template>
<script>
import utils from "../math/utils";
import Window from "../../../components/Window";
import AppendageStatic from "./AppendageStatic";
import { createViewer } from "../scripts/misc/createViewer";
import MaterialSelect from "../components/MaterialSelect";
import { Base64 } from "js-base64";
/* eslint-disable */
export default {
  components: {
    Window,
    MaterialSelect,
  },
  data() {
    return {
      usefield:false,
      modeler: "jingparam",
      show: true,
      imageData: [],
      searchContent: "",
      sendData: "",
      acceptName: "",
      indexshow: "",
      tab: true,
      cube: {
        param: {
          length: 1,
          width: 1,
          height: 1,
          cemiancaizhi: [
            {
              emissiveFactor: [0, 0, 0],
              matSelected: "",
              normalTexture: {
                extensions: {
                  KHR_texture_transform: {
                    offset: [0, 0],
                    rotation: 90,
                    scale: [1, 1],
                  },
                },
                id: "1d2c9694c527dc63e21a2fd38d4d94c8",
              },
              pbrMetallicRoughness: {
                baseColorFactor: [1, 1, 1, 1],
                baseColorTexture: {
                  extensions: {
                    KHR_texture_transform: {
                      offset: [0, 0],
                      rotation: 90,
                      scale: [1, 1],
                    },
                  },
                  id: "f3505fdd5ff732a301420905cb42e43a",
                },
                metallicFactor: 0,
                roughnessFactor: 1,
              },
            },
          ],
          dingmiancaizhi: {
            emissiveFactor: [0, 0, 0],
            matSelected: "",
            normalTexture: {
              extensions: {
                KHR_texture_transform: {
                  offset: [0, 0],
                  rotation: 90,
                  scale: [1, 1],
                },
              },
              id: "1d2c9694c527dc63e21a2fd38d4d94c8",
            },
            pbrMetallicRoughness: {
              baseColorFactor: [1, 1, 1, 1],
              baseColorTexture: {
                extensions: {
                  KHR_texture_transform: {
                    offset: [0, 0],
                    rotation: 90,
                    scale: [1, 1],
                  },
                },
                id: "f3505fdd5ff732a301420905cb42e43a",
              },
              metallicFactor: 0,
              roughnessFactor: 1,
            },
          },
          type: "cube",
        },
        type: "param",
        name: "立方体",
      },
      jingparam: {
        param: {
          param: [
            {
              bottom: 0,
              height: 1,
              material: {
                emissiveFactor: [0, 0, 0],
                matSelected: "",
                normalTexture: {
                  extensions: {
                    KHR_texture_transform: {
                      offset: [0, 0],
                      rotation: 90,
                      scale: [1, 1],
                    },
                  },
                  id: "1d2c9694c527dc63e21a2fd38d4d94c8",
                },
                pbrMetallicRoughness: {
                  baseColorFactor: [1, 1, 1, 1],
                  baseColorTexture: {
                    extensions: {
                      KHR_texture_transform: {
                        offset: [0, 0],
                        rotation: 90,
                        scale: [1, 1],
                      },
                    },
                    id: "f3505fdd5ff732a301420905cb42e43a",
                  },
                  metallicFactor: 0,
                  roughnessFactor: 1,
                },
              },
              size: 0.74,
              type: "jingshi",
            },
            {
              bottom: 1,
              bottom_radius: 0.74,
              height: 0.84,
              material: {
                emissiveFactor: [0, 0, 0],
                matSelected: "",
                normalTexture: {
                  extensions: {
                    KHR_texture_transform: {
                      offset: [0, 0],
                      rotation: 0,
                      scale: [1, 1],
                    },
                  },
                  id: "1d2c9694c527dc63e21a2fd38d4d94c8",
                },
                pbrMetallicRoughness: {
                  baseColorFactor: [1, 1, 1, 1],
                  baseColorTexture: {
                    extensions: {
                      KHR_texture_transform: {
                        offset: [0, 0],
                        rotation: 0,
                        scale: [1, 1],
                      },
                    },
                    id: "f3505fdd5ff732a301420905cb42e43a",
                  },
                  metallicFactor: 0,
                  roughnessFactor: 1,
                },
              },
              top_radius: 0.5,
              type: "shoukou",
            },
            {
              bottom: 1.84,
              height: 0.36,
              material: {
                emissiveFactor: [0, 0, 0],
                matSelected: "",
                normalTexture: {
                  extensions: {
                    KHR_texture_transform: {
                      offset: [0, 0],
                      rotation: 0,
                      scale: [1, 1],
                    },
                  },
                  id: "1d2c9694c527dc63e21a2fd38d4d94c8",
                },
                pbrMetallicRoughness: {
                  baseColorFactor: [1, 1, 1, 1],
                  baseColorTexture: {
                    extensions: {
                      KHR_texture_transform: {
                        offset: [0, 0],
                        rotation: 0,
                        scale: [1, 1],
                      },
                    },
                    id: "f3505fdd5ff732a301420905cb42e43a",
                  },
                  metallicFactor: 0,
                  roughnessFactor: 1,
                },
              },
              size: 0.5,
              type: "jingtong",
            },
            {
              bottom: 2.2,
              height: 0.04,
              material: {
                emissiveFactor: [0, 0, 0],
                matSelected: "",
                pbrMetallicRoughness: {
                  baseColorFactor: [1, 1, 1, 1],
                  baseColorTexture: {
                    extensions: {
                      KHR_texture_transform: {
                        offset: [0, 0],
                        rotation: 0,
                        scale: [1, 1],
                      },
                    },
                    id: "02d5e34753882c5cd612fbedb8673c58",
                  },
                  metallicFactor: 0,
                  roughnessFactor: 1,
                },
              },
              size: 0.4,
              type: "jinggai",
            },
          ],
          type: "well",
        },
        type: "param",
        name: "井",
      },
      fushuwuparam: "",
    };
  },
  props: {
    param: {
      type: Object,
      default() {
        return undefined;
      },
    },
  },
  mounted() {
    this.query();
    //初始化cesium
    this._viewer = createViewer(this.$refs.cesiumContainer);
    this.preview();
  },
  watch: {
    modeler() {
      this.preview();
    },
  },
  methods: {
    setJingMaterial(option, caizhi) {
      this.jingparam.param.param.forEach((element) => {
        if (option && option != "") {
          // 此时更改是井盖材质
          if (element.type == "jinggai") {
            element.material = caizhi.param;
          }
        } else {
          if (!(element.type == "jinggai")) {
            element.material = caizhi.param;
          }
        }
      });
      this.preview();
    },
    setCubeMaterial(options, caizhi) {
      if (options.name == "cemiancaizhi") {
        this.cube.param.cemiancaizhi[options.index] = caizhi.param;
      } else if (options.name == "dingmiancaizhi") {
        this.cube.param.dingmiancaizhi = caizhi.param;
      }
      this.preview();
    },
    selectMaterial(options, callback) {
      let self = this;
      this.$root.showPropertyWindow("MaterialSelect", {
        component: MaterialSelect,
        param: {
          ok: (caizhi) => {
            console.log("showPropertyWindow材质" + caizhi);
            callback(options, caizhi);
          },
        },
      });
    },
    tabSwitch() {
      this.tab = !this.tab;
    },
    format(value) {
      if (value == undefined) {
        return 0;
      }
      if (typeof value !== 'number') {
        return "(" + value + ")"
      } else {
        return value;
      }
    },
    jingparamcontentPreview() {
      this.jingparam.param.param[1].bottom =
        this.jingparam.param.param[0].bottom +
        this.jingparam.param.param[0].height;
      this.jingparam.param.param[2].bottom =
        this.jingparam.param.param[1].bottom +
        this.jingparam.param.param[1].height;
      this.jingparam.param.param[3].bottom =
        this.jingparam.param.param[2].bottom +
        this.jingparam.param.param[2].height;

      for (var i = 0; i < this.jingparam.param.param.length; i++) {
        delete this.jingparam.param.param[i].radius;
        delete this.jingparam.param.param[i].length;
        delete this.jingparam.param.param[i].width;
        var size = this.jingparam.param.param[i].size;
        if (size) {
          if (typeof size === "string" && size.indexOf("x") > 0) {
            let wh = size.split("x");
            this.jingparam.param.param[i].length = parseFloat(wh[0]);
            this.jingparam.param.param[i].width = parseFloat(wh[1]);
          } else {
            this.jingparam.param.param[i].radius = parseFloat(size);
          }
        }
      }
      this.jingparam.param.param[1].bottom_radius = this.jingparam.param.param[0].radius;
      this.jingparam.param.param[1].top_radius = this.jingparam.param.param[2].radius;
    },
    jingparamcontentOk() {
      if (typeof this.jingparam.param.param[0].bottom === 'number' && typeof this.jingparam.param.param[0].height === 'number') {
        this.jingparam.param.param[1].bottom = this.jingparam.param.param[0].bottom + this.jingparam.param.param[0].height;
      } else {
        this.jingparam.param.param[1].bottom = this.jingparam.param.param[0].bottom + "+" + this.jingparam.param.param[0].height;
      }
      if (typeof this.jingparam.param.param[1].bottom === 'number' && typeof this.jingparam.param.param[1].height === 'number') {
        this.jingparam.param.param[2].bottom = this.jingparam.param.param[1].bottom + this.jingparam.param.param[1].height;
      } else {
        this.jingparam.param.param[2].bottom = this.jingparam.param.param[1].bottom + "+" + this.jingparam.param.param[1].height;
      }
      if (typeof this.jingparam.param.param[2].bottom === 'number' && typeof this.jingparam.param.param[2].height === 'number') {
        this.jingparam.param.param[3].bottom = this.jingparam.param.param[2].bottom + this.jingparam.param.param[2].height;
      } else {
        this.jingparam.param.param[3].bottom = this.jingparam.param.param[2].bottom + "+" + this.jingparam.param.param[2].height;
      }
      for (var i = 0; i < this.jingparam.param.param.length; i++) {
        delete this.jingparam.param.param[i].radius;
        delete this.jingparam.param.param[i].length;
        delete this.jingparam.param.param[i].width;
        var size = this.jingparam.param.param[i].size;
        if (size) {
          if (typeof size === "string" && size.indexOf("x") > 0) {
            let wh = size.split("x");
            this.jingparam.param.param[i].length = wh[0];
            this.jingparam.param.param[i].width = wh[1];
            this.jingparam.param.param[i].size = undefined;
          } else {
            this.jingparam.param.param[i].radius = size;
          }
        }
        this.jingparam.param.param[i].bottom = this.format(this.jingparam.param.param[i].bottom);
        this.jingparam.param.param[i].height = this.format(this.jingparam.param.param[i].height);
        this.jingparam.param.param[i].length = this.format(this.jingparam.param.param[i].length);
        this.jingparam.param.param[i].width = this.format(this.jingparam.param.param[i].width);
        this.jingparam.param.param[i].radius = this.format(this.jingparam.param.param[i].radius);
        this.jingparam.param.param[i].size = this.format(this.jingparam.param.param[i].size);
      }
      this.jingparam.param.param[1].bottom_radius = this.jingparam.param.param[0].radius;
      this.jingparam.param.param[1].top_radius = this.jingparam.param.param[2].radius;
    },
    preview() {
      var param;
      //深拷贝
      if (this.modeler == "jingparam") {
        this.jingparamcontentPreview();
        param = JSON.parse(JSON.stringify(this.jingparam));
      } else if (this.modeler == "cube") {
        param = JSON.parse(JSON.stringify(this.cube));
      }
      param.direction = [1, 0, 0];
      // param.end = [0,0,0];
      // param.start = [-1,0,0];
      param.distanceRatio = 10;
      this.fushuwuparam = JSON.stringify(param);
      if (this.fushuwuparam.indexOf('(') > 0) {
        return;
      }
      this.loadGlb(
        "./glb/preview?previewjson=" +
        JSON.stringify(param) +
        "&projectid=" +
        this.$root.projectid
      );
    },
    resetGlb() {
      if (this._model && this._viewer) {
        this._viewer.scene.primitives.remove(this._model);
        this._model = undefined;
      }
    },
    loadGlb(url) {
      let self = this;
      this.resetGlb();
      fetch(url).then((response) => {
        response.arrayBuffer().then((arrayBuffer) => {
          // @ts-ignore
          var parsedGltf = Cesium.parseGlb(new Uint8Array(arrayBuffer));
          if (this._viewer) {
            const hpr = new Cesium.HeadingPitchRoll(Math.PI * -0.5, 0, 0);
            const origin = Cesium.Cartesian3.fromDegrees(116.39, 39.9, 100);
            const modelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(
              origin,
              hpr
            );

            let token = window.localStorage.getItem("token");
            const base64 = Base64.encode(token + ":");

            this._model = this._viewer.scene.primitives.add(
              new Cesium.Model({
                gltf: parsedGltf,
                // basePath: "3dtiles/" + this.$root.projectid + "/?token=" + window.localStorage.getItem('token'),
                basePath: new Cesium.Resource({
                  url: "3dtiles/" + self.$root.projectid + "/",
                  headers: {
                    Authorization: "Basic " + base64,
                  },
                }),
                modelMatrix,
              })
            );
            if (!this._model) {
              throw new Error(`!this._model`);
            }
            const viewer = this._viewer;
            const camera = this._viewer.camera;
            this._model.readyPromise.then(() => {
              if (!this._model) {
                throw new Error(`!this._model`);
              }

              this._model.activeAnimations.addAll({
                multiplier: 1,
                loop: Cesium.ModelAnimationLoop.REPEAT,
              });

              let radius = this._model.boundingSphere.radius;
              if (radius < 10) {
                const scale = 10 / radius;
                this._model.scale = scale;
                radius = 10;
              }

              const controller = viewer.scene.screenSpaceCameraController;
              controller.minimumZoomDistance = radius * 0.5;

              const { heading, pitch } = camera;
              camera.frustum.near = radius * 0.01;
              camera.lookAt(
                origin,
                new Cesium.HeadingPitchRange(heading, pitch, radius * 2.0)
              );
            });
          }
        });
      });
    },
    //获取当前项目下的所有附属物信息
    query() {
      let _this = this;
      this.$axios
        .get("/project/accessory/query", {
          params: {
            projectid: _this.$root.projectid,
          },
        })
        .then((res) => {
          this.imageData = res.data.data;
        })
        .catch((err) => {
          err = err.response || err;
          this.$root.$log("error", err);
        });
    },
    //根据名称搜索查询附属物信息
    searchQuery() {
      let data = {
        projectid: this.$root.projectid,
        name: this.searchContent,
      };
      this.$axios
        .get("/project/accessory/query", {
          params: data,
        })
        .then((res) => {
          this.imageData = res.data.data;
        })
        .catch((err) => {
          err = err.response || err;
          this.$root.$log("error", err);
        });
    },
    cancel() {
      this.$root.destroyTool(this);
    },
    ok() {
      if (this.param) {
        if (this.tab) {
          if (typeof this.indexshow === "number") {
            this.$root.destroyTool(this);
            if (this.param && this.param.ok) {
              this.param.ok(this.sendData);
            }
          } else {
            this.$root.$alert("请选中一种附属物");
          }
        } else {
          this.$root.destroyTool(this);
          if (this.param && this.param.ok) {
            if (this.modeler == "jingparam") {
              this.jingparamcontentOk();
              this.param.ok({
                name: this.jingparam.name,
                param: this.jingparam,
              });
            } else {
              this.param.ok({
                name: this.cube.name,
                param: this.cube,
              });
            }
          }
        }
      } else {
        this.$root.destroyTool(this);
      }
    },
    deleteAppendage(name) {
      let _this = this;
      let data = {
        projectid: _this.$root.projectid,
        name: name,
      };
      this.$root.$confirm("是否确认删除这个附属物?", () => {
        this.$axios
          .delete("/project/accessory/delete", {
            data,
          })
          .then((res) => {
            _this.query();
          })
          .catch((err) => {
            err = err.response || err;
            this.$root.$log("error", err);
          });
      });
    },
    eidtorAppendage(item) {
      this.$root.showPropertyWindow(
        "AppendageStatic",
        {
          component: AppendageStatic,
          param: {
            ok: () => {
              this.query();
            },
          },
        },
        item
      );
    },
    copyAppendage(item) {
      item = utils.clone(item, true);
      item.name = item.name + "copy_" + this.getRandomLetters();
      item.isCopy = true;
      this.$root.showPropertyWindow(
        "AppendageStatic",
        {
          component: AppendageStatic,
          param: {
            ok: () => {
              this.query();
            },
          },
        },
        item
      );
      console.log(item);
    },
    // 生成随机字符串
    getRandomLetters() {
      let letters = new Array();
      let randomStr = "";
      for (let i = 0; i < 15; i++) {
        letters.push(String.fromCharCode(65 + i).toUpperCase());
      }
      randomStr += letters.map((item) => {
        if (item) {
          return letters[Math.ceil(Math.random() * 25)];
        }
      });
      return randomStr.split(",").join("");
    },
    selectAppendage(index) {
      this.indexshow = index;
      this.sendData = this.imageData[index]; //拿到选中的附属物数据。确定时候发送此数据给上一级窗口
      console.log("send选中数据", this.sendData);
    },
  },
};
</script>
<style scoped>
.appendages_top {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
}

.appendages_top>input {
  box-sizing: border-box;
  width: 430px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  margin-right: 4px;
}

.appendages_top>button {
  margin-right: 4px;
}

.appendages_content {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 100px);
  overflow: auto;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.appendages_content>div {
  width: 100px;
  height: 132px;
  margin-left: 12px;
}

.thumbnail {
  position: relative;
  width: 100px;
  height: 100px;
  border: 2px solid #1a1a1a;
  opacity: 1;
  margin-bottom: 8px;
}

.thumbnail>span {
  width: 100%;
  height: 20px;
  line-height: 20px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  background: #000;
  opacity: 0.5;
  text-align: center;
  color: #ccc;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.operation {
  display: flex;
  justify-content: space-around;
}

.operation>img {
  cursor: pointer;
}

.appendages_content>div:last-child:hover {
  cursor: pointer;
}

.param,
.static {
  width: 100%;
  height: 100%;
}

.param {
  display: flex;
}

.param_left {
  width: 60%;
}

.param_right {
  width: 40%;
  overflow: auto;
}

.tab_static,
.tab_param {
  background: #295f99;
}

.cesiumContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #242424;
}

.parameters_top {
  display: flex;
  height: 28px;
  align-items: center;
  justify-content: center;
}

.parameters_top>div:nth-child(2) {
  display: flex;
  border: 1px solid #999595;
  flex-grow: 1;
  height: 24px;
  align-items: center;
}

.parameter_content {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.parameter_content>div:nth-child(1) {
  width: 670px;
  flex-grow: 0;
  border-right: none;
  height: 100%;
}

.parameter_content>div:nth-child(2) {
  width: 300px;
  flex-grow: 1;
  height: 100%;
}

.right {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
  padding-top: 10px;
}

.right>select {
  width: 220px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.right>label {
  width: 110px;
  text-align: center;
}

.right>button,
.right>input {
  width: 220px;
  height: 30px;
  line-height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.preview {
  width: 220px;
  height: 30px;
  background: #383838;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.jsoncontent {
  width: 100%;
  font-size: 16px;
  margin-top: 30px;
  word-wrap: break-word;
  margin-bottom: 40px;
}
</style>

<template>
  <Window
    :title="'导入建筑'"
    :footervisible="false"
    :width="1000"
    :height="600"
    @cancel="cancel"
  >
    <div class="maincontent">
      <div class="polygon_data">
        <InputFile
          @srsChanged="srsChanged"
          @fieldsChanged="pointFieldsChanged"
          ref="polygon_file"
        >
        </InputFile>
      </div>
      <div class="srs_data">
        空间参考
        <textarea class="srs" v-model="srs"></textarea>
      </div>
      <div class="page_config">
        <div class="config_right">
          <div class="code" ref="code"></div>
        </div>
      </div>

      <div class="options">
        <button @click="Selectfile">导入代码</button>
        <button @click="Exportcode">导出代码</button>
        <button @click="startImport">开始导入</button>
      </div>
      <input
        type="file"
        ref="refFile"
        v-on:change="Importcode"
        style="display: none"
        accept=".js"
      />
    </div>
  </Window>
</template>

<script>
import Window from "../../../components/Window";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api.js";
import "monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution";
//import * as monaco from 'monaco-editor'
import InputFile from "./import/InputFile";
import FieldCode from "./import/FieldCode";

function importBuild(input, context) {
  var coordinates = context.xbsj_projectPointsToWGS84(input["geometry"]);
  return {
    id: input["id"],
    geom: coordinates,
    dimiangaocheng: input["底面高程"],
    cenggao: input["层高"],
    cengshu: input["层数"],
    dingmianwenli: context.xbsj_getCaizhiByName(input["顶面纹理"]),
    cemianwenli: context.xbsj_getCaizhiByName(input["侧面纹理"])
  };
}

export default {
  components: {
    Window,
    InputFile,
    FieldCode
  },
  data() {
    return {
      page: "data",
      config: "point",
      srs: ""
    };
  },
  mounted() {
    this.initEditor();
    this.$axios
      .get("/project/build/importcode", {
        params: {
          projectid: this.$root.projectid
        }
      })
      .then(res => {
        var code;
        if (res.data.data) {
          code = res.data.data;
        } else {
          code = importBuild.toString();
        }
        code += "\n";
        this._editor.setValue(code);
        // console.log(code);
        // this.setPointFields();
      })
      .catch(err => {
        console.log(err.response || err);
      });
  },
  methods: {
    Selectfile() {
      this.$refs.refFile.click();
    },
    Importcode() {
      let selectedFile = null;
      selectedFile = this.$refs.refFile.files[0];
      if (selectedFile === undefined) {
        return;
      }
      var reader = new FileReader();
      reader.readAsText(selectedFile);
      reader.onload = () => {
        this._editor.setValue(reader.result);
      };
    },
    Exportcode() {
      var bin = this._editor.getValue();
      var mimeType = "text/javascript,charset=UTF-8";
      var name = "js导出代码";
      bin = "\ufeff" + bin; //防止乱码,不能删除
      const blob = new Blob([bin], {
        type: mimeType
      }); //bin是数据，type是MIME类型
      let objectUrl = URL.createObjectURL(blob); //用来将blob或file读取成一个url

      var a = document.createElement("a"); //动态创建一个a标签
      var event = new MouseEvent("click");
      a.download = name; // 把a链接的download属性设置下，该属性值就是我们的下载文件的文件名
      a.href = objectUrl; //设置下载URL
      a.dispatchEvent(event); //点击触发下载
      URL.revokeObjectURL(objectUrl); //下载完成后释放
    },
    pointFieldsChanged(fields) {
      this.$refs.polygon_config.setInputFields(fields);
    },
    srsChanged(srs) {
      console.log(srs);
      this.srs = srs;
    },
    initEditor() {
      this._editor = monaco.editor.create(this.$refs.code, {
        value: importBuild.toString(),
        language: "javascript",
        theme: "vs-dark",
        // 自适应layout
        automaticLayout: true,
        // 右侧预览
        minimap: {
          enabled: false
        },
        wordWrap: "on"
      });
      window.editor = this._editor;
    },
    cancel() {
      this.$root.destroyTool(this);
    },
    switchPage(page) {
      this.page = page;
    },
    // setPointFields() {
    //   var fields = [
    //     {xbsj_projectPointsToWGS84 
    //       name: "ID",
    //       inner: "id",
    //       type: "string",
    //       code: "input['id']"
    //     },
    //     {
    //       name: "X",
    //       type: "number",
    //       code: "input['geometry'].coordinates[0]"
    //     },
    //     {
    //       name: "Y",
    //       type: "number",
    //       code: "input['geometry'].coordinates[1]"
    //     },
    //     {
    //       name: "Z",
    //       type: "number",
    //       code: "0"
    //     },
    //     {
    //       name: "地面高程",
    //       inner: "dimiangaocheng",
    //       type: "number",
    //       unit: "米",
    //       code: "0"
    //     },
    //     {
    //       name: "附属物",
    //       inner: "fushuwu",
    //       type: "string",
    //       code: "undefined"
    //     }
    //   ];

    //   //其他字段需要请求数据库获得

    //   this.$refs.polygon_config.setFields(fields);

    //   // fields.forEach(f => {
    //   //     this.pointFieldChanged(f);
    //   // });
    // },
    // pointFieldChanged(field) {
    //   if (!field) return;
    //   //console.log(field);
    //   //选中代码里对应的
    //   // 判断ImportBuild(**)开始的地方
    //   var code = this._editor.getValue();
    //   var func = code.indexOf("ImportBuild");
    //   if (func < 0) return;

    //   function replace(str, sidx, eidx, newstr) {
    //     return str.substring(0, sidx + 1) + newstr + str.substring(eidx);
    //   }

    //   //特殊处理的几个
    //   if (field.name == "X") {
    //     let pfunc = code.indexOf("xbsj_projectToWGS84", func);
    //     let sidx = code.indexOf("(", pfunc);
    //     let eidx = code.indexOf(",", sidx);
    //     code = replace(code, sidx, eidx, field.code);
    //     this._editor.setValue(code);
    //   } else if (field.name == "Y") {
    //     let pfunc = code.indexOf("xbsj_projectToWGS84", func);
    //     let sidx = code.indexOf(",", pfunc);
    //     let eidx = code.indexOf(",", sidx + 1);
    //     code = replace(code, sidx, eidx, field.code);
    //     this._editor.setValue(code);
    //   } else if (field.name == "Z") {
    //     let pfunc = code.indexOf("xbsj_projectToWGS84", func);
    //     let sidx = code.indexOf(",", pfunc);
    //     sidx = code.indexOf(",", sidx + 1);
    //     let eidx = code.indexOf(")", sidx);
    //     code = replace(code, sidx, eidx, field.code);
    //     this._editor.setValue(code);
    //   } else if (field.name == "附属物") {
    //     let pfunc = code.indexOf("xbsj_getFushuwuByName", func);
    //     let sidx = code.indexOf("(", pfunc);
    //     let eidx = code.indexOf(")", sidx);
    //     code = replace(code, sidx, eidx, field.code);
    //     this._editor.setValue(code);
    //   }

    //   //其他字段
    //   if (field.inner) {
    //     let pfunc = code.indexOf("return", func);
    //     let sidx = code.indexOf(field.inner, pfunc);
    //     sidx = code.indexOf(":", sidx);
    //     let eidx = code.indexOf(",", sidx);
    //     code = replace(code, sidx, eidx, field.code);
    //     this._editor.setValue(code);
    //   }
    // },
    // pointFieldFocused() { },
    transCode(code) {
      //1,寻找
      let polygon = code.match(/function\s*?importBuild/);
      if (polygon == null) throw "importBuild函数未定义";
      code = code.replace(/function\s*?importBuild/, ",importBuild");
      code = "({a:0" + code + "})";
      return code;
    },
    startImport() {
      console.log(this.$root.projectid);
      //1, 获取线路径
      var data = {
        polygonpath: this.$refs.polygon_file.fakepath,
        projectid: this.$root.projectid,
        code: this._editor.getValue(),
        srs: this.srs
      };
      console.log(data);
      //检测参数完整性
      if (data.polygonpath == "") {
        this.$root.$alert("请上传建筑面数据");
        return;
      }

      if (data.srs == "") {
        this.$root.$alert("请设置空间参考");
        return;
      }
      if (data.code == "") {
        this.$root.$alert("请设置代码");
        return;
      }
      //运行代码定义函数
      var mappingObject;
      try {
        var code = this.transCode(data.code);
        mappingObject = eval(code);
      } catch (ex) {
        this.$root.$alert("代码有误", ex);
        return;
      }
      if (!mappingObject) return;
      console.log(mappingObject);

      var context = {
        xbsj_projectPointsToWGS84(points) {
          return points;
        },
        xbsj_projectToWGS84(x, y, z) {
          //检测x,y,z 都是数字
          if (
            typeof x != "number" ||
            typeof y != "number" ||
            typeof z != "number"
          ) {
            throw "x,y,z字段需要是数字";
          }
          return {
            lon: 0,
            lat: 0,
            height: 0
          };
        },
        xbsj_getFushuwuByName(fushuwu) {
          console.log(fushuwu);
        },
        xbsj_getCaizhiByName(caizhi) {
          console.log(caizhi);
        }
      };

      //如果点数据有效，才检测点数据
      if (data.polygonpath != "") {
        //从点里获取三组数据
        var polygons = this.$refs.polygon_file.getRecords();
        //检测所有点
        try {
          polygons.forEach(polygon => {
            var p = mappingObject.importBuild(polygon, context);

            //确保关键
            //1,id 必须是字符串而且不为空
            if (typeof p.id != "string") {
              throw "id必须是字符串";
            }
            if (p.id.length == 0) {
              throw "id不能为空字符";
            }
            //3,dimiangaocheng 必须是数字
            if (typeof p.dimiangaocheng != "number")
              throw "dimiangaocheng必须为数字";

          });
        } catch (ex) {
          this.$root.$alert("代码配置代码有误:" + ex);
          return;
        }
      }

      this.$axios
        .post("/project/build/import", data)
        .then(res => {
          if (res.status == 202) {
            let data = res.data;
            console.log(data);
            this.$root.$alert(
              `总计${res.data.data.total}个建筑对象，其中${res.data.data.success}个导入数据库成功。稍后后台会开始三维数据创建过程，请在任务查询里查看数据处理进度`
            );
            this.close();

          }
        })
        .catch(err => {
          if (err === 'timeout') {
            this.$root.$alert("数据导入耗时较长，请稍候刷新页面");
          } else {
            this.$root.$alert("数据导入失败:" + err.data.message || err.response);
          }
        });
    },
    close() {
      this.$root.destroyTool(this);
    }
  },
  beforeUnmount() {
    //销毁
  }
};
</script>

<style scoped>
.config_field > div {
  width: 100%;
  height: 100%;
}

.page_config {
  display: flex;
  flex-direction: row;
  justify-items: stretch;
  height: 100%;
  justify-content: stretch;
}

.config_left {
  flex-grow: 1;
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-items: stretch;
  justify-content: stretch;
  border: 1px solid #d3d3d3;
  border-right: none;
}

.config_tab {
  flex-grow: 0;
  width: 50px;
  display: flex;
  flex-direction: column;
}

.config_field {
  flex-grow: 1;
  border-left: 1px solid #d3d3d3;
  margin-top: 40px;
  padding-right: 30px;
  padding-right: 30px;
}

.config_right {
  flex-grow: 2;
  border: 1px solid #d3d3d3;
  width: 50%;
}

textarea {
  background: #242424;
  color: #cccccc;
}

.maincontent {
  background: #242424;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
}

.tab {
  height: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-items: stretch;
  margin-bottom: 2px;
  align-items: stretch;
}

.polygon_data {
  border: 1px solid #d3d3d3;
  min-height: 150px;
}
.srs_data {
  flex-grow: 0;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  background: #242424;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  margin-bottom: 2px;
  color: #ccc;
  font-size: 14px;
}

.srs {
  width: 100%;
  flex-grow: 1;
  border: none;
}

.tabbutton {
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
}

.tabbutton.checked {
  background: #288dfb;
}

.tabbutton::before {
  display: inline-block;
  content: "";
  height: 100%;
  vertical-align: middle;
}

.options {
  display: flex;
  width: 100%;
  text-align: right;
  height: 40px;
  flex-grow: 0;
}

.options > button {
  height: 33px;
  width: 100%;
  margin-top: 10px;
}

.code {
  width: 100%;
  height: 100%;
}
</style>

<template>
    <div class="property">
        <div class="propertyHeard">
            <h4>建筑属性</h4>
            <ul>
                <li>建筑ID：{{ dataBuild.id }}</li>
                <li>底面高程：{{ dataBuild.dimiangaocheng }}米</li>
                <li>高度：{{ dataBuild.height }}米</li>
                <li v-for="(item, index) in custom" :key="index">{{ item[0] }}:{{ item[1] }}</li>
            </ul>
        </div>
        <div class="propertyfoo">
            <h4>建筑操作</h4>
            <div class="propertybtn">
                <button @click="updateCoordinate">编辑底面</button>
                <button @click="updateCeCaizhiPop">编辑侧面材质</button>
                <button @click="updateDingCaizhiPop">编辑顶面材质</button>
                <button @click="flag=true ">修改参数</button>
                <button @click="deleteBuild">删除</button>
                <button @click="locate">定位</button>
            </div>
        </div>
        <Window :title="'修改建筑参数'" :footervisible="true" v-show="flag" @ok="ok" @cancel="cancel" :width="300" :height="180">
            <div class="row_single">
                <label>高度:</label>
                <input type="number" v-model="dataBuild.height">
            </div>
            <div class="row_single">
                <label>底面高程:</label>
                <input type="number" v-model=" dataBuild.dimiangaocheng">
            </div>
        </Window>
    </div>
</template>

<script>
/* eslint-disable */
import MaterialSelect from '../../page-editor/components/MaterialSelect'
import Window from '../../../components/Window'
export default {
	components: {
		Window,
		MaterialSelect
	},
	data() {
		return {
			flag: false,
			dataBuild: {},
			showQuery: true,
			show: true,
			lat: '',
			lng: '',
			height: '',
			custom: [],
			maptable: []
		}
	},
	name: 'PointProperty',
	props: {
		param: Object
	},
	created() {
		this.query()
	},
	beforeUnmount() {
		this.$root._tileset.unSelectFeature(this.param.id)
	},
	mounted() {},
	methods: {
		ok() {
			this.updateShape()
			// this.updateDiHeight()
		},
		cancel() {
			this.flag = false
			this.$root.destroyTool(this)
		},
		locate() {
			this.$root._tileset.selectFeature({
				id: this.dataBuild.id,
				otype: 'build'
			})
		},
		mapquery() {
			this.custom = []
			let self = this
			let data = {
				projectid: this.$root.projectid
			}
			this.$axios
				.get('/project/build/column/query', {
					params: data
				})
				.then(res => {
					let arr = Object.keys(self.dataBuild)
					self.maptable = res.data.data
					for (let j = 0; j < arr.length; j++) {
						for (let i = 0; i < self.maptable.length; i++) {
							if (arr[j] === self.maptable[i]['column_name'] && self.maptable[i]['column_editable']) {
								let tempObject = [self.maptable[i].column_chinese, self.dataBuild[arr[j]]]
								self.custom.push(tempObject)
							}
						}
					}
				})
				.catch(err => {
					console.log(err.response || err)
				})
		},
		async query() {
			//let _this = this
			let params = {
				projectid: this.$root.projectid,
				id: this.param.id
			}
			this.$axios
				.get('/project/build/query', {
					params
				})
				.then(res => {
					if (res.data) {
						if (res.data.data.results.length == 1) {
							//console.log("数据在这里", res.data.data)
							this.dataBuild = res.data.data.results[0]
							this.mapquery()
							this.lat = this.dataBuild.geom.coordinates[0]
							this.lng = this.dataBuild.geom.coordinates[1]
							this.height = this.dataBuild.geom.coordinates[2]
							this.$bus.emit('pickPolygon', this.dataBuild)
						}
					}
				})
				.catch(err => {
					console.log(err.response || err)
					this.$root.$log('error', err)
				})
		},
		updateCoordinate() {
			this.$root.$refs.bottom.switchTool({
				name: '编辑底面:' + this.dataBuild.id,
				component: 'ModifyPolygonTool',
				param: {
					polygon: this.dataBuild
				}
			})
		},

		updateCeCaizhiPop() {
			let _this = this
			this.$root.showPropertyWindow('MaterialSelect', {
				component: MaterialSelect,
				param: {
					ok: option => {
						console.log('( •̀ ω •́ )y拿到了侧面材质', option)
						_this.updateCeCaiZhi(option)
					}
				}
			})
		},
		updateDingCaizhiPop() {
			let _this = this
			this.$root.showPropertyWindow('MaterialSelect', {
				component: MaterialSelect,
				param: {
					ok: option => {
						console.log('( •̀ ω •́ )y拿到了顶面材质', option)
						_this.updateDingCaizhi(option)
					}
				}
			})
		},
		async updateCeCaiZhi(opt) {
			//http://localhost:3001/project/build/material/update/ids put
			let _this = this
			console.log('传参过程中', opt)
			let data = {
				projectid: _this.$root.projectid,
				ids: [this.param.id],
				cemiancaizhi: JSON.stringify(opt.param)
			}
			console.log('属性面板修改数据', data)
			this.$axios
				.put('/project/build/material/update/ids', data)
				.then(res => {
					_this.query()
					console.log(res)
					_this.$root.$alert('选中侧面材质修改成功')
					if (res.data.data && res.data.data === true) {
						_this.$root._tileset.reload()
					}
				})
				.catch(err => {
					this.$root.$log('error', err)
					console.log(err.response || err)
					this.$root.$alert('选中侧面材质修改失败')
				})
		},
		async updateDingCaizhi(opt) {
			//http://localhost:3001/project/build/material/update/ids put
			let _this = this
			//console.log("传参过程中", opt)
			let data = {
				projectid: _this.$root.projectid,
				ids: [this.param.id],
				dingmiancaizhi: JSON.stringify(opt.param)
			}
			console.log('属性面板修改数据', data)
			this.$axios
				.put('/project/build/material/update/ids', data)
				.then(res => {
					_this.query()
					console.log(res)
					this.$root.$alert('选中顶面材质修改成功')
					if (res.data.data && res.data.data === true) {
						this.$root._tileset.reload()
					}
				})
				.catch(err => {
					this.$root.$log('error', err)
					console.log(err.response || err)
					this.$root.$alert('选中顶面材质修改失败')
				})
		},
		async deleteBuild() {
			let _this = this
			// console.log(_this.items.id);
			let data = {
				projectid: _this.$root.projectid,
				ids: JSON.stringify([this.dataBuild.id])
			}

			//包含要删除管点的所有管线查询
			this.$axios
				.delete('/project/build/delete/ids', {
					data
				})
				.then(res => {
					console.log(res)

					this.dataBuild = ''
					this.$root.$alert('建筑删除成功')
					if (res.data.data && res.data.data === true) {
						this.$root._tileset.reload()
					}
				})
				.catch(err => {
					this.$root.$log('error', err)
					console.log(err.response || err)
					this.$root.$alert('建筑删除失败' + err.response || err)
				})
		},
		updateShape() {
			let _this = this
			let data = {
				projectid: _this.$root.projectid,
				ids: [this.param.id]
			}
			this.dataBuild.height = this.dataBuild.height * 1
			this.dataBuild.dimiangaocheng = this.dataBuild.dimiangaocheng * 1
			if (this.dataBuild.height || this.dataBuild.height === 0) {
				data.height = this.dataBuild.height * 1
			}
			if (this.dataBuild.dimiangaocheng || this.dataBuild.dimiangaocheng === 0) {
				data.dimiangaocheng = this.dataBuild.dimiangaocheng * 1
			}
			console.log('属性面板修改数据', data)
			this.$axios
				.put('/project/build/shape/update/ids', data)
				.then(res => {
					_this.query()
					_this.flag = false
					console.log(res)
					_this.$root.$alert('建筑参数修改成功')
					if (res.data.data && res.data.data === true) {
						_this.$root._tileset.reload()
					}
				})
				.catch(err => {
					this.$root.$log('error', err)
					console.log(err.response || err)
					this.$root.$alert('建筑参数修改失败')
				})
		}
	}
}
</script>

<style scoped>
.property {
	color: #cccccc;
	width: var(--RightWidth);
	height: 100%;
	background: var(--PanelBGColor);
}

.propertyHeard {
	border: 1px solid #383838;
	margin-bottom: 10px;
}

.propertyHeard > ul {
	height: 300px;
	margin-left: 30px;
	text-align: left;
	overflow: auto;
}

.propertyHeard li {
	height: 20px;
}

.propertyfoo {
	border: 1px solid #383838;
	margin-top: 10px;
}

.propertybtn {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
}

.propertybtn button {
	height: 30px;
	width: 120px;
	margin: 10px 0px;
}

.row_single {
	margin-top: 10px;
	display: flex;
	align-items: center;
}

.row_single > label {
	flex-shrink: 0;
	width: 30px;
}

.row_single > label:nth-child(1) {
	flex-shrink: 0;
	width: 75px;
}

.row_single > input {
	min-width: 10px;
	box-sizing: border-box;
	flex-grow: 1;
	background: #2e2e2e;
	border: 1px solid #383838;
	border-radius: 2px;
	color: #ccc;
	height: 30px;
	line-height: 30px;
}
</style>

<template>
    <div class="right" v-show="(property != undefined)">
        <div class="title">{{ title }}
            <!-- <ion-icon @click="hide" class="ion" name="close-outline"></ion-icon> -->
            <IosCloseIcon @click="hide" class="ion"></IosCloseIcon>
        </div>



        <component :is="property" v-if="property != undefined" :param="param"></component>
    </div>
</template>
<script>
    import ProjectProperty from "./properties/ProjectProperty";
    import PointProperty from "./properties/PointProperty";
    import BuildProperty from "./properties/BuildProperty";
    import LineProperty from "./properties/LineProperty";
    import ImageryProperty from "./properties/ImageryProperty";
    import LayerProperty from "./properties/LayerProperty";
    import SceneProperty from "./properties/SceneProperty";
    import TerrainProperty from "./properties/TerrainProperty";
    import TilesProperty from "./properties/TilesProperty";
    import CutSurfaceProperty from "./properties/effect/CutSurfaceProperty"
    import BloomProperty from "./properties/effect/BloomProperty"
    import DepthOfFieldProperty from "./properties/effect/DepthOfFieldProperty"
    import GlobeTranslucencyProperty from "./properties/effect/GlobeTranslucencyProperty"
    import ShadowProperty from "./properties/effect/ShadowProperty"
    import PipeTaskQueryProperty from "./properties/PipeTaskQueryProperty"
    import IosCloseIcon from "vue-ionicons/dist/ios-close.vue";
    import VideoTutorial from "./properties/VideoTutorial"
    export default {
        name: "Left",
        components: {
            ProjectProperty,
            PointProperty,
            BuildProperty,
            LineProperty,
            ImageryProperty,
            LayerProperty,
            SceneProperty,
            TerrainProperty,
            TilesProperty,
            CutSurfaceProperty,
            PipeTaskQueryProperty,
            IosCloseIcon,
            VideoTutorial

        },
        data() {
            return {
                property: undefined,
                title: "",
                param: undefined,
                show: true
            };
        },
        watch: {
            property: {
                handler() {
                    this.show = true
                }
            }
        },
        mounted() {
            this.showProject();

            window._testRight = this;
        },
        methods: {

            hide() {
                this.property = undefined;
            },
            //显示项目属性
            showProject() {
                this.property = undefined;
                this.$nextTick(() => {
                    this.property = ProjectProperty;
                    this.title = "项目属性";
                    this.param = undefined;
                });
            },
            // 显示任务查询
            showPipeTask() {
                this.property = undefined;
                this.$nextTick(() => {
                    this.property = PipeTaskQueryProperty;
                    this.title = "任务查询属性";
                    this.param = undefined;
                });
            },
            //显示一个管点的属性
            showPoint(point) {
                this.property = undefined;

                this.$nextTick(() => {
                    this.property = PointProperty;
                    this.title = "管点属性";
                    this.param = point;
                });
            },
            //显示一个管线的属性
            showLine(line) {
                this.property = undefined;

                this.$nextTick(() => {
                    this.property = LineProperty;
                    this.title = "管线属性";
                    this.param = line;
                });
            },
            //显示一个管线的属性
            showBuild(build) {
                this.property = undefined;

                this.$nextTick(() => {
                    this.property = BuildProperty;
                    this.title = "建筑属性";
                    this.param = build;
                });
            },
            //显示一个管线图层属性
            showLayer(param, arr) {
                this.property = undefined;

                this.$nextTick(() => {
                    this.property = LayerProperty;
                    this.title = "管线图层属性";
                    this.param = {
                        param: param,
                        arr: arr
                    };
                });
            },
            //显示一个基础场景属性
            showScene(param) {
                this.property = undefined;

                this.$nextTick(() => {
                    this.property = SceneProperty;
                    this.title = "基础场景属性";
                    this.param = param;
                });
            },
            //显示一个地形图层属性
            showTerrain(param) {
                this.property = undefined;

                this.$nextTick(() => {
                    this.property = TerrainProperty;
                    this.title = "地形图层属性";
                    this.param = param;
                });
            },
            //显示一个影像图层属性
            showImagery(param) {
                this.property = undefined;

                this.$nextTick(() => {
                    this.property = ImageryProperty;
                    this.title = "影像图层属性";
                    this.param = param;
                });
            },
            //显示一个挖坑图层属性
            showCutSurface(param) {
                this.property = undefined;

                this.$nextTick(() => {
                    this.property = CutSurfaceProperty;
                    this.title = "挖坑图层属性";
                    this.param = param;
                });
            },
            //显示一个瓦片属性
            showTiles(param) {
                this.property = undefined;

                this.$nextTick(() => {
                    this.property = TilesProperty;
                    this.title = "瓦片图层属性";
                    this.param = param;
                });
            },
            //显示泛光属性
            showBloom() {
                this.property = undefined;

                this.$nextTick(() => {
                    this.property = BloomProperty;
                    this.title = "泛光效果属性";
                });
            },
            //显示景深属性
            showDepthOfField() {
                this.property = undefined
                this.$nextTick(() => {
                    this.property = DepthOfFieldProperty;
                    this.title = "景深效果属性"
                })
            },
            //显示地表透明属性
            showglobeTranslucency() {
                this.property = undefined
                this.$nextTick(() => {
                    this.property = GlobeTranslucencyProperty;
                    this.title = "地表透明效果属性"
                })
            },
            //显示阴影效果属性
            showshadow() {
                this.property = undefined
                this.$nextTick(() => {
                    this.property = ShadowProperty;
                    this.title = "阴影效果属性"
                })
            },
            showcourse() {
                this.property = undefined
                this.$nextTick(() => {
                    this.property = VideoTutorial;
                    this.title = "视频教程"
                })
            }
        }
    };
</script>

<style scoped>
    .right {
        color: #CCCCCC;
        margin-top: 3px;
        display: block;
        width: var(--RightWidth);
        min-width: 300px;
        flex-grow: 0;
        background: var(--BackgroundColor);
    }

    .title {
        position: relative;
        text-align: center;
        height: 30px;
        line-height: 30px;
        flex-grow: 0;

    }

    .ion {
        position: absolute;
        right: 0px;
        font-size: 25px;
        cursor: pointer;

    }
</style>

import * as zrender from 'zrender'
/* eslint-disable */
class DrawRectangleSection {
  constructor(line) {
    this._line = line
    this._line.duanmian = {}
    this._line.duanmian.width = line.param_j.profile.width
    this._line.duanmian.height = line.param_j.profile.height
  }
  draw(offsetx, offsety, offset, height, scale, zr) {
    var x =
      (this._line.distance + offsetx - this._line.duanmian.width / 2) * scale
    var y =
      height -
      (this._line.height + offsety + this._line.duanmian.height / 2) * scale
    var width = this._line.duanmian.width * scale
    var height = this._line.duanmian.height * scale
    //更改属性，先创建一个矩形
    const rect = new zrender.Rect({
      id: this._line.qidian + '_' + this._line.zhongdian,
      shape: {
        x: x + offset,
        y: y + offset,
        height: height,
        width: width,
      },
      style: {
        fill: '#F000',
        stroke: '#fff',
      },
    })
    zr.add(rect)
    // ctx.beginPath();
    // ctx.rect(x + offset, y + offset, width, height);
    // ctx.stroke();
    // ctx.fillText(this._line.qidian + "_" + this._line.zhongdian, x + offset + width / 2, y + offset + height / 2);
    // ctx.closePath();
  }
  box() {
    return {
      minx: this._line.distance - this._line.duanmian.width,
      miny: this._line.height - this._line.duanmian.height,
      maxx: this._line.distance + this._line.duanmian.width,
      maxy: this._line.height + this._line.duanmian.height,
    }
  }
}

export default DrawRectangleSection

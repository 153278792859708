<template>
	<div class="property">
		<div class="panel">
			<label class="title">项目属性</label>
			<label>名称:</label>
			<input v-model="name" class="input" @keyup.enter="updateTitle">
			<label>缩略图:</label>
			<img class="img" :src="src ? src : require('../assets/pipeser.jpg')">
			<button @click="updateThumbnail">更新缩略图</button>
		</div>
		<div class="panel">
			<label class="title">3dtiles服务</label>
			<label>自动重构</label>
			<input class="bty-switch" @click="isCanautorebuild" type="checkbox" v-model="autorebuild">
			<label>访问验证:</label>
			<input class="bty-switch" type="checkbox" v-model="needToken">
			<label>地址:</label>
			<input v-model="tilesetUrl" readonly class="input">
			<button @click="flyTo">飞行定位</button>
			<button @click="updateAllFeature">重构数据</button>
			<button v-if="authType == 'auth'" @click="cach(true)">缓存clt</button>
			<button v-if="authType == 'auth'" @click="cach(false)">缓存3dtiles</button>
		</div>
		<div class="panel">
			<button @click="pipePointField">管点字段属性</button>
			<button @click="pipeLineField">管线字段属性</button>
		</div>
	</div>
</template>
<script>
/* eslint-disable */
import PipePointField from '../components/PipePointField'
export default {
	name: 'ProjectProperty',
	props: {
		param: Object
	},
	data() {
		return {
			user: undefined,
			tilesetUrl: '',
			name: '新建管线项目',
			needToken: true,
			needTokenserve: false,
			src: '',
			pagecap: 9,
			page: 1,
			autorebuild: true,
			iscan: false,
			authType: 'test'
		}
	},

	created() {
		this.tilesetUrl = this.$root._tileset.url()
		this.chonggou()
	},
	beforeUnmount() {
		console.log('ProjectProperty beforeUnmount')
		//this._disposers.forEach(d => d());
	},
	mounted() {
		// this.cache3dtiles = (this.$env == 'prod')
		this.$axios
			.get('/project/info', {
				params: {
					projectid: this.$root.projectid
				}
			})
			.then(res => {
				let project = res.data.data
				this.authType = project.type
				this.name = project.name
				document.title = 'PipeSer.com ' + this.name
				this.src = project.thumbnail
				this.needTokenserve = project.publish
				if (this.needToken != this.needTokenserve) {
					this.needToken = this.needTokenserve
				}
				this.$bus.emit('updatename', this.name)
			})
			.catch(err => {
				console.log(err.response || err)
			})
	},
	watch: {
		autorebuild() {
			if (this.iscan) {
				this.updateAutorebuild()
			}
		},
		needToken() {
			if (this.needToken === this.needTokenserve) {
				return
			}
			this.$axios
				.put('/project/update', {
					id: this.$root.projectid,
					publish: this.needToken
				})
				.then(res => {
					this.needTokenserve = this.needToken
				})
				.catch(err => {
					console.log(err.response || err)
				})
		}
	},
	methods: {
		isCanautorebuild() {
			this.iscan = true
		},
		updateAutorebuild() {
			this.$axios
				.put('/project/info/update', {
					projectid: this.$root.projectid,
					autorebuild: this.autorebuild
				})
				.then(res => {
					if (this.autorebuild) {
						this.updateAllFeature()
					}
					console.log('重构反馈', res)
				})
				.catch(err => {
					this.$root.$alert(err.data.message)
					console.log(err.response || err)
				})
		},
		chonggou() {
			if (this.$root.projectid) {
				let self = this
				this.$axios
					.get('/project/info/autorebuild', {
						params: {
							projectid: this.$root.projectid
						}
					})
					.then(res => {
						self.autorebuild = res.data.data
						console.log('autorebuild', self.autorebuild)
					})
					.catch(err => {
						this.$root.$alert(err.data.message)
						console.log(err.response || err)
					})
			}

		},
		pipePointField() {
			this.$root.showPropertyWindow(
				'PipePointField',
				{
					component: PipePointField
				},
				'point'
			)
		},
		pipeLineField() {
			this.$root.showPropertyWindow(
				'PipePointField',
				{
					component: PipePointField
				},
				'line'
			)
		},
		reload() {
			this.$root._tileset.reload()
		},
		cach(clt) {
			//提交缓存请求
			this.$root.$confirm('缓存请求过程比较缓慢是否继续提交?', () => {
				var params = {
					projectid: this.$root.projectid,
					clt: clt
				}
				let self = this
				this.$axios
					.get('/3dtiles/download', {
						params: params
					})
					.then(res => {
						this.$root.$alert(res.data.data)
					})
					.catch(err => {
						console.log(err.response || err)
					})
			})
		},
		async updateThumbnail() {
			let self = this
			//三维截图，提交到服务端存储
			await this.capture(this.$root._earth._scene, 200, 200).then(img => {
				self.$axios
					.put('/project/update', {
						id: this.$root.projectid,
						thumbnail: img
					})
					.then(res => {
						console.log(res)
						self.src = img
					})
					.catch(err => {
						console.log(err.response || err)
					})
			})
			//修改img的图像
		},
		updateTitle() {
			//提交到服务器存储
			this.$axios
				.put('/project/update', {
					id: this.$root.projectid,
					name: this.name
				})
				.then(() => {
					this.$bus.emit('updatename', this.name)
					document.title = 'PipeSer.com ' + this.name
				})
				.catch(err => {
					console.log(err.response || err)
				})
		},
		updateAllFeature() {
			this.$root.$confirm('该重构过程比较耗时是否继续?', () => {
				this.$axios
					.put('/project/feature/update', {
						projectid: this.$root.projectid
					})
					.then(() => {
						this.$root.$alert('任务已经添加，请在任务查询界面查看进度')
					})
					.catch(err => {
						console.log(err.response || err)
					})
			})
		},
		flyTo() {
			this.$root._tileset.tileset.flyTo()
		},
		// 截图base64格式
		capture(scene, width, height, format) {
			if (!width || typeof width != 'number') width = 128
			if (!height || typeof height != 'number') height = 128
			var captureScreenCanvas
			return new Promise((resolve, reject) => {
				var removeEventListenFunc = scene._postRender.addEventListener(() => {
					let sceneCanvas = scene.canvas
					let sceneWidth = sceneCanvas.width
					let sceneHeight = sceneCanvas.height

					if (!Cesium.defined(captureScreenCanvas)) {
						captureScreenCanvas = document.createElement('canvas')
					}
					captureScreenCanvas.setAttribute('width', width)
					captureScreenCanvas.setAttribute('height', height)

					let sx, sy, sw, sh
					//裁剪范围 判定按 w  或者 h 缩放
					if (sceneWidth / width < sceneHeight / height) {
						sx = 0
						sw = sceneWidth
						sh = (height * sceneWidth) / width
						sy = (sceneHeight - sh) * 0.5
					} else {
						sy = 0
						sh = sceneHeight
						sw = (width * sceneHeight) / height
						sx = (sceneWidth - sw) * 0.5
					}

					let ctx = captureScreenCanvas.getContext('2d')
					ctx.drawImage(sceneCanvas, sx, sy, sw, sh, 0, 0, width, height)
					let img64 = captureScreenCanvas.toDataURL(format ? format : 'image/jpeg')
					resolve(img64)
					removeEventListenFunc()
				}, null)
			})
		}
	}
}
</script>

<style scoped>
.property {
	color: #cccccc;
	width: var(--RightWidth);
	height: 100%;
	background: var(--PanelBGColor);
	padding: 5px;
}

.panel {
	width: 100%;
	display: grid;
	grid-template-columns: 20% 80%;
	margin-top: 10px;
	grid-row-gap: 10px;
	grid-column-gap: 5px;
	width: calc(100% - 10px);
	border: 1px solid #484545;
}

.title {
	grid-column-start: 1;
	grid-column-end: 3;
	text-align: center;
}

button {
	width: 120px;
	grid-column-start: 2;
}

.input {
	width: 200px;
	background: #383838;
	border: 1px solid #4d4d4d;
	opacity: 1;
	border-radius: 2px;
	color: white;
	height: 25px;
}

img {
	width: 200px;
	height: 200px;
}
</style>>

<template>
    <div class="bottom">
        <div class="title">
            <span>{{ title }}</span>
            <span class="mousestatus">
                鼠标位置:{{ jingdu.toFixed(6) }} ° {{ weidu.toFixed(6) }}°
                {{ height.toFixed(2) }}米
            </span>
            <div class="tools">
                <div class="tool_">
                    <label>颜色模式:</label>
                    <select v-model="colormode">
                        <option value="default">真实材质</option>
                        <option value="color">图层纯色</option>
                        <option value="mixed">图层混色</option>
                    </select>
                </div>
                <div class="tool_">
                    <label>光照模式:</label>
                    <select v-model="lightmode">
                        <option value="default">实时太阳光</option>
                        <option value="noon">正午太阳光</option>
                        <option value="head">头顶灯</option>
                    </select>
                </div>
                <div class="tool" title="地形深度检测" @click="depthTest = !depthTest">
                    <img v-show="depthTest" src="./assets/newicons/shendujiance2.png" class="img">
                    <img v-show="!depthTest" src="./assets/newicons/shendujiance1.png" class="img" alt="">
                    <!-- <div
            class="icon"
            :class="{ checked: depthTest }"
            @click="depthTest = !depthTest"
                    ></div>-->
                </div>
                <div class="tool" title="地下模式" @click="subSurfaceEnabled = !subSurfaceEnabled">
                    <img v-show="subSurfaceEnabled" src="./assets/newicons/dixia2.png" class="img">
                    <img v-show="!subSurfaceEnabled" src="./assets/newicons/dixia1.png" class="img" alt="">
                    <!-- <div
            class="icon"
            :class="{ checked: subSurfaceEnabled }"
            @click="subSurfaceEnabled = !subSurfaceEnabled"
                    ></div>-->
                </div>
                <div class="tool" title="地表透明">
                    <img v-show="globelenable" src="./assets/newicons/dibiaotouming2.png" class="img" @click="globel">
                    <img v-show="!globelenable" src="./assets/newicons/dibiaotouming1.png" class="img" alt="" @click="globel">
                    <!-- <div
            class="icon"
            @click="globel"
            :class="{ checked: globelenable }"
                    ></div>-->
                    <div class="more" @click="globeTranslucencyProperty">▼</div>
                </div>
                <div class="tool" title="模型阴影">
                    <img v-show="shadow" src="./assets/newicons/moxingyinying2.png" class="img" @click="shadowshow">
                    <img v-show="!shadow" src="./assets/newicons/moxingyinying1.png" class="img" @click="shadowshow" alt="">
                    <!-- <div
            class="icon"
            @click="shadowshow"
            :class="{ checked: shadow }"
                    ></div>-->
                    <div class="more" @click="shadowProperty">▼</div>
                </div>
                <div class="tool" title="全局泛光">
                    <img v-show="bloom" src="./assets/newicons/quanjufanguang2.png" class="img" @click="bloomshow">
                    <img v-show="!bloom" src="./assets/newicons/quanjufanguang1.png" class="img" @click="bloomshow" alt="">
                    <!-- <div
            class="icon"
            @click="bloomshow"
            :class="{ checked: bloom }"
                    ></div>-->
                    <div class="more" @click="bloomProperty">▼</div>
                </div>
                <div class="tool" title="全局景深">
                    <img v-show="depthOfField" src="./assets/newicons/quanjujingshen2.png" class="img" @click="depthOfFieldShow">
                    <img v-show="!depthOfField" src="./assets/newicons/quanjujingshen1.png" class="img" @click="depthOfFieldShow" alt="">
                    <div class="more" @click="depthOfFieldProperty">▼</div>
                </div>
                <!-- <div class="tool" :title="logshow ? '关闭日志' : '显示日志'" @click="logshow = !logshow">
                    <img v-show="logshow" src="./assets/newicons/xianshirizhi.png" class="img" />
                    <img v-show="!logshow" src="./assets/newicons/guanbirizhi.png" class="img" alt="" />
                    
                </div>-->
                <div class="tool" title="强制刷新">
                    <img src="./assets/newicons/shuaxin1.png" class="img" @click="reload" alt="">
                </div>
            </div>
        </div>
        <component :is="tool" v-if="tool != undefined" :param="param"></component>
        <div v-show="logshow" class="bottomlog">
            <p
                v-for="(item, index) in logContent"
                :key="index"
                :class="[
          { redcolor: item.type == 'error' },
          { yellowcolor: item.type == 'warning' },
        ]"
            >{{ item.type }}——{{ item.time }} ~{{ item.content }}</p>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import pipeBuildQuery from './components/pipeBuildQuery'
import PipePointQuery from './components/PipePointQuery'
import PipeLineQuery from './components/PipeLineQuery'
import Connectivity from './components/Connectivity'
import Burst from './components/Burst'
import Section from './components/Section/Index'
import Profile from './components/Profile/Index'
import AddLineTool from './components/AddLineTool'
import DropDownList from '../../components/DropDownList'
import AddPointTool from './components/AddPointTool'
import AddBuildTool from './components/AddBuildTool'
import ModifyPointTool from './components/ModifyPointTool'
import ModifyPolygonTool from './components/ModifyPolygonTool'
import ModifyPolygonCaizhi from './components/ModifyPolygonCaizhi'
import ModifyFSWTool from './components/ModifyFSWTool'

export default {
	name: 'Bottom',
	components: {
		AddBuildTool,
		pipeBuildQuery,
		PipePointQuery,
		PipeLineQuery,
		Connectivity,
		Burst,
		Section,
		Profile,
		AddLineTool,
		AddPointTool,
		DropDownList,
		ModifyPointTool,
		ModifyPolygonTool,
		ModifyPolygonCaizhi,
		ModifyFSWTool
	},
	data() {
		return {
			logContent: [
				{
					type: '',
					content: ''
				}
			],
			colormode: 'default',
			lightmode: 'default',
			logshow: false,
			name: '',
			title: '状态栏',
			jingdu: 0,
			weidu: 0,
			height: 0,
			tool: undefined,
			param: undefined,
			shadow: false,
			bloom: false,
			depthOfField: false,
			globelenable: false,
			depthTest: false,
			subSurfaceEnabled: false,
			selectname: ''
		}
	},
	mounted() {
		this.init()

		this.$root.$bus.on('3d_moving', ({ carto }) => {
			if (carto) {
				const [l, b, h] = carto
				this.jingdu = (l * 180) / Math.PI
				this.weidu = (b * 180) / Math.PI
				this.height = h
			}
		})

		window.bottom = this
	},

	methods: {
		reload() {
			this.$root._tileset.reload()
		},
		init() {
			this._disposers = []
			this._disposers.push(XE.MVVM.bind(this, 'globelenable', this.$root._earth.terrainEffect.globeTranslucency, 'enabled'))
			this.$root._earth.terrainEffect.globeTranslucency.frontFaceAlpha = 0.5
			this._disposers.push(XE.MVVM.bind(this, 'shadow', this.$root._earth.effect.shadow, 'enabled'))
			this._disposers.push(XE.MVVM.bind(this, 'shadow', this.$root._earth.effect.shadow, 'enabled'))
			this._disposers.push(XE.MVVM.bind(this, 'bloom', this.$root._earth.postProcess.bloom, 'enabled'))
			this._disposers.push(XE.MVVM.bind(this, 'depthOfField', this.$root._earth.postProcess.depthOfField, 'enabled'))
			this._disposers.push(XE.MVVM.bind(this, 'depthTest', this.$root._earth.terrainEffect, 'depthTest'))
			this._disposers.push(XE.MVVM.bind(this, 'subSurfaceEnabled', this.$root._earth.terrainEffect, 'subSurfaceEnabled'))
		},
		closeTool() {
			this.tool = undefined
			this.title = '状态栏'
		},
		switchTool(tool) {
			//无论如何都关闭tool
			this.closeTool()
			//如果名称不同，那么重开tool
			console.log(tool.name)
			if (tool.name != this.name) {
				this.$nextTick(() => {
					this.tool = tool.component
					this.title = tool.name
					this.param = tool.param
					this.name = tool.name
				})
			} else {
				this.name = ''
			}
		},
		globel() {
			this.globelenable = !this.globelenable
			console.log('地表透明', this.$root._earth.terrainEffect.globeTranslucency)
		},
		globeTranslucencyProperty() {
			this.$root.$refs.right.showglobeTranslucency()
		},
		shadowshow() {
			this.shadow = !this.shadow
		},
		shadowProperty() {
			this.$root.$refs.right.showshadow()
		},
		bloomshow() {
			this.bloom = !this.bloom
		},
		bloomProperty() {
			this.$root.$refs.right.showBloom()
		},
		depthOfFieldShow() {
			this.depthOfField = !this.depthOfField
		},
		depthOfFieldProperty() {
			this.$root.$refs.right.showDepthOfField()
		},
		rizhi() {
			this.logshow = !this.logshow
		}
	},
	watch: {
		colormode: function(newv) {
			if (newv == 'default') {
				this.$root._tileset.colorDefault()
			} else if (newv == 'color') {
				this.$root._tileset.colorByLayer()
			} else if (newv == 'mixed') {
				this.$root._tileset.colorByLayerMixed()
			}
		},
		lightmode: function(newv) {
			const earth = this.$root._earth
			if (newv == 'default') {
				//默认，随着时间变化的
				earth.effect.forceSunPos.enabled = false
				earth.effect.forceSunPos.useCameraPos = false
			} else if (newv == 'noon') {
				//正午12点光照
				earth.effect.forceSunPos.enabled = true
				earth.effect.forceSunPos.useCameraPos = false
				earth.effect.forceSunPos.position = [(116.39 / 180) * Math.PI, (39.9 / 180) * Math.PI, 100000000]
			} else if (newv == 'head') {
				//光照方向和观察者一致
				earth.effect.forceSunPos.enabled = true
				earth.effect.forceSunPos.useCameraPos = true
			}
		}
	}
}
</script>

<style scoped>
.logspan {
	cursor: pointer;
}

.bottom {
	border: 1px solid #383838;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	background: var(--PanelBGColor);
	color: #fff;
	height: auto;
	box-sizing: border-box;
}

.title {
	text-align: left;
	height: 30px;
	line-height: 30px;
	color: #cccccc;
	background: var(--BackgroundColor);
	display: grid;
	grid-template-columns: 300px 300px 1fr;
}

.bottomlog {
	width: 100%;
	height: 100px;
	background: var(--PanelBGColor);
	overflow: auto;
	z-index: 9999;
}

.logbutton {
	float: right;
	margin-right: 10px;
	margin-top: 5px;
}

.redcolor {
	color: rgb(255, 0, 0);
}

.yellowcolor {
	color: rgb(255, 255, 0);
}

.bottom > div > p {
	margin-bottom: 20px;
	border-bottom: 1px solid rgb(212, 89, 89);
}

.tools {
	display: flex;
	flex-direction: row;
	height: 30px;
	justify-content: flex-end;
}

.tool_ {
	display: flex;
	align-items: center;
	margin-right: 10px;
}

.tool {
	display: flex;
	align-items: center;
	margin-right: 10px;
}

.tool > img:hover {
	display: flex;
	align-items: center;
	/* margin-right: 10px; */
	background: #1a1a1a;
	opacity: 1;
	border-radius: 2px;
}

.tool .icon {
	width: 20px;
	height: 20px;
	background: white;
	border-radius: 2px;
	cursor: pointer;
}

.tool .icon {
	width: 20px;
	height: 20px;
	background: white;
	border-radius: 2px;
	cursor: pointer;
}

.tool .icon.checked {
	background: red;
}

.tool .more {
	width: 10px;
	height: 20px;
	line-height: 25px;
	cursor: pointer;
}

select {
	width: 100px;
	background: #383838;
	border: 1px solid #4d4d4d;
	opacity: 1;
	border-radius: 2px;
	color: #ccc;
}

.img {
	width: 20px;
	height: 20px;
	border: none;
	cursor: pointer;
}
</style>

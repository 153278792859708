import DrawCircleSection from "./DrawCircleSection"
import DrawRectangleSection from "./DrawRectangleSection"
class DrawOneSection {

    constructor(line) {
        if (line.param_j.type == "rectangle") {
            this.section = new DrawRectangleSection(line);
        } else {
            this.section = new DrawCircleSection(line);
        }
    }
    draw(offsetx, offsety, offset, height, scale, zr) {
        this.section.draw(offsetx, offsety, offset, height, scale, zr);
    }
    box() {
        return this.section.box();
    }
}

export default DrawOneSection;

import { vModelText as _vModelText, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _withDirectives(_createVNode("input", {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.cval = $event)),
      type: "range",
      min: _ctx.minVal,
      max: _ctx.maxVal,
      step: 0.001*(_ctx.maxVal - _ctx.minVal),
      style: {"width":"100%"}
    }, null, 8, ["min", "max", "step"]), [
      [
        _vModelText,
        _ctx.cval,
        void 0,
        { number: true }
      ]
    ])
  ]))
}
<template>
  <Window
    :title="'图层自动创建工具'"
    :footervisible="true"
    @ok="ok"
    @cancel="cancel"
    :width="800"
    :height="450"
  >
    <div class="checklayer">
      <ul class="checkziduan">
        <li v-for="(item, index) in thearder" :key="index">
          <label>
            <input
              type="checkbox"
              :value="item.zhname"
              v-model="fields"
              @change="checkeField"
            />{{ item.name }}</label
          >
        </li>
        <button class="xbsj-button-tab" @click="query">获取唯一值</button>
      </ul>
      <div class="tablecheck">
        <table>
          <tr>
            <th>
              <input type="checkbox" v-model="allchecked" />
              {{ allchecked ? "取消全选" : "全部选中" }}
            </th>
            <th>值</th>
            <th>个数</th>
            <th>配置</th>
          </tr>
          <tr v-for="(item, index) in onlyData" :key="index">
            <td><input type="checkbox" v-model="item.checked" /></td>
            <td>{{ item.field }}</td>
            <td>{{ item.count }}</td>
            <td>
              <ColorButton v-model:color="item.color"></ColorButton>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </Window>
</template>

<script>
import ColorButton from "../../../components/ColorButton/Index"
import Window from "../../../components/Window"
export default {
  components: {
    Window,
    ColorButton

  },
  data() {
    return {
      tree: [],
      allchecked: false,
      thearder: [{
        zhname: "qidian",
        name: "起点ID"
      },
      {
        zhname: "zhongdian",
        name: "终点ID"
      },
      {
        zhname: "tuceng",
        name: "图层"
      },
      {
        zhname: "duanmian",
        name: "断面"
      },
      {
        zhname: "guanjing",
        name: "管径(毫米)"
      },
      {
        zhname: "guanbihou",
        name: "管壁厚(毫米)"
      },
      {
        zhname: "caizhi",
        name: "材质"
      },
      {
        zhname: "create_t",
        name: "创建时间"
      },
      {
        zhname: "update_t",
        name: "更新时间"
      }
      ],
      onlyData: "",
      fields: []
    }
  },
  watch: {
    allchecked: {
      handler() {
        this.onlyData.forEach(item => {
          item.checked = this.allchecked
        })
      }
    }
  },
  props: {
    param: {
      type: Object,
      default: undefined
    }
  },
  mounted() {



  },
  methods: {

    query() {
      //http://localhost:3001/project/line/column/uniquevalue
      //查询勾选
      if (this.fields.length === 0) {
        this.$root.$alert("请选择字段")
      } else {
        let data = {
          projectid: this.$root.projectid,
          fields: JSON.stringify(this.fields)
        }
        this.$axios
          .get('/project/line/column/uniquevalue', {
            params: data
          })
          .then(res => {
            console.log("部分数据", res.data.data)
            if (res.data.data) {
              this.onlyData = res.data.data
              this.onlyData.forEach(item => {
                let r = Math.floor(Math.random() * 256)
                let g = Math.floor(Math.random() * 256)
                let b = Math.floor(Math.random() * 256)
                item.color = {
                  r: r,
                  g: g,
                  b: b,
                  a: 1,
                }
                item.checked = false
              })
              console.log(this.onlyData)
            }
          }).catch(err => {
            console.log(err.response || err)
          })
      }
    },
    clear() {
      this.onlyData = []
      this.fields = []
    },
    acceptDrop(types) {
      if (!(types instanceof Array)) return false;
      return true;
    },
    changeData(o) {
      var cache = [];
      var str = JSON.stringify(o, function (key, value) {
        if (typeof value === 'object' && value !== null) {
          if (cache.indexOf(value) !== -1) {
            // 移除
            return;
          }
          // 收集所有的值
          cache.push(value);
        }
        return value;
      });
      cache = null; // 清空变量，便于垃圾回收机制回收
      return str
    },
    cancel() {
      this.$root.destroyTool(this);
    },


    ok() {
      var folder = {
        title: "我的图层",
        checked: true,
        expanded: true,
        children: [],
        dragType: "folder",
        acceptDrop: this.acceptDrop
      };
      this.tree.push(folder)
      this.onlyData.forEach(item => {
        if (item.checked) {
          let layer = {
            id: item.field,
            title: item.field,
            checked: true,
            dragType: "layer",
            config: {
              color: `rgba(${[item.color.r, item.color.g, item.color.b, item.color.a].join(",")})`
            },
            acceptDrop: this.acceptDrop
          };
          this.tree[0].children.push(layer)
        }

      })

      this.tree = this.changeData(this.tree)
      this.$axios.put("/project/info/update", {
        projectid: this.$root.projectid,
        layers: this.tree
      }).then(res => {
        console.log("保存", res)
        this.$root._tileset.setLayersConfig(JSON.parse(this.tree));
        this.$bus.emit("reloadlayer")
      }).catch(err => {
        console.log(err.response || err)
      })
      this.$root.destroyTool(this);
    },
  }


}
</script>

<style scoped>
.checkziduan {
  width: 20%;
  height: 30px;
}

.checkziduan > li {
  height: 30px;
  margin-top: 3px;
  line-height: 30px;
  padding-left: 45px;
  background: #2e2e2e;
  border: 1px solid #383838;
  border-radius: 2px;
  color: #ccc;
}

.checkziduan > button {
  margin-top: 20px;
  width: 100%;
}

.checklayer {
  width: 100%;
  height: 80%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

tr > th:first-child,
tr > td:first-child {
  width: 13%;
}

tr > th:nth-child(2),
tr > td:nth-child(2) {
  width: 35%;
}

tr > th:nth-child(3),
tr > td:nth-child(3) {
  width: 10%;
}

.clear {
  margin-left: 60%;
}

.tablecheck {
  width: 80%;
  height: 100%;
  overflow: auto;
}

.tablecheck > table {
  width: 100%;
}

.tablecheck > table > tr > td,
.tablecheck > table > tr > th {
  height: 30px;
  text-align: center;
  background: #2e2e2e;
  border: 1px solid #383838;
  border-radius: 2px;
  color: #ccc;
}
</style>

<template>
  <div class="addLine">
    <label class="info">请单击地球，绘制多边形，(shift+右键结束绘制)</label>
    <div class="op">

      <div>
        <label>绝对高度:</label>
        <input type="number"
          v-model="height" />
      </div>
      <div> <label>地面高程:</label>
        <input type="number"
          v-model="dimiangaocheng" />
      </div>
      <div>
        <button @click="draw"
          v-show="!showdelete">开始绘制</button>
        <button @click="redraw"
          v-show="showdelete">重绘</button>
        <button @click="ok">确定</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  components: {

  },
  data() {
    return {
      showdelete: false,
      polylineData: [],
      height: 10,
      dimiangaocheng: 0,
      creating: false,
      geojson: "",
      showQuery: true,
    };
  },
  watch: {
    creating() {
      if (!this.creating) {
        if (this.polylineData.length > 0) {
          let arr = this.ArrayAdd(this.polylineData)
          if ([...arr].length) {
            this.geojson = JSON.stringify({
              "type": "Polygon",
              "coordinates": [
                arr
              ]
            })

          }
        }else{
            this.geojson = ""
        }

        console.log("绘制完成", this.geojson)

      }
    },
  },
  mounted() {
    this._polyline = new XE.Obj.Plots.GeoPolygon(this.$root._earth);
    this._disposers = this._disposers || [];
    this._disposers.push(XE.MVVM.bind(this, "creating", this._polyline, "creating"));
  },
  methods: {
    ArrayAdd(arr) {
      let a = [...new Set(arr)] //去重加首尾相同
      a.push(a[0])
      return a
    },
    draw() {

      this._polyline.positions = []
      this._polyline._polygon.positions = []
      this._polyline.enabled = true
      this._polyline.creating = true; //可以在地图上画
      XE.MVVM.watch(
        () => ({
          positions: [...this._polyline.positions]
        }), () => {
          if (this._polyline.positions.length >= 3) {
            this.polylineData = []
            let lat
            let lng
            for (let index = 0; index < this._polyline.positions.length; index++) {
              lat = this._polyline.positions[index][0] * 180 / Math.PI
              lng = this._polyline.positions[index][1] * 180 / Math.PI
              this.polylineData.push([lat, lng])
            }
            //console.log("多边形经纬度", this.polylineData)
          }
        }
      )
      // }
      this.showQuery = false
      this.showdelete = true
    },
    redraw() {
      if (this._polyline) {
        this._polyline.show = false
        this._polyline.creating = false
        this.polylineData = []
        this.showdelete = false
        this.showQuery = true
        this.height = 10,
          this.dimiangaocheng = 0
      }
      this.geojson = ""


    },
    ok() {
      console.log("添加建筑", this.height, this.dimiangaocheng, this.geojson)
      if (this.geojson && this.height) {
        // @api {post} /project/build/add  添加建筑
        let data = {
          projectid: this.$root.projectid,
          height: this.height * 1,
          geom: this.geojson,
          dimiangaocheng: this.dimiangaocheng * 1
        }
        this.$axios
          .post("/project/build/add", data)
          .then(() => {
            //self.$refs.User.query();
            this.$root.$alert("添加建筑成功");
            this.$root._tileset.reload();
            this.redraw()
          })
          .catch((err) => {
            this.redraw()
            console.log(err);
            this.$root.$alert(err.data.message);
          });

      } else {
        this.$root.$alert("请绘制多边形并且填写高度与底面高程！");
      }

    }
  },
  beforeUnmount() {

  }
};
</script>

<style scoped>
.addLine {
  flex-grow: 1;
  height: 60px;
}

.info {
  text-align: center;
  padding: 5px;
  display: block;
}

.op {
  width: 700px;
  display: grid;
  margin: auto;
  grid-template-columns: 1fr 1fr 1fr 20px;
}

input {
  width: 100px;
  height: 20px;
}

button {
  height: 30px;
  width: 60px;
  margin-left: 10px;
}
</style>

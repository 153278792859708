<template>
  <div class="fieldcode">
    <div v-for="(f, idx) in fields" :key="f.inner" class="field">
      <div class="mingCheng">{{ getName(f) }}</div>

      <div class="leftSelect">
        <select :id="f.inner" @change="changeValue(f)" class="point-select">
          <option selected label="字段" value="字段"></option>
          <option label="固定值" value="固定值"></option>
        </select>
      </div>

      <div class="leftSelect gudingzhi" v-show="f.selectsWitch">
        <input placeholder="请输入固定值" type="text" v-model="f.code" @input="codeChanged(f)" />
      </div>

      <div v-show="!f.selectsWitch">
        <div>
          <select v-model="f.select" placeholder="请选择" :id="f.inner + 'id'" @change="changeFields(f)"  class="point-select"
            @focus="fieldFocused(f)">
            <option v-for="item in inputFields" :value="item.name">
              <tr style="float: left;text-align: left;"><td>{{ item.name }}</td></tr>
            </option>
          </select>
          <!-- <select class="point-select"
          v-if="f.name === 'ID' || f.name === '起点' || f.name === '终点'"
          :id="f.inner + 'id'"
          @change="changeFields(f)"
          @focus="fieldFocused(f)"
        >
          <option
            v-for="f in inputFields"
            :key="f.name"
            :label="
              f.name === '--请选择--'
                ? '--请选择--'
                : f.name === 'geometry'
                ? '--请选择--'
                : '字段:' +
                  f.name +
                  '\xa0' +
                  '\xa0' +
                  '类型:' +
                  f.type +
                  '\xa0' +
                  '\xa0' +
                  '值:' +
                  f.value
            "
            :value="f.name"
            :disabled="f.name === '--请选择--' || f.name === 'geometry'"
            :selected="f.name === '--请选择--' || f.name === 'geometry'"
          ></option>
        </select> -->
        </div>
        <!-- <div class="lastLeftSelect_div" v-if="f.name === 'ID' || f.name === '起点' || f.name === '终点'">
          <input disabled v-model="f.code" type="text" placeholder="请选择" :title="idChooseTrData" >
          <button type="button" @click="popFields(f, idx)">选择</button>
        </div>
    
        <div class="lastLeftSelect_div" v-if="f.type === 'number'">
          <input disabled v-model="f.code" type="text" placeholder="请选择" :title="zChooseTrData">
          <button type="button" @click="popFields(f, idx)">选择</button>
        </div>

        <div class="lastLeftSelect_div"  
          v-if="f.type === 'string' 
          && f.name != 'ID' 
          && f.name != '起点' 
          && f.name != '终点'">
          <input disabled v-model="f.code" type="text" placeholder="请选择" :title="fswChooseTrData">
          <button type="button" @click="popFields(f, idx)">选择</button>
        </div> -->

      </div>
    </div>

    <Window :height="410" :title="''" :footervisible="true" @ok="windowOk" v-show="showfields" @cancel="cancel">
      <div class="Window_table_div">
        <table>
          <tr class="table-header ">
            <th>字段</th>
            <th>类型</th>
            <th>值</th>
          </tr>
          <tr class="table-content" :class="{ 'table-content_click': f.name === tableData.name }"
            v-for="f in inputFields" :key="f.name" @click="chooseTr(f)">
            <td>{{ f.name }}</td>
            <td>{{ f.type }}</td>
            <td>{{ f.value }}</td>
          </tr>
        </table>
      </div>
    </Window>

  </div>
</template>
<script>
import Window from '@/components/Window.vue';

export default {
  components: {
    Window
  },
  data() {
    return {
      fields: [],
      inputFields: [
        {
          name: "name",
          type: "string",
          value: "aaaaa",
        },
        {
          name: "height",
          type: "number",
          value: 10,
        },
      ],
      showfields: false,
      tableDataShow: false,
      tableData: "",
      idChooseTrData: "",
      zChooseTrData: "",
      fswChooseTrData: "",
      flag: ""
    };
  },
  mounted() {
  },
  methods: {
    // 传递给父组件字段下拉框选择
    changeFields(val) {
      
      // alert(val);
      if (val.inner === "id") {
        // alert('id')
        var obj = document.getElementById(val.inner + "id");
        var id = obj.options[obj.selectedIndex].value;
        val.select = id;
        val.code = 'input["' + id + '"]';
        this.$emit("changeField", val);
      }
      if (val.inner === "z") {
        // alert('z')
        var obj1 = document.getElementById(val.inner + "id");
        var z = obj1.options[obj1.selectedIndex].value;
        val.code = 'input["' + z + '"]';
        this.$emit("changeField", val);
      }
      if (val.name === "附属物") {
        // alert('fusuwu')
        var obj2 = document.getElementById(val.inner + "id");
        var fusuwu = obj2.options[obj2.selectedIndex].value;
        val.code = 'input["' + fusuwu + '"]';
        this.$emit("changeField", val);
      }
      if (val.name === "起点") {
        var obj3 = document.getElementById(val.inner + "id");
        var qidian = obj3.options[obj3.selectedIndex].value;
        val.code = 'input["' + qidian + '"]';
        this.$emit("changeField", val);
      }
      if (val.name === "终点") {
        var obj4 = document.getElementById(val.inner + "id");
        var zhongdian = obj4.options[obj4.selectedIndex].value;
        val.code = 'input["' + zhongdian + '"]';
        this.$emit("changeField", val);
      }
      if (val.name === "材质") {
        var obj5 = document.getElementById(val.inner + "id");
        var caizhi = obj5.options[obj5.selectedIndex].value;
        val.code = 'input["' + caizhi + '"]';
        this.$emit("changeField", val);
      }
      if (val.name === "图层") {
        var obj6 = document.getElementById(val.inner + "id");
        var tuceng = obj6.options[obj6.selectedIndex].value;
        val.code = 'input["' + tuceng + '"]';
        this.$emit("changeField", val);
      }
    },
    getName(f) {
      var r = f.name;
      if (f.unit && f.unit.length != 0) {
        r += "(" + f.unit + ")";
      }
      // r += " : ";
      return r;
    },
    popFields(field, idx) {
      if (this.showfields && this.field === field) {
        this.showfields = false;
        return;
      }
      this.showfields = true;
      // this.$refs.inputfileds.style.top = 30 * idx + 30 + "px";
      this.field = field;
      this.tableData = field;
    },

    selectInput(inputf) {
      // alert(inputf.name)
      this.field.code = 'input["' + inputf.name + '"]';
      // alert(this.field.code)
      this.showfields = false;
      this.codeChanged(this.field);
    },

    setDefault() {
      this.field.code = this.field.default;
      this.showfields = false;
      this.codeChanged(this.field);
    },

    setFields(fields) {
      fields.forEach((a) => {
        a.default = a.code;
        a.selectsWitch = false;
      });
      this.fields = fields;
    },

    setInputFields(inputfields) {
      this.inputFields = inputfields;
    },

    // 传递给父组件处理固定值输入框
    codeChanged(field) {
      // alert(field)
      this.$emit("codeChanged", field);
    },

    fieldFocused(field) {
      this.$emit("fieldFocused", field);
    },



    // 下拉框选择是固定值或字段，默认字段展示下拉框进行字段选择，固定值输入框输入
    changeValue(val) {
      var objS = document.getElementById(val.inner);
      var value = objS.options[objS.selectedIndex].value;
      // alert(value)
      if (value === "固定值") {
        val.selectsWitch = true;
      } else if (value === "字段") {
        val.selectsWitch = false;
      }
    },

    openChoose(val) {
      this.tableDataShow = true
      this.flag = val
    },
    cancel() {
      this.showfields = false
    },
    chooseTr(f) {
      this.tableData = f
    },
    windowOk() {
      this.selectInput(this.tableData)
    }
  },
};
</script>
<style scoped>
.fieldcode {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-around;
}

.field {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  align-items: center;
}

.field .mingCheng {
  width: 50px;
  margin-left: 4px;
  text-align: right;
}

.field>div {
  margin: 4px 4px;
}

.leftSelect {
  width: 90px;
  flex: 1;
}

.leftSelect>select {
  width: 90px;
}

.leftSelect>input {
  width: 179px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
}

.lastLeftSelect {
  flex: 1;
}

.lastLeftSelect_div {
  display: flex;
  width: 195px;
}

.lastLeftSelect_div>input {
  width: 126px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
}

.lastLeftSelect_div>button {
  width: 48px;
  margin-left: 5px;
  cursor: pointer;
}

.gudingzhi {
  width: 195px;
  flex: 2;
}


.code {
  position: absolute;
  flex-grow: 1;
  position: absolute;
  left: 10px;
  margin-left: -46px;
  margin-top: 6px;
}

.code>input {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 6px;
  color: #ccc;
}

.setting {
  flex-grow: 0;
  width: 50px;
  text-align: center;
  margin-top: 6px;
}

.inputfields {
  position: absolute;
  right: 10px;
  top: 0px;
  background: var(--BackgroundColor);
  border: 1px solid var(--BackgroundColor);
  border-radius: 4px;
  max-height: 300px;
  overflow: scroll;
  color: #ccc;
}

.inputfields tr {
  cursor: pointer;
}

.point-select {
  width: 180px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
  font-size: 14px;
  /* margin-left: 85px; */
}

/* .fields1-select {
  width: 140px;
  height: 30px;
  position: relative;
  top: 0px;
  left: 138px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
} */
.setting-fixed>input {
  background: #383838;
  border-radius: 4px;
  height: 28px;
  color: #ccc;
  position: relative;
  top: 0px;
  left: 136px;
  text-align: center;
  width: 139px;
  margin-top: 5px;
}

.Window_table_div {
  width: 100%;
  height: 312px;
  padding: 5px;
  box-sizing: border-box;
  overflow: auto;
}

.Window_table_div table {
  border-collapse: collapse;
  width: 100%;
}

.Window_table_div table tr td {
  border: 1px solid rgb(54 54 55);
  text-align: center;
}

.Window_table_div table tr th {
  border: 1px solid rgb(54 54 55);
  text-align: center;
}

.table-header {
  background-color: #383838;
  /* opacity: 0.5; */
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-content:hover {
  background-color: #4d4d4d;
  cursor: pointer;
}

.table-content_click {
  background-color: #4d4d4d;
}
</style>

import * as zrender from 'zrender';
class DrawCircleSection {
    constructor(line) {
        this._line = line;
        this._line.radius = line.param_j.profile.radius;
    }
    draw(offsetx, offsety, offset, height, scale, zr) {
        var x = (this._line.distance + offsetx) * scale;
        var y = height - (this._line.height + offsety) * scale;
        var radius = this._line.radius * scale;
        var circle = new zrender.Circle({
            id: this._line.qidian + "_" + this._line.zhongdian,
            shape: {
                cx: x + offset,
                cy: y - offset,
                r: radius
            },
            style: {
                fill: '#F000',
                stroke: '#fff'
            }
        });
        zr.add(circle);
        // ctx.beginPath();
        // ctx.arc(x + offset, y + offset, radius, 0, Math.PI * 2, false);
        // ctx.stroke()
        // ctx.fillText(this._line.qidian + "_" + this._line.zhongdian, x + offset, y + offset);
        // ctx.closePath();
    }
    box() {
        return {
            minx: this._line.distance - this._line.radius,
            miny: this._line.height - this._line.radius,
            maxx: this._line.distance + this._line.radius,
            maxy: this._line.height + this._line.radius,
        }
    }
}
export default DrawCircleSection;

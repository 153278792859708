<template>
    <div ref="xbsjmodelbox" class="xbsj-model-box" :class="{ 'xbsj-model-box-close': collapsed }" :style="windowstyle"
        @click="showclick">
        <i @mousedown="startResize($event)" ref="barbr" class="bar" :class="{ hidden: collapsed }"></i>
        <!-- 标题 -->
        <div class="xbsj-model-header" ref="xbsjmodelheader" @mousedown="startMove($event)">
            {{ title }}
            <!-- <span
        class="xbsj-model-arrow"
        :class="{ closestatus: collapsed }"
        @click.stop="collapse"
      ></span> -->
            <span class="xbsj-model-close" @click.stop="cancel"></span>
        </div>
        <!-- 内容 -->
        <div class="xbsj-model-content" :class="{
        'xbsj-model-closetatus-body': collapsed,
        'xbsj-model-nofooter': footervisible,
      }" ref="xbsjmodelbody" @contextmenu.prevent="contextMenu">
            <div class="xbsj-model-content-box">
                <slot></slot>
            </div>
        </div>
        <!-- 底部按钮 -->
        <div class="xbsj-model-footer" v-if="footervisible && !collapsed">
            <button v-if="showCancelButton" @click="cancel">
                {{ compCancelText }}
            </button>
            <button @click="ok">{{ compOkText }}</button>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    export default {
        props: {
            title: {
                type: String,
                default: "",
            },
            confirmtext: {
                type: String,
                default: "",
            },
            canceltext: {
                type: String,
                default: "",
            },
            showCancelButton: {
                type: Boolean,
                default: true,
            },
            left: {
                type: Number,
                default: 350,
            },
            top: {
                type: Number,
                default: 200,
            },
            width: {
                type: Number,
                default: 300,
            },
            height: {
                type: Number,
                default: 300,
            },
            minWidth: {
                type: Number,
                default: 100,
            },
            minHeight: {
                type: Number,
                default: 100,
            },
            footervisible: {
                type: Boolean,
                default: false,
            },
            floatright: {
                type: Boolean,
                default: false,
            },
            windowID: {
                type: String,
                default: "",
            },
        },
        data() {
            return {
                langs: {
                    zh: {
                        ok: "确定",
                        cancel: "取消",
                    },
                    en: {
                        ok: "OK",
                        cancel: "Cancel",
                    },
                },
                collapsed: false,

                windowstyle: {},
            };
        },
        created() {
            this._width = 0;
            this._height = 0;
            this._left = 0;
            this._top = 0;
            // console.log(earth.czm.viewer.container)
            var dom = document.getElementById("app");
            let earthUIWidth = dom.clientWidth;
            this._width = this.width;
            this._height = this.height;
            if (this.floatright) {
                this._left = earthUIWidth - this.width;
            } else {
                this._left = this.left;
            }

            this._top = this.top;

            this.updateStyle();
        },
        computed: {
            compCancelText: function () {
                if (this.canceltext == "") {
                    return "取消";
                }
                return this.canceltext;
            },
            compOkText: function () {
                if (this.confirmtext == "") {
                    return "确定";
                }
                return this.confirmtext;
            },
        },
        mounted() {},
        methods: {
            contextMenu() {
                this.$emit("context-menu");
            },
            updateStyle() {
                this.windowstyle = {
                    width: this._width + "px",
                    height: this._height + "px",
                    top: this._top + "px",
                    left: this._left + "px",
                };
            },
            collapse() {
                this.collapsed = !this.collapsed;
                this.$parent.refShow = !this.$parent.refShow;
            },
            cancel() {
                this.$emit("cancel");
            },
            ok() {
                this.$emit("ok");
            },
            showclick() {
                this.$emit("showclick");
            },
            startMove(e) {
                e = e || event;

                if (e.target != this.$refs.xbsjmodelheader) return;
                //记录鼠标所在位置
                var navheight = 0;

                var dom = document.getElementById("app");

                var maxWidth = dom.clientWidth;
                var maxHeight = dom.clientHeight;

                var box = this.$refs.xbsjmodelbox;

                var disx = e.pageX - box.offsetLeft;
                var disy = e.pageY - box.offsetTop;

                var self = this;

                var headerHeight = this.$refs.xbsjmodelheader.clientHeight;

                function move(e) {
                    e = e || event;
                    e.stopPropagation();
                    //计算当前鼠标位置
                    let left = e.pageX - disx;
                    let top = e.pageY - disy;
                    if (left > 0 && left <= maxWidth - self._width) {
                        self._left = left;
                    } else if (left <= 0 && self._left < left) {
                        self._left = left;
                    } else if (left > maxWidth - self._width && self._left > left) {
                        self._left = left;
                    }

                    if (
                        top > navheight &&
                        top <= maxHeight - (self.collapsed ? headerHeight : self._height)
                    ) {
                        self._top = top;
                    } else if (top <= navheight && self._top < top) {
                        self._top = top;
                    } else if (
                        top > maxHeight - (self.collapsed ? headerHeight : self._height) &&
                        self._top > top
                    ) {
                        self._top = top;
                    }

                    self.updateStyle();
                }

                var conbox = dom;

                function up() {
                    conbox.removeEventListener("mousemove", move);
                    document.removeEventListener("mouseup", up);
                }

                conbox.addEventListener("mousemove", move);
                document.addEventListener("mouseup", up);
            },
            startResize() {
                //记录鼠标所在位置
                var dom = document.getElementById("app");
                var maxWidth = dom.clientWidth - this._left;
                var maxHeight = dom.clientHeight - this._top;

                var self = this;

                function move(e) {
                    e = e || event;
                    e.stopPropagation();
                    //计算当前鼠标位置和全局点击位置的偏插值，尝试修改宽度和高度
                    let w = e.movementX + self._width;
                    let h = e.movementY + self._height;

                    if (w >= self.minWidth && w <= maxWidth) {
                        self._width = w;
                    }

                    if (h >= self.minHeight && h <= maxHeight) {
                        self._height = h;
                    }
                    self.updateStyle();
                }

                var conbox = dom;

                function up() {
                    self.$emit("resized");
                    conbox.removeEventListener("mousemove", move);
                    document.removeEventListener("mouseup", up);
                }

                conbox.addEventListener("mousemove", move);
                document.addEventListener("mouseup", up);
            },
        },
    };
</script>

<style>
    .xbsj-model-box {
        position: absolute;
        background: #383838;
        color: #CCCCCC;
        top: 200px;
        left: 200px;
        border-radius: 3px;
        overflow: hidden;
        z-index: 10009;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.4);
        overflow: visible;
    }

    .xbsj-model-content {
        /* overflow-x: auto;
  overflow-y: auto; */
        background: #242424;
        position: relative;
        padding: 5px;
        text-align: left;
        width: calc(100% - 10px);
        transition: all 0.5s linear;
        height: calc(100% - 40px);
    }

    .xbsj-model-content-box {
        /* overflow-x: auto;
  overflow-y: auto; */
        width: 100%;
        height: calc(100% - 10px);
    }

    .xbsj-model-nofooter {
        height: calc(100% - 62px);
    }

    /* .xbsj-model-content p {
        color: #1fffff;
    } */

    .xbsj-model-header {
        cursor: all-scroll;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        top: 0;
        left: 0;
        min-height: 30px;
        line-height: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        font-size: 14px;
        font-family: \5fae\8f6f\96c5\9ed1;
        padding: 0 31px 0 18px;
        text-align: left;
        user-select: none;
        background-color: var(--BackgroundColor);
        color: #cccccc
    }

    .xbsj-model-close {
        cursor: pointer;
        position: absolute;
        /* top: 25%; */
        right: 20px;
        width: 20px;
        height: 20px;
        line-height: 13px;
        background: url(../assets/model_close.png) no-repeat;
        background-size: 60% 60%;
        background-position: center center;
    }

    .xbsj-model-close:hover {
        background: url(../assets/model_close_hover.png) no-repeat;
        background-size: 60% 60%;
        background-position: center center;
    }

    .xbsj-model-arrow {
        cursor: pointer;
        position: absolute;
        /* top: 25.2%; */
        right: 50px;
        width: 20px;
        height: 20px;
        line-height: 12px;
        background: url(../assets/model_arrow.png) no-repeat;
        background-size: 60% 60%;
        background-position: center center;
        transition: all 0.5s linear;
    }

    .xbsj-model-arrow:hover {
        background: url(../assets/model_arrow_hover.png) no-repeat;
        background-size: 60% 60%;
        background-position: center center;
    }

    .xbsj-model-close:hover {
        color: #47f0ff;
    }

    .xbsj-model-footer {
        margin: 0;
        padding: 3px 0;
        text-align: right;
        border-top: 1px solid rgba(0, 0, 0, 0.4);
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: var(--BackgroundColor);
    }

    .xbsj-model-footer>button {
        width: 70px;
        height: 30px;
        background: #383838;
        border: 1px solid #4D4D4D;
        color: #CCC;
        opacity: 1;
        border-radius: 2px;
        cursor: pointer;
    }


    /* button + button {
  margin-left: 15px;
} */

    .xbsj-model-box .bar {
        position: absolute;
        z-index: 2;
    }

    .xbsj-model-box i.bart,
    .xbsj-model-box i.barb {
        height: 4px;
        left: -1px;
        right: -1px;
        cursor: n-resize;
    }

    .xbsj-model-box i.bart {
        top: -1px;
    }

    .xbsj-model-box i.barb {
        bottom: -1px;
    }

    .xbsj-model-box i.barr,
    .xbsj-model-box i.barl {
        width: 4px;
        top: -1px;
        bottom: -1px;
        cursor: e-resize;
    }

    .xbsj-model-box i.barl {
        left: -1px;
    }

    .xbsj-model-box i.barr {
        right: -1px;
    }

    .xbsj-model-box i.bar {
        width: 14px;
        height: 14px;
        cursor: nw-resize;
        bottom: -1px;
        right: -1px;
        /* background: rgba(221, 221, 221, 0.4); */
        background: url(../assets/model_right_bottom.png) no-repeat;
        background-size: cover;
    }

    .xbsj-model-box .closestatus {
        transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        /* IE 9 */
        -moz-transform: rotate(-180deg);
        /* Firefox */
        -webkit-transform: rotate(-180deg);
        /* Safari 和 Chrome */
        -o-transform: rotate(-180deg);
    }

    .xbsj-model-box .xbsj-model-closetatus-body {
        height: 0;
    }

    .xbsj-model-box-close {
        height: 40px !important;
    }

    .hidden {
        display: none;
    }
</style>
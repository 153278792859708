<template>
  <div class="connectivity">
    <div class="left">
      <h4>{{ getInfo() }}</h4>
      <div class="start">
        <label>起点:</label>
        <span>{{ qidian }}</span>

      </div>
      <div class="start">
        <label>终点:</label>
        <span>{{ zhongdian }}</span>
      </div>
      <div class="start">
        <label>字段:</label>
        <input type="text"
          v-model="field">
      </div>
      <div class="start">
        <label>值:</label>
        <input type="text"
          v-model="value">
      </div>
      <div class="start">
        <label>当搜索到field=value时停止搜索</label>
      </div>
      <div class="submit">
        <button @click="clear">清除</button>
        <button @click="ok">确定</button>
      </div>
    </div>
    <div class="right">
      <div v-for="(item, index) in thearder"
        :key="index">{{ item }}</div>
      <!-- <table cellspacing="0"
        cellpadding="0">
        <tr>
          <th v-for="(item, index) in thearder"
            :key="index">{{ item }}</th>
        </tr>
        <tr v-for="(item, index) in infos"
          :key="index">
          <td>{{ item.qidian }}</td>
          <td>{{ item.zhongdian }}</td>
          <td>{{ item.caizhi }}</td>
          <td>{{ item.tuceng }}</td>
          <td>{{ item.duanmian }}</td>
          <td>{{ item.guanjing }}</td>
          <td>{{ item.guanbihou }}</td>
          <td>{{ item.lenght.toFixed(2) }}</td>
        </tr>
      </table> -->
      <div>{{ total }}</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  data() {
    return {
      qidian: undefined,
      zhongdian: undefined,
      field: 'fushuwu',
      value: '阀门',
      thearder: [],
      infos: [],
      total: '',
      projectId: undefined
    }
  },
  mounted() {
    //启动鼠标交互
    this.$root.$enablePicking()
    //截获选择管点事件
    this.$bus.on('pickLine', this.pickLine)
    this.$bus.on('earthClicked', this.earthClicked)
  },
  methods: {
    earthClicked(carto) {
      this.clear()
      var position = this.createLabel('爆管位置', 'rgb(31,255,255)')
      position.position = carto.carto
      this._temLebal.push(position)
    },
    pickLine(obj) {
      this.getPoint(obj)
    },
    clear() {
      this.qidian = undefined
      this.zhongdian = undefined
      if (this._temLebal) {
        this._temLebal.forEach(temline => {
          temline.destroy();
        })
      }
      this._temLebal = []
      if (this._temline) {
        this._temline.forEach(temline => {
          temline.destroy();
        })
      }
      this._temline = []
    },
    getPoint(obj) {
      this.qidian = obj.qidian
      this.zhongdian = obj.zhongdian
      this.projectId = obj.projectid
    },
    getInfo() {
      if (!this.qidian) return '点击选择爆管位置'
      if (!this.field || this.field == '') return '字段不能为空'
      if (!this.value || this.value == '') return '值不能为空'
      return ''
    },
    ok() {
      if (!this.qidian) {
        this.$root.$alert('请选择起点')
        return
      }
      let data = {
        projectid: this.projectId || this.$root.projectid,
        qidian: this.qidian,
        zhongdian: this.zhongdian,
        field: this.field,
        value: this.value
      }
      this.$axios
        .get('/project/line/burst', {
          params: data
        })
        .then(res => {
          let result = res.data.data
          if (this._temline) {
            this._temline.forEach(temline => {
              temline.destroy()
            })
          }
          this._temline = []
          this.thearder = []
          let downstream = result.downstream;
          downstream.forEach(l => {
            this.thearder.push(l.path);
            var geom = JSON.parse(l.geom);
            if (geom.type == 'LineString' && geom.coordinates.length > 1) {
              var temline = new XE.Obj.CustomPrimitiveExt.Tube(this.$root._earth)
              temline.radius = 2
              temline.radialSegments = 4
              temline.imageUrl = './image/03.png'
              temline.textureSize = [Math.PI, Math.PI]
              temline.speed = [-2, 0]
              temline.isCurve = false
              geom.coordinates.forEach(p => {
                temline.positions.push([(p[0] * Math.PI) / 180, (p[1] * Math.PI) / 180, p[2]])
              })
              this._temline.push(temline);
              var label = this.createLabel(l.path[l.path.length - 1], 'rgb(224,51,51)')
              if (geom.type == 'LineString') {
                var p = geom.coordinates[geom.coordinates.length - 1]
                label.position = [(p[0] * Math.PI) / 180, (p[1] * Math.PI) / 180, p[2]]
              } else {
                var p = geom.coordinates
                label.position = [(p[0] * Math.PI) / 180, (p[1] * Math.PI) / 180, p[2]]
              }
              this._temLebal.push(label)
            }
          })
          let upstream = result.upstream;
          upstream.forEach(l => {
            this.thearder.push(l.path);
            var geom = JSON.parse(l.geom);
            if (geom.type == 'LineString' && geom.coordinates.length > 1) {
              var temline = new XE.Obj.CustomPrimitiveExt.Tube(this.$root._earth)
              temline.radius = 2
              temline.radialSegments = 4
              temline.imageUrl = './image/03.png'
              temline.textureSize = [Math.PI, Math.PI]
              temline.speed = [-2, 0]
              temline.isCurve = false
              geom.coordinates.forEach(p => {
                temline.positions.push([(p[0] * Math.PI) / 180, (p[1] * Math.PI) / 180, p[2]])
              })
              this._temline.push(temline);
            }
            var label = this.createLabel(l.path[0], 'rgb(31,224,31)')
            if (geom.type == 'LineString') {
              var p = geom.coordinates[0]
              label.position = [(p[0] * Math.PI) / 180, (p[1] * Math.PI) / 180, p[2]]
            } else {
              var p = geom.coordinates
              label.position = [(p[0] * Math.PI) / 180, (p[1] * Math.PI) / 180, p[2]]
            }
            this._temLebal.push(label)
          })
        })
        .catch(err => {
          this.$root.$log('error', err)
        })
    },
    cancel() {
      this.$root.destroyTool(this)
    },
    createLabel(content, color) {
      let p = new XE.Obj.Plots.GeoPin(this.$root._earth);
      p.innerHTML = `<!DOCTYPE HTML>
<html>

<head>
<title></title>
<style>
@keyframes scale {
    0% {
        transform: scale(1)
    }

    50%,
    75% {
        transform: scale(3)
    }

    78%,
    100% {
        opacity: 0
    }
}

@keyframes scales {
    0% {
        transform: scale(1)
    }

    50%,
    75% {
        transform: scale(2)
    }

    78%,
    100% {
        opacity: 0
    }
}
body{
    --BGColor:white;
}
.smallcircle {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color:var(--BGColor);
    opacity: 0.8;
    border-radius: 50%;
    top: -4px;
    left: -6px;
}

.smallcircle:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: .4;
    background-color: var(--BGColor);
    opacity: 0.8;
    animation: scale 1s infinite cubic-bezier(0, 0, .49, 1.02);
}

.bigcircle {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: .4;
    background-color: var(--BGColor);
    opacity: 0.8;
    animation: scales 1s infinite cubic-bezier(0, 0, .49, 1.02);
    top: -4px;
    left: -6px;
}
.contentLabel {
    position: absolute;
    top: -60px;
    left: ${content.length * -6}px;
    width: ${content.length * 12}px;
    height: 20px;
    text-align: center;
    background-color: rgba(50, 50, 50, 0.5);
    border-radius: 2px;
}

.lineLabel {
    position: absolute;
    top: -40px;
    left: -1px;
    width: 1px;
    height: 36px;
}
</style>
</head>

<body> 
<div class="smallcircle"></div>
<div class="bigcircle"></div>
${content && content.length > 0
          ? `<div class="contentLabel" style='color:${color};'>${content}</div>
<div class="lineLabel"  style='border-left: 2px solid ${color};'></div>`
          : ""
        }
</body>

</html>`;
      return p;
    }
  },
  beforeUnmount() {
    this.clear()
    //停止监控地球鼠标点击事件
    // var style = `var style = {
    //           color: {
    //             conditions: []
    //           }
    //       }`;
    // this.$root._tileset.tileset.xbsjStyle = style;
    this.$bus.off('pickLine', this.pickLine)
    this.$bus.off('earthClicked', this.earthClicked)
    // this.$root._tileset.pointClicked.removeEventListener(this.getPoint, this);
  }
}
</script>

<style scoped>
button {
  width: 30px;
}
.connectivity {
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: auto;
  line-height: 24px;
  padding-top: 20px;
  box-sizing: border-box;
}

.connectivity > div {
  flex-grow: 1;
}

.left {
  display: flex;
  flex-direction: column;
  width: 26%;
  border-right: 1px solid #4d4d4d;
  margin-right: 20px;
}

.right {
  width: 74%;
}

.start,
.end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
  line-height: 24px;
  margin-bottom: 10px;
  padding-right: 20px;
  padding-left: 10px;
}

.start > h4 {
  width: 100%;
  text-align: start;
}

.left > div > span {
  display: inline-block;
  width: 150px;
  height: 24px;
  line-height: 24px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  text-align: start;
  margin-right: 10px;
  margin-left: 10px;
}

.left > div:nth-child(4) {
  display: flex;
  justify-content: flex-end;
  height: 24px;
  line-height: 24px;
  margin-bottom: 10px;
  padding-right: 20px;
}

table {
  width: 100%;
}

table > tr > td,
table > tr > th {
  height: 22px !important;
  line-height: 22px;
  border-left: 1px solid rgb(211, 198, 198);
  border-right: none;
  border-bottom: 1px solid rgb(211, 198, 198);
  text-align: center;
  background: rgb(0, 0, 0, 0);
}

table > tr:first-child > th {
  border-top: 1px solid rgb(211, 198, 198);
}

table > tr > td:last-child,
table > tr > th:last-child {
  border-right: 1px solid rgb(211, 198, 198);
}
</style>

<template>
  <div ref="earthContainer" class="earth"></div>
</template>

<script>
import ProjectTile from "../scripts/ProjectTile";
/* eslint-disable */

// 1 创建Earth的vue组件
var Earth = {
  data() {
    return {
      message: "页面加载于 " + new Date().toLocaleString()
    };
  },
  components: {},
  methods: {
    addSceneObject(layer, title) {
      //构造场景树结构
      const obj = new XE.SceneTree.Leaf(layer);
      obj.title = title;
      var earth = this.$root._earth;
      //获得当前选中的树节点
      var selected = earth.sceneTree.currentSelectedNode;
      //如果为空，那么添加到根下
      if (!selected) {
        earth.sceneTree.root.children.push(obj);
      }
      //如果为group，添加到group最后
      else if (selected.children) {
        selected.children.push(obj);
      }
      //如果为node，添加到node的parent最后
      else if (selected.parent) {
        selected.parent.children.push(obj);
      }
      console.log("场景树", earth.sceneTree)
    }

  },
  // 1.1 资源创建
  mounted() {
    // 1.1.1 创建地球
    var earth = new XE.Earth(this.$refs.earthContainer);
    console.log("create earth!", XE.HTML.cesiumDir);
    earth.camera.navigator.showCompass = true;
    earth.sceneTree.root = {
      children: [{
        czmObject: {
          name: "arcgis在线影像",
          xbsjType: "Imagery",
          xbsjImageryProvider: {
            createTileMapServiceImageryProvider: {
              // url: XE.HTML.cesiumDir + "Assets/Textures/NaturalEarthII",
              url: 'https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
              fileExtension: "jpg"
            },
            type: "createTileMapServiceImageryProvider"
          }
        }
      }]
    };

    this._earth = earth;

    this.$root._earth = earth;

    //构造我们的项目的3dtiles对象
    var tileset = new ProjectTile(earth, this.$root.projectid);
    tileset.featureSelected.addEventListener(obj => {
      console.log("featureSelected", obj);


      if (obj.otype === 'build') {
        this.$root.$refs.right.showBuild({
          id: obj.id
        });
      }
      else if (obj.id.indexOf('_') < 0) {
        this.$root.$refs.right.showPoint({
          id: obj.id
        });
      } else {
        this.$root.$refs.right.showLine({
          id: obj.id
        });
      }
    });



    this.$root._tileset = tileset;

    //监控鼠标点击事件，向外发送
    const canvas = earth.czm.viewer.canvas;

    var _bus = this.$bus;
    const scratchCartograghic = [0, 0, 0];

    function pointerDownFunc(pointerEvent) {
      // 左键按下时才起作用
      const x = pointerEvent.offsetX;
      const y = pointerEvent.offsetY;
      const carto = earth.pickPosition({
        x,
        y
      }, scratchCartograghic);

      _bus.emit("earthClicked", {
        carto,
        pointerEvent
      });
    }
    canvas.addEventListener("pointerdown", pointerDownFunc);

    function mousemoveFunc(mouseEvent) {
      const {
        offsetX: x,
        offsetY: y
      } = mouseEvent;
      const carto = earth.pickPosition({
        x,
        y
      }, scratchCartograghic);

      _bus.emit('3d_moving', {
        x,
        y,
        carto
      });

    }
    canvas.addEventListener("mousemove", mousemoveFunc);

    // 仅为调试方便用
    window.earth = earth;
    window.tileset = this.$root._tileset;

    this.$root._earthVue = this;
    this.$bus.emit("initearth");



  },
  // 1.2 资源销毁
  beforeUnmount() {
    // vue程序销毁时，需要清理相关资源
    this._earth = this._earth && this._earth.destroy();
  }
};

export default Earth;
</script>
<style scoped>
.earth {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
</style>

<template>
    <div class="property">管线图层属性
        <div>
            <label>图层ID:<input type="text" v-model="paramA.id"></label><br>
            <label>图层名称:<input type="text" v-model="paramA.title"></label><br>
            <label>图层配置:</label><br>
            <div class="configcolor">
                <div>颜色:{{paramA.config.color}}</div>
                <ColorButton v-model:color="color"></ColorButton>
            </div>
        </div>
        <button class="xbsj-button-tab" @click="ok">确认更改</button>
    </div>
</template>
<script>
    import ColorButton from "../../../components/ColorButton/Index"
    export default {
        components: {
            ColorButton
        },
        data() {
            return {
                treeId: [],
                treeid: [],
                paramD: "",
                color: "",
                paramA: {
                    id: "",
                    title: "",
                    config: {
                        color: ""
                    }
                }

            }
        },
        props: {
            param: {
                type: Object,
                default: undefined
            }
        },
        created() {},
        mounted() {
            this.paramD = this.param.param
            //console.log(this.paramD)
            this.creatIdArr(this.param.arr)
            //this.paramA = this.paramD
            this.paramA.id = this.paramD.id
            this.paramA.title = this.paramD.title
            this.color = this.rgbaNum(this.paramD.config.color)
            this.treeid = this.removeEmptyArrayEle(this.treeId)
        },
        watch: {
            "color": function () {
                this.paramA.config.color =
                    `rgba(${[this.color.r, this.color.g, this.color.b,this.color.a].join(",")})`
            }
        },
        methods: {
            rgbaNum(rgba) { //rgba转换
                let val = rgba.match(/(\d(\.\d+)?)+/g);
                let color = {
                    r: val[0],
                    g: val[1],
                    b: val[2],
                    a: val[3]
                }
                return color
            },
            ok() {
                if (!this.paramA.id || !this.paramA.title || !this.paramA.config.color) {
                    this.$root.$alert("图层属性内容不能为空");
                    return;
                } else {
                    if (this.paramD.id == this.paramA.id) {
                        //console.log("id未作更改，去保存")
                        this.paramD.id = this.paramA.id
                        this.paramD.title = this.paramA.title
                        this.paramD.config = this.paramA.config
                    } else {
                        // console.log("ID更改，需要判断重复")
                        if (this.checkNameBesidesMy_this(this.paramD.id, this.paramA.id)) {
                            this.$root.$alert("ID已经存在,请重新输入");
                            return;
                        } else {
                            //console.log("id判断未作更改，去保存")
                            this.paramD.id = this.paramA.id
                            this.paramD.title = this.paramA.title
                            this.paramD.config = this.paramA.config
                        }
                    }
                }

            },
            checkNameBesidesMy_this(oldname, newname) { //判断id是否重复，跳过自己
                let flag;
                for (let i = 0; i < this.treeid.length; i++) {
                    if (oldname == this.treeid[i]) {
                        flag = i;
                        break;
                    }
                }
                for (let j = 0; j < this.treeid.length; j++) {
                    if (!(j == flag)) {
                        if (newname == this.treeid[j]) {
                            return true;
                        }
                    }
                }
                return false;
            },
            creatIdArr(treeData) { //循环拿到所有图层id
                treeData.forEach((element) => {
                    let id
                    if (element.dragType === "layer") {
                        id = element.id
                    }
                    if (element.children && element.children.length) {
                        id = this.creatIdArr(element.children);
                    }
                    this.treeId.push(id);
                });
            },
            removeEmptyArrayEle(arr) { //去除数组内undefined
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i] === undefined) {
                        arr.splice(i, 1);
                        i = i - 1
                    }
                }
                return arr;
            }
        },
        beforeUnmount() {}
    };
</script>

<style scoped>
    .property {
        color: #CCCCCC;
        width: var(--RightWidth);
        height: 100%;
        background: var(--PanelBGColor);
    }

    .property>div {
        width: 100%;
    }

    .configcolor {
        margin: 5px 5px 5px 27px;
    }
</style>>
<template>
    <div class="xbsj-editor-header">
        <div>
            <div class="left_title">
                <div>
                    <img src="./assets/biglogo.png" alt="" width="66" height="33" />
                </div>
                <div>
                    <span>{{ name }}</span>
                </div>
            </div>
            <div class="center_content">
                <!-- 数据对象-->
                <div>
                    <div class="tip"><span>数据对象</span></div>
                    <ul>
                        <li @click="pipePointQuery">
                            <img v-show="selectname == '管点管理'" src="./assets/newicons/guandian2.png" class="img" />
                            <img v-show="!(selectname == '管点管理')" src="./assets/newicons/guandian1.png" class="img"
                                alt="" />
                            <span>管点管理</span>
                        </li>
                        <li @click="pipeLineQuery">
                            <img v-show="selectname == '管线管理'" src="./assets/newicons/guanxian2.png" class="img" />
                            <img v-show="!(selectname == '管线管理')" src="./assets/newicons/guanxian1.png" alt="" />
                            <span>管线管理</span>
                        </li>
                        <li @click="pipeBuildQuery">
                            <img v-show="selectname == '建筑管理'" src="./assets/newicons/build2.png" class="img" />
                            <img v-show="!(selectname == '建筑管理')" src="./assets/newicons/build1.png" alt="" />
                            <span>建筑管理</span>
                        </li>
                        <li @click="addPoint">
                            <img v-show="selectname == '添加管点'" src="./assets/newicons/addguandian2.png" class="img" />
                            <img v-show="selectname != '添加管点'" src="./assets/newicons/addguandian1.png" class="img"
                                alt="" />
                            <span>添加管点</span>
                        </li>
                        <li @click="addLine">
                            <img v-show="selectname == '添加管线'" src="./assets/newicons/addguanxian2.png" class="img" />
                            <img v-show="selectname != '添加管线'" src="./assets/newicons/addguanxian1.png" class="img"
                                alt="" />
                            <span>添加管线</span>
                        </li>
                        <li @click="addBuild">
                            <img v-show="selectname == '添加建筑'" src="./assets/newicons/addbuild2.png" class="img" />
                            <img v-show="selectname != '添加建筑'" src="./assets/newicons/addbuild1.png" class="img" />
                            <span>添加建筑</span>
                        </li>
                        <li @click="ImportPoint">
                            <img src="./assets/newicons/import1.png" alt="" />
                            <span>导入管点</span>
                        </li>
                        <li @click="importLine">
                            <img src="./assets/newicons/import1.png" alt="" />
                            <span>导入管线</span>
                        </li>
                        <li @click="ImportBuild">
                            <img src="./assets/newicons/import1.png" alt="" />
                            <span>导入建筑</span>
                        </li>
                    </ul>
                </div>
                <!-- 项目资源-->
                <div>
                    <div class="tip"><span>项目资源</span></div>
                    <ul>
                        <li @click="appendages">
                            <img src="./assets/newicons/fushuwu1.png" alt="" />
                            <span>附属物库</span>
                        </li>
                        <li @click="materialSelect">
                            <img src="./assets/newicons/caizhi1.png" alt="" />
                            <span class="min_span">材质库</span>
                        </li>
                        <li @click="textureSelect">
                            <img src="./assets/newicons/wenli1.png" alt="" />
                            <span class="min_span">纹理库</span>
                        </li>
                        <li @click="parameterTool">
                            <img src="./assets/newicons/zaoxing1.png" alt="" />
                            <span class="min_span">造型库</span>
                        </li>
                    </ul>
                </div>
                <!-- 数值分析-->
                <div>
                    <div class="tip"><span>数值分析</span></div>
                    <ul>
                        <li @click="connectivity">
                            <img v-show="selectname == '连通性分析'" src="./assets/newicons/liantongxing2.png" class="img" />
                            <img v-show="!(selectname == '连通性分析')" src="./assets/newicons/liantongxing1.png" class="img"
                                alt="" />
                            <span class="min_span">连通性分析</span>
                        </li>
                        <li @click="burst">
                            <img v-show="selectname == '爆管分析'" src="./assets/newicons/liantongxing2.png" class="img" />
                            <img v-show="!(selectname == '爆管分析')" src="./assets/newicons/liantongxing1.png" class="img"
                                alt="" />
                            <span>爆管分析</span>
                        </li>
                        <li @click="section">
                            <img v-show="selectname == '横断面分析'" src="./assets/newicons/poumian2.png" class="img" />
                            <img v-show="!(selectname == '横断面分析')" src="./assets/newicons/poumian1.png" class="img"
                                alt="" />
                            <span class="min_span">横断面分析</span>
                        </li>
                        <li @click="profile">
                            <img v-show="selectname == '纵断面分析'" src="./assets/newicons/poumian2.png" class="img" />
                            <img v-show="!(selectname == '纵断面分析')" src="./assets/newicons/poumian1.png" class="img"
                                alt="" />
                            <span class="min_span">纵断面分析</span>
                        </li>
                    </ul>
                </div>
                <!-- 鼠标交互-->
                <div>
                    <div class="tip"><span>鼠标交互</span></div>
                    <ul>
                        <li @click="flyTo">
                            <img src="./assets/newicons/quanping1.png" class="img" />
                            <span>全图</span>
                        </li>
                        <li @click="clickSelect">
                            <img v-show="mouseClickSelect" src="./assets/newicons/dianxuan2.png" class="img" />
                            <img v-show="!mouseClickSelect" src="./assets/newicons/dianxuan1.png" class="img" alt="" />
                            <span>点选</span>
                        </li>
                        <li @click="measure">
                            <img v-show="measureshow" src="./assets/newicons/celiang2.png" class="img" />
                            <img v-show="!measureshow" src="./assets/newicons/celiang1.png" class="img" alt="" />
                            <span>测量</span>
                        </li>

                        <li @click="cutsurface">
                            <img v-show="cutsurfaceshow" src="./assets/newicons/wakeng2.png" class="img" />
                            <img v-show="!cutsurfaceshow" src="./assets/newicons/wakeng1.png" class="img" alt="" />
                            <span>挖坑</span>
                        </li>
                    </ul>
                </div>
                <!-- 项目支撑-->
                <div>
                    <div class="tip"><span>项目支撑</span></div>
                    <ul>
                        <li @click="projectProperty">
                            <img src="./assets/newicons/peizhi1.png" class="img" alt="" />
                            <span>项目配置</span>
                        </li>
                        <li @click="pipeTaskQuery">
                            <img src="./assets/newicons/renwu1.png" class="img" alt="" />
                            <span>任务查询</span>
                        </li>
                    </ul>
                </div>
                <!-- 关于 -->
                <div class="chart">
                    <div class="tip"><span>关于</span></div>
                    <ul>
                        <li>
                            <img src="./assets/newicons/peizhi1.png" class="img" alt="" />
                            <span>关于</span>
                            <div class="on">
                                <p style='line-height:40px'>PipeSer管线管网云服务平台</p>
                                <a href='http://www.pipeser.com/' target='_blank'
                                    style='line-height:20px;color:white;'>联系我们</a>
                                <img src="./assets/wechart.png" width="90" height="90" alt="">
                            </div>


                        </li>
                        <li @click="help">
                            <img src="./assets/newicons/peizhi1.png" class="img" alt="" />
                            <span>帮助</span>
                        </li>
                        <li @click="course">
                            <img src="./assets/newicons/peizhi1.png" class="img" alt="" />
                            <span>视频教程</span>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="right">
                <div class="right_time">
                    {{timeout}}
                </div>
                <div class="right_setUp">
                    <div class="user" ref="user">
                        <img :src="src ? src : require('../page-editor/assets/pipeser.jpg')" />
                        <span>{{username}}</span>
                    </div>
                    <div ref="information" class="information" v-show="usershow">
                        <div @click="showUserInformation"><span>个人资料</span></div>
                        <div @click="cancellation"><span>注销</span></div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import MaterialSelect from "./components/MaterialSelect";
    import Appendages from "./components/Appendages";
    import ImportLine from "./components/ImportLine";
    import ImportBuild from "./components/ImportBuild";
    import ImportPoint from "./components/ImportPoint";
    import parameterTool from "./components/ParameterTool";
    import TextureSelect from "./components/TextureSelect";
    import MeasureTool from "./components/MeasureTool";
    import CutSurface from "./components/CutSurface";
    import Table from "@/components/Table.vue";
    import moment from 'moment';
    /* eslint-disable */
    export default {
        name: "Bottom",
        data() {
            return {
                mouseClickSelect: false,
                usershow: false,
                name: "",
                src: "",
                selectname: "",
                measureshow: false,
                cutsurfaceshow: false,
                username: "",
                timeout: "",
                chart: ""
            };
        },
        mounted() {

            setInterval(this.uptime, 1000)
            this.$bus.on("updateAppend", name => {
                this.selectname = name
            })
            this.$bus.on("updatename", name => {
                this.name = name;
            });
            this.$bus.on("cancelMeasure", () => {
                this.measureshow = false;
            });
            this.$bus.on("cancelCutface", () => {
                this.cutsurfaceshow = false;
            });
            // let data = {
            //     phone: localStorage.getItem("phone")
            // }
            let self = this;
            this.$axios
                .get("/user/info?t="+new Date())
                .then(res => {
                    self.src = res.data.data.thumbnail;
                    self.username = res.data.data.nickname;
                })
                .catch(err => {
                    console.log(err.response || err);
                });
            document.addEventListener("click", this.isUser);
            //console.log('aaaaaaaaaaaaa');
            //绑定交互按钮
            this.$bus.on("initearth", () => {
                XE.MVVM.bind(
                    this,
                    "mouseClickSelect",
                    this.$root._earth.interaction.picking,
                    "enabled"
                );
                //console.log('aaaaaaaaaaaaa');
            });

        },
        components: {
            Table
        },
        methods: {
            uptime() {
                //let self = this
                // var date = new Date()
                // self.timeout = date.toLocaleString()
                this.timeout = moment(new Date).format("YYYY年MM月DD日  HH:mm:ss");


            },
            course() {
                this.$root.$refs.right.showcourse()
            },

            flyTo() {
                this.$root._tileset.tileset.flyTo();
            },
            about() {
                this.$root.$alert(
                    "<div style='line-height:0px;'><p style='line-height:40px'>PipeSer管线管网云服务平台</p><a href='http://www.pipeser.com/' target='_blank'  style='line-height:20px;color:white;'>联系我们</a></div>"
                );
            },
            help() {
                window.open("./models/PipeSer.pdf");
            },
            // 显示个人资料
            showUserInformation() {
                window.open("/user.html");
            },
            // 注销
            cancellation() {
                this.$root.$confirm("确定注销退出吗？", () => {
                    localStorage.removeItem("token");
                    window.location.href = "/login.html";
                });
            },
            // 监听用户头像
            isUser($event) {
                //监听全局事件，判断点击的是否为用户头像
                var user = this.$refs.user;
                if (user) {
                    if (user.contains($event.target)) {
                        this.usershow = true;
                    } else {
                        this.usershow = false;
                    }
                }
            },
            textureSelect() {
                this.$root.showPropertyWindow("TextureSelect", {
                    component: TextureSelect
                });
            },
            pipeTaskQuery() {
                //查询状态任务查询
                this.$root.$refs.right.showPipeTask();
            },
            addPoint() {
                if (this.selectname == "添加管点") {
                    this.selectname = "";
                } else {
                    this.selectname = "添加管点";
                }
                this.$root.$refs.bottom.switchTool({
                    name: "添加管点",
                    component: "AddPointTool"
                });
            },
            addLine() {
                if (this.selectname == "添加管线") {
                    this.selectname = "";
                } else {
                    this.selectname = "添加管线";
                }
                this.$root.$refs.bottom.switchTool({
                    name: "添加管线",
                    component: "AddLineTool"
                });
            },
            addBuild() {
                if (this.selectname == "添加建筑") {
                    this.selectname = "";
                } else {
                    this.selectname = "添加建筑";
                }
                this.$root.$refs.bottom.switchTool({
                    name: "添加建筑",
                    component: "AddBuildTool"
                });
            },
            ImportBuild() {
                this.$root.showPropertyWindow("ImportBuild", {
                    component: ImportBuild
                });
            },
            ImportPoint() {
                this.$root.showPropertyWindow("ImportPoint", {
                    component: ImportPoint
                });
            },
            importLine() {
                this.$root.showPropertyWindow("ImportLine", {
                    component: ImportLine
                });
            },
            materialSelect() {
                this.$root.showPropertyWindow("MaterialSelect", {
                    component: MaterialSelect
                });
            },
            appendages() {
                this.$root.showPropertyWindow("Appendages", {
                    component: Appendages
                });
            },
            parameterTool() {
                this.$root.showPropertyWindow("parameterTool", {
                    component: parameterTool
                });
            },
            clickSelect() {
                //切换鼠标点选状态
                this.mouseClickSelect = !this.mouseClickSelect;
                // if (this.mouseClickSelect) {
                //     this.$root.$enablePicking();
                // } else {
                //     this.$root.$disablePicking();
                // }
            },
            projectProperty() {

                this.$root.$refs.right.showProject();
            },
            measure() {
                this.measureshow = !this.measureshow;
                if (this.measureshow) {
                    this.$root.showPropertyWindow("MeasureTool", {
                        component: MeasureTool
                    });
                } else {
                    this.$root.$refs.MeasureTool.cancel();
                }
            },

            pipePointQuery() {
                if (this.selectname == "管点管理") {
                    this.selectname = "";
                } else {
                    this.selectname = "管点管理";
                }
                this.$root.$refs.bottom.switchTool({
                    name: "管点查询",
                    component: "PipePointQuery"
                });
            },
            pipeLineQuery() {
                if (this.selectname == "管线管理") {
                    this.selectname = "";
                } else {
                    this.selectname = "管线管理";
                }
                this.$root.$refs.bottom.switchTool({
                    name: "管线查询",
                    component: "PipeLineQuery"
                });
            },
            pipeBuildQuery() {
                if (this.selectname == "建筑管理") {
                    this.selectname = "";
                } else {
                    this.selectname = "建筑管理";
                }
                this.$root.$refs.bottom.switchTool({
                    name: "建筑查询",
                    component: "pipeBuildQuery"
                });
            },
            connectivity() {
                if (this.selectname == "连通性分析") {
                    this.selectname = "";
                } else {
                    this.selectname = "连通性分析";
                }
                this.$root.$refs.bottom.switchTool({
                    name: "连通性分析",
                    component: "Connectivity"
                });
            },
            burst() {
                if (this.selectname == "爆管分析") {
                    this.selectname = "";
                } else {
                    this.selectname = "爆管分析";
                }
                this.$root.$refs.bottom.switchTool({
                    name: "爆管分析",
                    component: "burst"
                });
            },
            section() {
                if (this.selectname == "横断面分析") {
                    this.selectname = "";
                } else {
                    this.selectname = "横断面分析";
                }
                this.$root.$refs.bottom.switchTool({
                    name: "横断面分析",
                    component: "Section"
                });
            },
            profile() {
                if (this.selectname == "纵断面分析") {
                    this.selectname = "";
                } else {
                    this.selectname = "纵断面分析";
                }
                this.$root.$refs.bottom.switchTool({
                    name: "纵断面分析",
                    component: "Profile"
                });
            },
            cutsurface() {
                this.cutsurfaceshow = !this.cutsurfaceshow;
                if (this.cutsurfaceshow) {
                    this.$root.showPropertyWindow("CutSurface", {
                        component: CutSurface
                    });
                } else {
                    this.$root.$refs.CutSurface.cancel();
                }
            }
        }
    };
</script>

<style scoped>
    .xbsj-editor-header {
        color: #cccccc;
        position: absolute;
        width: 100%;
        left: 0px;
        top: 0px;
        right: 0px;
        height: var(--HeaderHeight);
        background: var(--BackgroundColor);
    }

    .xbsj-editor-header>div {
        height: var(--HeaderHeight);
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin: auto;
    }

    .tip {
        position: absolute;
        top: 5px;
        width: 64px;
        height: 20px;
        line-height: 20px;
        color: #ccc;
        background: #000;
        margin-bottom: 5px;
        margin-left: 15px;
        font-size: 12px;
    }

    .center_content {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
    }

    .center_content>div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

    }

    ul {
        height: 80px;
        display: flex;
        border: 1px solid #1a1a1a;
        border-left: none;
        opacity: 1;
        border-radius: 2px;
        margin-bottom: 3px;
        align-content: flex-end;
    }

    .center_content>div:nth-child(1)>ul {
        border-left: 1px solid #1a1a1a;
    }

    ul>li {
        width: 50px;
        text-align: center;
        height: 80px;
        border: none;
        padding: none;
        margin: none;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        justify-content: center;
    }

    li>img {
        width: 20px;
        height: 20px;
        margin: 0 auto;
        margin-bottom: 5px;
    }

    li>span {
        margin: 0 auto;
        margin-top: 0px;
        font-size: 12px;
        text-align: center;
        display: block;
        width: 30px;
        height: 16px;
        line-height: 16px;
    }

    ul>li:hover {
        background: #1a1a1a !important;
        opacity: 1;
    }

    .min_span {
        width: 40px;
    }

    .left_title {
        width: 300px;
        line-height: 98px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left_title>div:nth-child(1) {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left_title>div:nth-child(2) {
        display: flex;
        align-items: center;
        width: 70%;
        text-align: start;
        height: 98px;
    }

    .left_title>div>span {
        width: 100%;
        font-size: 20px;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        color: #cccccc;
    }

    .right {
        line-height: 98px;
        flex-grow: 0;
        width: 300px;
        margin-right: 20px;
        display: flex;
        justify-content: space-around;
        align-content: center;
        align-items: center;
        flex-shrink: 0;

    }

    .right_setUp {
        width: 60px;
        height: 98px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .user {
        margin-top: 20px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        line-height: 30px;
    }

    .user>img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        cursor: pointer;
    }

    .user>span {
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

    }

    .right_time {
        width: 108px;
        height: 43px;
        font-size: 16px;
        line-height: 25px;
        border-radius: 20px;
        word-break: keep-all;
    }

    .information {
        width: 100px;
        position: absolute;
        top: 100px;
        z-index: 8000;
        background: var(--BackgroundColor);
    }

    .information>div:nth-child(1) {
        border-bottom: 2px solid var(--PanelBGColor);
    }

    .information>div {
        height: 35px;
        line-height: 35px;
        cursor: pointer;
    }

    .img {
        /* background: #288DFB; */
        width: 20px;
        height: 20px;
        border: none;
    }

    .chart {
        position: relative;
    }

    .chart>ul>li:hover div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .on {
        width: 200px;
        padding-bottom: 10px;
        display: none;
        position: absolute;
        top: 98px;
        z-index: 9999;
        border: 3px solid var(--BackgroundColor);
    }
</style>

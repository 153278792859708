<template>
    <div class="property">瓦片属性
        <div>
            <label>url</label> <input class="input" type="text" v-model="url">
        </div>
        <div>
            <label>材质底色</label> <input class="input" type="range" min=0.2 max=5 step="0.01"
                v-model.number="luminanceAtZenith">{{luminanceAtZenith}}
        </div>
        <div>
            <label>最大内存</label> <input type="range" min=512 max=5120 step="1"
                v-model.number="maximumMemoryUsage">{{maximumMemoryUsage}}
        </div>
        <div>
            <label>显示精度</label> <input type="range" min=0.1 max=256 :step="0.1"
                v-model.number="maximumScreenSpaceError">{{maximumScreenSpaceError}}
        </div>
        <div>
            <label>backFaceCulling</label> <input class="bty-switch" type="checkbox" v-model="backFaceCulling">
        </div>
        <div>
            <button @click="save">确认保存</button>
        </div>

    </div>
</template>
<script>
    /* eslint-disable */
    export default {
        data() {
            return {
                url: "",
                luminanceAtZenith: 0.2,
                maximumMemoryUsage: 512,
                maximumScreenSpaceError: 4,
                backFaceCulling: true
            }
        },
        props: {
            param: Object
        },
        created() {},
        mounted() {
            console.log("瓦片", this.param)
            this.bind("url")
            this.bind("luminanceAtZenith")
            this.bind("maximumMemoryUsage")
            this.bind("maximumScreenSpaceError")
            this.bind("backFaceCulling")
        },
        methods: {
            bind(prp) {
                this._viewUnbinds = this._viewUnbinds || [];
                this._viewUnbinds.push(
                    XE.MVVM.bind(this, prp, this.param, prp)
                );
            },
            save() {
                this.$axios.put("/project/info/update", {
                    projectid: this.$root.projectid,
                    basemap: JSON.stringify(this.$root._earth.sceneTree.root.children)
                }).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err.response || err)
                })
            },
        },
        beforeUnmount() {}
    };
</script>

<style scoped>
    .property {
        color: #CCCCCC;
        width: var(--RightWidth);
        height: 100%;
        background: var(--PanelBGColor);
    }

    .property>div {
        display: flex;
        line-height: 30px;
        height: 30px;
        align-items: center;
    }

    .property>div>label {
        width: 100px;
        text-align: end;
        padding: 0 10px;
    }

    .input {
        height: 24px;
        background: #383838;
        border: 1px solid #4D4D4D;
        opacity: 1;
        border-radius: 2px;
        color: #CCC;
    }
</style>>
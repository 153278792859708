<template>
    <Model :title="'挖坑'" :width="360" :height="36" @cancel="cancel">
        <div class="cutsurface">
            <span @click="reset">重置</span>
            <span :class="{select:index==1}" @click="clickcircle">圆形坑</span>
            <span :class="{select:index==2}" @click="clickrectangle">矩形坑</span>
            <span :class="{select:index==3}" @click="clickpolygon">任意多边形</span>
            <!-- <span @click="save">保存到场景树</span> -->
        </div>
    </Model>
</template>

<script>
    /* eslint-disable */
    import Model from "../../../components/Model"
    export default {
        components: {
            Model
        },
        data() {
            return {
                index: 1,
                showPinSelect: false,
                drag_over: false,
                name: "",
                show: true,
                polygoncreating: false,
                editing: false,
                sampledDistance: 10,
                textureRotationEditing: false,
                imageWidthScale: 1.0,
                wallImageUrl: "",
                wallImageHeight: 3,
                wallImageWidthScale: 1,
                bottomImageUrl: "",
                bottomImageHeight: 3,
                bottomImageWidthScale: 1,
                bottomTextureRotationEditing: false,
                connections: [],
                connectedTileset: "",
                showSelect: false,
                circlecreating: false,
                rectanglecreating: false,
                polygoncreating: false,
                data: []
            };
        },
        mounted() {
            // 挖坑
            this.cutsurface = new XE.Obj.CutSurface(this.$root._earth);
            this.$root._earth.terrainEffect.depthTest = true;
            this.cutsurface.isCreating = true;
            this.cutsurface.notUseSampleHeightMostDetailed = true; // vtxf 加速获取高程结果 20210430
            this.cutsurface.name = "挖坑";

            this._disposers = this._disposers || [];
            // 圆形坑属性绑定
            this.circleline = new XE.Obj.Plots.GeoCircle(this.$root._earth);
            this._disposers.push(XE.MVVM.bind(this, "circlecreating", this.circleline, "creating"));

            //矩形坑属性绑定
            this.rectangleline = new XE.Obj.Plots.GeoRectangle(this.$root._earth);
            this._disposers.push(XE.MVVM.bind(this, "rectanglecreating", this.rectangleline, "creating"));

            // 任意多边形坑属性绑定
            this.polygonline = new XE.Obj.Plots.GeoPolygon(this.$root._earth);
            this._disposers.push(XE.MVVM.bind(this, "polygoncreating", this.polygonline, "creating"));

            window.cutface = this.cutsurface;

            var czmObj = this.cutsurface;
            if (czmObj) {
                this._czmObj = czmObj;
                const bindData = {
                    name: "name",
                    show: "show",
                    editing: "editing",
                    sampledDistance: "sampledDistance",
                    wallImageUrl: "wallImageUrl",
                    wallImageHeight: "wallImageHeight",
                    wallImageWidthScale: "wallImageWidthScale",
                    bottomImageUrl: "bottomImageUrl",
                    bottomImageHeight: "bottomImageHeight",
                    bottomImageWidthScale: "bottomImageWidthScale",
                    bottomTextureRotationEditing: "bottomTextureRotationEditing"
                };
                // 挖坑属性绑定
                Object.entries(bindData).forEach(([sm, vm]) => {
                    if (typeof vm === "string") {
                        this._disposers.push(XE.MVVM.bind(this, vm, this._czmObj, sm));
                    } else {
                        this._disposers.push(vm.handler(this, vm.prop, this._czmObj, sm));
                    }
                });
                this.$root._earth.terrainEffect.depthTest = true;
            }
            this.clickcircle()
        },
        watch: {
            polygoncreating() {
                console.log("监听到了多边形creating", this.polygoncreating)
                if (!this.polygoncreating) {
                    this.polygonline.enabled = false;
                    this.turnPolygon()
                }
            },
            circlecreating() {
                console.log("监听到了圆形坑的creating", this.circlecreating)
                if (!this.circlecreating) {
                    this.circleline.enabled = false;
                    this.turnCircle()
                }
            },
            rectanglecreating() {
                console.log("监听到了矩形坑的creating", this.circlecreating)
                if (!this.rectanglecreating) {
                    this.rectangleline.enabled = false;
                    this.turnRectangle()
                }
            }
        },
        computed: {
            guid() {
                return this.cutsurface.getDiscardGuid();
            }
        },
        methods: {
            // 计算包围盒
            calculateSpacing(arr) {
                let spacing = [...arr]
                if (Array.isArray(spacing) && spacing.length) {

                    var west, east, north, south;
                    for (let i = 0; i < spacing.length; i++) {
                        if (!west) {
                            west = east = spacing[i];
                            i++;
                            north = south = spacing[i];
                        } else {
                            west = Math.min(west, spacing[i]);
                            east = Math.max(east, spacing[i]);
                            i++;
                            south = Math.min(south, spacing[i]);
                            north = Math.max(north, spacing[i]);
                        }
                    }
                    let min = [west, south]
                    let max = [east, north]
                    return XE.Tool.Math.geoDistance(min, max) / 10
                }
            },
            //多边形坑的高度获取并绘制 
            turnPolygon() {
                let arr = [...this.polygonline._polygon.positions]
                if (arr.length > 0) {
                    this.takePoint(arr)
                    this.sampledDistance = this.calculateSpacing(arr)
                    let self = this
                    let geojson = {
                        "type": "Polygon",
                        "coordinates": [this.data]
                    }
                    let params = {
                        projectid: this.$root.projectid,
                        geojson: JSON.stringify(geojson)
                    }
                    this.$axios
                        .get('/project/line/heightrange', {
                            params: params
                        })
                        .then(res => {
                            self.index = 0
                            self.cutsurface.enabled = true
                            self._czmObj.positions = []
                            self._czmObj.positions = [...this.polygonline._polygon.positions]
                            console.log("多边形挖坑的点", self._czmObj.positions)
                            if (res.data.data[0].min) {
                                self._czmObj.height = res.data.data[0].min - 6
                            } else {
                                self._czmObj.height = 0
                            }
                            self.terrainDiscardClick()

                        }).catch(err => {
                            console.log(err.response || err)
                        })
                }

            },
            //圆形坑的高度获取并绘制 
            turnCircle() {
                console.log("获取高度")
                this.data = []
                let jingdu = [];
                let weidu = []
                let spacing = [...this.circleline._polygon.positions]
                if (Array.isArray(spacing) && spacing.length) {

                    var west, east, north, south;
                    for (let i = 0; i < spacing.length; i++) {
                        if (!west) {
                            west = east = spacing[i];
                            i++;
                            north = south = spacing[i];
                        } else {
                            west = Math.min(west, spacing[i]);
                            east = Math.max(east, spacing[i]);
                            i++;
                            south = Math.min(south, spacing[i]);
                            north = Math.max(north, spacing[i]);
                        }
                    }
                    west = west * 180 / Math.PI
                    east = east * 180 / Math.PI
                    north = north * 180 / Math.PI
                    south = south * 180 / Math.PI

                    this.data = [
                        [west, south],
                        [east, south],
                        [east, north],
                        [west, north],
                        [west, south]
                    ]
                }
                if (spacing.length > 0) {
                    let arr1 = [...this.circleline.positions]
                    this.sampledDistance = XE.Tool.Math.geoDistance([...arr1[0]], [...arr1[1]]) / 20
                    console.log('this.sampledDistance', this.sampledDistance);
                    let self = this
                    let geojson = {
                        "type": "Polygon",
                        "coordinates": [this.data]
                    }
                    let params = {
                        projectid: this.$root.projectid,
                        geojson: JSON.stringify(geojson)
                    }
                    this.$axios
                        .get('/project/line/heightrange', {
                            params: params
                        })
                        .then(res => {
                            self.index = 0
                            self.cutsurface.enabled = true
                            this._czmObj.positions = [...this.circleline._polygon.positions]
                            console.log("服务端获取的高度", res.data.data)
                            if (res.data.data[0].min) {
                                self._czmObj.height = res.data.data[0].min - 6
                            } else {
                                self._czmObj.height = 0
                            }
                            self.terrainDiscardClick()
                        }).catch(err => {
                            console.log(err.response || err)
                        })
                }
            },
            //矩形坑的高度获取并绘制 
            turnRectangle() {
                let arr = [...this.rectangleline._polygon.positions]
                if (arr.length > 0) {
                    this.takePoint(arr)
                    this.sampledDistance = this.calculateSpacing(arr)
                    let self = this
                    let geojson = {
                        "type": "Polygon",
                        "coordinates": [this.data]
                    }
                    let params = {
                        projectid: this.$root.projectid,
                        geojson: JSON.stringify(geojson)
                    }
                    this.$axios
                        .get('/project/line/heightrange', {
                            params: params
                        })
                        .then(res => {
                            self.index = 0
                            self.cutsurface.enabled = true
                            this._czmObj.positions = [...this.rectangleline._polygon.positions]
                            console.log("矩形挖坑的点", self._czmObj.positions)
                            if (res.data.data[0].min) {
                                self._czmObj.height = res.data.data[0].min - 6
                            } else {
                                self._czmObj.height = 0
                            }
                            self.terrainDiscardClick()
                        }).catch(err => {
                            console.log(err.response || err)
                        })
                }
            },
            //取点计算挖坑高度
            takePoint(arr) {
                console.log("点个数", arr)
                this.data = []
                for (let index = 0; index < arr.length; index++) {
                    var lat = arr[index] * 180 / Math.PI
                    var lng = arr[++index] * 180 / Math.PI
                    this.data.push([lat, lng])
                }
                this.data.push(this.data[0])
            },
            // 重置
            reset() {
                this.index = 0
                this.circlecreating = false;
                this.rectanglecreating = false;
                this.polygoncreating = false;
                this.rectangleline.enabled = false;
                this.circleline.enabled = false;
                this.polygonline.enabled = false;
                this.cutsurface.enabled = false
                this._czmObj.positions = []
                this.polygonline._polygon.positions = []
                this.rectangleline._polygon.positions = []
                this.circleline._polygon.positions = []
                this._czmObj.applyTerrain();
            },
            // 圆形坑
            clickcircle() {
                this._czmObj.positions = []
                this.polygonline._polygon.positions = []
                this.rectangleline._polygon.positions = []
                this.circleline._polygon.positions = []
                this.cutsurface.enabled = false
                this.index = 1;
                this.rectanglecreating = false;
                this.polygoncreating = false;
                this.rectangleline.enabled = false;
                this.polygonline.enabled = false;
                this._czmObj.applyTerrain();
                this.circlecreating = true;
                this.circleline.enabled = true;
            },
            // 矩形形坑
            clickrectangle() {
                this._czmObj.positions = []
                this.polygonline._polygon.positions = []
                this.rectangleline._polygon.positions = []
                this.circleline._polygon.positions = []
                this.cutsurface.enabled = false
                this.index = 2;
                this.circleline.enabled = false;
                this.polygonline.enabled = false;
                this.circlecreating = false;
                this.polygoncreating = false;
                this._czmObj.applyTerrain();
                this.rectangleline.enabled = true;
                this.rectanglecreating = true;

            },
            // 多边形坑
            clickpolygon() {
                this._czmObj.positions = []
                this.polygonline._polygon.positions = []
                this.rectangleline._polygon.positions = []
                this.circleline._polygon.positions = []
                this.cutsurface.enabled = false
                this.index = 3
                this.circleline.enabled = false;
                this.rectangleline.enabled = false;
                this.rectanglecreating = false;
                this.circlecreating = false;
                this._czmObj.applyTerrain();
                this.polygonline.enabled = true
                this.polygoncreating = true;

            },
            terrainDiscardClick() {
                console.log('terrainDiscardClick')
                this._czmObj.applyTerrain();
                this.$root._earth.tilesetCollection.forEach(item => {
                    if (!(item === this.$root._tileset.tileset)) {
                        item.xbsjFlattenGuid = this.guid
                    }

                })
            },
            cancel() {
                this.close()
                this.reset()
                this.$bus.emit("cancelCutface")
                this.$root.destroyTool(this);
            },
            close() {
                const modelToolObj = this._czmObj;
                if (!modelToolObj) {
                    return;
                }
                modelToolObj.positionEditing = false;
                if (modelToolObj.isCreating) {
                    modelToolObj.isCreating = false;
                    modelToolObj.destroy();
                }
            },
            save() {
                console.log("挖坑对象保存")
                const modelToolObj = this._czmObj;
                modelToolObj.editing = false;
                if (!modelToolObj) {
                    return;
                }
                modelToolObj.positionEditing = false;
                if (modelToolObj.isCreating) {
                    modelToolObj.isCreating = false;
                    const sceneObject = new XE.SceneTree.Leaf(modelToolObj);
                    this.$root._earthVue.addSceneObject(sceneObject.czmObject, this.name);
                }
            },
        },
        beforeUnmount() {
            this._czmObj.creating = false;
            this._czmObj.editing = false;
            this._czmObj.isCreating = false
            console.log("czmObj", this._czmObj)
            // 解绑数据关联
            this._polygonDisposers = this._polygonDisposers && this._polygonDisposers();
            this._disposers.forEach(e => e());
            this._disposers.length = 0;

        }
    };
</script>

<style scoped>
    .cutsurface {
        display: flex;
        height: 36px;
        justify-content: space-around;
        align-items: center;
        color: #CCC;
    }

    .cutsurface>span:hover {
        background: #288DFB;
        opacity: 0.5;
    }

    .cutsurface>span:active {
        background: #288DFB;
        opacity: 1;
    }

    .cutsurface>span {
        width: 70px;
        height: 20px;
        text-align: center;
        cursor: pointer
    }

    .select {
        background: #288DFB;

    }
</style>
<template>
    <Window :footervisible="true" @ok="ok" @cancel="cancel" :height="420" :width="500" :minWidth="202" :left="200"
        :top="138" :title="'在线地形服务'" class="xbsj-onlineTerrain">
        <div class="onlineTerrainDiv">
            <label>服务地址:</label>
            <input v-model="selectedUrl" />
        </div>
        <div v-if="selected != null">
            <div v-if="!selected.notSupportNormal">
                <div class="onlineTerrainDiv">
                    <label>法向量:</label>
                    <input class="inputcheck" type="checkbox" v-model="normal" />
                </div>
            </div>
            <div v-if="!selected.notSupportWater">
                <div class="onlineTerrainDiv">
                    <label>水平:</label>
                    <input class="inputcheck" type="checkbox" v-model="water" />
                </div>
            </div>
            <div v-show="selected.requireField" class="onlineTerrainDiv">
                <label>{{ selected.requireField }}:</label>
                <input v-model="requireValue" />
            </div>
        </div>
        <ul class="flexul" :class="showContent ? 'contentUl' : ''">
            <li v-for="s in services" @click="select(s)" :key="s.url">
                <div :class="[{ highlight: selected == s }]" class="backimg">
                    <img style="width: 64px; height: 64px" :src="s.thumbnail" alt />
                </div>
                <div class="onlinTerrainName">
                    {{ s.cnname }}
                    <span class="onlineTerrainToolTip">{{ s.cnname }}</span>
                </div>
            </li>
        </ul>
    </Window>
</template>

<script>
    /* eslint-disable */
    import Window from "../../../../components/Window";
    export default {
        components: {
            Window
        },
        data() {
            return {
                error: "",
                selected: null,
                selectedUrl: "",
                normal: true,
                water: true,
                showContent: false,
                services: [],
                terrainType: "XbsjCesiumTerrainProvider",
                type: "XbsjCesiumTerrainProvider"
            };
        },
        mounted() {
            this.getonlineinfo();
        },
        props: {
            getBind: Boolean,
            default () {
                false;
            }
        },
        watch: {

            terrainType(val) {
                this.type = val;
            }
        },
        methods: {
            getonlineinfo() {

                this.$axios
                    .get("/system/service/terrain")
                    .then(res => {
                        this.services = res.data.data.result;
                    })
                    .catch(err => {
                        console.log(err.response || err);
                    });
            },
            changelang() {
                this.services = [];
            },
            select(service) {
                this.selected = service;
                // console.log(service);
                //因为界面可能修改这个值，所以需要单独一个变量
                this.selectedUrl = this.selected.url;
                this.requireValue = "";
                this.error = "";
                this.showContent = false;
                if (service.supportNormal === true) {
                    this.showContent = true;
                }
            },
            ok() {
                console.log("地形");
                if (this.selectedUrl == "") {
                    //提示需要弹出url
                    this.error = this.lang.selectinput;
                    this.$root.$earthUI.promptInfo(this.error, "error");
                } else {
                    //构造地形
                    var terrain = new XE.Obj.Terrain(this.$root._earth);
                    //如何设置全球数据，如何附加法向量和水面属性
                    if (this.type === "XbsjCesiumTerrainProvider") {
                        terrain.xbsjTerrainProvider = {
                            type: this.type,
                            XbsjCesiumTerrainProvider: {
                                url: this.selectedUrl,
                                requestVertexNormals: this.normal,
                                requestWaterMask: this.water
                            }
                        };
                    } else {
                        terrain.xbsjTerrainProvider = {
                            type: this.type,
                            GoogleEarthEnterpriseTerrainProvider: {
                                url: this.selectedUrl
                            }
                        };
                    }

                    //添加到场景树中
                    this.$root._earthVue.addSceneObject(
                        terrain,
                        this.selected ? this.selected.cnname : "未命名地形"
                    );
                    this.$root.destroyTool(this);
                    this.error = "";
                }
            },
            cancel() {
                this.$root.destroyTool(this);
            }
        }
    };
</script>

<style scoped>
    /* .xbsj-onlineTerrain .google_image {
        background: url("../../../images/services/google_image.jpg") !important;
    }

    .xbsj-onlineTerrain .google_map {
        background: url("../../../images/services/google_map.png") !important;
    } */

    .xbsj-onlineTerrain .error {
        color: red;
    }

    .xbsj-onlineTerrain .flexul {
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 48px);
    }

    .xbsj-onlineTerrain .contentUl {
        overflow-x: hidden;
        height: calc(100% - 104px);
    }

    .xbsj-onlineTerrain .flexul li {
        list-style: none;
        /* float: left; */
        position: relative;
        height: 102px;
    }

    .xbsj-onlineTerrain .backimg {
        width: 64px;
        height: 64px;
        border: 2px solid;
        border-radius: 4px;
        background: gray;
        margin: 5px;
    }

    .xbsj-onlineTerrain .backimg.highlight,
    .xbsj-onlineTerrain .backimg:hover {
        border: 2px solid rgba(31, 255, 255, 1);
    }

    .xbsj-onlineTerrain .onlineTerrainDiv {
        margin: 10px 10px;
    }

    .xbsj-onlineTerrain .onlineTerrainDiv>label {
        display: inline-block;
        width: 60px;
        text-align: right;
        margin-right: 15px;
    }

    .xbsj-onlineTerrain .onlineTerrainDiv>input {
        width: calc(100% - 100px);
        height: 28px;
        background: #383838;
        border: 1px solid #4D4D4D;
        border-radius: 3px;
        border: none;
        color: #ccc;
        padding: 0 5px;
    }

    .xbsj-onlineTerrain .flexul li .onlinTerrainName {
        display: inline-block;
        width: 64px;
        text-align: center;
        margin-left: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .xbsj-onlineTerrain .flexul li .onlinTerrainName .onlineTerrainToolTip {
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        text-align: center;
        padding: 5px 5px;
        border-radius: 3px;
        /* 定位 */
        position: absolute;
        z-index: 1;
        left: 11px;
        bottom: 30px;
    }

    .xbsj-onlineTerrain .flexul li .onlinTerrainName:hover .onlineTerrainToolTip {
        visibility: visible;
    }

    .xbsj-onlineTerrain .onlineTerrainDiv .inputcheck {
        display: inline-block;
        width: 15px;
        height: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        background: url(../../assets/check.png) no-repeat top center;
        background-size: 100% 100%;
        cursor: pointer;
        vertical-align: middle;
        border-radius: 0%;
    }

    .xbsj-onlineTerrain .onlineTerrainDiv input:checked {
        position: relative;
        background: url(../../assets/check_on.png) no-repeat top center;
        background-size: 100% 100%;
    }
</style>

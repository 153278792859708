import * as zrender from 'zrender'
// Y轴
/* eslint-disable */
class AxisX {
  intervalCnt = 10 //刻度数量
  constructor() {}

  draw(centerx, offsetx, offset, width, height, scale, zr) {
    //间距：像素
    let intervalDis = width / this.intervalCnt

    // 间距：像素————>毫米（并向下取整）
    let TurnIntervalDis = Math.floor((width / this.intervalCnt / scale) * 1000) //转成毫米并为整数的间隔距离(mm)

    // 间距：(整数)毫米————>像素
    intervalDis = (TurnIntervalDis / 1000) * scale

    // 转毫米offsety
    let mmoffsetx = centerx * 1000
    let centerPointText = Math.ceil(mmoffsetx)
    let centerPointX = width - (centerPointText / 1000 + offsetx) * scale

    let downPointX = centerPointX
    let downPointText = centerPointText
    console.log(centerPointText, centerPointX)
    while (downPointX < width) {
      // 刻度线
      let line = new zrender.Line({
        style: {
          stroke: '#fff',
        },
        shape: {
          x1: downPointX + offset,
          y1: height - 3,
          y2: height - 8,
          x2: downPointX + offset,
        },
      })
      zr.add(line)
      // 刻度标签
      var text = new zrender.Text({
        style: {
          text: `${downPointText.toFixed(0)}`,
          fontSize: 12,
          textFill: '#000',
          fill: '#fff',
          textBackgroundColor: '#000',
        },
      })
      text.attr('position', [downPointX + offset + 8, height - 10])
      zr.add(text)
      downPointX += intervalDis
      downPointText += TurnIntervalDis
    }

    let upPointX = centerPointX + intervalDis
    let upPointText = centerPointText + TurnIntervalDis
    while (upPointX > 0) {
      // 刻度线
      let line = new zrender.Line({
        style: {
          stroke: '#fff',
        },
        shape: {
          x1: upPointX + offset,
          y1: height - 3,
          y2: height - 8,
          x2: upPointX + offset,
        },
      })
      zr.add(line)
      // 刻度标签
      var text = new zrender.Text({
        style: {
          text: `${upPointText.toFixed(0)}`,
          fontSize: 12,
          textFill: '#000',
          fill: '#fff',
          textBackgroundColor: '#000',
        },
      })
      text.attr('position', [upPointX + offset + 8, height - 10])
      zr.add(text)
      upPointX -= intervalDis
      upPointText -= TurnIntervalDis
    }
    // 横向X轴的直线
    let yaxios = new zrender.Line({
      style: {
        stroke: '#fff',
      },
      shape: {
        x1: 0,
        y1: height,
        y2: height,
        x2: width,
      },
    })
    zr.add(yaxios)
    // x轴单位
    var text = new zrender.Text({
      style: {
        text: '距离(mm)',
        fontSize: 12,
        textFill: '#000',
        fill: '#fff',
        textBackgroundColor: '#000',
      },
    })
    text.attr('position', [width - 45, height - 20])
    zr.add(text)

    // // 绘制刻度和标签,(0,0)原点是左上角
    // for (let i = 0; i < this.intervalCnt; i++) {
    //   if (i == 0) continue
    //   // 刻度线
    //   let line = new zrender.Line({
    //     style: {
    //       stroke: '#fff',
    //     },
    //     shape: {
    //       x1: x1 + intervalDis * (this.intervalCnt - i),
    //       y1: y1 + height,
    //       y2: y1 + height - 8,
    //       x2: x1 + intervalDis * (this.intervalCnt - i),
    //     },
    //   })
    //   zr.add(line)
    //   // 刻度标签
    //   var text = new zrender.Text({
    //     style: {
    //       text: `${((intervalDis * i - offset) / scale - offsetx).toFixed(3)}`,
    //       fontSize: 12,
    //       textFill: '#000',
    //       fill: '#fff',
    //       textBackgroundColor: '#000',
    //     },
    //   })
    //   text.attr('position', [x1 + intervalDis * i, y1 + height - 13])
    //   zr.add(text)
    // }
  }
}
export default AxisX

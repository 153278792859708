<template>
    <Window :title="title" :footervisible="true" @ok="ok" @cancel="cancel" v-show="show" :width="1016" :height="780">
        <CesiumContainer ref="gltfEditing" @glbLoaded="glbLoaded"></CesiumContainer>
    </Window>
</template>
<script>
    import Window from "../../../components/Window";
    import CesiumContainer from "./CesiumContainer";
    /* eslint-disable */
    export default {
        data() {
            return {
                title: "材质编辑器面板",
                show: true,
                isupdate: false, //区别更新编辑/添加
                oldname: "",
                newname: "",
                queryData: []
            };
        },
        props: {
            getBind: Object,
            default () {
                {}
            },
            param: {
                type: Object,
                default: undefined
            }
        },
        components: {
            CesiumContainer,
            Window
        },
        mounted() {
            var editor = this.$refs.gltfEditing;
            this.query()
            editor.glbUrl = "./models/pipe_texturetransform.glb";
            if (this.getBind) {
                // 编辑更新材质获取属性值
                this.oldname = this.getBind.name;
                this.isupdate = true;
                if (this.getBind.isCopy) {
                    this.isupdate = false;
                    this.title = "材质复制器面板"
                }

            } else {
                editor.name = "";
                editor.emissiveFactor = [0, 0, 0];
                editor.normalTextureUrl = "";
                editor.baseColorFactor = [1, 1, 1, 1];
                editor.baseColorTextureUrl = "";
                editor.metallicFactor = 0;
                editor.roughnessFactor = 0.5;
                this.isupdate = false;
            }
        },
        methods: {
            query() {
                let _this = this
                let data = {
                    projectid: this.$root.projectid
                }
                this.$axios.get("/project/material/query", {
                    params: data
                }).then(res => {
                    _this.queryData = res.data.data
                }).catch(err => {
                    err = err.response || err
                    this.$root.$log("error", err)
                })
            },
            glbLoaded() {

                var item = this.getBind;
                console.log("水水水水水水水水水水", item)
                var editor = this.$refs.gltfEditing;
                if (!item.param.pbrMetallicRoughness.baseColorTexture) {
                    editor.resetBaseColorTexture();
                }
                if (editor._currentModelMaterial.getValue("normalTexture")) {
                    if (item.param.normalTexture.id) {
                        editor.normalTextureUrl =
                            "/project/texture/" +
                            this.$root.projectid +
                            "/" +
                            item.param.normalTexture.id +
                            ".jpg";
                    }
                }
                if (item.param.pbrMetallicRoughness.baseColorTexture.id) {
                    editor.baseColorTextureUrl =
                        "/project/texture/" +
                        this.$root.projectid +
                        "/" +
                        item.param.pbrMetallicRoughness.baseColorTexture.id +
                        ".jpg";
                }
                editor.name = this.oldname;
                editor.emissiveFactor = item.param.emissiveFactor;

                editor.baseColorFactor = item.param.pbrMetallicRoughness.baseColorFactor;
                editor.id = item.param.pbrMetallicRoughness.baseColorTexture.id;
                editor.id2 = item.param.normalTexture.id;
                editor.metallicFactor = item.param.pbrMetallicRoughness.metallicFactor;
                editor.roughnessFactor = item.param.pbrMetallicRoughness.roughnessFactor;
                // 基础纹理
                editor.baseColorTextureOffset =
                    item.param.pbrMetallicRoughness.baseColorTexture.extensions.KHR_texture_transform.offset;
                editor.baseColorTextureRotation =
                    item.param.pbrMetallicRoughness.baseColorTexture.extensions.KHR_texture_transform.rotation;
                editor.baseColorTextureScale =
                    item.param.pbrMetallicRoughness.baseColorTexture.extensions.KHR_texture_transform.scale;

                //法向纹理
                editor.normalTextureOffset =
                    item.param.normalTexture.extensions.KHR_texture_transform.offset;
                editor.normalTextureRotation =
                    item.param.normalTexture.extensions.KHR_texture_transform.rotation;
                editor.normalTextureScale =
                    item.param.normalTexture.extensions.KHR_texture_transform.scale;
                editor.width = item.param.width;
                editor.height = item.param.height
            },
            cancel() {
                this.$root.destroyTool(this);
            },
            async ok() {
                this.newname = this.$refs.gltfEditing.name;
                //console.log(this.newname)
                let _this = this;
                //截图作为用户交互的材质图片
                await this.capture(this.$refs.gltfEditing._viewer.scene, 100, 100)
                    .then(res => {
                        _this.img = res;
                    })
                    .catch(err => {
                        console.log(err.response || err);
                    });
                // 更新材质
                if (this.isupdate) {
                    if (this.newname == "") {
                        this.$root.$log("warning", "材质名称未输入")
                        this.$root.$alert("名称不能为空");

                        return;
                    }
                    if (this.checkNameBesidesMy_this(this.oldname, this.newname)) {
                        this.$root.$alert("名字已经存在,请重新输入");
                        this.$root.$log("warning", "更新材质名称重复")
                        return;
                    }
                    let json = this.$refs.gltfEditing.toJson();
                    let data = {
                        projectid: _this.$root.projectid,
                        oldname: this.oldname,
                        param: JSON.stringify(json),
                        newname: this.newname,
                        thumbnail: _this.img
                    };
                    console.log(data)
                    this.$axios
                        .put("/project/material/update", data)
                        .then(res => {
                            if (_this.param && _this.param.ok) {
                                _this.param.ok();
                            }
                            this.$root.destroyTool(this);
                        })
                        .catch(err => {
                            err = err.response || err
                            console.log(err)
                            this.$root.$alert(err)
                            this.$root.$log("error", err)
                        });
                } else {
                    if (this.newname == "") {
                        this.$root.$alert("名称不能为空");
                        this.$root.$log("warning", "创建新材质名称未输入")
                        return;
                    } else if (this.checkName(this.newname)) {
                        this.$root.$alert("名字已经存在,请重新输入");
                        this.$root.$log("warning", "创建新材质名称重复")
                        return;
                    }
                    // 创建新材质
                    let json = this.$refs.gltfEditing.toJson();
                    let data = {
                        projectid: this.$root.projectid,
                        name: this.newname,
                        param: JSON.stringify(json),
                        thumbnail: _this.img
                    };
                    console.log("建筑材质更改", data)
                    this.$axios
                        .post("/project/material/add", data)
                        .then(res => {
                            if (_this.param && _this.param.ok) {
                                _this.param.ok();
                            }
                            this.$root.destroyTool(this);
                        })
                        .catch(err => {
                            err = err.response || err
                            this.$root.$alert(err.data.message);
                            this.$root.$log("error", err)
                        });
                }
            },
            // 判断新建材质是否名称重复
            checkName(name) {
                for (let i = 0; i < this.queryData.length; i++) {
                    if (name == this.queryData[i].name) {
                        return true;
                    }
                }
                return false;
            },
            // 判断更改名称是否和其他材质重复
            checkNameBesidesMy_this(oldname, newname) {
                let flag;
                for (let i = 0; i < this.queryData.length; i++) {
                    if (oldname == this.queryData[i].name) {
                        flag = i;
                        break;
                    }
                }
                for (let j = 0; j < this.queryData.length; j++) {
                    if (!(j == flag)) {
                        if (newname == this.queryData[j].name) {
                            return true;
                        }
                    }
                }
                return false;
            },
            // 截图base64格式
            capture(scene, width, height, format) {
                if (!width || typeof width != "number") width = 128;
                if (!height || typeof height != "number") height = 128;
                var captureScreenCanvas;
                return new Promise((resolve, reject) => {
                    var removeEventListenFunc = scene._postRender.addEventListener(() => {
                        let sceneCanvas = scene.canvas;
                        let sceneWidth = sceneCanvas.width;
                        let sceneHeight = sceneCanvas.height;

                        if (!Cesium.defined(captureScreenCanvas)) {
                            captureScreenCanvas = document.createElement("canvas");
                        }
                        captureScreenCanvas.setAttribute("width", width);
                        captureScreenCanvas.setAttribute("height", height);

                        let sx, sy, sw, sh;
                        //裁剪范围 判定按 w  或者 h 缩放
                        if (sceneWidth / width < sceneHeight / height) {
                            sx = 0;
                            sw = sceneWidth;
                            sh = (height * sceneWidth) / width;
                            sy = (sceneHeight - sh) * 0.5;
                        } else {
                            sy = 0;
                            sh = sceneHeight;
                            sw = (width * sceneHeight) / height;
                            sx = (sceneWidth - sw) * 0.5;
                        }

                        let ctx = captureScreenCanvas.getContext("2d");
                        ctx.drawImage(sceneCanvas, sx, sy, sw, sh, 0, 0, width, height);
                        let img64 = captureScreenCanvas.toDataURL(
                            format ? format : "image/jpeg"
                        );
                        resolve(img64);
                        removeEventListenFunc();
                    }, null);
                });
            }
        }
    };
</script>
<style>
</style>

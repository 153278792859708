import axios from 'axios'
import {
    Base64
} from 'js-base64'
import store from "../pages/page-editor/store"
const service = axios.create({
    // baseURL: '/pipeser/',
    timeout: 60000 // 请求超时时间
})
//添加一个请求拦截器
service.interceptors.request.use(function (config) {
    let token = window.localStorage.getItem('token');
    if (config.url.indexOf("http") == 0) {
        if (config.url.indexOf(window.location.origin) == 0) {
            const base64 = Base64.encode(token + ':');
            config.headers.Authorization = 'Basic ' + base64;
        }
    } else {
        const base64 = Base64.encode(token + ':');
        config.headers.Authorization = 'Basic ' + base64;
    }
    if(config.showlog != false){
        store.commit('showLoading')
    }
    return config;
}, function (error) {
    store.commit('hideLoading')
    console.info("error: ");
    console.info(error);
    return Promise.reject(error);
});

// 添加一个响应拦截器
service.interceptors.response.use(function (response) {
    store.commit('hideLoading')
    if (response.headers.token) {
        window.localStorage.setItem('token', response.headers.token);
    }
    return response;
}, function (error) {
    store.commit('hideLoading')
    console.dir(error);
    if(error.code === 'ECONNABORTED' &&　error.message.indexOf('timeout of ') === 0){
        return Promise.reject('timeout');
    }
    if (error.response.data && error.response.data.data) {
        if (error.response.data.data === "无效的token") {
            window.location.href = "/login.html";
            return;
        } else if (error.response.data.data.msg === "权限不足") {
            return error.response.data.data;
        }
    }
    return Promise.reject(error.response.data);
})

export default service;

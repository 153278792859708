<template>
    <Window :title="'视频教程'" :footervisible="false" @cancel="cancel" v-show="show" :width="800" :height="700">
        <iframe :src="src" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
    </Window>
</template>

<script>
    import Window from "../../../components/Window"
    export default {
        components: {
            Window
        },
        data() {
            return {
                show: true,
                src: ""
            }
        },
        props: {
            getBind: String,
            default () {
                ""
            },
        },
        mounted() {

            if (this.getBind) {
                this.src = this.getBind
            }
        },
        methods: {
            cancel() {
                this.$root.destroyTool(this)
            }
        }
    }
</script>

<style scoped>
    iframe {
        width: 100%;
        height: 100%;
    }
</style>
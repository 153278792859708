<template>
    <div ref="model" id="model" class="model" :style="{width:width+'px',height:height+'px'}">
        <div class="title" ref="model">
            <span>
                {{title}}
            </span>
        </div>
        <div class="contentslot" :style="{width:(width-40)+'px',height:height+'px'}">
            <slot></slot>
        </div>
        <div class="close" @click.stop="cancel">
            <img src="../assets/close.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            width: {
                type:Number,
                 default () {
                return 100
            }},
            height:{
                type: Number,
                default () {
                return 36
            }},
            title:{
                type: String,
                default () {
                return "标题"
            }
            }
        },
        mounted() {
            var dragging = false
            var position = null
            let model = this.$refs.model
            model.addEventListener('mousedown', function (e) {
                dragging = true
                position = [e.clientX, e.clientY]
            })
            document.addEventListener('mousemove', function (e) {
                if (dragging === false) {
                    return
                }
                const x = e.clientX
                const y = e.clientY
                const deltaX = x - position[0]
                const deltaY = y - position[1]
                const left = parseInt(model.style.left || 370)
                const top = parseInt(model.style.top || 150)
                model.style.left = left + deltaX + 'px'
                model.style.top = top + deltaY + 'px'
                position = [x, y]
            })
            document.addEventListener('mouseup', function () {
                dragging = false
            })
        },
        methods: {
            cancel() {
                this.$emit("cancel");
            },
        }

    }
</script>

<style scoped>
    .title {
        width: 20px;
        color: #288DFB;
        font-size: 14px;
        background: #383838;
        font-size: 14px;
        text-align: center;
    }

    .title:hover {
        cursor: move;
    }

    .title>span {
        width: 14px;
        height: 33px;
        font-size: 14px;
        font-family: Segoe UI;
        font-weight: 400;
        line-height: 14px;
        color: #288DFB;
        opacity: 1;
    }

    .contentslot {
        width: 200px;
        background: #242424;
        cursor: move;
    }

    .contentslot:focus {
        cursor: move;
    }

    .close {
        width: 20px;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        background: #383838;
    }

    .model {
        position: absolute;
        top: 150px;
        left: 370px;
        display: flex;
        background: #fff;
        z-index: 1000;
    }
</style>>
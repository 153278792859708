<template>
  <div class="property">
    <input ref="files" type="file" title="选择图片" accept="image/*" @change="upLoadFile" multiple="multiple"
      style="display: none" />
    <div class="propertyHeard">
      <h4>管线属性</h4>
      <ul>
        <li>起点id： {{ lineData.qidian }}</li>
        <li>终点id： {{ lineData.zhongdian }}</li>
        <li>材质： {{ lineData.caizhi }}</li>
        <li>断面： {{ lineData.duanmian }}</li>
        <li>管径： {{ lineData.guanjing }}米</li>
        <li>管壁厚： {{ lineData.guanbihou }}米</li>
        <li>图层： {{ lineData.tuceng }}</li>
        <li>创建时间：{{ lineData.create_t }}</li>
        <li>修改时间：{{ lineData.update_t }}</li>
        <li v-for="(item, index) in custom" :key="index">{{ item[0] }}:{{ item[1] }}</li>
        <li>资源：
          <div v-for="img in lineData.resource">
            <img :src="'resource/' + projectid + '/' + img" />
            <button @click="deleteResource(img)">删除</button>
          </div>
          <button @click="selectFile">添加</button>
        </li>
      </ul>
    </div>
    <div class="propertyfoo">
      <h4>属性操作</h4>
      <div class="propertybtn">
        <button @click="updateTucengPop">修改图层</button>
        <button @click="updateCaizhiPop">修改材质</button>
        <button @click="updateCangshuPop">修改参数</button>
        <button @click="deleteLine">删除</button>
        <button @click="locate">定位</button>
      </div>
    </div>
  </div>
</template>
<script>
import parameterTool from '../../page-editor/components/ParameterTool'
import MaterialSelect from '../../page-editor/components/MaterialSelect'
import LayerTool from '../../page-editor/components/LayerTool'
export default {
  name: 'LineProperty',
  data() {
    return {
      lineData: [],
      ids: '',
      items: '',
      custom: [],
      maptable: []
    }
  },
  props: {
    param: Object
  },
  created() {
    this.foundText(this.param.id, '_')
    this.query()
  },
  beforeUnmount() {
    //console.log("LineProperty beforeUnmount");
    this.$root._tileset.unSelectFeature(this.param)
  },
  mounted() {
    this.projectid = this.$root.projectid
  },
  methods: {
    deleteResource(id) {
      this.$root.$confirm("确定删除？", () => {
        var deleteIndex = this.lineData.resource.findIndex(item => {
          return item === id;
        })
        if (deleteIndex < 0) {
          return;
        }
        var resource = this.lineData.resource;
        resource.splice(deleteIndex, 1)
        let data = {
          projectid: this.$root.projectid,
          id: id,
          param: {
            resource: JSON.stringify(resource)
          },
        };
        let _this = this;
        this.$axios
          .put("/project/point/update/info", data)
          .then((res) => {
            this.$root.$alert("删除成功");
            _this.lineData.resource = resource;
            _this.$axios
              .delete(`/resource/${_this.projectid}/${id}`)
              .then(res => {
              })
              .catch((err) => {
              });
          })
      })
    },
    upLoadFile(event) {
      let _this = this;
      let formData = new FormData();
      let files = event.target.files;
      for (let i = 0; i <= files.length - 1; i++) {
        formData.append("files", files[i]);
      }
      formData.append("projectid", this.$root.projectid);
      this.$axios
        .post("/resource/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          timeout: 0,
        })
        .then(res => {
          if (res.status == 200) {
            var resource = _this.lineData.resource || [];
            for (var i = 0; i < res.data.data.length; i++) {
              resource.push(res.data.data[i].id);
            }
            let data = {
              projectid: _this.$root.projectid,
              qidian: _this.lineData.qidian,
              zhongdian: _this.lineData.zhongdian,
              param: {
                resource: JSON.stringify(resource)
              },
            };
            _this.$axios
              .put("/project/line/update/info", data)
              .then((res) => {
                _this.query();
                this.$root.$alert("添加成功");
              })
              .catch((err) => {
                this.$root.$log("error", err);
                console.log(err.response || err);
                this.$root.$alert("添加失败");
              });
          }
        })
        .catch(err => {
          if (err.data) this.$root.$alert(err.data.msg || err.data.message);
        });
      event.target.value = "";
    },
    selectFile() {
      this.$refs.files.click();
    },
    mapquery() {
      this.custom = []
      let self = this
      let data = {
        projectid: this.$root.projectid
      }
      this.$axios
        .get('/project/line/column/query', {
          params: data
        })
        .then(res => {
          let arr = Object.keys(self.lineData)
          self.maptable = res.data.data
          for (let j = 0; j < arr.length; j++) {
            for (let i = 0; i < self.maptable.length; i++) {
              if (arr[j] === self.maptable[i]['column_name'] && self.maptable[i]['column_editable']) {
                let tempObject = [self.maptable[i].column_chinese || self.maptable[i].column_name, self.lineData[arr[j]]]
                var found = self.custom.find(c => {
                  return c[0] == tempObject[0]
                })
                if (!found)
                  self.custom.push(tempObject)
              }
            }
          }
        })
        .catch(err => {
          console.log(err.response || err)
        })
    },
    updateTucengPop() {
      //更新图层弹窗
      this.$root.showPropertyWindow('LayerTool', {
        component: LayerTool,
        param: {
          ok: option => {
            //console.log("( •̀ ω •́ )y拿到了图层数据", option);
            if (option) {
              this.updateTuceng(option)
            }
          }
        }
      })
    },
    updateTuceng(opt) {
      //修改图层
      let data = {
        projectid: this.$root.projectid,
        tuceng: opt.id,
        ilayer: isNaN(opt.id) ? opt.id : parseFloat(opt.id),
        ids: this.ids
      }
      this.$axios
        .put('/project/line/layer/update/ids', data)
        .then(res => {
          this.query()
          console.log(res)
          this.$root.$alert('图层修改成功')
          if (res.data.data && res.data.data === true) {
            this.$root._tileset.reload()
          }
        })
        .catch(err => {
          this.$root.$log('error', err)
          console.log(err.response || err)
          this.$root.$alert('图层修改失败')
        })
    },
    locate() {
      this.$root._tileset.selectFeature({ id: this.lineData.qidian + '_' + this.lineData.zhongdian, otype: 'pipe' })
    },
    async query() {
      //console.log("单个管线查询");
      let params = {
        qidian: this.items.qidian,
        zhongdian: this.items.zhongdian
      }
      this.$axios
        .get('/project/line/query', {
          params: {
            projectid: this.$root.projectid,
            param: JSON.stringify(params)
          }
        })
        .then(res => {
          //console.log("单个管线查询", res);
          if (res.data.data.results.length == 1) this.lineData = res.data.data.results[0]
          this.mapquery()
          this.$bus.emit('pickLine', this.lineData)
        })
        .catch(err => {
          console.log(err.response || err)
          this.$root.$log('error', err)
        })
    },
    deleteLine() {
      //删除管
      let _this = this
      let data = {
        projectid: _this.$root.projectid,
        ids: _this.ids
      }
      _this.$root.$confirm('确认删除该管线?', () => {
        _this.$axios
          .delete('/project/line/delete/ids', {
            data
          })
          .then(res => {
            this.query()
            console.log(res)
            this.lineData = ''
            this.$root.$alert('管线删除成功')
            if (res.data.data && res.data.data === true) {
              this.$root._tileset.reload()
            }
            //alert("删除成功");
          })
          .catch(err => {
            this.$root.$log('error', err)
            console.log(err.response || err)
            this.$root.$alert('管线删除失败')
          })
      })
    },
    foundText(str, key) {
      //从某个字符截取分二(不包括该字符)
      var index = str.indexOf(key)
      //console.log(index)
      let ids = [[str.substr(0, index), str.substr(index + 1, str.length - index - 1)]]
      let items = {
        qidian: str.substr(0, index),
        zhongdian: str.substr(index + 1, str.length - index - 1)
      }
      this.items = items
      this.ids = ids
    },
    updateCaizhiPop() {
      //更新材质弹窗
      this.$root.showPropertyWindow('MaterialSelect', {
        component: MaterialSelect,
        param: {
          ok: caizhi => {
            //console.log('( •̀ ω •́ )y拿到了材质', caizhi);
            this.updateCaizhi(caizhi)
          }
        }
      })
    },
    updateCangshuPop() {
      //更新参数弹窗
      var param_j = this.lineData.param_j
      if (param_j === undefined) {
        param_j = {
          type: 'circle',
          profile: {
            radius: 0.05, //不能为0
            thickness: 0.01
          },
          flange: {
            length: 0.01,
            thickness: 0.01
          }
        }
      }

      this.$root.showPropertyWindow(
        'parameterTool',
        {
          component: parameterTool,
          param: {
            ok: option => {
              //console.log('( •̀ ω •́ )y拿到了参数', option);
              this.updateCangshu(option)
            }
          }
        },
        param_j
      )
    },
    updateCaizhi(opt) {
      //修改管线材质请求
      let data = {
        projectid: this.$root.projectid,
        caizhi: opt.name,
        ids: this.ids,
        caizhi_j: JSON.stringify(opt.param)
      }
      this.$axios
        .put('/project/line/material/update/ids', data)
        .then(res => {
          this.query()
          console.log(res)

          this.$root.$alert('材质修改成功')
          if (res.data.data && res.data.data === true) {
            this.$root._tileset.reload()
          }
        })
        .catch(err => {
          this.$root.$log('error', err)
          console.log(err.response || err)
          this.$root.$alert('材质修改失败')
        })
    },
    updateCangshu(opt) {
      //修改参数
      let data = {
        ids: this.ids,
        projectid: this.$root.projectid,
        param_j: JSON.stringify(opt)
      }
      // console.log(data1)
      this.$axios
        .put('/project/line/shape/update/ids', data)
        .then(res => {
          this.query()
          console.log(res)

          this.$root.$alert('参数修改成功')
          if (res.data.data && res.data.data === true) {
            this.$root._tileset.reload()
          }
        })
        .catch(err => {
          this.$root.$log('error', err)
          console.log(err.response || err)
          this.$root.$alert('参数修改失败')
        })
    }
  }
}
</script>

<style scoped>
.property {
  color: #cccccc;
  width: var(--RightWidth);
  height: 100%;
  background: var(--PanelBGColor);
}

.propertyHeard {
  border: 1px solid #383838;
  margin-bottom: 10px;
}

.propertyHeard>ul {
  height: 300px;
  margin-left: 30px;
  text-align: left;
  overflow: auto;
}

.propertyHeard li {
  height: 20px;
}

.propertyfoo {
  border: 1px solid #383838;
  margin-top: 10px;
}

.propertybtn {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.propertybtn button {
  height: 30px;
  width: 120px;
  margin: 10px 0px;
}
</style>

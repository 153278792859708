import {
    createApp
} from 'vue'
import App from './App.vue'
import axios from '../../api/http'
import mitt from 'mitt'
import "./css/common.css"
import store from "./store"

require('vue-ionicons/ionicons.css')
 




var app = createApp(App)
app.use(store) 
app.config.globalProperties.$env = process.env.VUE_APP_BUILD_ENV
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$bus = new mitt();
app.config.globalProperties.$app = app;

function format(el, fixed) {
    var v = parseFloat(el.value);
    if (el.value === "") {
        el.value = 0.00;
        el.dispatchEvent(new Event('input'));
    } else if (!isNaN(v)) {
        el.value = v.toFixed(fixed);
    } else {
        v = parseFloat(el.innerText);
        if (!isNaN(v)) {
            el.innerText = v.toFixed(fixed);
        }
    }
}

app.directive('numberFixed', {
        mounted(el, binding) {
            //如果值改变 或者焦点丢失，进行format
            el.addEventListener("change", () => {
                format(el, binding.value)
            });
            el.addEventListener("blur", () => {
                format(el, binding.value)
            });
        },
        updated(el, binding) {
            //如果不是当前输入焦点，才会更新
            if (el !== document.activeElement) {
                format(el, binding.value);
            }
        }
    })
    // axios.get("/project/info/getallparam", {
    //     params: {
    //         projectid: "moshi31_1618020975410"
    //     }
    // }).then(() => {
    //     XE.ready().then(() => {
    //         app.mount('#app')
    //     });
    // })
/* eslint-disable */
axios.get("/database/datatypes").then(() => {
    XE.ready().then(() => {
        // ../../XbsjEarth-Plugins/plottingSymbol/plottingSymbol.js
        XE.HTML.loadJS('../../XbsjEarth-Plugins/plottingSymbol/plottingSymbol.js')
        XE.HTML.loadJS('../../XbsjEarth-Plugins/customPrimitive/customPrimitive.js')
        return XE.HTML.loadJS('../../XbsjEarth-Plugins/plottingSymbol2/plottingSymbol2.js')
    }).then(() => {
        app.mount('#app')
    })
}).catch(
    () => {
        window.location.replace("/login.html");
        window.sessionStorage.setItem('herf', window.location.href);
    }
)
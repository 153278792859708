<template>
    <Window :title="'材质选择器面板'" :footervisible="true" @ok="ok" @cancel="cancel" v-show="show" :width="600" :height="600">
        <div class="masterialSelect_top">
            <input v-model="searchContent" type="text" @keyup.enter="searchQuery" />
            <button class="xbsj-button-tab" @click="searchQuery">搜索</button>
            <button @click="materialEditor(null)">添加</button>
        </div>
        <div class="masterialSelect_content">
            <div v-for="(item, index) in imageData" :key="index">
                <div class="thumbnail">
                    <img :src="item.thumbnail" />
                    <span>{{item.name}}</span>
                </div>

                <div class="operation">
                    <img v-show="param && !(indexshow===index)" src="../assets/selectup.png" @click="selectRadio(index)"
                        title="选中">
                    <img v-show="param && indexshow===index" src="../assets/selectdown.png" title="选中">
                    <img src="../assets/editor.png" @click="materialEditor(item)" title="编辑">
                    <img src="../assets/delete.png" @click="deletematerial(item.name)" title="删除">
                    <img src="../assets/copy.png" @click="copymaterial(item)" title=“复制>
                </div>
            </div>
        </div>
    </Window>

</template>
<script>
    import Window from "../../../components/Window";
    import MaterialEditor from "./MaterialEditor";
    import utils from "../math/utils"

    /* eslint-disable */
    export default {
        props: {
            param: {
                type: Object,
                default () {
                    return undefined
                }
            }
        },
        data() {
            return {
                show: true,
                searchContent: "",
                imageData: [],
                sendData: "",
                indexshow: "",


            };
        },
        components: {
            Window,

        },
        mounted() {
            this.query();
        },
        methods: {
            // 获取当前项目下的所有材质信息
            query() {
                let _this = this;
                this.$axios
                    .get("/project/material/query", {
                        params: {
                            projectid: this.$root.projectid
                        }
                    })
                    .then(res => {
                        this.imageData = res.data.data;
                    })
                    .catch(err => {
                        err = err.response || err
                        this.$root.$log("error", err)
                    });
            },
            //根据名称搜索查询材质信息
            searchQuery() {
                let data = {
                    projectid: this.$root.projectid,
                    name: this.searchContent
                };
                this.$axios
                    .get("/project/material/query", {
                        params: data
                    })
                    .then(res => {
                        this.imageData = res.data.data;
                    })
                    .catch(err => {
                        err = err.response || err
                        this.$root.$log("error", err)
                    });
            },
            // 编辑/添加
            materialEditor(option) {
                console.log("打开材质编辑器", option);
                this.$root.showPropertyWindow(
                    "MaterialEditor", {
                        component: MaterialEditor,
                        param: {
                            ok: () => {
                                this.query();
                            }
                        }

                    },
                    option
                );
            },
            //删除材质
            deletematerial(name) {
                let _this = this;
                this.$root.$confirm("是否确认删除这个材质?", () => {
                    let data = {
                        projectid: _this.$root.projectid,
                        name: name
                    };
                    this.$axios
                        .delete("/project/material/delete", {
                            data
                        })
                        .then((res) => {
                            _this.query();
                        })
                        .catch(err => {
                            err = err.response || err
                            this.$root.$log("error", err)
                        });
                });
            },
            selectRadio(index) {
                this.indexshow = index
                this.sendData = this.imageData[index];
                console.log("send选中数据", this.sendData);
            },
            // 复制材质
            copymaterial(option) {
                option = utils.clone(option, true)
                option.isCopy = true
                option.name = option.name + "copy_" + this.getRandomLetters()
                console.log("复制信息", option)
                this.$root.showPropertyWindow(
                    "MaterialEditor", {
                        component: MaterialEditor,
                        param: {
                            ok: () => {
                                this.query();
                            }
                        }

                    },
                    option
                );
            },
            // 生成随机字符串
            getRandomLetters() {
                let letters = new Array()
                let randomStr = ''
                for (let i = 0; i < 15; i++) {
                    letters.push(String.fromCharCode((65 + i)).toUpperCase())
                }
                randomStr += letters.map(item => {
                    if (item) {
                        return letters[Math.ceil(Math.random() * 25)]
                    }
                })
                return randomStr.split(',').join('')
            },
            cancel() {
                this.$root.destroyTool(this);
            },
            ok() {
                if (this.param) {
                    if (typeof (this.indexshow) === 'number') {
                        this.$root.destroyTool(this);
                        if (this.param && this.param.ok) {
                            this.param.ok(this.sendData);
                        }
                    } else {
                        this.$root.$alert("请选中一种材质");
                    }
                } else {
                    this.$root.destroyTool(this);
                }

            }
        }
    };
</script>
<style scoped>
    .masterialSelect_top {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }

    .masterialSelect_top>input {
        box-sizing: border-box;
        width: 430px;
        height: 30px;
        background: #383838;
        border: 1px solid #4D4D4D;
        opacity: 1;
        border-radius: 2px;
        color: #CCC;
        margin-right: 4px;

    }

    .masterialSelect_top>button {
        margin-right: 4px;
    }

    .masterialSelect_content {
        box-sizing: border-box;
        width: 100%;
        height: calc(100% - 65px);
        overflow: auto;
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start
    }

    .masterialSelect_content>div {
        width: 100px;
        height: 132px;
        margin-left: 12px;

    }

    .thumbnail {
        position: relative;
        width: 100px;
        height: 100px;
        border: 2px solid #1A1A1A;
        opacity: 1;
        margin-bottom: 8px;
    }

    .thumbnail>span {
        width: 100%;
        height: 20px;
        line-height: 20px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        white-space: nowrap;
        background: #000;
        opacity: 0.5;
        text-align: center;
        color: #ccc;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
    }

    .operation {
        display: flex;
        justify-content: space-around;
    }

    .operation>img {
        cursor: pointer;
    }

    .masterialSelect_content>div:last-child:hover {
        cursor: pointer;
    }
</style>
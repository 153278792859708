<template>
    <div class="property">
        <div class="xbsj-mainbar-popup popup" ref="globeTranslucency">
            <div class="row">
                地表透明 <input class="bty-switch" type="checkbox" v-model="enabled" />
            </div>
            <div class="row">
                <label class="label" style="text-align:start">正面</label>
            </div>
            <div class="row">
                <input type="checkbox" v-model="frontFixAlphaShow">
                <label class="label">固定透明度:</label>
                <div class="field">
                    <input type="range" min="0" max="1" step="0.1" :disabled="!frontFixAlphaShow"
                        v-model="frontFaceAlpha" ref="widthXbsjSlider">
                </div>
            </div>
            <div class="row">
                <input type="checkbox" v-model="frontGradientAlphaShow">
                <label class="label">渐变透明度:</label>
                <div class="field">
                    <input type="range" min="0" max="1" step="0.1" :disabled="!frontGradientAlphaShow"
                        v-model="frontGradientAlpha" ref="widthXbsjSlider">
                </div>
            </div>
            <div class="row">
                <label class="label" style="margin-left:12px;">渐变距离:</label>
                <div class="field">
                    <input type="range" min="0" max="1000000" step="1" v-model="frontGradientDistance"
                        ref="widthXbsjSlider">
                </div>
            </div>
            <div class="row">
                <label class="label" style="text-align:start">背面</label>
            </div>
            <div class="row">
                <input type="checkbox" v-model="backFixAlphaShow">
                <label class="label">固定透明度:</label>
                <div class="field">
                    <input type="range" min="0" max="1" step="0.1" :disabled="!backFixAlphaShow" v-model="backFaceAlpha"
                        ref="widthXbsjSlider">
                </div>
            </div>
            <div class="row">
                <input type="checkbox" v-model="backGradientAlphaShow">
                <label class="label">渐变透明度:</label>
                <div class="field">
                    <input type="range" min="0" max="1" step="0.1" :disabled="!backGradientAlphaShow"
                        v-model="backGradientAlpha" ref="widthXbsjSlider">
                </div>
            </div>
            <div class="row">
                <label class="label" style="margin-left:12px;">渐变距离:</label>
                <div class="field">
                    <input type="range" min="0" max="1000000" step="1" v-model="backGradientDistance"
                        ref="widthXbsjSlider">
                </div>
            </div>
            <div class="row">
                <input type="checkbox" style="margin-top:5px;" v-model="rectangleShow">区域限制
            </div>
            <div class="row">
                <label class="labelitem">西:</label>
                <input class="inputitem" type="text" :disabled="!rectangleShow" v-model.number="rectangleArr[0]" />
                <span class="spanitem" v-show="rectangleShow">°</span>
                <label class="labelitem">南:</label>
                <input class="inputitem" type="text" :disabled="!rectangleShow" v-model.number="rectangleArr[1]" />
                <span class="spanitem" v-show="rectangleShow">°</span>
            </div>
            <div class="row">
                <label class="labelitem">东:</label>
                <input class="inputitem" type="text" :disabled="!rectangleShow" v-model.number="rectangleArr[2]" />
                <span class="spanitem" v-show="rectangleShow">°</span>
                <label class="labelitem">北:</label>
                <input class="inputitem" type="text" :disabled="!rectangleShow" v-model.number="rectangleArr[3]" />
                <span class="spanitem" v-show="rectangleShow">°</span>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    export default {
        components: {

        },
        data() {
            return {
                frontFaceAlpha: 1,
                backFaceAlpha: 1,
                frontGradientAlpha: [0, 0],
                frontGradientDistance: [0, 1],
                frontFixAlphaShow: true,
                frontGradientAlphaShow: false,
                frontGradientDistanceShow: false,
                backGradientAlpha: [0, 0],
                backGradientDistance: [0, 1],
                backFixAlphaShow: true,
                backGradientAlphaShow: false,
                backGradientDistanceShow: false,
                rectangleShow: false,
                rectangle: [],
                rectangleArr: [],
                enabled: true
            };
        },
        mounted() {
            this.bind("frontFaceAlpha");
            this.bind("backFaceAlpha");
            this.bind("enabled")
        },
        methods: {
            bind(prp) {
                this._viewUnbinds = this._viewUnbinds || [];
                this._viewUnbinds.push(
                    XE.MVVM.bind(
                        this,
                        prp,
                        this.$root._earth.terrainEffect.globeTranslucency,
                        prp
                    )
                );
            },
            ok() {
                this.$root.destroyTool(this);
            },
            cancel() {
                this.$root.destroyTool(this);
            }
        },
        watch: {

            frontFixAlphaShow(val) {
                if (val) {
                    this.frontGradientAlphaShow = false;
                } else {
                    this.frontGradientAlphaShow = true;
                }
            },
            frontGradientAlphaShow(val) {
                if (!val) {
                    this.$root._earth.terrainEffect.globeTranslucency.frontFaceAlphaByDistance = undefined;
                    this.frontGradientAlpha = [0, 0];
                    this.frontFixAlphaShow = true;
                } else {
                    this.frontFixAlphaShow = false;
                }
            },
            frontGradientAlpha: {
                handler(newval, oldval) {
                    if (this.frontGradientAlphaShow) {
                        let frontFaceAlphaByDistanceArr1 = new Array(4);
                        if (
                            this.$root._earth.terrainEffect.globeTranslucency
                            .frontFaceAlphaByDistance !== undefined
                        ) {
                            frontFaceAlphaByDistanceArr1 = [
                                ...this.$root._earth.terrainEffect.globeTranslucency
                                .frontFaceAlphaByDistance
                            ];
                        }
                        frontFaceAlphaByDistanceArr1[1] = newval[1];
                        frontFaceAlphaByDistanceArr1[3] = newval[0];
                        this.$root._earth.terrainEffect.globeTranslucency.frontFaceAlphaByDistance = [
                            ...frontFaceAlphaByDistanceArr1
                        ];
                    }
                },
                deep: true
            },
            frontGradientDistanceShow(val) {
                if (!val) {
                    this.$root._earth.terrainEffect.globeTranslucency.frontFaceAlphaByDistance = undefined;
                    this.frontGradientDistance = [0, 1];
                }
            },
            frontGradientDistance: {
                handler(newval, oldval) {
                    if (this.frontGradientDistanceShow) {
                        let frontFaceAlphaByDistance2 = new Array(4);
                        if (
                            this.$root._earth.terrainEffect.globeTranslucency
                            .frontFaceAlphaByDistance !== undefined
                        ) {
                            frontFaceAlphaByDistance2 = [
                                ...this.$root._earth.terrainEffect.globeTranslucency
                                .frontFaceAlphaByDistance
                            ];
                        }
                        frontFaceAlphaByDistance2[0] = newval[0];
                        frontFaceAlphaByDistance2[2] = newval[1];
                        this.$root._earth.terrainEffect.globeTranslucency.frontFaceAlphaByDistance = [
                            ...frontFaceAlphaByDistance2
                        ];
                    }
                },
                deep: true
            },
            backFixAlphaShow(val) {
                if (val) {
                    this.backGradientAlphaShow = false;
                } else {
                    this.backGradientAlphaShow = true;
                }
            },
            backGradientAlphaShow(val) {
                if (!val) {
                    this.$root._earth.terrainEffect.globeTranslucency.backFaceAlphaByDistance = undefined;
                    this.backGradientAlpha = [0, 0];
                    this.backFixAlphaShow = true;
                } else {
                    this.backFixAlphaShow = false;
                }
            },
            backGradientAlpha: {
                handler(newval, oldval) {
                    if (this.backGradientAlphaShow) {
                        let backFaceAlphaByDistanceArr1 = new Array(4);
                        if (
                            this.$root._earth.terrainEffect.globeTranslucency
                            .backFaceAlphaByDistance !== undefined
                        ) {
                            backFaceAlphaByDistanceArr1 = [
                                ...this.$root._earth.terrainEffect.globeTranslucency
                                .backFaceAlphaByDistance
                            ];
                        }
                        backFaceAlphaByDistanceArr1[1] = newval[0];
                        backFaceAlphaByDistanceArr1[3] = newval[1];
                        this.$root._earth.terrainEffect.globeTranslucency.backFaceAlphaByDistance = [
                            ...backFaceAlphaByDistanceArr1
                        ];
                    }
                },
                deep: true
            },
            backGradientDistanceShow(val) {
                if (!val) {
                    this.$root._earth.terrainEffect.globeTranslucency.backFaceAlphaByDistance = undefined;
                    this.backGradientDistance = [0, 1];
                }
            },
            backGradientDistance: {
                handler(newval, oldval) {
                    if (this.backGradientDistanceShow) {
                        let backFaceAlphaByDistanceArr2 = new Array(4);
                        if (
                            this.$root._earth.terrainEffect.globeTranslucency
                            .backFaceAlphaByDistance !== undefined
                        ) {
                            backFaceAlphaByDistanceArr2 = [
                                ...this.$root._earth.terrainEffect.globeTranslucency
                                .backFaceAlphaByDistance
                            ];
                        }
                        backFaceAlphaByDistanceArr2[0] = newval[0];
                        backFaceAlphaByDistanceArr2[2] = newval[1];
                        this.$root._earth.terrainEffect.globeTranslucency.backFaceAlphaByDistance = [
                            ...backFaceAlphaByDistanceArr2
                        ];
                    }
                },
                deep: true
            },
            rectangleShow(val) {
                if (val) {
                    if (this.rectangleArr.length === 0) {
                        this.rectangleArr = [-180, -90, 180, 90];
                    }
                    this.$root._earth.terrainEffect.globeTranslucency.rectangle = [
                        (this.rectangleArr[0] * Math.PI) / 180,
                        (this.rectangleArr[1] * Math.PI) / 180,
                        (this.rectangleArr[2] * Math.PI) / 180,
                        (this.rectangleArr[3] * Math.PI) / 180
                    ];
                } else {
                    this.rectangleArr = [];
                    this.$root._earth.terrainEffect.globeTranslucency.rectangle = undefined;
                }
            },
            rectangleArr: {
                handler(n, o) {
                    if (this.rectangleShow) {
                        this.$root._earth.terrainEffect.globeTranslucency.rectangle = [
                            (n[0] * Math.PI) / 180,
                            (n[1] * Math.PI) / 180,
                            (n[2] * Math.PI) / 180,
                            (n[3] * Math.PI) / 180
                        ];
                    }
                },
                deep: true
            }
        },
        beforeDestroy() {
            this._viewUnbinds.forEach(u => u());
            this._viewUnbinds.length = 0;
        }
    };
</script>


<style scoped>
    .property {
        width: 300px;
        height: 100%;
        background: var(--PanelBGColor);
    }

    .notallowe {
        cursor: not-allowed;
        background-color: #777777 !important;
        pointer-events: none;
    }

    .coloron {
        color: #1fffff !important;
    }

    .popup {
        width: 288px;
        height: 340px;
        margin: auto;
    }

    .popup button {
        height: 28px;
        border: 1px solid #666666;
        background-color: rgba(0, 0, 0, 0.5);
        color: #dddddd;
        margin-bottom: 10px;
        width: 70px;
        border-radius: 5px;
        cursor: pointer;
    }

    .label {
        display: inline-block;
        width: 90px;
        text-align: right;
    }

    .ramp {
        display: inline-block;
    }

    .row {
        display: flex;
        margin-top: 10px;
    }

    .field {
        width: 170px;
        margin-top: 7px;
        margin-left: 10px;
    }

    input:disabled {
        cursor: not-allowed;
        background: #4D4D4D;
    }

    .labelitem {
        line-height: 28px;
        margin-right: 6px;
    }

    .inputitem {
        width: 74px;
        margin-right: 18px;
        background: #383838;
        border: 1px solid #4D4D4D;
        opacity: 1;
        border-radius: 2px;
        color: #CCC;
        margin-right: 10px;
    }

    .spanitem {
        margin-left: -16px;
        margin-right: 26px;
    }
</style>
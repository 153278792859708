<template>
    <div class="selectWrap">
        <div class="select" @click="triggerOption">
            <div class="select-content">
                <input :class="{ text: show }" v-if="editable" type="text" v-model="editText" />
                <span v-else> {{ editText }}</span>
            </div>
            <div class="triangle-wrapper">
                <img width="13" height="13" src="../assets/drop.png" alt="" />
            </div>
        </div>
        <!-- 后端不支持管理员赋予用户权限，暂时默认普通用户，后端支持后解注释下面注释即可 -->
        <!-- <div class="option-wrapper" :class="{ overflowdiv: view }" v-show="showOptions">
            
            <div :class="{ text1: show }" ref="xiala" class="option-item" v-for="(item, index) in subject" :key="index"
                @click="choose(item)">
                {{ item.text }}
            </div>
        </div> -->
    </div>
</template>
<script>
    /* eslint-disable */
    export default {
        name: "DropDownList",
        data() {
            return {
                selected: {
                    text: "",
                },
                editText: "",
                showOptions: false,
            };
        },
        props: {
            view: {
                type: Boolean,
                default: false,
            },
            show: {
                type: Boolean,
                default: false,
            },
            editable: {
                type: Boolean,
                default: false,
            },
            selectWidth: {
                type: Number,
                default: 100,
            },
            //   接受父组件传过来的值
            subject: {
                type: Array,
                default: [{
                        value: "1",
                        text: "类型一"
                    },
                    {
                        value: "2",
                        text: "类型二"
                    },
                    {
                        value: "3",
                        text: "类型三"
                    },
                ],
            },
            selectContent: undefined,
            selectText: "",
        },
        created() {},
        mounted() {
            if (this.selectContent !== undefined) {
                this.selectByProp(this.selectContent);
            } else {
                this.editText = this.selectText;
            }

            let self = this;

            function domContain(dom, child) {
                if (dom === child) return true;
                if (!child) return false;
                return domContain(dom, child.parentElement);
            }
            //添加一个发生在dom元素外部的事件
            function addOutterEventListener(dom, name, callback) {
                return document.body.addEventListener(name, (e) => {
                    e = e || event;
                    //判断event是否发生在dom内部
                    if (!domContain(dom, e.target)) {
                        if (callback) callback(dom);
                    }
                });
            }

            //添加一个外部事件，如果点击后隐藏
            addOutterEventListener(this.$el, "mousedown", () => {
                this.showOptions = false;
                // console.log(this.selected.text);
                // if (this.editable) this.$emit("update:selectContent", this.editText);
            });
        },
        watch: {
            selectContent: function (newv) {
                //if (sel >= 0) this.selected = this.subject[sel];
                //this.choose(this.selected);
                console.log(newv);
                this.selectByProp(newv);
            },
            selectText: function (newv) {
                this.editText = newv;
            },
            editText: function (newv) {
                this.$emit("update:selectText", newv);
            },
        },
        methods: {
            selectByProp(str) {
                // if (this.editable) {
                //   return;
                // }
                this.selected = {
                    text: "",
                };
                var sel = this.subject.findIndex((ele) => {
                    return ele.value === str;
                });
                if (sel >= 0) {
                    this.selected = this.subject[sel];
                    this.editText = this.selected.text;
                }

                //this.$emit("update:selectContent", this.selected.value);
            },
            triggerOption() {
                this.showOptions = true;
            },
            choose(item) {
                this.selected = item;
                this.editText = item.text;
                this.$emit("update:selectContent", item.value);
                //this.$emit("change", item.value);
                console.log("this.selected", this.selected);
                this.showOptions = false;
            },
        },
    };
</script>
<style scoped>
    .overflowdiv {
        height: 150px !important;
        overflow: auto !important;
    }

    .selectWrap {
        width: 100%;
        position: relative;
    }

    .select {
        width: 100%;
        cursor: default;
        font-size: 13px;
        display: flex;
        justify-content: stretch;

    }

    .select-content {
        text-align: center;
        width: 100%;
        flex-grow: 1;
    }

    .select-content>input .triangle-wrapper {
        width: 20px;
        flex-grow: 0;
    }

    .option-wrapper {
        width: 100%;
        max-height: 150px;
        overflow: auto;
        position: absolute;
        margin-top: 5px;
        border-right: 1px solid rgba(0, 0, 0, 0.16);
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        border-left: 1px solid rgba(0, 0, 0, 0.16);
        background-color: var(--FormBGColor);
        z-index: 1;
    }

    .option-item {
        background: var(--FormBGColor);
        height: 20px;
        line-height: 20px;
        /* padding-right: 10px; */
        text-align: left;
        cursor: pointer;
        color: #ccc;
    }

    .option-item:hover {
        color: #ccc;
        background: #288DFB;

    }

    .text {
        display: inline-block;
        width: 100%;
        text-align: center;
        border: none;
        border-style: none;
    }

    .text1 {
        text-align: center;
    }
</style>

<template>
    <div class="property">
        <div class="row">
            <label class="label">是否泛光</label>
            <input class="bty-switch" type="checkbox" v-model="enabled">
        </div>
        <div class="row">
            <label class="label">仅发光图:</label>
            <input class="bty-switch" type="checkbox" v-model="glowOnly">
        </div>
        <div class="row">
            <label class="label" @click="contrast=128">对比度:</label>
            <input type="range" min="0" max="255" step="1" v-model.number="contrast">
        </div>
        <div class="row">
            <label class="label" @click="brightness=-0.3">亮度:</label>
            <input type="range" min="-5" max="5" step="0.1" v-model.number="brightness">
        </div>
        <div class="row">
            <label class="label" @click="delta=1">delta:</label>
            <input type="range" min="0" max="5" step="0.1" v-model.number="delta">
        </div>
        <div class="row">
            <label class="label" @click="sigma=3.78">sigma:</label>
            <input type="range" min="0" max="5" step="0.1" v-model.number="sigma">
        </div>

        <div class="row">
            <label class="label" @click="stepSize=5.0">stepSize:</label>
            <input type="range" min="1" max="10" step="0.1" v-model.number="stepSize">
        </div>
    </div>
</template>

<script>
    /* eslint-disable */

    export default {
        data() {
            return {
                enabled: true,
                glowOnly: false,
                contrast: 128,
                brightness: -0.3,
                delta: 1.0,
                sigma: 3.78,
                stepSize: 5.0
            };
        },
        components: {

        },
        mounted() {
            this.bind("enabled");
            this.bind("glowOnly");
            this.bind("contrast");
            this.bind("brightness");
            this.bind("delta");
            this.bind("sigma");
            this.bind("stepSize");
        },
        methods: {
            bind(prp) {
                console.log("绑定")
                this._viewUnbinds = this._viewUnbinds || [];
                this._viewUnbinds.push(
                    XE.MVVM.bind(this, prp, this.$root._earth.postProcess.bloom, prp)
                );
            },
            cancel() {
                this.$root.destroyTool(this)
            },
            ok() {
                this.$root.destroyTool(this)
            },
        },
    };
</script>

<style scoped>
    .property {
        width: 300px;
        height: 100%;
        background: var(--PanelBGColor);
    }

    .label {
        display: inline-block;
        width: 60px;
        text-align: right;
        margin-right: 5px;
        vertical-align: middle;
        line-height: 29px;
    }

    .row {
        display: flex;
        margin-top: 10px;
    }
</style>
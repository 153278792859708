<template>
    <div class="crouse">
        <ul>
            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347852206&page=1')">
                <img src="../assets/video1.png" alt="" width="200" height="100">
                <p>1.Pipeser整体介绍</p>
            </li>

            <li
                @click="sendVideo( '//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347851424&page=2')">
                <img src="../assets/video2.png" alt="" width="200" height="100">
                <p>2.PipeSer编辑器布局</p>
            </li>

            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347853820&page=3')">
                <img src="../assets/video3.png" alt="" width="200" height="100">
                <p>3.PipeSer基础场景配置</p>
            </li>

            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347853980&page=4')">
                <img src="../assets/video4.png" alt="" width="200" height="100">
                <p>4.PipeSer单个管点管线的编辑</p>
            </li>

            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347852004&page=5')">
                <img src="../assets/video5.png" alt="" width="200" height="100">
                <p>5.PipeSer修改附属物以及修改材质</p>
            </li>

            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347851680&page=6')">
                <img src="../assets/video6.png" alt="" width="200" height="100">
                <p>6.PipeSer导入管点</p>
            </li>

            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347852099&page=7')">
                <img src="../assets/video7.png" alt="" width="200" height="100">
                <p>7.PipeSer导入管线</p>
            </li>

            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347852292&page=8')">
                <img src="../assets/video8.png" alt="" width="200" height="100">
                <p>8.PipeSer图层配置</p>
            </li>

            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347853452&page=9')">
                <img src="../assets/video9.png" alt="" width="200" height="100">
                <p>9.PipeSer管点管理</p>
            </li>

            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347853140&page=10')">
                <img src="../assets/video10.png" alt="" width="200" height="100">
                <p>10.PipeSer管线管理</p>
            </li>
            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347853292&page=11')">
                <img src="../assets/video11.png" alt="" width="200" height="100">
                <p>11.PipeSer数值分析</p>
            </li>

            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347852676&page=12')">
                <img src="../assets/video12.png" alt="" width="200" height="100">
                <p>12.PipeSer鼠标交互</p>
            </li>

            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347853767&page=13')">
                <img src="../assets/video13.png" alt="" width="200" height="100">
                <p>13.PipeSer项目支撑</p>
            </li>

            <li
                @click="sendVideo('//player.bilibili.com/player.html?aid=503498396&bvid=BV1Eg411G7qW&cid=347853842&page=14')">
                <img src="../assets/video14.png" alt="" width="200" height="100">
                <p>14.PipeSer状态栏</p>
            </li>
        </ul>
    </div>
</template>

<script>
    import VideoIframe from "./VideoIframe"
    export default {
        components: {
            VideoIframe
        },
        methods: {
            // src="//player.bilibili.com/player.html?aid=588470564&bvid=BV1MB4y1g7wy&cid=347788694&page=1"
            sendVideo(src) {
                this.$root.showPropertyWindow(
                    "VideoIframe", {
                        component: VideoIframe,
                    },
                    src
                );
            }
        }
    }
</script>

<style scoped>
    .crouse {
        color: #CCCCCC;
        width: var(--RightWidth);
        padding-top: 20px;
        height: 100%;
        background: var(--PanelBGColor);
        overflow: auto;
    }

    .crouse>ul {
        margin-bottom: 70px;
    }

    .crouse>ul>li {
        padding: 5px;
        text-align: center;
        cursor: pointer;
    }

    .crouse>ul>li>img {
        border: 3px solid #ccc;
    }

    .crouse>ul>li>img:hover {
        border: 3px solid #288dfb;
    }
</style>
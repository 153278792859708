<template>
    <div class="xbsj-color">
        <div @click="showPicker" ref="bgColor" :style="{ backgroundColor: colorRGBA }"></div>
        <ColorPicker class="picker" :color="colorRGBA" @changed="pickerChanged" v-show="pickerShow" ref="picker">
        </ColorPicker>
    </div>
</template>
<script>
    function domContain(doms, child) {
        if (!child) return false;

        if (doms instanceof Array) {
            for (var i = 0; i < doms.length; i++) {
                if (doms[i] === child) return true;
            }
        } else if (doms === child) return true;

        return domContain(doms, child.parentElement);
    }
    //添加一个发生在dom元素外部的事件
    function addOutterEventListener(doms, name, callback) {
        return document.body.addEventListener(name, (e) => {
            e = e || event;
            //判断event是否发生在dom内部
            if (!domContain(doms, e.target)) {
                if (callback) callback(doms);
            }
        });
    }

    import ColorPicker from "../ColorPicker/ColorPicker";
    export default {
        components: {
            ColorPicker,
        },
        props: {
            color: {
                type: Object,
                default () {
                    return {
                        r: 255,
                        g: 255,
                        b: 255,
                        a: 255,
                    };
                },
            },
        },
        data() {
            return {
                pickerShow: false,
                colorRGBA: "",
            };
        },
        created() {

        },
        mounted() {
            addOutterEventListener(
                [this.$refs.bgColor, this.$refs.picker.$el],
                "click",
                () => {
                    //console.log("mousedown");
                    this.pickerShow = false;
                }
            );
            this.colorRGBA = `rgba(${[
      this.color.r,
      this.color.g,
      this.color.b,
      this.color.a,
    ].join(",")})`;
        },
        methods: {
            pickerChanged(color) {
                var rgba = color.rgba;
                this.$emit("update:color", rgba);
                this.colorRGBA = `rgba(${[rgba.r, rgba.g, rgba.b, rgba.a].join(",")})`;
            },
            showPicker() {
                this.pickerShow = !this.pickerShow;
            },
        },
        watch: {
            color: {
                deep: true,
                handler(c) {
                    this.pickerChanged({
                        rgba: c
                    });
                },
            }
        },
    };
</script>
<style scoped>
    .xbsj-color {
        position: relative;
    }

    .xbsj-color div:nth-child(1) {
        width: 30px;
        height: 30px;
        border: none;
        outline: none;
        position: relative;
        border: 1px solid black;
        bottom: 1px;
    }

    .picker {
        left: 10px;
        top: 30px !important;
        z-index: 20000;
    }
</style>
<template>
  <div>
    <Window :title="'批量修改管点高度'" :footervisible="true" @ok="ok" @cancel="cancel" v-show="show" :width="420"
      :height="340">
      <div class="editPoint">
        <!-- <label>请拖拽移动管点</label>
                <div class="editor">
                    <label>x增量:</label>
                    <input v-model.number="x" v-number-fixed="6">
                </div>
                <div>
                    <label>y增量:</label>
                    <input v-model.number="y" v-number-fixed="6">
                </div> -->
        <div>
          <label><input type="checkbox" v-model="updatebyline" />根据相连管线端点埋深调整高度</label>
        </div>
        <div>
          <label>高度增量:</label>
          <input v-model.number="z" v-number-fixed="2">
        </div>
        <br />

      </div>
      <div class="editPoint">
        <!-- <label>请拖拽移动管点</label>
                <div class="editor">
                    <label>经度:</label>
                    <input v-model.number="position[0]" v-number-fixed="6">
                </div>
                <div>
                    <label>纬度:</label>
                    <input v-model.number="position[1]" v-number-fixed="6">
                </div>
                <div>
                    <label>高度:</label>
                    <input v-model.number="position[2]" v-number-fixed="2">
                </div> -->
        <!-- <div>
          <button @click="cancel">取消</button>
          <button @click="ok">确定</button>
        </div> -->
      </div>

    </Window>
  </div>
</template>
<script>
/* eslint-disable */
import Window from '../../../components/Window'

export default {
  components: {
    Window
  },
  data() {
    return {
      show: true,
      position: [0, 0, 0],
      updatebyline: false,
      x: 0,
      y: 0,
      z: 0
    }
  },
  props: {
    param: {
      type: Object,
      default: undefined
    }
  },
  mounted() {
    this.createTempModel()
    this.createTempLine()
  },
  watch: {
    position: {
      deep: true,
      handler(c) {
        this.x = this.position[0] - this.param.point.geom.coordinates[0]
        this.y = this.position[1] - this.param.point.geom.coordinates[1]
        this.z = this.position[2] - this.param.point.geom.coordinates[2]
      }
    }
  },
  methods: {
    createTempModel() {
      this._temmodel = this.$root.$createLabel('管点:' + this.param.point.id)
      var coordinate = this.param.point.geom.coordinates
      this._temmodel.position = [(coordinate[0] * Math.PI) / 180, (coordinate[1] * Math.PI) / 180, coordinate[2] * 1]
      this._temmodel.editing = true
      this._positionBind = XE.MVVM.bindPosition(this, 'position', this._temmodel, 'position')
    },
    createTempLine() {
      let self = this
      this._temLines = []
      let params = {
        projectid: this.$root.projectid,
        id: this.param.point.id
      }
      this.$axios
        .get('/project/line/connected/point', {
          params
        })
        .then(res => {
          var lines = res.data.data
          // var coordinate = this.dataPoint.geom.coordinates;
          lines.forEach(element => {
            var line = new XE.Obj.Polyline(this.$root._earth)
            line.positions.push(self._temmodel.position)
            line.positions.push([(element.x * Math.PI) / 180, (element.y * Math.PI) / 180, element.z])
            self._temLines.push(line)
          })
        })
        .catch(err => {
          console.log(err.response || err)
          this.$root.$log('error', err)
        })
    },

    ok() {
      this.$root.destroyTool(this)
      this.param.ok({ x: this.x, y: this.y, z: this.z, updatebyline: this.updatebyline })
    },
    cancel() {
      this.$root.destroyTool(this)
    }
  }
}
</script>
<style scoped>
.appendages_top {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
}

.appendages_top>input {
  box-sizing: border-box;
  width: 430px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  margin-right: 4px;
}

.appendages_top>button {
  margin-right: 4px;
}

.appendages_content {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 100px);
  overflow: auto;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.appendages_content>div {
  width: 100px;
  height: 132px;
  margin-left: 12px;
}

.thumbnail {
  position: relative;
  width: 100px;
  height: 100px;
  border: 2px solid #1a1a1a;
  opacity: 1;
  margin-bottom: 8px;
}

.thumbnail>span {
  width: 100%;
  height: 20px;
  line-height: 20px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  background: #000;
  opacity: 0.5;
  text-align: center;
  color: #ccc;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.operation {
  display: flex;
  justify-content: space-around;
}

.operation>img {
  cursor: pointer;
}

.appendages_content>div:last-child:hover {
  cursor: pointer;
}

.param,
.static {
  width: 100%;
  height: 100%;
}

.param {
  display: flex;
}

.param_left {
  width: 60%;
}

.param_right {
  width: 40%;
  overflow: auto;
}

.tab_static,
.tab_param {
  background: #295f99;
}

.cesiumContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #242424;
}

.parameters_top {
  display: flex;
  height: 28px;
  align-items: center;
  justify-content: center;
}

.parameters_top>div:nth-child(2) {
  display: flex;
  border: 1px solid #999595;
  flex-grow: 1;
  height: 24px;
  align-items: center;
}

.parameter_content {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.parameter_content>div:nth-child(1) {
  width: 670px;
  flex-grow: 0;
  border-right: none;
  height: 100%;
}

.parameter_content>div:nth-child(2) {
  width: 300px;
  flex-grow: 1;
  height: 100%;
}

.right {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
  padding-top: 10px;
}

.right>select {
  width: 220px;
  height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.right>label {
  width: 110px;
  text-align: center;
}

.right>button,
.right>input {
  width: 220px;
  height: 30px;
  line-height: 30px;
  background: #383838;
  border: 1px solid #4d4d4d;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.preview {
  width: 220px;
  height: 30px;
  background: #383838;
  opacity: 1;
  border-radius: 2px;
  color: #ccc;
  text-align: center;
}

.jsoncontent {
  width: 100%;
  font-size: 16px;
  margin-top: 30px;
  word-wrap: break-word;
  margin-bottom: 40px;
}
</style>

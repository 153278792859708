<template>
    <div v-show="show" :style="position" class="menu-box" @contextmenu="contextmenu">
        <slot name="above"></slot>
        <ul class="list-unstyled">
            <li v-for="(it, i) in items" :key="i" @click="click(it)" :style="{ itemborder: it.border }"
                :class="{ divideron: it.type == 'divider' }">
                <span v-if="it.type !== 'divider'">{{ it.text }}</span>
                <span v-if="it.type !== 'divider'" style="float: right; display: block">{{ it.keys }}</span>
            </li>
        </ul>
        <slot name="below"></slot>
    </div>
</template>
<script>
    export default {
        props: {
            domID: {
                type: String,
                default: "app"
            }
        },
        data() {
            return {
                items: [],
                show: false,
                cursor: {
                    x: 0,
                    y: 0
                },
                position: "left:0px;top:0px;"
            };
        },
        mounted() {
            /*
            //防止浏览器默认菜单
            document.body.oncontextmenu = () => {
              return false;
            };
            */
            if (!this.$root.$el) return;
            this.watchDom = this.$root.$el.parentElement;
            if (this.domID) this.watchDom = document.getElementById(this.domID);
            //监控鼠标位置
            document.body.addEventListener("mousemove", event => {
                let left = this.watchDom.offsetLeft;
                let top = this.watchDom.offsetTop;

                this.cursor = {
                    x: event.clientX - left,
                    y: event.clientY - top
                };
            });

            function domContain(dom, child) {
                if (dom === child) return true;
                if (!child) return false;
                return domContain(dom, child.parentElement);
            }
            //添加一个发生在dom元素外部的事件
            function addOutterEventListener(dom, name, callback) {
                return document.body.addEventListener(name, e => {
                    e = e || event;
                    //判断event是否发生在dom内部
                    if (!domContain(dom, e.target)) {
                        if (callback) callback(dom);
                    }
                });
            }

            //添加一个外部事件，如果点击后隐藏
            addOutterEventListener(this.$el, "pointerdown", () => {
                this.show = false;
            });

            this.$root.$contextMenu = this;
        },
        methods: {
            contextmenu($event) {
                $event.stopPropagation();
                $event.preventDefault();
            },
            click(it) {
                if (typeof it.func === "function") {
                    it.func();
                }
                this.show = false;
                this.items = [];
            },
            pop(items, x, y) {
                console.log('context menu:', x, y);
                if (this.show) return;
                this.items = items;
                this.show = true;
                if (x == undefined) x = this.cursor.x;
                if (y == undefined) y = this.cursor.y;
                let bottom = 0;
                items.forEach(element => {
                    if (element.type === "divider") {
                        bottom += 2;
                    } else {
                        bottom += 30;
                    }
                });
                if (this.watchDom.clientHeight < y + bottom) {
                    y = y - bottom;
                }
                if (this.watchDom.clientWidth < x + 150) {
                    x = x - 150;
                }
                this.position = `left:${x}px; top:${y}px;`;
            },
            close() {
                if (!this.show) return;
                this.show = false;
                this.items = [];
            }
        },
        unmounted() {
            document.body.oncontextmenu = null;
        }
    };
</script>

<style scoped>
    /* .divideron:hover {
  background: none !important;
} */
    .divideron {
        height: 2px !important;
        width: 100% !important;
        line-height: 2px !important;
        background: var(--BackgroundColor);
    }

    .itemborder {
        border-top: 2px solid #6b6b6b;
    }

    .menu-divider {
        width: 100%;
        height: 2px;
        background: red;
    }

    .menu-box {
        width: 150px;
        /* padding: 5px 10px; */
        position: absolute;
        z-index: 10000;
        left: 100px;
        right: 100px;
        background: var(--PanelBGColor);
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.4);
        color: #ccc
    }

    .menu-box>ul {
        margin: auto;
    }

    .menu-box ul li {
        cursor: pointer;
        height: 30px;
        line-height: 30px;
        padding: 0px 10px;
        box-sizing: border-box;
        list-style: none;
    }

    /* .menu-box ul li+li {
        border-top: 1px solid;
    } */

    .menu-box ul li:hover {
        background: #288DFB;
        opacity: 0.5;
    }

    .menu-box ul li span {
        display: inline-block;
    }

    .menu-box ul li span:first-child {
        margin-right: 5px;
    }
</style>